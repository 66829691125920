import * as React from 'react';
import { Formik, Form, FormikHandlers } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import _ from 'lodash';

import { Panel, PanelBody, PanelHeader } from 'Components/panels';
import Button from 'Components/button';
import CheckboxFancy from 'Components/checkboxFancy';

import SelectControl from '../field/selectControl';
import styles from './settingsSupplierForm.module.scss';
import Field from '../field/field';
import InputControl from '../field/inputControl';

const TYPE_IDENTIFICATIONS: string[] = [
  'SIRET',
  'UE_HORS_FRANCE',
  'HORS_UE',
  'RIDET',
  'TAHITI',
  'AUTRE',
  'PARTICULIER',
];

const TYPES: string[] = ['FOURNISSEUR', 'VALIDATEUR'];

const SettingsSupplierForm = (props: {
  data: any;
  submitLabel: string;
  onSubmit: (values: any, actions: FormikHandlers) => Promise<void>;
}) => {
  const [checked, setChecked] = React.useState(!_.isEqual(props.data.adresseLegal, props.data.adresse));
  const toogledChecked = React.useCallback(() => setChecked(!checked), [checked]);
  const getSchema = React.useCallback(
    () =>
      Yup.object().shape(
        _.extend(
          {
            code: Yup.string().required('Obligatoire'),
            typeIdentification: Yup.string().required('Obligatoire').oneOf(TYPE_IDENTIFICATIONS),
            type: Yup.string().required('Obligatoire').oneOf(TYPES),
            identification: Yup.string().required('Obligatoire'),
            nom: Yup.string().required('Obligatoire'),
            nomLegal: Yup.string().nullable(),
            adresse: Yup.object().shape({
              rue: Yup.string().required('Obligatoire'),
              codePostal: Yup.string().length(5, 'Le code postal doit avoir exactement 5 caractères.'),
              ville: Yup.string().required('Obligatoire'),
              pays: Yup.string().required('Obligatoire'),
            }),
            codeService: Yup.string().nullable(),
            service: Yup.string().nullable(),
            contact: Yup.object().shape({
              nom: Yup.string().nullable(),
              telephone: Yup.string().nullable(),
              telecopie: Yup.string().nullable(),
              courriel: Yup.string().nullable().email('Email invalide'),
            }),
          },
          // tslint:disable: ter-indent
          checked
            ? {
                adresseLegal: Yup.object().shape({
                  rue: Yup.string().required('Obligatoire'),
                  codePostal: Yup.string().length(5, 'Le code postal doit avoir exactement 5 caractères.'),
                  ville: Yup.string().required('Obligatoire'),
                  pays: Yup.string().required('Obligatoire'),
                }),
              }
            : {},
          // tslint:enable: ter-indent
        ),
      ),
    [checked],
  );
  const onSubmit = React.useCallback(
    (values, actions) => {
      const schema = getSchema();
      const enhancedValues = _.merge({}, values, !checked ? { adresseLegal: values.adresse } : {});
      schema
        .isValid(enhancedValues)
        .then(() => props.onSubmit(enhancedValues, actions))
        .finally(() => actions.setSubmitting(false));
    },
    [checked, getSchema, props],
  );
  return React.useMemo(
    () => (
      <Formik initialValues={props.data} validationSchema={getSchema()} onSubmit={onSubmit}>
        {({ errors, touched }) => (
          <Form>
            <Panel>
              <PanelHeader>Emetteur</PanelHeader>
              <PanelBody disablePadding>
                <Field
                  label="Code émetteur"
                  help="Un code interne identifiant de manière unique cet émetteur de facture"
                  required
                  inline
                >
                  <InputControl type="text" name="code" errors={errors} touched={touched} />
                </Field>
                <Field label="Type" help="S'agit il d'un Fournisseur ou d'un Valideur." required inline>
                  <SelectControl
                    name="type"
                    options={TYPES.map(v => ({
                      value: v,
                      label: v,
                    }))}
                  />
                </Field>
                <Field
                  label="Identification"
                  help="L'identifiant de la société émettant la facture. Par exemple son SIRET"
                  required
                  inline
                >
                  <SelectControl
                    name="typeIdentification"
                    options={TYPE_IDENTIFICATIONS.map(v => ({
                      value: v,
                      label: v,
                    }))}
                  />
                  <InputControl type="text" name="identification" errors={errors} touched={touched} />
                </Field>
                <Field label="Nom de la société" help="Le nom commercial de la société" required inline>
                  <InputControl type="text" name="nom" errors={errors} touched={touched} />
                </Field>
                <Field
                  label="Nom légal"
                  help="La dénomination sociale de la société. Elle peut être identique au nom de la société"
                  inline
                >
                  <InputControl type="text" name="nomLegal" errors={errors} touched={touched} />
                </Field>
                <Field label="Code Service" help="Le code du service émetteur de la facture" inline>
                  <InputControl type="text" name="codeService" errors={errors} touched={touched} />
                </Field>
                <Field label="Nom du Service" help="Le nom du service émetteur de la facture" inline>
                  <InputControl type="text" name="nomService" errors={errors} touched={touched} />
                </Field>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader>Adresse de facturation</PanelHeader>
              <PanelBody disablePadding>
                <Field label="Rue" help="L'adresse de la société" required inline>
                  <InputControl type="text" name="adresse.rue" errors={errors} touched={touched} />
                </Field>
                <Field label="Code postal" required inline>
                  <InputControl type="text" name="adresse.codePostal" errors={errors} touched={touched} />
                </Field>
                <Field label="Ville" required inline>
                  <InputControl type="text" name="adresse.ville" errors={errors} touched={touched} />
                </Field>
                <Field label="Pays" help="Le code du pays. Par exemple FR" required inline>
                  <InputControl type="text" name="adresse.pays" errors={errors} touched={touched} />
                </Field>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader>
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={toogledChecked}
                >
                  <div
                    className={classnames(styles['checkbox-wrapper'], {
                      [styles.checked]: checked,
                    })}
                  >
                    <CheckboxFancy className={classnames(styles.checkbox)} checked={checked} />
                  </div>
                  <div>Adresse du siège social (cocher si différente)</div>
                </div>
              </PanelHeader>
              {checked && (
                <PanelBody disablePadding>
                  <Field label="Rue" help="L'adresse du siège social" required inline>
                    <InputControl type="text" name="adresseLegal.rue" errors={errors} touched={touched} />
                  </Field>
                  <Field label="Code postal" required inline>
                    <InputControl type="text" name="adresseLegal.codePostal" errors={errors} touched={touched} />
                  </Field>
                  <Field label="Ville" required inline>
                    <InputControl type="text" name="adresseLegal.ville" errors={errors} touched={touched} />
                  </Field>
                  <Field label="Pays" help="Le code du pays. Par exemple FR" required inline>
                    <InputControl type="text" name="adresseLegal.pays" errors={errors} touched={touched} />
                  </Field>
                </PanelBody>
              )}
            </Panel>
            <Panel>
              <PanelHeader>Contact</PanelHeader>
              <PanelBody disablePadding>
                <Field label="Nom" inline>
                  <InputControl type="text" name="contact.nom" errors={errors} touched={touched} />
                </Field>
                <Field label="Téléphone" inline>
                  <InputControl type="text" name="contact.telephone" errors={errors} touched={touched} />
                </Field>
                <Field label="Fax" inline>
                  <InputControl type="text" name="contact.telecopie" errors={errors} touched={touched} />
                </Field>
                <Field label="Courriel" inline>
                  <InputControl type="text" name="contact.courriel" errors={errors} touched={touched} />
                </Field>
              </PanelBody>
            </Panel>
            <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'flex-end' }}>
              <Button size="small" priority="default" style={{ marginRight: '8px' }} to="/settings/suppliers">
                Annuler
              </Button>
              <Button type="submit" size="small" priority="primary">
                {props.submitLabel}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    ),
    [checked, getSchema, onSubmit, props.data, props.submitLabel, toogledChecked],
  );
};

export default SettingsSupplierForm;
