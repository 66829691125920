import React, { FC, ComponentType, useMemo, useCallback } from 'react';
import _ from 'lodash';

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import { DYNAMIC_FEATURE_FLAG } from 'Flags';
import useSessionStorage from 'UtilHooks/useSessionStorage';

type WrappedComponentProps<T = any> = {
  flags: DYNAMIC_FEATURE_FLAG[];
  component: ComponentType<T>;
  componentProps?: T;
};
const WrappedComponent: FC<WrappedComponentProps> = ({ flags, component, componentProps }) => {
  const [token] = useSessionStorage('token');
  const { data, error, loading }: any = useQuery(
    gql`
      query {
        me {
          id
          featureFlags
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
    },
  );
  const renderError = useCallback(() => null, []);
  const renderLoading = useCallback(() => null, []);
  const renderNothing = useCallback(() => null, []);
  const render = useCallback(() => {
    const { children, ...props } = componentProps;
    return React.createElement(component, props, children);
  }, [component, componentProps]);
  return useMemo(() => {
    if (loading) return renderLoading();
    if (_.isError(error)) return renderError();
    if (data && _.some(flags, flag => _.includes(data.me.featureFlags, flag))) return render();
    return renderNothing();
  }, [data, error, flags, loading, render, renderError, renderLoading, renderNothing]);
};

export default (...flags: DYNAMIC_FEATURE_FLAG[]) => (Cmp: ComponentType<any>) => (props: any) =>
  useMemo(() => <WrappedComponent flags={flags} component={Cmp} componentProps={props} />, [props]);
