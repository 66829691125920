import * as React from 'react';
import classNames from 'classnames';

import styles from './panel.module.scss';

interface PanelHeaderProps {
  disablePadding?: boolean;
  hasButtons?: boolean;
  noBorder?: boolean;
  children: React.ReactNode;
  [propName: string]: any;
}

const PanelHeader = (props: PanelHeaderProps) => {
  const { children, disablePadding, hasButtons, noBorder, ...moreProps } = props;
  return (
    <div
      className={classNames(styles.panelHeader, {
        [styles.hasButtons]: hasButtons,
        [styles.disablePadding]: disablePadding,
        [styles.noBorder]: noBorder,
      })}
      {...moreProps}
    >
      {children}
    </div>
  );
};

export default PanelHeader;
