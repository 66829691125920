import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';

import { AppRouteProps } from 'Routes';
import { AppLayoutProps } from 'Layouts';
import useSessionStorage from 'UtilHooks/useSessionStorage';
import NoLayout from 'Layouts/noLayout';
import SentryErrorBoundary from 'Components/sentryErrorBoundary';

export interface AppRouterProps {
  history?: History;
  routes?: AppRouteProps[];
}

class AppRouter extends React.PureComponent<AppRouterProps> {
  public render() {
    const { history, routes, children } = this.props;
    return (
      history && (
        <ConnectedRouter history={history}>
          <>
            <SentryErrorBoundary>{children}</SentryErrorBoundary>
            <Switch>
              {routes &&
                routes.length > 0 &&
                routes.map((routeProps, index) => {
                  const { layout, view, isPublic, redirectTo, ...props } = routeProps;
                  return (
                    <Route
                      // eslint-disable-next-line react/no-array-index-key
                      key={`route-${index}`}
                      component={() => {
                        const [currentToken] = useSessionStorage('token');
                        if (!isPublic && !currentToken) return <Redirect to="/login" />;
                        if (redirectTo) return <Redirect to={redirectTo} />;
                        return (
                          <SentryErrorBoundary>
                            {React.createElement<AppLayoutProps>(layout || NoLayout, {
                              view,
                            })}
                          </SentryErrorBoundary>
                        );
                      }}
                      {...props}
                    />
                  );
                })}
            </Switch>
          </>
        </ConnectedRouter>
      )
    );
  }
}

export default AppRouter;
