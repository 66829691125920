import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import theme from 'Utils/theme';
import styles from './badgeDisplayName.module.scss';

type BadgeDisplayNameProps = {
  hideOverflow?: string | boolean;
  className?: string;
};

const BadgeDisplayName: React.FunctionComponent<BadgeDisplayNameProps> = ({ hideOverflow, className, ...props }) => (
  <span
    className={classnames(styles.root, { [styles.hideOverflow]: hideOverflow }, className)}
    style={{
      maxWidth: _.isString(hideOverflow) ? hideOverflow : theme.settings.maxCrumbWidth,
    }}
    {...props}
  />
);
export default BadgeDisplayName;
