import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

const FieldWrapper = (props: any) => {
  const { children, className, style, ...otherProps } = props;
  return (
    <div
      className={classnames(
        styles['field-wrapper'],
        {
          [styles.highlighted]: otherProps.highlighted,
          [styles.inline]: otherProps.inline,
          [styles.stacked]: otherProps.stacked,
          [styles.withoutSeparator]: !!otherProps.withoutSeparator,
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default FieldWrapper;
