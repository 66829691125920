import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import styles from 'Components/toast/toast.module.scss';
import InlineSvg from 'Components/inlineSvg';
import { TOAST_TYPE } from 'Constants';
import { ToastContainerProps, defaultProps } from 'Components/toast/toastContainer';

export function notification(
  component: string | React.ComponentType<any>,
  options: {
    toastType?: TOAST_TYPE;
    fallbackComponent?: string | React.ComponentType<any>;
    toastId?: string | number;
    isUnique?: boolean;
    onNewToastId?: (newToastId: string | number) => void;
    props?: ToastContainerProps;
  } = {},
) {
  const {
    toastId,
    fallbackComponent,
    toastType = TOAST_TYPE.DEFAULT,
    isUnique = false,
    onNewToastId = () => null,
    props = {},
  } = options;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (isUnique && toastId && !toast.isActive(toastId)) {
    onNewToastId(_.get(toast, toastType.toLowerCase(), toast)(fallbackComponent || component, props));
  }
  let toastOptions: any = _.merge({}, defaultProps, props);
  if (toastId) toastOptions = { ...toastOptions, toastId };
  onNewToastId(_.get(toast, toastType.toLowerCase(), toast)(component, toastOptions));
}

const notify = (message: string, success = true) => {
  notification(
    () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ color: success ? 'green' : 'red', marginRight: '8px' }}>
          <InlineSvg src={success ? 'icon-circle-check' : 'icon-circle-close'} size="24px" />
        </div>
        <div>
          <span>{message}</span>
        </div>
      </div>
    ),
    {
      props: {
        closeButton: <></>,
        className: classnames(styles.toast),
        draggable: false,
        hideProgressBar: true,
      },
    },
  );
};
notify.success = (message: string) => notify(message);
notify.error = (message: string) => notify(message, false);

export default notify;
