import * as React from 'react';

import styles from './textOverflow.module.scss';

const TextOverflow = (props: any) => {
  const { children } = props;
  return <div className={styles.textOverflow}>{children}</div>;
};

export default TextOverflow;
