import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
// @ts-ignore
import { Vega } from 'react-vega';
// @ts-ignore
import { Handler } from 'vega-tooltip';
// @ts-ignore
import { withResizeDetector } from 'react-resize-detector';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import EmptyStateWarning from 'Components/emptyStateWarning';

import styles from './dashboard.module.scss';

const moment: any = extendMoment(Moment);

const getSpec: (values: any, sameYear?: boolean) => any = (values, sameYear) => ({
  $schema: 'https://vega.github.io/schema/vega-lite/v3.json',
  description: 'A simple bar chart with embedded data.',
  config: {
    view: {
      stroke: 'transparent',
    },
  },
  width: 600,
  height: 200,
  autosize: { type: 'pad', contains: 'padding', resize: true },
  data: { values },
  encoding: {
    x: {
      field: 'date',
      type: 'ordinal',
      timeUnit: `${!sameYear ? 'year' : ''}month`,
      scale: {
        paddingInner: 0.5,
      },
      axis: {
        title: '',
        labelAngle: 0,
        labelFontSize: 14,
      },
    },
  },
  layer: [
    {
      mark: { type: 'bar', color: '#6c5fc7' },
      encoding: {
        y: {
          field: 'amount',
          type: 'quantitative',
          axis: {
            title: 'Montant facturé',
            grid: true,
            titleFontSize: 14,
            titlePadding: 10,
            labelFontSize: 12,
          },
        },
        tooltip: [
          {
            timeUnit: `${!sameYear ? 'year' : ''}month`,
            field: 'date',
            type: 'ordinal',
          },
          { field: 'amount', type: 'quantitative' },
          { field: 'clients', type: 'quantitative' },
        ],
      },
    },
    {
      mark: {
        type: 'point',
        filled: false,
        fill: 'white',
        stroke: 'orange',
      },
      encoding: {
        y: {
          field: 'clients',
          type: 'quantitative',
          axis: {
            title: 'Nombre de clients facturés',
            titleColor: 'orange',
            grid: false,
            titleFontSize: 14,
            titlePadding: 10,
            labelFontSize: 12,
          },
        },
        tooltip: [
          {
            timeUnit: `${!sameYear ? 'year' : ''}month`,
            field: 'date',
            type: 'ordinal',
          },
          { field: 'amount', type: 'quantitative' },
          { field: 'clients', type: 'quantitative' },
        ],
      },
    },
    {
      mark: {
        type: 'line',
        stroke: 'orange',
        interpolate: 'monotone',
      },
      encoding: {
        y: {
          field: 'clients',
          type: 'quantitative',
          axis: null,
          impute: { method: 'mean' },
        },
      },
    },
  ],
  resolve: { scale: { y: 'independent' } },
});

const InvoiceBarChart = (props: any) => {
  const {
    width,
    data,
    range: { start, end },
    formatTooltip = _.identity,
    ...others
  } = _.omit(props, ['height']);
  if (_.isEmpty(data)) return <EmptyStateWarning message="Aucun résultat" />;
  const values = Array.from(
    moment
      .range(
        _.min([..._.keys(data).map(k => moment(k, 'YYYY-MM-DD')), start]),
        _.max([..._.keys(data).map(k => moment(k, 'YYYY-MM-DD')), end]),
      )
      .snapTo('year')
      .by('month'),
  )
    .map((m: any) => m.format('YYYY-MM-DD'))
    .map((date: any) =>
      _.merge({}, _.get(data, date, { amount: null, clients: null }), {
        date,
      }),
    );
  const [, isSameYear] = _.reduce(
    _.map(values, v => v.date),
    ([year, result], d) => {
      if (_.isNull(year)) return [d, result];
      return [year, result && moment(d).isSame(year, 'year')];
    },
    [null, true],
  );
  return (
    <div className={classnames(styles.body)} style={{ width }}>
      <Vega
        {...others}
        actions={false}
        spec={getSpec(values, isSameYear)}
        width={width * 0.85}
        tooltip={(handler: any, event: any, item: any, value: any) => {
          const newValue = value
            ? _.omitBy(
                formatTooltip(
                  _.omitBy(
                    _.merge(
                      {},
                      {
                        period: _.get(value, `date (${!isSameYear ? 'year-' : ''}month)`),
                        amount: _.get(item, 'datum.amount'),
                      },
                      _.isNumber(_.get(item, 'datum.amount'))
                        ? {
                            clientsCount: _.get(item, 'datum.imputed_clients_value', _.get(item, 'datum.clients')),
                          }
                        : {},
                    ),
                    _.isUndefined,
                  ),
                ),
                _.isUndefined,
              )
            : value;
          return new Handler({
            sanitize: _.identity,
          }).call(handler, event, item, !_.isEmpty(newValue) ? newValue : null);
        }}
      />
    </div>
  );
};

export default withResizeDetector(InvoiceBarChart);
