import moment from 'moment-timezone';

/**
 * Returns user timezone from their account preferences
 */
export default () => {
  // TODO: Add user's preferences to backend
  /* const user = ConfigStore.get('user');
  return user && user.options && user.options.timezone; */
  return moment.tz.guess(true);
};
