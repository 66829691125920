import * as React from 'react';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';
// @ts-ignore
import omitDeep from 'omit-deep-lodash';

import useSessionStorage from 'Utils/hooks/useSessionStorage';
import notify from 'Utils/notify';
import useRouter from 'UtilHooks/useRouter';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';
import LoadingIndicator from 'Components/loadingIndicator';

import SupplierForm from './settingsSupplierForm';
import styles from './settingsSupplierEditView.module.scss';

const SettingsSupplierEditView = () => {
  const [token] = useSessionStorage('token');
  const {
    replace,
    location: { pathname },
  } = useRouter();
  const match: any = matchPath(pathname, {
    path: '/settings/suppliers/:id/edit',
    exact: true,
  });

  let id: any;
  if (!match) {
    id = null;
  } else {
    // eslint-disable-next-line prefer-destructuring
    id = match.params.id;
  }

  const { data, loading, refetch } = useQuery(
    gql`
      query fournisseurById($id: ID!) {
        fournisseurById(id: $id) {
          code
          type
          typeIdentification
          identification
          nom
          codeService
          nomService
          adresse {
            rue
            codePostal
            ville
            pays
          }
          nomLegal
          adresseLegal {
            rue
            codePostal
            ville
            pays
          }
          contact {
            nom
            telecopie
            telephone
            courriel
          }
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    },
  );

  const updateSupplierMutation = useMutation<any>(
    gql`
      mutation updateFournisseur($id: ID!, $supplier: FournisseurInput!) {
        updateFournisseur(id: $id, input: $supplier) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    const updateValues = omitDeep(values, '__typename');
    try {
      const { error }: any = await updateSupplierMutation({
        variables: { id, supplier: updateValues },
      });

      if (error) {
        notify(error.message);
      } else {
        await refetch();
        notify('Emetteur modifié avec succès.');
        replace('/settings/suppliers');
      }
      actions.setSubmitting(false);
    } catch (e) {
      notify('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  const formData = data.fournisseurById;

  return (
    <PageContent style={{ overflow: 'auto' }}>
      <PageHeading withMargins>{`Edition de l'émetteur ou valideur ${formData.nom}`}</PageHeading>
      <div className={classnames(styles['form-wrapper'])}>
        <SupplierForm data={formData} submitLabel="Enregistrer" onSubmit={onSubmit} />
      </div>
    </PageContent>
  );
};
export default SettingsSupplierEditView;
