import * as React from 'react';
import classNames from 'classnames';

import styles from './page.module.scss';

interface HeaderTitleProps {
  children: React.ReactNode;
  className?: string;
}

const HeaderTitle = (props: HeaderTitleProps) => {
  const { children, className } = props;
  return <h4 className={classNames(className, styles.headerTitle)}>{children}</h4>;
};

export default HeaderTitle;
