import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import InlineSvg from 'Components/inlineSvg';
import Link from 'Components/link';
import ExternalLink from 'Components/externalLink';

import styles from './button.module.scss';

type ButtonProps = {
  active?: boolean;
  activable?: boolean;
  priority?: 'default' | 'primary' | 'danger' | 'link' | 'success';
  size?: 'zero' | 'small' | 'xsmall' | 'large';
  disabled?: boolean;
  busy?: boolean;
  /**
   * Use this prop if button is a react-router link
   */
  to?: string;
  /**
   * Use this prop if button should use a normal (non-react-router) link
   */
  href?: string;
  /**
   * Path to an icon svg that will be displayed to left of button label
   */
  icon?: string | ((props: any) => React.ReactNode);
  /**
   * Is an external link? (Will open in new tab)
   */
  external?: boolean;
  /**
   * Button with a border
   */
  borderless?: boolean;
  /**
   * Label for screen-readers (`aria-label`).
   * `children` will be used by default (only if it is a string), but this property takes priority.
   */
  label?: string;
  onClick?: (...args: any[]) => any;
  style?: object;
  invertOnActive?: boolean;
  className?: string;
  type?: 'submit' | 'button';
  withoutBoxShadow?: boolean;
};

const Button: React.FunctionComponent<ButtonProps> = props => {
  // Intercept onClick and propagate
  const handleClick = (...args: any[]) => {
    const { disabled, busy, onClick } = props;

    // Don't allow clicks when disabled or busy
    if (disabled || busy) return;

    if (typeof onClick !== 'function') return;

    onClick(...args);
  };

  const getUrl = (prop: string) => {
    const { disabled } = props;
    if (disabled) return null;
    return prop;
  };

  const render = () => {
    const {
      active,
      activable,
      size,
      to,
      href,
      invertOnActive,
      icon,
      children,
      label,
      borderless,
      priority,

      // destructure from `buttonProps`
      // not necessary, but just in case someone re-orders props
      onClick,
      ...buttonProps
    } = props;

    // For `aria-label`
    const screenReaderLabel = label || (typeof children === 'string' ? children : undefined);

    _.updateWith(buttonProps, 'active', () => (activable && active ? active.toString() : undefined), Object);

    // *Note* you must still handle tabindex manually.
    const button = (
      <StyledButton
        aria-label={screenReaderLabel}
        to={to && getUrl(to)}
        href={href && getUrl(href)}
        size={size}
        priority={priority}
        borderless={borderless}
        {...buttonProps}
        onClick={onClick ? handleClick : undefined}
        role="button"
      >
        <span
          className={classnames(
            styles.label,
            { [styles[`priority-${priority || 'default'}`]]: true },
            priority === 'link' ? styles['size-zero'] : { [styles[`size-${size || 'zero'}`]]: true },
          )}
        >
          {icon && (
            <span className={classnames({ [styles['with-margin']]: !!children })}>
              {typeof icon === 'string' ? (
                <InlineSvg
                  className={classnames(styles.icon)}
                  src={icon}
                  size={size && size.endsWith('small') ? '16px' : '28px'}
                />
              ) : (
                icon({
                  className: classnames(styles.icon),
                  style: {
                    // eslint-disable-next-line no-nested-ternary
                    filter: `invert(${active ? (!invertOnActive ? 0 : 100) : 0}%)`,
                  },
                  size: size && size.endsWith('small') ? '16px' : '28px',
                })
              )}
            </span>
          )}
          {children}
        </span>
      </StyledButton>
    );

    return button;
  };

  return render();
};
export default Button;

const StyledButton = (props: any) => {
  const { className, busy, external, borderless, children, withoutBoxShadow, ...otherProps } = props;
  const classes: any[] = [styles.button];
  if (borderless) classes.push({ [styles.borderless]: true });
  if (busy) classes.push({ [styles.busy]: true });
  if (otherProps.disabled) classes.push({ [styles.disabled]: true });
  if (otherProps.priority) classes.push({ [styles[`priority-${otherProps.priority}`]]: true });
  classes.push(className);
  const cx = classnames(...classes);
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    boxShadow:
      // eslint-disable-next-line no-nested-ternary
      otherProps.disabled || borderless || otherProps.priority === 'link'
        ? 'none'
        : withoutBoxShadow
        ? 'unset'
        : `${otherProps.active ? 'inset' : ''} 0 2px rgba(0, 0, 0, 0.05)`,
  };

  // eslint-disable-next-line react/destructuring-assignment
  if (props.to) {
    return (
      <Link className={cx} style={style} {...otherProps}>
        {children}
      </Link>
    );
  }

  // eslint-disable-next-line react/destructuring-assignment
  if (!props.href || props.type === 'submit') {
    // eslint-disable-next-line
    return (
      // eslint-disable-next-line
      <button className={cx} style={style} {...otherProps}>
        {children}
      </button>
    );
  }

  if (external) {
    return (
      <ExternalLink className={cx} style={style} {...otherProps}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <a className={cx} style={style} {...otherProps}>
      {children}
    </a>
  );
};
