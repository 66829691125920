import * as React from 'react';
import classNames from 'classnames';

import { Field, ErrorMessage, FieldProps } from 'formik';

export interface InputFieldProps {
  name: string;
  errors: any;
  touched: any;
  placeholder: string;
  type?: string;
}

const InputField = (props: InputFieldProps) => {
  const { name, errors, touched, placeholder } = props;
  const hasError = errors[name] && touched[name];
  const type = props.type || 'text'; // eslint-disable-line

  return (
    <>
      <Field
        name={name}
        render={({ field }: FieldProps) => (
          <input
            {...field}
            type={type}
            className={classNames({
              'form-control': true,
              'is-invalid': hasError,
            })}
            placeholder={placeholder}
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={msg => <div className={classNames({ 'invalid-feedback': hasError })}>{msg}</div>}
      />
    </>
  );
};

export default InputField;
