import * as React from 'react';

import { ViewSidebarOptionSectionItem, ViewSidebarSectionItemProps } from './viewSidebarSectionItem';

export type ViewSidebarOptionSectionChildrenGenerator<T = any> = (payload: {
  props: any;
  helpers: {
    buildItem: (options?: {
      props?: ViewSidebarSectionItemProps;
      Component?: React.ComponentType<T>;
      componentProps?: T;
    }) => ViewSidebarOptionSectionItem<T>;
  };
}) => ViewSidebarOptionSectionItem<T>[];
export type ViewSidebarOptionSection = {
  type: 'section';
  props?: ViewSidebarSectionProps;
  children?: ViewSidebarOptionSectionChildrenGenerator | ViewSidebarOptionSectionItem[];
};
type ViewSidebarSectionProps = {
  className?: string;
  leadingSeparator?: React.ComponentType<any>;
  withoutLeadingSeparator?: boolean;
  trailingSeparator?: React.ComponentType<any>;
  withoutTrailingSeparator?: boolean;
};

const ViewSidebarSection: React.FunctionComponent<ViewSidebarSectionProps> = ({
  className,
  leadingSeparator,
  withoutLeadingSeparator,
  trailingSeparator,
  withoutTrailingSeparator,
  children,
}) => (
  <>
    {!withoutLeadingSeparator && leadingSeparator && React.createElement(leadingSeparator)}
    {React.createElement('div', { className }, children)}
    {!withoutTrailingSeparator && trailingSeparator && React.createElement(trailingSeparator)}
  </>
);
export default ViewSidebarSection;
