import * as React from 'react';

export default function useSessionStorage(
  key: string,
  initialValue: string | null = null,
): [string | null, (newValue: string | null) => void, () => void] {
  const getItemFromSessionStorage = React.useCallback(() => {
    const value = sessionStorage.getItem(key) || initialValue;
    if (value) sessionStorage.setItem(key, value);
    return value;
  }, [key, initialValue]);
  const [item, _setItem] = React.useState(getItemFromSessionStorage);
  React.useEffect(() => {
    _setItem(getItemFromSessionStorage());
  }, [getItemFromSessionStorage]);
  const setItem = React.useCallback(
    (newValue: string | null) => {
      _setItem(newValue);
      if (newValue) sessionStorage.setItem(key, newValue);
    },
    [key],
  );
  const clear = React.useCallback(() => {
    sessionStorage.removeItem(key);
  }, [key]);

  return [item, setItem, clear];
}
