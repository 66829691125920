import * as React from 'react';
import classnames from 'classnames';

import styles from './dashboard.module.scss';

type KpiProps = {
  value: any;
  small?: boolean;
  xsmall?: boolean;
};

const Kpi = (props: KpiProps) => {
  const { value, small, xsmall } = props;
  return (
    <div className={styles.kpi}>
      <div
        className={classnames(styles.value, {
          [styles.small]: small,
          [styles.xsmall]: xsmall,
        })}
      >
        {value}
      </div>
    </div>
  );
};

export default Kpi;
