import _ from 'lodash';
import { Thunk, thunk } from 'easy-peasy';

export type Tag = {
  key: string;
  name?: string;
  values?: string[];
  isInput?: boolean;
  predefined?: boolean;
};

export type Tags = { [key: string]: Tag };

export type TagsModel = {
  data: Tags;
  fetchTagValue: Thunk<TagsModel>;
};

const tagsModel: TagsModel = {
  data: {
    is: {
      key: 'is',
      name: 'Status',
      values: [
        'resolved',
        'unresolved',
        'ignored',
        // TODO(dcramer): remove muted once data is migrated and 9.0+
        'muted',
        'assigned',
        'unassigned',
      ],
      predefined: true,
    },
    has: {
      key: 'has',
      name: 'Status',
      values: [
        'resolved',
        'unresolved',
        'ignored',
        // TODO(dcramer): remove muted once data is migrated and 9.0+
        'muted',
        'assigned',
        'unassigned',
      ],
      predefined: true,
    },
  },
  fetchTagValue: thunk(_.noop),
};
export default tagsModel;
