import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
// @ts-ignore
import PaginatorBase from 'react-pagify';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import SelectControl from 'Components/forms/selectControl';
import segmentize from 'Utils/segmentize';

import styles from './paginator.module.scss';

type PaginatorProps = {
  page: number;
  perPage: number;
  updatePage: (newPage: number) => void;
  updatePerPage: (newPerPage: number) => void;
  errorPage?: Error;
  errorPerPage?: Error;
  pages: number;
  perPageOptions: number[];
};

const Paginator: React.FunctionComponent<PaginatorProps> = ({
  page,
  perPage,
  updatePage,
  updatePerPage,
  errorPage,
  errorPerPage,
  pages,
  perPageOptions,
}) =>
  !(errorPage || errorPerPage) && pages > 0 ? (
    <div className={classnames(styles.wrapper)}>
      <div className={classnames(styles['per-page-selector'])}>
        <SelectControl
          isSearchable={false}
          styles={{
            control: (provided: any) => ({
              ...provided,
              minWidth: '70px',
              height: '42px',
            }),
          }}
          value={{ value: perPage, label: perPage }}
          onChange={(_1: any) => {
            updatePerPage(_1.value);
          }}
          options={_.map(perPageOptions, (_1: number) => ({
            value: _1,
            label: _1,
          }))}
        />
      </div>
      <div className={classnames(styles.paginator)}>
        <PaginatorBase.Context
          tags={{
            container: {
              props: { className: classnames(styles.container) },
            },
            segment: { props: { className: classnames(styles.segment) } },
            ellipsis: {
              props: { className: classnames(styles.ellipsis) },
            },
            link: { props: { className: classnames(styles.link) } },
          }}
          segments={segmentize({
            page,
            pages,
            beginPages: 2,
            endPages: 2,
            sidePages: 2,
          })}
          onSelect={updatePage}
        >
          {page >= 1 ? (
            <PaginatorBase.Button
              className={classnames(styles.segment, styles.button, {
                [styles.disabled]: page === 1,
              })}
              page={page - 1}
            >
              <IoIosArrowBack />
            </PaginatorBase.Button>
          ) : null}
          {page >= 1 && page <= pages ? (
            <>
              <PaginatorBase.Segment field="beginPages" />
              <PaginatorBase.Ellipsis previousField="beginPages" nextField="previousPages" />
              <PaginatorBase.Segment field="previousPages" />
              {pages > 1 && (
                <PaginatorBase.Segment
                  field="centerPage"
                  className={classnames(styles.segment, {
                    [styles.active]: true,
                  })}
                />
              )}
              <PaginatorBase.Segment field="nextPages" />
              <PaginatorBase.Ellipsis previousField="nextPages" nextField="endPages" />
              <PaginatorBase.Segment field="endPages" />
            </>
          ) : null}
          {page <= pages ? (
            <PaginatorBase.Button
              className={classnames(styles.segment, styles.button, {
                [styles.disabled]: page === pages,
              })}
              page={page + 1}
            >
              <IoIosArrowForward />
            </PaginatorBase.Button>
          ) : null}
        </PaginatorBase.Context>
      </div>
    </div>
  ) : null;

export default Paginator;
