import * as React from 'react';
import classnames from 'classnames';

import Alert from 'Components/alert';
import styles from './panel.module.scss';

const DEFAULT_ICONS: any = {
  info: 'icon-circle-info',
  error: 'icon-circle-close',
  warning: 'icon-circle-exclamation',
  success: 'icon-circle-success',
};

const PanelAlert = (props: any) => {
  const { type, icon, children, ...otherProps } = props;
  return (
    <Alert className={classnames(styles.panelAlert)} {...otherProps} icon={icon || DEFAULT_ICONS[type]} type={type}>
      {children}
    </Alert>
  );
};

export default PanelAlert;
