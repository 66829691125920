import * as React from 'react';
import classnames from 'classnames';

import InlineSvg from 'Components/inlineSvg';

import styles from './heroIcon.module.scss';

type HeroIconProps = {
  src: string;
  [x: string]: any;
};

const HeroIcon: React.FunctionComponent<HeroIconProps> = props => {
  const { size, ...otherProps } = props;
  return <InlineSvg className={classnames(styles['hero-icon'])} size={size || '72px'} {...otherProps} />;
};

export default HeroIcon;
