import * as React from 'react';

export default function createComponent<T extends { bsPrefix?: string }>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  opts: any,
) {
  let newOpts = opts;
  if (typeof opts === 'string') newOpts = { prefix: opts };
  const isClassy = Component.prototype && Component.prototype.isReactComponent;
  // If it's a functional component make sure we don't break it with a ref
  const { prefix, forwardRefAs = isClassy ? 'ref' : 'innerRef' } = newOpts;
  const result = React.forwardRef<any, React.PropsWithChildren<T>>(({ bsPrefix, ...props }, ref) =>
    React.createElement<React.PropsWithChildren<T>>(Component, {
      ...props,
      [forwardRefAs]: ref,
      bsPrefix: bsPrefix || prefix,
    } as React.PropsWithChildren<T>),
  );
  result.displayName = `${Component.displayName || Component.name}`;
  return result;
}
