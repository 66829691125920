import * as React from 'react';
import classnames from 'classnames';
import onEnd from 'dom-helpers/transitionEnd';
import Transition, { TransitionProps, ENTERED, ENTERING } from 'react-transition-group/Transition';

import triggerBrowserReflow from '../utils/triggerBrowserReflow';

type FadeProps = TransitionProps;
const defaultProps: Partial<FadeProps> = {
  in: false,
  timeout: 300,
  mountOnEnter: false,
  unmountOnExit: false,
  appear: false,
};
const fadeStyles: { [x: string]: string } = {
  [ENTERING]: 'show',
  [ENTERED]: 'show',
};

const Fade: React.FunctionComponent<FadeProps> = props => {
  const handleEnter = (node: any) => {
    triggerBrowserReflow(node);
    const { onEnter } = props;
    return onEnter && onEnter(node, true);
  };
  const { className, children, ...otherProps } = props;
  return (
    <Transition addEndListener={onEnd} {...otherProps} onEnter={handleEnter}>
      {(status: string, innerProps: any) =>
        children &&
        React.cloneElement(children as any, {
          ...innerProps,
          className: classnames('fade', className, (children as any).props.className, fadeStyles[status]),
        })
      }
    </Transition>
  );
};
Fade.defaultProps = defaultProps;
export default Fade;
