import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

const FieldDescription: React.FunctionComponent<{
  inline?: boolean;
  [x: string]: any;
}> = (props): React.ReactElement => {
  const { inline, children, ...otherProps } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classnames(styles.description, { [styles.inline]: inline })} {...otherProps}>
      {children}
    </label>
  );
};

export default FieldDescription;
