import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';

import DropdownAutoCompleteMenu, { DropdownAutoCompleteMenuProps } from 'Components/dropdownAutoCompleteMenu';
import styles from './dropdownAutoComplete.module.scss';

type DropdownAutoCompleteProps = DropdownAutoCompleteMenuProps & {
  // Should clicking the actor toggle visibility?
  allowActorToggle?: boolean;
  closeOnSelect?: boolean;
  zIndex?: number;
  children: (...args: any[]) => React.ReactNode;
};

const DropdownAutoComplete: React.FunctionComponent<DropdownAutoCompleteProps> = props => {
  const { children, allowActorToggle, ...innerProps } = props;

  return (
    <DropdownAutoCompleteMenu {...innerProps}>
      {(renderProps: any) => {
        // Don't pass `onClick` from `getActorProps`
        const { className: actorClassName, ...actorProps } = _.omit(renderProps.getActorProps({}), ['onClick']);
        return (
          <div
            className={classnames(styles.actor, { [styles.isOpen]: renderProps.isOpen }, actorClassName)}
            role="button"
            tabIndex={-1}
            onClick={renderProps.isOpen && allowActorToggle ? renderProps.actions.close : renderProps.actions.open}
            {...actorProps}
          >
            {children(renderProps)}
          </div>
        );
      }}
    </DropdownAutoCompleteMenu>
  );
};
DropdownAutoComplete.defaultProps = {
  alignMenu: 'right',
};
export default DropdownAutoComplete;
