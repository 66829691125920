import * as React from 'react';
import { Provider } from 'react-redux';
import { StoreProvider, Store } from 'easy-peasy';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';

import { RootStoreModel } from 'Store';
import { GraphQLClient } from 'GraphqlClient';
import ToastContainer, { ToastContainerProps } from 'Components/toast/toastContainer';
import ModalContainer, { ModalContainerProps } from 'Components/modal/modalContainer';

import AppRouter, { AppRouterProps } from './appRouter';

interface AppProviderProps {
  store: Store<RootStoreModel>;
  graphQLClient: GraphQLClient;
  routerProps?: AppRouterProps;
  modalContainerProps?: ModalContainerProps;
  toasterProps?: ToastContainerProps;
}

// eslint-disable-next-line
class AppProvider extends React.Component<AppProviderProps> {
  public render() {
    const { store, graphQLClient, routerProps, modalContainerProps, toasterProps } = this.props;
    return (
      <StoreProvider store={store}>
        <Provider store={store as any}>
          <ApolloProvider client={graphQLClient}>
            <ApolloHooksProvider client={graphQLClient}>
              {routerProps && (
                <AppRouter {...routerProps}>
                  <ModalContainer {...modalContainerProps} />
                  <ToastContainer {...toasterProps} />
                </AppRouter>
              )}
            </ApolloHooksProvider>
          </ApolloProvider>
        </Provider>
      </StoreProvider>
    );
  }
}

export default AppProvider;
