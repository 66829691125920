import * as React from 'react';
import _ from 'lodash';
import JSZip from 'jszip';
import moment from 'moment';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { saveAs } from 'file-saver';
import { useQuery, useMutation, useApolloClient } from 'react-apollo-hooks';
import { Redirect, matchPath } from 'react-router';
import { FaPlusCircle, FaFileAlt, FaTimesCircle, FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';

import notify from 'Utils/notify';

import useModal from 'UtilHooks/useModal';
import useRouter from 'UtilHooks/useRouter';
import useSessionStorage from 'UtilHooks/useSessionStorage';

import Button from 'Components/button';
import { Panel, PanelBody, PanelHeader } from 'Components/panels';
import LoadingIndicator from 'Components/loadingIndicator';
import InputControl from 'Components/forms/inputControl';
import TextAreaControl from 'Components/forms/textAreaControl';

import ViewTable from 'Views/components/viewTable';

import UploadViewModal from 'Views/components/modals/uploadViewModal';
import ConfirmationViewModal from 'Views/components/modals/confirmationViewModal';
import DeleteConfirmationViewModal from 'Views/components/modals/deleteConfirmationViewModal';

import Field from 'Views/settings/field/field';

import styles from './invoiceDetailView.module.scss';

const QUERY = gql`
  query invoiceById($id: ID!) {
    factureById(id: $id) {
      client {
        typeIdentification
        identification
        nom
        adresse {
          rue
          codePostal
          ville
        }
        codeService
        nomService
      }
      fournisseur {
        typeIdentification
        identification
        nom
        adresse {
          rue
          codePostal
          ville
        }
        codeService
        nomService
      }
      moyenPaiement {
        modePaiement
        typeReferenceBancaire
        referenceBancaire
        referenceEtablissementBancaire
      }
      factureDocument
      locked
    }
  }
`;

const InvoiceDetailViewInner: React.FC<{ id: string }> = ({ id }) => {
  const [token] = useSessionStorage('token');
  const { loading, error, data, refetch }: any = useQuery(QUERY, {
    variables: { id },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  return React.useMemo(() => {
    if (loading) return <LoadingIndicator />;
    if (_.isError(error)) return <Redirect to="/404" />;
    const {
      factureById: {
        client,
        locked,
        fournisseur: supplier,
        moyenPaiement: bank,
        factureDocument: { Facture: invoice },
      },
    } = data;
    return (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.container)}>
          <div className={classnames(styles.content)}>
            <div className={classnames(styles.header)}>
              <Title invoice={invoice} client={client} />
            </div>
            <Party collapsed label="Émetteur" data={supplier} />
            <Party collapsed label="Client" data={client} />
            <Infos invoice={invoice} />
            <Details invoice={invoice} />
            <Notes invoice={invoice} />
            <DiscountsAndCharges invoice={invoice} />
            <VAT invoice={invoice} />
            <Totals invoice={invoice} />
            <Bank bank={bank} />
            <Attachments id={id} refetch={refetch} locked={locked} invoice={invoice} />
            <Actions id={id} refetch={refetch} locked={locked} invoice={invoice} />
          </div>
        </div>
      </div>
    );
  }, [data, error, id, loading, refetch]);
};

const InputField: React.FC<{ label: string; value: string; help?: string }> = ({ label, value, help }) =>
  React.useMemo(
    () => (
      <Field inline label={label} help={help} style={{ padding: '10px' }}>
        <InputControl disabled readOnly type="text" value={value} />
      </Field>
    ),
    [help, label, value],
  );

const TextAreaField: React.FC<{ label?: string; value: string; help?: string; rows?: number }> = ({
  label,
  value,
  help,
  rows,
}) =>
  React.useMemo(
    () =>
      label ? (
        <Field inline label={label} help={help} style={{ padding: '10px' }}>
          <TextAreaControl disabled readOnly withoutResize rows={rows || 3} value={value} />
        </Field>
      ) : (
        <TextAreaControl disabled readOnly withoutResize rows={rows || 3} value={value} />
      ),
    [help, label, rows, value],
  );

const Title: React.FC<{ invoice: any; client: any }> = ({ invoice, client }) =>
  React.useMemo(
    () => (
      <h1 className={classnames(styles.title)}>
        {`Consultation de ${invoice.TypeFacture === 'AVOIR' ? "l'avoir" : 'la facture'} #${invoice.NumeroFacture} - ${
          client.nom
        }`}
      </h1>
    ),
    [invoice, client],
  );

const Party: React.FC<{ label: string; data: any; collapsed?: boolean }> = ({ label, data, collapsed: _1 }) => {
  const [collapsed, setCollapsed] = React.useState(React.useCallback(() => _1, [_1]));
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  return React.useMemo(
    () => (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          {label}
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <InputField label="Nom" value={data.nom} />
            <InputField label="Identification" value={`${data.typeIdentification} ${data.identification}`} />
            <InputField label="Service" value={`${data.codeService} - ${data.nomService}`} />
            <InputField
              label="Adresse"
              value={`${data.adresse.rue} ${data.adresse.codePostal} ${data.adresse.ville}`}
            />
          </PanelBody>
        )}
      </Panel>
    ),
    [
      collapsed,
      data.adresse.codePostal,
      data.adresse.rue,
      data.adresse.ville,
      data.codeService,
      data.identification,
      data.nom,
      data.nomService,
      data.typeIdentification,
      label,
      toggleCollapsed,
    ],
  );
};

const Infos: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const invoiceInfosPaymentPeriod = React.useMemo(
    () =>
      _.has(invoice, 'PeriodeFacturation')
        ? _.omitBy(
            {
              start: invoice.PeriodeFacturation.Debut,
              end: invoice.PeriodeFacturation.Fin,
              description: invoice.PeriodeFacturation.description,
            },
            _.isUndefined,
          )
        : undefined,
    [invoice],
  );
  const invoiceInfosReferences = React.useMemo(() => {
    const result = _.omitBy(
      {
        contract: invoice.ReferenceContrat,
        business: invoice.ReferenceMarche,
        command: invoice.ReferenceCommande,
      },
      _.isUndefined,
    );
    if (_.isEmpty(result)) return undefined;
    return result;
  }, [invoice.ReferenceCommande, invoice.ReferenceContrat, invoice.ReferenceMarche]);
  const infos = React.useMemo(
    () =>
      _.omitBy(
        {
          type: invoice.TypeFacture,
          number: invoice.NumeroFacture,
          category: invoice.CategorieFacture,
          originalNumber: invoice.NumeroFactureOrigine,
          emissionDate: invoice.DateEmission,
          dueDate: invoice.DateEcheance,
          period: invoiceInfosPaymentPeriod,
          references: invoiceInfosReferences,
        },
        _.isUndefined,
      ),
    [
      invoice.CategorieFacture,
      invoice.DateEcheance,
      invoice.DateEmission,
      invoice.NumeroFacture,
      invoice.NumeroFactureOrigine,
      invoice.TypeFacture,
      invoiceInfosPaymentPeriod,
      invoiceInfosReferences,
    ],
  );
  return React.useMemo(
    () => (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          {`Informations ${infos.type === 'AVOIR' ? "d'" : 'de '}`}
          {infos.type}
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <InputField label={`Numéro ${infos.type === 'AVOIR' ? "d'avoir" : 'de facture'}`} value={infos.number} />
            {infos.type === 'AVOIR' && _.has(infos, 'originalNumber') && (
              <InputField label="Facture d'origine" value={infos.originalNumber} />
            )}
            <InputField label="Date de la facture" value={moment(infos.emissionDate).format('LL')} />
            {_.has(infos, 'dueDate') && (
              <InputField label="Date d'échéance" value={moment(infos.dueDate).format('LL')} />
            )}
            {_.has(infos, 'references') && (
              <>
                {_.map(
                  _.zip(['contract', 'business', 'command'], ['Contrat', 'Marché', 'Bon de commande']),
                  ([key, prefix]) =>
                    prefix && _.has(infos, `references.${key}`) ? (
                      <InputField label={prefix} value={_.get(infos, `references.${key}`)} />
                    ) : null,
                )}
              </>
            )}
            {_.has(infos, 'period') && (
              <>
                <InputField
                  label="Période de facturation"
                  value={`Du ${moment(infos.period.start).format('LL')} au ${moment(infos.period.end).format('LL')}`}
                />
                {_.has(infos, 'period.description') && <TextAreaField value={infos.period.description} />}
              </>
            )}
          </PanelBody>
        )}
      </Panel>
    ),
    [collapsed, infos, toggleCollapsed],
  );
};

const DetailDescription: React.FC<{ description: string }> = ({ description }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  return React.useMemo(
    () => (
      <Panel noBorder noMargin style={{ width: '100%' }}>
        <PanelHeader
          noBorder
          style={{ cursor: 'pointer', justifyContent: 'center' }}
          onClick={_.partial(toggleCollapsed)}
        >
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Description
        </PanelHeader>
        {!collapsed && <PanelBody>{description}</PanelBody>}
      </Panel>
    ),
    [collapsed, description, toggleCollapsed],
  );
};

const DetailDiscountsAndCharges: React.FC<{ currencyFormat: any; discountsAndCharges: any }> = ({
  currencyFormat,
  discountsAndCharges,
}) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const data = React.useMemo(
    () => ({
      rows: _.map(discountsAndCharges, ({ Indicateur, CodeMotif, Montant, MontantBase }, index) =>
        _.omitBy(
          { _id: index, type: Indicateur, code: CodeMotif, amount: Montant, baseAmount: MontantBase },
          _.isUndefined,
        ),
      ),
      details: _.map(
        _.toPairs(
          _.omitBy(
            _.reduce(
              discountsAndCharges,
              (acc, { CodeMotif, Motif }) =>
                !(_.isUndefined(Motif) || _.isEmpty(Motif)) ? _.set(acc, [CodeMotif], Motif) : acc,
              {},
            ),
            _.isUndefined,
          ),
        ),
        ([code, description], index) => ({ code, description, _id: index }),
      ),
    }),
    [discountsAndCharges],
  );
  return React.useMemo(() => {
    const { rows, details } = data;
    return (
      <Panel noBorder noMargin style={{ width: '100%' }}>
        <PanelHeader
          noBorder
          style={{ cursor: 'pointer', justifyContent: 'center' }}
          onClick={_.partial(toggleCollapsed)}
        >
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Charges et Remises
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <DiscountsAndChargesTable currencyFormat={currencyFormat} rows={rows} details={details} />
          </PanelBody>
        )}
      </Panel>
    );
  }, [collapsed, currencyFormat, data, toggleCollapsed]);
};

const Details: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed]);
  const [expanded, setExpanded] = React.useState<{ [x: number]: boolean }>({});
  const toggleExpanded = React.useCallback(
    (index: number) => setExpanded(_.merge({}, expanded, { [index]: !_.get(expanded, index, false) })),
    [expanded],
  );
  const invoiceDetails = React.useMemo(
    () => ({
      currency: invoice.Devise,
      rows: _.get(
        _.reduce(
          _.sortBy(invoice.LignesFacturation, ['NumeroLigne']),
          (
            { index, rows },
            { NumeroLigne, CodeArticle, Quantite, PrixUnitaire, MontantHT, TauxTaxe, Description, ChargesEtRemises },
          ) => {
            // eslint-disable-next-line no-underscore-dangle
            let _rows: any = [];
            let offset = 1;
            const expandable = Description || ChargesEtRemises;
            if (_.get(expanded, index)) {
              if (Description) {
                _rows = _.concat(_rows, { _id: index + offset, lineNumber: NumeroLigne, description: Description });
                offset += 1;
              }
              if (ChargesEtRemises) {
                _rows = _.concat(_rows, {
                  _id: index + offset,
                  lineNumber: NumeroLigne,
                  discountsAndCharges: ChargesEtRemises,
                });
                offset += 1;
              }
            }
            _rows = _.concat(
              [
                {
                  expandable,
                  _id: index,
                  lineNumber: NumeroLigne,
                  productCode: CodeArticle,
                  quantity: Quantite,
                  unitPrice: PrixUnitaire,
                  amount: MontantHT,
                  rate: TauxTaxe,
                },
              ],
              _rows,
            );
            return { index: index + offset, rows: _.concat(rows, _rows) };
          },
          { index: 1, rows: [] } as any,
        ),
        'rows',
        [],
      ),
    }),
    [expanded, invoice.Devise, invoice.LignesFacturation],
  );
  return React.useMemo(() => {
    const { rows, currency } = invoiceDetails;
    const currencyFormat: any = new Intl.NumberFormat('fr-FR', {
      currency,
      style: 'currency',
    });
    return (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Détails
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <div className={classnames(styles.detailsWrapper)}>
              <ViewTable
                rowKey="_id"
                columns={[
                  {
                    property: '__expandButton__',
                    header: { label: '' },
                    cell: {
                      transforms: [
                        (_1, { rowData: { description, discountsAndCharges } }) => {
                          const result: any = {};
                          let children: any[] = [];
                          if (description || discountsAndCharges) _.set(result, 'colSpan', 7);
                          if (description)
                            children = _.concat(children, <DetailDescription description={description} />);
                          if (discountsAndCharges)
                            children = _.concat(
                              children,
                              <DetailDiscountsAndCharges
                                currencyFormat={currencyFormat}
                                discountsAndCharges={discountsAndCharges}
                              />,
                            );
                          if (_.isArray(children) && !_.isEmpty(children))
                            _.set(
                              result,
                              'children',
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {children}
                              </div>,
                            );
                          return result;
                        },
                      ],
                      formatters: [
                        (_1, { rowData: { _id, expandable } }) => {
                          if (!expandable) return ' ';
                          return _.get(expanded, _id) ? <FaRegMinusSquare /> : <FaRegPlusSquare />;
                        },
                      ],
                    },
                    props: {
                      style: {
                        maxWidth: 0,
                      },
                    },
                  },
                  {
                    property: 'lineNumber',
                    header: { label: 'N°' },
                    cell: {
                      formatters: [(v, { rowData: { description } }) => (description ? null : JSON.stringify(v))],
                    },
                    props: {
                      style: {
                        maxWidth: 0,
                      },
                    },
                  },
                  { property: 'productCode', header: { label: 'Code article' } },
                  { property: 'quantity', header: { label: 'Qté' } },
                  {
                    property: 'unitPrice',
                    header: { label: 'PU' },
                    cell: {
                      formatters: [currencyFormat.format],
                    },
                  },
                  {
                    property: 'amount',
                    header: { label: 'Montant HT' },
                    cell: {
                      formatters: [currencyFormat.format],
                    },
                  },
                  {
                    property: 'rate',
                    header: { label: 'Taxe' },
                    cell: {
                      formatters: [v => (_.isNumber(v) ? `${v} %` : null)],
                    },
                  },
                ]}
                rows={rows}
                onRow={({ _id, expandable, lineNumber, description, discountsAndCharges }) =>
                  _.omitBy(
                    {
                      rowData: { description, discountsAndCharges },
                      className: lineNumber ? classnames(lineNumber % 2 ? styles.oddRow : styles.evenRow) : undefined,
                      onClick: expandable ? _.partial(toggleExpanded, _id) : undefined,
                      style: expandable ? { cursor: 'pointer' } : undefined,
                    },
                    _.isUndefined,
                  )
                }
                renderers={{
                  body: {
                    row: ({ rowData: { description, discountsAndCharges }, children, ...props }: any) => {
                      return (
                        <tr {...props}>
                          {description || discountsAndCharges ? React.Children.only(_.head(children)) : children}
                        </tr>
                      );
                    },
                  },
                }}
              />
            </div>
          </PanelBody>
        )}
      </Panel>
    );
  }, [collapsed, expanded, invoiceDetails, toggleCollapsed, toggleExpanded]);
};

const Notes: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const invoiceNotes = React.useMemo(() => invoice.Notes, [invoice]);
  return React.useMemo(
    () => (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Notes
        </PanelHeader>
        {!collapsed && (
          <PanelBody>
            <ul>
              {_.map(invoiceNotes, (note, index) => (
                <li key={`note-${index}`}>{note}</li>
              ))}
            </ul>
          </PanelBody>
        )}
      </Panel>
    ),
    [collapsed, invoiceNotes, toggleCollapsed],
  );
};

const DiscountsAndChargesTable: React.FC<{ currencyFormat: any; rows: any[]; details?: any[] }> = ({
  currencyFormat,
  rows,
  details,
}) =>
  React.useMemo(
    () => (
      <div className={classnames(styles.discountsAndChargesWrapper)}>
        <ViewTable
          rowKey="_id"
          columns={[
            { property: 'type', header: { label: 'Type' } },
            { property: 'code', header: { label: 'Code motif' } },
            {
              property: 'baseAmount',
              header: { label: 'Montant de base' },
              cell: {
                formatters: [currencyFormat.format],
              },
            },
            {
              property: 'amount',
              header: { label: 'Montant' },
              cell: {
                formatters: [currencyFormat.format],
              },
            },
          ]}
          rows={rows}
        />
        {_.isArray(details) && !_.isEmpty(details) && (
          <ViewTable
            rowKey="_id"
            columns={[
              { property: 'code', header: { label: 'Code motif' } },
              { property: 'description', header: { label: 'Motif' } },
            ]}
            rows={details}
          />
        )}
      </div>
    ),
    [currencyFormat.format, details, rows],
  );

const DiscountsAndCharges: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const invoiceDiscountsAndCharges = React.useMemo(
    () => ({
      currency: invoice.Devise,
      rows: _.map(invoice.ChargesEtRemises, ({ Indicateur, CodeMotif, Montant, MontantBase }, index) =>
        _.omitBy(
          { _id: index, type: Indicateur, code: CodeMotif, amount: Montant, baseAmount: MontantBase },
          _.isUndefined,
        ),
      ),
      details: _.map(
        _.toPairs(
          _.omitBy(
            _.reduce(
              invoice.ChargesEtRemises,
              (acc, { CodeMotif, Motif }) =>
                !(_.isUndefined(Motif) || _.isEmpty(Motif)) ? _.set(acc, [CodeMotif], Motif) : acc,
              {},
            ),
            _.isUndefined,
          ),
        ),
        ([code, description], index) => ({ code, description, _id: index }),
      ),
    }),
    [invoice.ChargesEtRemises, invoice.Devise],
  );
  return React.useMemo(() => {
    const { rows, details, currency } = invoiceDiscountsAndCharges;
    const currencyFormat: any = new Intl.NumberFormat('fr-FR', {
      currency,
      style: 'currency',
    });
    if (_.isEmpty(rows)) return null;
    return (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Charges et Remises
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <DiscountsAndChargesTable currencyFormat={currencyFormat} rows={rows} details={details} />
          </PanelBody>
        )}
      </Panel>
    );
  }, [collapsed, invoiceDiscountsAndCharges, toggleCollapsed]);
};

const VAT: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const invoiceVAT = React.useMemo(
    () => ({
      currency: invoice.Devise,
      rows: _.map(invoice.RecapitulatifTaxes, ({ TypeTaxe, MontantTaxable, Taux, MontantTaxe }, index) =>
        _.omitBy(
          {
            _id: index,
            type: TypeTaxe,
            rate: Taux.toFixed(1),
            taxableAmount: MontantTaxable,
            taxAmount: MontantTaxe,
          },
          _.isUndefined,
        ),
      ),
      details: _.map(
        _.toPairs(
          _.omitBy(
            _.reduce(
              invoice.RecapitulatifTaxes,
              (acc, { Taux, MotifExoneration }) =>
                !(_.isUndefined(MotifExoneration) || _.isEmpty(MotifExoneration))
                  ? _.set(acc, [Taux.toFixed(1)], MotifExoneration)
                  : acc,
              {},
            ),
            _.isUndefined,
          ),
        ),
        ([rate, description], index) => ({ rate, description, _id: index }),
      ),
    }),
    [invoice.Devise, invoice.RecapitulatifTaxes],
  );
  return React.useMemo(() => {
    const { rows, details, currency } = invoiceVAT;
    const currencyFormat: any = new Intl.NumberFormat('fr-FR', {
      currency,
      style: 'currency',
    });
    if (_.isEmpty(rows)) return null;
    return (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          TVA
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <div className={classnames(styles.VATWrapper)}>
              <ViewTable
                rowKey="_id"
                columns={[
                  { property: 'type', header: { label: 'Type' } },
                  {
                    property: 'rate',
                    header: { label: 'Taux' },
                    cell: {
                      formatters: [v => `${v} %`],
                    },
                  },
                  {
                    property: 'taxableAmount',
                    header: { label: 'Montant taxable' },
                    cell: {
                      formatters: [currencyFormat.format],
                    },
                  },
                  {
                    property: 'taxAmount',
                    header: { label: 'Montant' },
                    cell: {
                      formatters: [currencyFormat.format],
                    },
                  },
                ]}
                rows={rows}
              />
              {!_.isEmpty(details) && (
                <ViewTable
                  rowKey="_id"
                  columns={[
                    {
                      property: 'rate',
                      header: { label: 'Taux' },
                      cell: {
                        formatters: [v => `${v} %`],
                      },
                    },
                    { property: 'description', header: { label: 'Motif' } },
                  ]}
                  rows={details}
                />
              )}
            </div>
          </PanelBody>
        )}
      </Panel>
    );
  }, [collapsed, invoiceVAT, toggleCollapsed]);
};

const Totals: React.FC<{ invoice: any }> = ({ invoice }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const invoiceTotals = React.useMemo(
    () => ({
      currency: invoice.Devise,
      row: _.omitBy({ _id: 0, ...invoice.MontantsTotal }, _.isUndefined),
    }),
    [invoice],
  );
  return React.useMemo(() => {
    const { row, currency } = invoiceTotals;
    const currencyFormat: any = new Intl.NumberFormat('fr-FR', {
      currency,
      style: 'currency',
    });
    if (_.keys(row) === ['_id']) return null;
    return (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Totaux
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <div className={classnames(styles.totalsWrapper)}>
              <ViewTable
                rowKey="_id"
                columns={_.filter(
                  [
                    {
                      property: 'TotalHT',
                      header: { label: 'Total HT' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                    {
                      property: 'TotalTTC',
                      header: { label: 'Total TTC' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                    {
                      property: 'TotalAPayer',
                      header: { label: 'Total à payer' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                    {
                      property: 'TotalRemises',
                      header: { label: 'Total remises' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                    {
                      property: 'TotalCharges',
                      header: { label: 'Total charges' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                    {
                      property: 'TotalDejaPaye',
                      header: { label: 'Total déjà payé' },
                      cell: {
                        formatters: [currencyFormat.format],
                      },
                    },
                  ],
                  o => _.includes(_.keys(row), o.property),
                )}
                rows={[row]}
              />
            </div>
          </PanelBody>
        )}
      </Panel>
    );
  }, [collapsed, invoiceTotals, toggleCollapsed]);
};

const Bank: React.FC<{
  bank: {
    modePaiement: string;
    typeReferenceBancaire: string;
    referenceBancaire: string;
    referenceEtablissementBancaire: string;
  };
}> = ({ bank }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  return React.useMemo(
    () => (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Informations bancaires
        </PanelHeader>
        {!collapsed && (
          <PanelBody disablePadding>
            <InputField label="Mode de paiement" value={bank.modePaiement} />
            <TextAreaField
              label="Référence bancaire"
              value={_.join(
                _.map(
                  ['typeReferenceBancaire', 'referenceBancaire', 'referenceEtablissementBancaire'],
                  _.partial(_.get, bank),
                ),
                '\n',
              )}
              rows={3}
            />
          </PanelBody>
        )}
      </Panel>
    ),
    [bank, collapsed, toggleCollapsed],
  );
};

const Attachment: React.FC<{
  id: string;
  invoiceNumber: string;
  key: string;
  attachment: any;
  locked: boolean;
  token: string | null;
  refetch: (variables: any) => Promise<any>;
}> = ({ id, invoiceNumber, key, attachment, locked, token, refetch }) => {
  const graphqlClient = useApolloClient();
  const deleteAttachmentMutation = useMutation<any>(
    gql`
      mutation deleteAttachment($invoiceNumber: String!, $name: String!) {
        supprimerPJ(numeroFacture: $invoiceNumber, nom: $name)
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const deleteAttachment = React.useCallback(
    async (name: string) => {
      let error;
      try {
        const { error: _error }: any = await deleteAttachmentMutation({
          variables: { invoiceNumber, name },
        });
        error = _error;
      } catch (err) {
        error = err;
      }
      if (error) {
        notify.error("Impossible d'accéder au serveur");
      } else {
        await refetch({ id });
        notify('Pièce jointe supprimée');
      }
    },
    [deleteAttachmentMutation, id, invoiceNumber, refetch],
  );
  const [showDeleteAttachmentConfirmationModal, closeDeleteAttachmentConfirmationModal] = useModal<{
    name: string;
    close: () => Promise<void>;
  }>(({ name, close }) => (
    <DeleteConfirmationViewModal
      title={`la pièce jointe ${name}`}
      close={close}
      onConfirm={_.partial(deleteAttachment, name)}
    />
  ));
  return React.useMemo(
    () => (
      <div
        key={key}
        className={classnames(styles.attachment, { [styles.locked]: locked })}
        onClick={async (e: any) => {
          e.stopPropagation();
          const { data, errors } = await graphqlClient.query({
            query: gql`
              query($invoiceNumber: String!, $id: ID!) {
                content: telechargerPJ(numeroFacture: $invoiceNumber, id: $id)
              }
            `,
            variables: {
              invoiceNumber,
              id: attachment.id,
            },
            context: { headers: { Authorization: `Bearer ${token}` } },
            fetchPolicy: 'network-only',
          });
          if (errors) {
            notify.error('Une erreur est survenue.');
          } else if (_.isString(data.content)) {
            saveAs(
              await (await JSZip().loadAsync(data.content, { base64: true }))
                .file(attachment.Nom.replace(/^PJC_/, ''))
                .async('blob'),
              attachment.Nom.replace(/^PJC_/, ''),
            );
          }
        }}
      >
        <div className={classnames(styles.attachmentIconWrapper)}>
          <FaFileAlt className={classnames(styles.attachmentIcon)} size="50px" />
          {locked && (
            <FaTimesCircle
              className={classnames(styles.deleteAttachmentIcon)}
              color="red"
              size="18px"
              onClick={(e: any) => {
                e.stopPropagation();
                showDeleteAttachmentConfirmationModal({
                  name: attachment.Nom,
                  close: closeDeleteAttachmentConfirmationModal,
                });
              }}
            />
          )}
        </div>
        <div>{attachment.Nom.replace(/^PJC_/, '')}</div>
      </div>
    ),
    [
      attachment.Nom,
      attachment.id,
      closeDeleteAttachmentConfirmationModal,
      graphqlClient,
      invoiceNumber,
      key,
      locked,
      showDeleteAttachmentConfirmationModal,
      token,
    ],
  );
};

const Attachments: React.FC<{
  id: string;
  refetch: (variables: any) => Promise<any>;
  invoice: any;
  locked: boolean;
}> = ({ id, refetch, invoice, locked }) => {
  const [token] = useSessionStorage('token');
  const [collapsed, setCollapsed] = React.useState(true);
  const toggleCollapsed = React.useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const attachments = React.useMemo(() => invoice.PiecesJointes, [invoice.PiecesJointes]);
  const MIMETypes = React.useMemo(
    () => ({
      'image/bmp': 'BMP',
      'image/gif': 'GIF',
      'image/g3fax': 'FAX',
      'application/vnd.oasis.opendocument.text': 'ODT',
      'application/vnd.ms-powerpoint': 'PPT',
      'image/tiff': 'TIFF',
      'application/vnd.ms-excel': 'XLS',
      'application/x-bzip': 'BZ2',
      'application/x-gzip': 'GZIP',
      'image/jpeg': 'JPEG',
      'application/pkcs7-mime': 'P7S',
      'application/rtf': 'RTF',
      'text/plain': 'TXT',
      'application/xml': 'XML',
      'text/csv': 'CSV',
      'application/pdf': 'PDF',
      'image/svg+xml': 'SVG',
      'application/xhtml+xml': 'XHTML',
      'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet': 'XLSX',
      'application/msword': 'DOC',
      'text/html': 'HTML',
      'application/vnd.oasis.opendocument.presentation': 'ODP',
      'image/png': 'PNG',
      'application/x-tar': 'TGZ',
      'application/zip': 'ZIP',
      'application/vnd.openxmlformatsofficedocument.wordprocessingml.document': 'DOCX',
      'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
      'application/vnd.openxmlformatsofficedocument.presentationml.presentation': 'PPTX',
    }),
    [],
  );
  const addAttachmentMutation = useMutation<any>(
    gql`
      mutation addAttachment(
        $invoiceNumber: String!
        $name: String!
        $format: FacturePieceJointeFormat!
        $file: Upload!
      ) {
        ajouterPJ(numeroFacture: $invoiceNumber, nom: $name, format: $format, file: $file)
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const addAttachment = React.useCallback(
    async (file: File) => {
      let error;
      try {
        const { error: _error }: any = await addAttachmentMutation({
          variables: _.omitBy(
            {
              file,
              invoiceNumber: invoice.NumeroFacture,
              name: file.name,
              format: _.get(MIMETypes, file.type),
            },
            _.isUndefined,
          ),
        });
        error = _error;
      } catch (err) {
        error = err;
      }
      if (error) {
        let message = 'Une erreur est survenue';
        switch (error.message.replace(/^GraphQL error: /, '')) {
          case `Invoice '${invoice.NumeroFacture}' already have PJ with name '${file.name}'`:
            message = 'Une pièce jointe existe déjà avec le même nom';
            break;
          default:
            break;
        }
        notify.error(message);
      } else {
        await refetch({ id });
        notify('Pièce jointe ajoutée');
      }
    },
    [MIMETypes, addAttachmentMutation, id, invoice.NumeroFacture, refetch],
  );
  const [showUploadModal, closeUploadModal] = useModal<{ close: () => Promise<void> }>(({ close }) => (
    <UploadViewModal accept={_.keys(MIMETypes)} maxSize={20 * 1e6} close={close} onSuccess={addAttachment} />
  ));
  return React.useMemo(() => {
    if (!locked && _.isEmpty(attachments)) return null;
    return (
      <Panel>
        <PanelHeader style={{ cursor: 'pointer' }} onClick={_.partial(toggleCollapsed)}>
          {collapsed ? (
            <FaRegPlusSquare style={{ marginRight: '5px' }} />
          ) : (
            <FaRegMinusSquare style={{ marginRight: '5px' }} />
          )}
          Pièces jointes
        </PanelHeader>
        {!collapsed && (
          <PanelBody className={classnames(styles.attachments, { [styles.locked]: locked })}>
            {_.map(attachments, (attachment, index) => (
              <Attachment
                id={id}
                token={token}
                refetch={refetch}
                invoiceNumber={invoice.NumeroFacture}
                key={`attachment-${index}`}
                attachment={attachment}
                locked={locked}
              />
            ))}
            {locked && (
              <FaPlusCircle
                className={classnames(styles.newAttachmentIcon)}
                size="35px"
                onClick={_.partial(showUploadModal, { close: closeUploadModal })}
              />
            )}
          </PanelBody>
        )}
      </Panel>
    );
  }, [
    attachments,
    closeUploadModal,
    collapsed,
    id,
    invoice.NumeroFacture,
    locked,
    refetch,
    showUploadModal,
    toggleCollapsed,
    token,
  ]);
};

const Actions: React.FC<{
  id: string;
  refetch: (variables: any) => Promise<any>;
  invoice: any;
  locked: boolean;
}> = ({ id, refetch, invoice: { NumeroFacture, TypeFacture }, locked }) => {
  const [token] = useSessionStorage('token');
  const { replace } = useRouter();
  const unlockInvoiceMutation = useMutation<any>(
    gql`
      mutation unlockInvoice($invoiceNumber: String!) {
        debloquerFacture(numero: $invoiceNumber) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const unlockInvoice = React.useCallback(async () => {
    let error;
    try {
      const { error: _error }: any = await unlockInvoiceMutation({
        variables: _.omitBy(
          {
            invoiceNumber: NumeroFacture,
          },
          _.isUndefined,
        ),
      });
      error = _error;
    } catch (err) {
      error = err;
    }
    if (error) {
      let message;
      switch (error.message.replace(/^GraphQL error: /, '')) {
        default:
          message = 'Une erreur est survenue';
          break;
      }
      if (message) notify.error(message);
    } else {
      await refetch({ id });
      notify('Facture envoyée');
    }
  }, [NumeroFacture, id, refetch, unlockInvoiceMutation]);
  const [showModal, closeModal] = useModal<{ close: () => Promise<void> }>(({ close }) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useMemo(
      () => (
        <ConfirmationViewModal
          permanent
          title={`de vouloir envoyer ${
            TypeFacture === 'AVOIR' ? "l'avoir" : 'la facture'
          } ${NumeroFacture} au service Chorus Pro`}
          close={close}
          actions={[
            { priority: 'default', onClick: close, label: 'Annuler' },
            {
              priority: 'primary',
              onClick: async () => unlockInvoice(),
              label: 'Confirmer',
            },
          ]}
        />
      ),
      [close],
    ),
  );
  return React.useMemo(
    () => (
      <div className={classnames(styles.actions)}>
        <Button
          size="small"
          priority="default"
          style={{ marginRight: '8px' }}
          onClick={_.partial(replace, '/invoices')}
        >
          Retour à la liste des factures
        </Button>
        {locked && (
          <Button size="small" priority="primary" onClick={_.partial(showModal, { close: closeModal })}>
            Envoyer à Chorus Pro
          </Button>
        )}
      </div>
    ),
    [closeModal, locked, replace, showModal],
  );
};

const InvoiceDetailView: React.FC = () => {
  const {
    location: { pathname },
  } = useRouter();
  const [id, setId] = React.useState<string>();
  React.useEffect(() => {
    const match: any = matchPath(pathname, {
      path: '/invoices/:id',
      exact: true,
    });
    setId(match ? match.params.id : undefined);
    return _.noop;
  }, [pathname]);
  return React.useMemo(() => (!_.isUndefined(id) ? <InvoiceDetailViewInner id={id} /> : null), [id]);
};

export default InvoiceDetailView;
