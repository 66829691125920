import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { WiCloudUp } from 'react-icons/wi';
import filesize from 'filesize';

import LoadingIndicator from 'Components/loadingIndicator';
import ErrorStateWarning from 'Components/errorStateWarning';

import styles from './uploadViewModal.module.scss';

type UploadViewModalProps = {
  accept?: string | string[];
  maxSize?: number;
  close: () => Promise<void>;
  onSuccess?: (file: File) => Promise<void>;
};

const UploadViewModal: React.FC<UploadViewModalProps> = ({ accept, maxSize, onSuccess, close }) => {
  const [error, setError] = React.useState<Error | null>();
  const loadingRef = React.useRef<boolean>(false);
  const [loading, _setLoading] = React.useState<boolean>(loadingRef.current);
  // eslint-disable-next-line no-shadow
  const setLoading = React.useCallback(loading => {
    _setLoading(loading);
    loadingRef.current = loading;
  }, []);
  const nodeRef = React.useRef<any>();
  const handleClick = React.useCallback((e: any) => {
    if (nodeRef && nodeRef.current.contains(e.target)) return;
    if (loadingRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);
  const onDrop = React.useCallback(() => {
    setError(null);
    setLoading(true);
  }, [setLoading]);
  const onDropAccepted = React.useCallback(
    async ([file]: File[]) => {
      window.addEventListener('click', handleClick, true);
      try {
        if (_.isFunction(onSuccess)) await onSuccess(file);
        await close();
      } finally {
        window.removeEventListener('click', handleClick, true);
      }
      setLoading(false);
    },
    [close, handleClick, onSuccess, setLoading],
  );
  React.useEffect(
    () => () => {
      window.removeEventListener('click', handleClick, true);
    },
    [handleClick],
  );
  const onDropRejected = React.useCallback(() => {
    setError(
      Error(
        _.join(
          _.reject(
            [
              !_.isUndefined(accept) ? "Le format de fichier n'est pas supporté" : '',
              !_.isUndefined(maxSize)
                ? `le fichier dépasse la taille maximale de ${filesize(maxSize, {
                    base: 10,
                    locale: 'fr',
                  })}`
                : '',
            ],
            _.isEmpty,
          ),
          ' ou ',
        ),
      ),
    );
    setLoading(false);
  }, [accept, maxSize, setLoading]);
  const { getRootProps, getInputProps } = useDropzone({ accept, maxSize, onDrop, onDropAccepted, onDropRejected });
  return React.useMemo(
    () => (
      <div ref={nodeRef} className={classnames(styles.wrapper)}>
        {!loading && <h4>Déposer votre fichier</h4>}
        {loading && <LoadingIndicator />}
        {!loading && (
          <div className={classnames(styles.dropzone)} {...getRootProps()}>
            <input {...getInputProps()} />
            <WiCloudUp className={classnames(styles.icon)} />
          </div>
        )}
        {loading && <h4>Upload en cours</h4>}
        {!loading && <h4>ou cliquer pour parcourir</h4>}
        {error && <ErrorStateWarning className={classnames(styles.error)} message={error.message} />}
      </div>
    ),
    [error, getInputProps, getRootProps, loading],
  );
};

export default UploadViewModal;
