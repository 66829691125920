import * as React from 'react';
import classnames from 'classnames';

import CheckboxFancy from 'Components/checkboxFancy';

import styles from './globalSelectionHeaderRow.module.scss';

type GlobalSelectionHeaderRowProps = {
  checked: boolean;
  multi?: boolean;
  onCheckClick: (e: any) => void;
  children: React.ReactNode;
  [x: string]: any;
};

const GlobalSelectionHeaderRow: React.FunctionComponent<GlobalSelectionHeaderRowProps> = props => {
  const render = () => {
    const { checked, onCheckClick, multi, children, ...innerProps } = props;

    return (
      <div className={classnames(styles.container)} {...innerProps}>
        <div className={classnames(styles.content, { [styles.multi]: !!multi })}>{children}</div>
        <div
          className={classnames(styles['checkbox-wrapper'], {
            [styles.checked]: checked,
          })}
          onClick={multi ? onCheckClick : undefined}
        >
          <CheckboxFancy className={classnames(styles.checkbox)} checked={checked} />
        </div>
      </div>
    );
  };
  return render();
};
GlobalSelectionHeaderRow.defaultProps = { multi: true };
export default GlobalSelectionHeaderRow;
