import * as React from 'react';
import classnames from 'classnames';

import styles from './panel.module.scss';

type PanelItemProps = {
  className?: string;
};

const PanelItem: React.FunctionComponent<PanelItemProps> = ({ className, children }) => (
  <div className={classnames(styles.panelItem, className)}>{children}</div>
);

export default PanelItem;
