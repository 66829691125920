import { useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';

import compose from 'Utils/compose';
import useRouter from 'UtilHooks/useRouter';

export type Params = {
  [x: string]: any;
};
type Option = {
  initialValue?: any;
  defaultValue?: any;
  acceptedValues?: any[];
  validate?: (value: any, context: { getParams: () => Params }) => void;
  mapFromUrl?: (value: string, context?: { getParams: () => Params }) => any;
  mapToUrl?: (value: any, context?: { getParams: () => Params; getNewParams: () => Params }) => string;
  onUpdate?: ({
    pathname,
    search,
    helpers,
  }: {
    pathname: string;
    search: object;
    helpers: {
      omit: (search: object, ...names: string[]) => object;
    };
  }) => { pathname: string; search: object };
  dependsOn?: string[];
};
type Options = {
  [x: string]: Option;
};

function tsort(edges: number[][]) {
  class Node {
    public id: number;

    public afters: number[];

    public constructor(id: number) {
      this.id = id;
      this.afters = [];
    }
  }
  const nodes: { [x: number]: Node } = {}; // hash: stringified id of the node => { id: id, afters: lisf of ids }
  const sorted: number[] = []; // sorted list of IDs ( returned value )
  const visited: { [x: number]: boolean } = {}; // hash: id of already visited node => true

  // 1. build data structures
  _.each(edges, v => {
    const [from, to] = v;
    if (!nodes[from]) _.set(nodes, from, new Node(from));
    if (!nodes[to]) _.set(nodes, to, new Node(to));
    nodes[from].afters.push(to);
  });

  // 2. topological sort
  const visit = (idstr: string, ancestors: number[] = []) => {
    const node = _.get(nodes, idstr);
    const { id } = node;

    // if already exists, do nothing
    if (_.get(visited, idstr)) return;

    ancestors.push(id);

    _.set(visited, idstr, true);

    _.each(node.afters, afterID => {
      if (ancestors.indexOf(afterID) >= 0) {
        // if already in ancestors, a closed chain exists.
        throw new Error(`Closed chain : ${afterID} is in ${id}`);
      }
      visit(
        afterID,
        _.map(ancestors, v => v),
      ); // recursive call
    });

    sorted.unshift(id);
  };
  _.each(Object.keys(nodes), key => visit(key, []));

  return sorted;
}

export default (options: Options = {}) => {
  const { location, replace } = useRouter();
  const errorParamsRef = useRef<{ [K in keyof Options]: Error }>({});
  const getErrorParams = useCallback(() => errorParamsRef.current, []);
  const setErrorParams = useCallback(
    (newErrorParams: { [K in keyof Options]: Error }) => (errorParamsRef.current = newErrorParams), // eslint-disable-line
    [],
  );
  useEffect(() => {
    setErrorParams({});
  }, [setErrorParams]);
  const getSortedOptions = useCallback(() => {
    const [keys, reverseKeys] = _.reduce(
      _.keys(options),
      (acc, value, index) => [_.set(acc[0], value, index), _.set(acc[1], index, value)],
      [{}, {}],
    );
    const edges = _.reduce(
      options,
      (acc, option, endKey) =>
        _.concat(
          acc,
          _.map(
            _.filter(_.get(option, 'dependsOn', []), startKey => startKey !== endKey),
            startKey => _.map([startKey, endKey], key => _.get(keys, key)),
          ),
        ),
      [] as number[][],
    );
    const sort = tsort(edges);
    const resultKeys = _.map(sort, value => _.get<string>(reverseKeys, value));
    const result = _.concat(resultKeys, _.keys(_.omit(keys, resultKeys)));
    return result;
  }, [options]);
  const updatedParamsRef = useRef<Set<string>>(new Set());
  const getUpdatedParams = useCallback(() => updatedParamsRef.current, []);
  const setUpdatedParams = useCallback((key: string) => updatedParamsRef.current.add(key), []);
  const isUpdatedParams = useCallback((key: string) => getUpdatedParams().has(key), [getUpdatedParams]);
  const getParamsFromUrlSearchParams = useCallback(
    (urlSearchParams: URLSearchParams) => {
      const sortedOptions = getSortedOptions();
      // eslint-disable-next-line no-underscore-dangle
      const _params = _.omitBy(
        _.reduce(
          options,
          (acc, value, key) => {
            _.set(acc, key, _.get(value, 'initialValue'));
            return acc;
          },
          {},
        ),
        _.isUndefined,
      );
      const getParams = () => _params;
      _.each(sortedOptions, key => {
        const option = _.get(options, key);
        let paramString = urlSearchParams.get(key);
        let param;
        if (!_.isNull(paramString)) {
          paramString = decodeURIComponent(paramString);
          if (!_.isUndefined(option) && _.isFunction(option.mapFromUrl)) {
            param = option.mapFromUrl(paramString, { getParams });
          } else param = paramString;
        } else if (!_.isUndefined(option))
          param = !isUpdatedParams(key) ? _.get(_params, key) || option.defaultValue : option.defaultValue;
        if (!_.isUndefined(param)) {
          const validate = _.get(options, `${key}.validate`);
          try {
            if (_.isFunction(validate)) validate(param, { getParams });
            const acceptedValues = _.get(options, `${key}.acceptedValues`);
            if (_.isArray(acceptedValues) && !_.includes(acceptedValues, param)) {
              throw new Error(`Paramètre '${key}' doit être une des valeurs suivantes: [${acceptedValues.join(', ')}]`);
            }
            _.set(_params, key, param);
          } catch (error) {
            setErrorParams(_.extend(getErrorParams(), { [key]: error }));
          }
        }
      });
      return _params;
    },
    [getErrorParams, getSortedOptions, isUpdatedParams, options, setErrorParams],
  );
  const paramsRef = useRef<Params>(getParamsFromUrlSearchParams(new URLSearchParams(location.search)));
  const getParams = useCallback(() => paramsRef.current, []);
  const setParams = useCallback((newParams: Params) => (paramsRef.current = newParams), []); // eslint-disable-line
  useEffect(() => {
    setParams(getParamsFromUrlSearchParams(new URLSearchParams(location.search)));
  }, [getParamsFromUrlSearchParams, location.search, setParams]);
  const updateParams = useCallback(
    (newParams?: Params, initialSearch?: URLSearchParams) => {
      if (_.isUndefined(newParams)) return;
      const updateFn = (key: string) => _.get(options, `${key}.onUpdate`) as Option['onUpdate'];
      const [newUrlSearchParams, updateFns] = _.reduce(
        _.extend(getParams(), newParams),
        // eslint-disable-next-line no-shadow
        ([newUrlSearchParams, updateFns], newParam, key) => {
          if (_.isUndefined(newParam)) return [newUrlSearchParams, updateFns];
          const defaultValue = _.get(options, `${key}.defaultValue`, undefined);
          if (!_.isUndefined(defaultValue) && _.isEqual(newParam, defaultValue)) {
            // eslint-disable-next-line no-param-reassign
            newParam = null;
          }
          // eslint-disable-next-line no-underscore-dangle
          const _newUrlSearchParams = new URLSearchParams(Array.from(newUrlSearchParams.entries()));
          if (_.isNull(newParam)) {
            _newUrlSearchParams.delete(key);
          } else {
            const validate = _.get(options, `${key}.validate`);
            const mapToUrl = _.get(options, `${key}.mapToUrl`);
            if (_.isFunction(validate)) validate(newParam, { getParams: () => newParams });
            _newUrlSearchParams.set(
              key,
              encodeURIComponent(
                _.isFunction(mapToUrl)
                  ? mapToUrl(newParam, {
                      getParams,
                      getNewParams: () => newParams,
                    })
                  : newParam,
              ),
            );
          }
          setUpdatedParams(key);
          // tslint:disable: ter-func-call-spacing
          return [_newUrlSearchParams, _.has(newParams, key) ? _.concat(updateFns, updateFn(key) || []) : updateFns];
          // tslint:enable: ter-func-call-spacing
        },
        [
          new URLSearchParams(
            initialSearch
              ? Array.from(initialSearch.entries())
              : Array.from(new URLSearchParams(location.search).entries()),
          ),
          [] as Option['onUpdate'][],
        ],
      );
      const withHelpers = ({ pathname, search }: any) => ({
        pathname,
        search,
        helpers: {
          // eslint-disable-next-line  no-shadow
          omit: (search: object, ...names: string[]) =>
            _.fromPairs(_.reject(_.entries(search), ([name]) => _.includes(names, name))),
        },
      });
      compose(
        replace,
        ({ pathname, search }) =>
          `${pathname}${
            search
              ? `?${_.entries(search)
                  .map(e => e.join('='))
                  .join('&')}`
              : ''
          }`,
        ({ pathname, search }) =>
          _.reduce(
            updateFns,
            (acc, value) => (_.isFunction(value) ? withHelpers(value(acc)) : acc),
            withHelpers({ pathname, search }),
          ),
      )({
        pathname: location.pathname,
        search: _.fromPairs(Array.from(newUrlSearchParams.entries())),
      });
    },
    [getParams, location.search, location.pathname, replace, options, setUpdatedParams],
  );
  return {
    updateParams,
    params: getParams(),
    errorParams: getErrorParams(),
    helpers: {
      getParams: useCallback(
        () => _.omitBy(getParams(), (value, key) => _.isEqual(value, _.get(options, `${key}.defaultValue`))),
        [getParams, options],
      ),
      toUrlSearchString: useCallback(
        (search: _.Dictionary<any>) =>
          `?${_.entries(search)
            .map(([key, value]) =>
              _([
                key,
                encodeURIComponent(
                  (_.get(options, `${key}.mapToUrl`, (v: any) => JSON.stringify(v)) as any)(value, {
                    getParams,
                  }),
                ),
              ]).join('='),
            )
            .join('&')}`,
        [getParams, options],
      ),
    },
  };
};
