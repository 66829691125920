import * as React from 'react';
import classnames from 'classnames';

type CheckboxProps = {
  checked?: boolean;
  [x: string]: any;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = ({ className, ...otherProps }) => (
  <input type="checkbox" className={classnames('chk-select', className)} {...otherProps} />
);
export default Checkbox;
