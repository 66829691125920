import * as React from 'react';
import classNames from 'classnames';

import { AppLayoutProps } from '.';
import styles from './loginLayout.module.scss';

const LoginLayout: React.SFC<AppLayoutProps> = props => {
  const { view } = props;
  return (
    <div className={classNames(styles.container, 'container')}>
      <div className="content">
        <div className="pattern-bg" />
        <div className={styles.box}>
          <div className={styles.sidebar}>
            <a href="/">
              <span className="icon-sentry-logo" />
            </a>
          </div>
          <section className={styles.main}>{React.createElement(view, null)}</section>
        </div>
      </div>
    </div>
  );
};
export default LoginLayout;
