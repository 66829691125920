import * as React from 'react';
import classnames from 'classnames';

import MainLayout from 'Layouts/mainLayout';
import { AppLayoutProps } from 'Layouts';
import SettingsLayoutHeader from 'Layouts/settingsLayout/settingsLayoutHeader';
import SettingsLayoutSidebar from 'Layouts/settingsLayout/settingsLayoutSidebar';

import styles from './settingsLayout.module.scss';

type SettingsLayoutProps = AppLayoutProps;

const SettingsLayoutInner: React.FunctionComponent<SettingsLayoutProps> = ({ view, viewProps }) => {
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.header)}>
          <SettingsLayoutHeader />
        </div>
        <div className={classnames(styles.content)}>
          <div className={classnames(styles.sidebar)}>
            <SettingsLayoutSidebar />
          </div>
          <div className={classnames(styles.view)}>{React.createElement(view, { ...viewProps })}</div>
        </div>
      </div>
    ),
    [view, viewProps],
  );
};

const SettingsLayout: React.FunctionComponent<SettingsLayoutProps> = ({ view, viewProps }) =>
  React.useMemo(() => <MainLayout view={SettingsLayoutInner} viewProps={{ view, viewProps }} />, [view, viewProps]);

export default SettingsLayout;
