import * as React from 'react';
import classnames from 'classnames';
import { FaCheck } from 'react-icons/fa';

import styles from './checkboxFancy.module.scss';

type CheckboxFancyProps = {
  className?: string;
  checked?: boolean;
  size?: string;
};

const CheckboxFancy: React.FunctionComponent<CheckboxFancyProps> = props => {
  const render = () => {
    const { className, checked, size, ...innerProps } = props;
    return (
      <div
        role="checkbox"
        aria-checked={checked}
        className={classnames(styles.container, { [styles.checked]: checked }, className)}
        style={{ width: size, height: size }}
        {...innerProps}
      >
        {checked && <FaCheck className={classnames(styles.check)} />}
      </div>
    );
  };
  return render();
};
CheckboxFancy.defaultProps = { checked: false, size: '16px' };
export default CheckboxFancy;
