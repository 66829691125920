/* eslint-disable no-shadow */
import * as React from 'react';
import classnames from 'classnames';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { FaClock, FaFileAlt, FaCalendarTimes, FaTasks, FaFileImport, FaInfoCircle } from 'react-icons/fa';
import { useQuery } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { DEFAULT_PERIOD } from 'Constants/date';
import parsePeriod from 'Utils/date/parsePeriod';
import formatError from 'Utils/formatError';
import getDateWithTimezoneInUtc from 'Utils/date/getDateWithTimezoneInUtc';
import useUrlSearchParams, { Params } from 'Utils/hooks/useUrlSearchParams';
import useObservable from 'UtilHooks/useObservable';
import useRouter from 'UtilHooks/useRouter';

import useSessionStorage from 'UtilHooks/useSessionStorage';
import LoadingIndicator from 'Components/loadingIndicator';
import ErrorStateWarning from 'Components/errorStateWarning';
import EmptyStateWarning from 'Components/emptyStateWarning';
import HeaderSeparator from 'Components/headerSeparator';
import OverlayTrigger from 'Components/overlayTrigger';
import Tooltip from 'Components/tooltip';

import MultipleClientSelector from 'Views/components/multipleClientSelector';
import TimeRangeSelector from 'Views/components/timeRangeSelector';

import InvoiceBarChart from './invoiceBarChart';
import InvoiceStatusPieChart from './invoiceStatusPieChart';
import Kpi from './kpi';
import List from './list';

import styles from './dashboard.module.scss';

const fromString = (str?: string | null, context?: { getParams: () => Params }) => {
  if (_.isUndefined(str)) return undefined;
  return !_.isNull(str) ? getDateWithTimezoneInUtc(str, context && context.getParams()) : null;
};

const toString = (date?: Moment | null, context?: { getParams: () => Params }) => {
  if (_.isUndefined(date)) return undefined;
  return !_.isNull(date)
    ? getDateWithTimezoneInUtc(date, _.extend({}, context && context.getParams(), { stringify: true }))
    : null;
};

const Block = (props: any) => {
  const { icon, title, info, children } = props;
  return (
    <div className={styles.block}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles['block-content']}>
        <div className={styles['block-title']}>{title}</div>
        <div className={styles['block-info']}>{info}</div>
        <div className={styles.kpis}>{children}</div>
      </div>
    </div>
  );
};

const mapStatutLabel = (statut: any) => {
  const mapping: { [s: string]: string } = {
    EN_TRAITEMENT: 'En cours',
    FLUX_REJETE: 'Erreur Chorus',
    FLUX_INTEGRE: 'Intégré Chorus',
    A_VALIDER_1: 'Attente validation',
    ERREUR_FOURNISSEUR_SUR_VALIDATEUR: 'Erreur valideur fournisseur',
    REFUSEE_1: 'Refusée',
    VALIDEE_1: 'Validée',
    ABSENCE_VALIDATION_1_HORS_DELAI: 'Délai dépassé',
    A_VALIDER_2: 'À valider',
    ERREUR_COTRAITANT_SUR_VALIDATEUR: 'Erreur valideur cotraitant',
    REFUSEE_2: 'Refusée',
    VALIDEE_2: 'Validée',
    ABSENCE_VALIDATION_2_HORS_DELAI: 'Délai dépassé',
    DEPOSEE: 'Déposée',
    EN_COURS_ACHEMINEMENT: 'Acheminement',
    MISE_A_DISPOSITION: 'À disposition',
    A_RECYCLER: 'À recycler',
    SUSPENDUE: 'Suspendue',
    COMPLETEE: 'Complétée',
    REJETEE: 'Rejetée',
    MANDATEE: 'Mandatée',
    MISE_EN_PAIEMENT: 'Mise en paiement',
    COMPTABILISEE: 'Comptabilisée',
    MISE_A_DISPOSITION_COMPTABLE: 'Attente Comptable',

    INTERCEPTEE: 'Interceptée',

    EMAIL_FAILED: 'Erreur email',
    EMAIL_BOUNCED: 'Erreur transmission',
    EMAIL_DELIVERED: 'Email reçu',
    EMAIL_OPENED: 'Email lu',
    EMAIL_MARKED_AS_SPAM: 'Marqué spam',
    TELECHARGEE: 'Téléchargée',
  };
  return mapping[statut];
};

const getKpis = (stats: any) => {
  return stats.reduce(
    (result: any, current: any) => {
      switch (current.statut) {
        case 'EN_TRAITEMENT':
        case 'FLUX_INTEGRE':
        case 'INTERCEPTEE':
          return {
            ...result,
            seizeInProgressCount: result.seizeInProgressCount + current.count,
          };
        case 'FLUX_REJETE':
        case 'EMAIL_FAILED':
        case 'EMAIL_BOUNCED':
        case 'EMAIL_MARKED_AS_SPAM':
          return {
            ...result,
            seizeErrorCount: result.seizeErrorCount + current.count,
          };
        case 'REJETEE':
          return {
            ...result,
            rejectedAmount: result.rejectedAmount + current.total,
            rejectedCount: result.rejectedCount + current.count,
            integratedCount: result.integratedCount + current.count,
          };
        case 'MISE_EN_PAIEMENT':
          return {
            ...result,
            paidCount: result.paidCount + current.count,
            paidAmount: result.paidAmount + current.total,
            integratedCount: result.integratedCount + current.count,
          };
        default:
          return {
            ...result,
            unPaidCount: result.unPaidCount + current.count,
            unPaidAmount: result.unPaidAmount + current.total,
            integratedCount: result.integratedCount + current.count,
          };
      }
    },
    {
      unPaidAmount: 0.0,
      unPaidCount: 0,
      paidCount: 0,
      paidAmount: 0.0,
      rejectedCount: 0,
      rejectedAmount: 0.0,
      seizeInProgressCount: 0,
      seizeErrorCount: 0,
      integratedCount: 0,
    },
  );
};

const renderError = (message: string) => {
  return (
    <div className={styles.wrapper}>
      <ErrorStateWarning message={message} />
    </div>
  );
};

const DashboardView = () => {
  const [token] = useSessionStorage('token');
  const { push } = useRouter();
  const {
    updateParams,
    params: { period, start, end, utc, client },
    errorParams: { period: errorPeriod },
    helpers: { getParams, toUrlSearchString },
  } = useUrlSearchParams({
    start: {
      mapFromUrl: fromString,
      mapToUrl: toString,
      dependsOn: ['utc'],
    },
    end: {
      // eslint-disable-next-line no-shadow
      validate: (end, { getParams }) => {
        if (_.isNull(end)) return;
        const params = getParams();
        if (params.start && params.start > end) throw new Error("Paramètre 'end' est invalide.");
      },
      mapFromUrl: fromString,
      mapToUrl: toString,
      dependsOn: ['start', 'utc'],
    },
    utc: {
      defaultValue: false,
      // eslint-disable-next-line no-shadow
      validate: utc => {
        if (_.isNull(utc) || _.isBoolean(utc)) return;
        throw new Error("Paramètre 'utc' est invalide.");
      },
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
    period: {
      defaultValue: DEFAULT_PERIOD,
      // eslint-disable-next-line no-shadow
      validate: period => {
        if (_.isNull(period)) return;
        try {
          parsePeriod(period);
        } catch {
          throw new Error("Paramètre 'period' est invalide.");
        }
      },
    },
    client: {
      defaultValue: null,
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
  });
  const updatePeriodRange = React.useCallback(
    ({ newPeriod, newPeriodRangeStart, newPeriodRangeEnd, newUtc }: any) =>
      updateParams({
        period: newPeriod,
        start: newPeriodRangeStart,
        end: newPeriodRangeEnd,
        utc: newUtc,
      }),
    [updateParams],
  );
  // eslint-disable-next-line no-shadow
  const updateClient = React.useCallback((client: any) => updateParams({ client }), [updateParams]);

  const parsedPeriod = !errorPeriod && period ? parsePeriod(period) : undefined;
  let startDate;
  if (!_.isUndefined(start)) startDate = moment(start);
  else if (!_.isUndefined(parsedPeriod)) startDate = parsedPeriod.start;
  let endDate;
  if (!_.isUndefined(end)) endDate = moment(end);
  else if (!_.isUndefined(parsedPeriod)) endDate = parsedPeriod.end;
  const {
    data: {
      statutFactureStats,
      monthlyFactureStats,
      topBestClientsByAmount,
      topBestClientsByDelay,
      topWorstClientsByAmount,
      facturesCount,
      clients,
    },
    loading: queryLoading,
    error: queryError,
  }: any = useQuery(
    gql`
      query dashboardData($startDate: String, $endDate: String, $clients: [String!]) {
        statutFactureStats(
          filter: { Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate } }
        ) {
          statutFlux
          statutFacture
          statut
          count
          total
          clientCount
          averageDelay
        }
        monthlyFactureStats(
          filter: {
            statutFlux_in: ["IN_INTEGRE", "EMAIL_SENDED", "EMAIL_DELIVERED", "EMAIL_OPENED"]
            statutFacture_nin: ["REJETEE", null]
            Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate }
          }
        ) {
          month
          year
          count
          total
          clientCount
        }
        topBestClientsByAmount: topClientsByAmount(
          filter: {
            statutFlux_in: ["IN_INTEGRE", "EMAIL_SENDED", "EMAIL_DELIVERED", "EMAIL_OPENED"]
            statutFacture_nin: ["REJETEE", null]
            Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate }
          }
        ) {
          codeClient
          client {
            nom
          }
          invoiceAmount
        }
        topBestClientsByDelay: topClientsByDelay(
          filter: {
            statutFlux_in: ["IN_INTEGRE", "EMAIL_SENDED", "EMAIL_DELIVERED", "EMAIL_OPENED"]
            statutFacture_eq: "MISE_EN_PAIEMENT"
            Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate }
          }
        ) {
          codeClient
          client {
            nom
          }
          averageDelay
        }
        topWorstClientsByAmount: topClientsByAmount(
          filter: {
            statutFlux_in: ["IN_INTEGRE", "EMAIL_SENDED", "EMAIL_DELIVERED", "EMAIL_OPENED"]
            statutFacture_nin: ["MISE_EN_PAIEMENT", "REJETEE", null]
            Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate }
          }
        ) {
          codeClient
          client {
            nom
          }
          invoiceAmount
        }
        facturesCount(
          filter: { Facture: { CodeClient_in: $clients, DateEmission_gte: $startDate, DateEmission_lte: $endDate } }
        )
        clients {
          id
          code
          nom
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      variables: {
        startDate: startDate && startDate.format(moment.HTML5_FMT.DATE),
        endDate: endDate && endDate.format(moment.HTML5_FMT.DATE),
        clients: client || undefined,
      },
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: { facturesAValider },
    loading: queryInvoicesToValidateLoading,
    error: queryInvoicesToValidateError,
    refetch: refetchInvoicesToValidate,
  }: any = useQuery(
    gql`
      query invoicesToValidate {
        facturesAValider(input: { filter: { statut: [A_VALIDER] } }) {
          count
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
    },
  );
  useObservable('nbInvoicesToValidate', {
    regex: new RegExp(/^refetch$/),
    onMessage: async () => {
      await refetchInvoicesToValidate();
    },
  });

  if (!_.isUndefined(errorPeriod)) {
    return renderError("Le paramètre 'period' est invalide.");
  }

  if (queryError) {
    return renderError('Une erreur est survenue. Veuillez verifier votre connexion internet.');
  }

  if (queryLoading) {
    return (
      <div className={classnames(styles['loading-wrapper'])}>
        <div className={classnames(styles['loading-container'])}>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  const kpis = getKpis(statutFactureStats);

  const miseEnPaiementStat = _.find(statutFactureStats, stat => stat.statut === 'MISE_EN_PAIEMENT');
  const averageDelaysInDays = miseEnPaiementStat ? miseEnPaiementStat.averageDelay : null;

  const topBestClientsByAmountData = _.map(
    _.take(_.sortBy(topBestClientsByAmount, ['invoicesAmount']), 10),
    (topClient: any) => ({
      description: topClient.client.nom,
      value: `${topClient.invoiceAmount} €`,
    }),
  );

  const topBestClientsByDelayData = _.map(
    _.take(_.sortBy(topBestClientsByDelay, ['averageDelay']), 10),
    (topClient: any) => ({
      description: topClient.client.nom,
      value: topClient.averageDelay ? `${topClient.averageDelay} jour${topClient.averageDelay > 1 ? 's' : ''}` : 'N/A',
    }),
  );

  const topWorstClientsByAmountData = _.map(
    _.take(_.sortBy(topWorstClientsByAmount, ['invoicesAmount']), 10),
    (topClient: any) => ({
      description: topClient.client.nom,
      value: `${topClient.invoiceAmount} €`,
    }),
  );

  const currencyFormat = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  });
  const percentFormat = new Intl.NumberFormat('fr-FR', { style: 'percent' });

  return (
    <div style={{ flex: 1, flexDirection: 'column' }}>
      <div className={classnames(styles['header-wrapper'], styles.sticky)}>
        <div className={classnames(styles['header-container'])}>
          <div className={classnames(styles['header-item'])}>
            {clients && (
              <MultipleClientSelector
                multi
                clients={_.map(clients, ({ code, nom }: { code: string; nom: string }) => ({
                  id: code,
                  name: nom,
                }))}
                selected={client}
                onUpdate={updateClient}
              />
            )}
          </div>
          <div className={classnames(styles['header-separator'])} />
          <div className={classnames(styles['header-item'])}>
            <TimeRangeSelector
              start={start}
              end={end}
              relative={period}
              utc={utc}
              updatePeriodRange={updatePeriodRange}
            />
          </div>
        </div>
      </div>
      <div style={{ flex: 1, overflow: 'auto', height: '100%' }}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Block title="Facturé" info="Total TTC facturé" icon={<FaFileAlt size="36" />}>
              <Kpi value={currencyFormat.format(kpis.paidAmount + kpis.unPaidAmount)} />
            </Block>
            <Block title="Délai" info="Délai moyen de paiement" value="23 000" icon={<FaClock size="36" />}>
              <Kpi
                value={
                  averageDelaysInDays ? `${averageDelaysInDays} ${averageDelaysInDays > 1 ? 'jours' : 'jour'}` : 'N/A'
                }
              />
            </Block>
            <Block title="Déposé" info="Taux dépôt" icon={<FaTasks size="36" />}>
              <Kpi
                value={kpis.integratedCount === 0 ? '0 %' : percentFormat.format(kpis.integratedCount / facturesCount)}
              />
            </Block>
            <Block
              title={kpis.rejectedCount > 1 ? 'Rejetées' : 'Rejetée'}
              info="Nombre de factures rejetées"
              icon={<FaCalendarTimes size="36" />}
            >
              <Kpi value={kpis.rejectedCount} />
            </Block>
            <Block title="À valider" info="Nombre de factures à valider" icon={<FaFileImport size="36" />}>
              <Kpi
                value={
                  // eslint-disable-next-line no-nested-ternary
                  queryInvoicesToValidateLoading || !facturesAValider ? (
                    <div className={classnames(styles['loading-wrapper'])}>
                      <div className={classnames(styles['loading-container'])}>
                        <LoadingIndicator mini />
                      </div>
                    </div>
                  ) : queryInvoicesToValidateError ? (
                    <div style={{ display: 'flex' }}>
                      <div>N/A</div>
                      <div style={{ marginLeft: '5px' }}>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={innerProps => (
                            <Tooltip {...(_.omit(innerProps, 'show') as any)}>
                              {formatError(
                                queryInvoicesToValidateError,
                                "Une erreur s'est produite, veuillez contacter l'administrateur du site",
                              )}
                            </Tooltip>
                          )}
                        >
                          <FaInfoCircle size="16px" />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ) : (
                    facturesAValider.count
                  )
                }
              />
            </Block>
          </div>
          {facturesCount > 0 ? (
            <div className={styles.content}>
              <div className={styles.widget}>
                <h3 className={styles.title}>Trésorerie</h3>
                {kpis.paidAmount + kpis.unPaidAmount > 0 ? (
                  <div style={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'space-around' }}>
                    <div style={{ width: '40%' }}>
                      <CircularProgressbarWithChildren
                        value={kpis.paidAmount / (kpis.paidAmount + kpis.unPaidAmount)}
                        maxValue={1}
                        styles={{
                          path: {
                            stroke: 'rgba(50, 205, 50, 0.80)',
                          },
                          trail: {
                            stroke: 'rgba(65, 105, 255, 0.80)',
                          },
                        }}
                      >
                        <h2
                          style={{
                            color: kpis.paidAmount > 0 ? 'rgba(50, 205, 50, 0.80)' : 'rgba(65, 105, 255, 0.80)',
                          }}
                        >
                          {Math.round((kpis.paidAmount / (kpis.paidAmount + kpis.unPaidAmount)) * 100)}
                          <sub>%</sub>
                        </h2>
                      </CircularProgressbarWithChildren>
                    </div>
                    <HeaderSeparator style={{ height: '75%' }} />
                    <div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: '20px',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        {_.map(
                          _.reject(
                            [
                              kpis.paidCount > 0
                                ? {
                                    key: 'paid',
                                    title: 'Payé',
                                    value: {
                                      _: currencyFormat.format(kpis.paidAmount),
                                      color: 'rgba(50, 205, 50, 0.80)',
                                    },
                                    description: `${kpis.paidCount} facture${kpis.paidCount > 1 ? 's' : ''}`,
                                  }
                                : undefined,
                              kpis.unPaidCount > 0
                                ? {
                                    key: 'unPaid',
                                    title: 'Créance',
                                    value: {
                                      _: currencyFormat.format(kpis.unPaidAmount),
                                      color: 'rgba(65, 105, 255, 0.80)',
                                    },
                                    description: `${kpis.unPaidCount} facture${kpis.unPaidCount > 1 ? 's' : ''}`,
                                  }
                                : undefined,
                            ],
                            _.isUndefined,
                          ) as any,
                          ({ key, title, value: { _: value, color }, description }) => (
                            <div
                              key={key}
                              style={{
                                flex: 1,
                                margin: '10px 0',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <div style={{ display: 'flex', width: '50%' }}>
                                <div
                                  style={{
                                    opacity: 0.87,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {title}
                                </div>
                              </div>
                              <div style={{ marginLeft: 'auto' }}>
                                <div
                                  style={{
                                    flex: 1,
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div>
                                    <h4
                                      style={{
                                        color,
                                      }}
                                    >
                                      {value}
                                    </h4>
                                  </div>
                                  <div style={{ marginTop: '-15px' }}>
                                    <div
                                      style={{
                                        fontSize: '11px',
                                        textAlign: 'end',
                                        textTransform: 'uppercase',
                                        fontStyle: 'italic',
                                      }}
                                    >
                                      {description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <EmptyStateWarning message="Aucun résultat" />
                )}
              </div>

              <div className={styles.widget}>
                <h3 className={styles.title}>Répartition par facture</h3>
                <InvoiceStatusPieChart
                  className={classnames(styles.body)}
                  title={`${kpis.paidCount + kpis.unPaidCount} ${
                    kpis.paidCount + kpis.unPaidCount > 1 ? 'factures' : 'facture'
                  }`}
                  data={{
                    table: _.reject(statutFactureStats, (stat: any) =>
                      _.includes(['EN_TRAITEMENT', 'FLUX_INTEGRE', 'FLUX_REJETE', 'REJETEE'], stat.statut),
                    ).map((stat: any) => ({
                      initialStatut: stat.statut,
                      statut: mapStatutLabel(stat.statut),
                      value: stat.count,
                    })),
                  }}
                  formatTooltip={(datum: any) => `${datum.value} facture${datum.value > 1 ? 's' : ''}`}
                  onClick={({ initialStatut }: any) => {
                    if (!_.isString(initialStatut)) return;
                    push({
                      pathname: '/invoices',
                      search: toUrlSearchString(
                        _.extend(getParams(), {
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          statut: [initialStatut],
                        }),
                      ),
                    });
                  }}
                />
              </div>
              <div className={styles.widget}>
                <h3 className={styles.title}>Répartition par valeur</h3>
                <InvoiceStatusPieChart
                  className={classnames(styles.body)}
                  title={`${Math.round(kpis.paidAmount + kpis.unPaidAmount)} €`}
                  data={{
                    table: _.reject(statutFactureStats, (stat: any) =>
                      _.includes(['EN_TRAITEMENT', 'FLUX_INTEGRE', 'FLUX_REJETE', 'REJETEE'], stat.statut),
                    ).map((stat: any) => ({
                      initialStatut: stat.statut,
                      statut: mapStatutLabel(stat.statut),
                      value: stat.total,
                    })),
                  }}
                  formatTooltip={(datum: any) => currencyFormat.format(datum.value)}
                  onClick={({ initialStatut }: any) => {
                    if (!_.isString(initialStatut)) return;
                    push({
                      pathname: '/invoices',
                      search: toUrlSearchString(
                        _.extend(getParams(), {
                          // eslint-disable-next-line @typescript-eslint/camelcase
                          statut: [initialStatut],
                        }),
                      ),
                    });
                  }}
                />
              </div>
              <div className={classnames(styles.widget, styles.double)}>
                <h3 className={styles.title}>Historique de facturation</h3>
                <InvoiceBarChart
                  data={_.fromPairs(
                    monthlyFactureStats.map((stat: any) => [
                      moment({
                        month: stat.month - 1,
                        year: stat.year,
                      })
                        .startOf('month')
                        .format('YYYY-MM-DD'),
                      {
                        clients: stat.clientCount,
                        amount: stat.total,
                      },
                    ]),
                  )}
                  range={{
                    start: !_.isUndefined(startDate) ? startDate.startOf('month') : undefined,
                    end: !_.isUndefined(endDate) ? endDate.startOf('month') : undefined,
                  }}
                  formatTooltip={({ period, amount, clientsCount }: any) => ({
                    Période: amount && clientsCount ? period : undefined,
                    Montant: amount ? currencyFormat.format(amount) : undefined,
                    'Nb clients': clientsCount || undefined,
                  })}
                />
              </div>
              <div className={styles.widget}>
                <List
                  withoutPosition
                  fullHeight
                  title="Situation des transferts de facture"
                  data={
                    facturesCount > 0
                      ? [
                          { description: 'Reçu SEIZE', value: facturesCount },
                          {
                            description: 'En cours de traitement',
                            value: kpis.seizeInProgressCount,
                          },
                          {
                            description: 'En erreur Chorus Pro',
                            value: kpis.seizeErrorCount,
                          },
                          {
                            description: 'Intégré Chorus Pro',
                            value: kpis.integratedCount,
                          },
                        ]
                      : []
                  }
                />
              </div>
              <div className={styles.widget}>
                <List title="Top 10 des clients facturés" data={topBestClientsByAmountData} />
              </div>
              <div className={styles.widget}>
                <List title="Top 10 des créances client" data={topWorstClientsByAmountData} />
              </div>
              <div className={styles.widget}>
                <List title="Top 10 des délais de paiement moyen" data={topBestClientsByDelayData} />
              </div>
              <footer />
            </div>
          ) : (
            <div
              style={{
                flex: 1,
              }}
            >
              <EmptyStateWarning message="Aucun résultat" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default DashboardView;
