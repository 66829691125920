import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import styles from './viewSidebar.module.scss';
import ViewSidebarSectionGroup, { ViewSidebarOptionSectionGroup } from './viewSidebarSectionGroup';
import ViewSidebarSection, { ViewSidebarOptionSection } from './viewSidebarSection';
import ViewSidebarSectionItem, { ViewSidebarOptionSectionItem } from './viewSidebarSectionItem';

type ViewSidebarOption = ViewSidebarOptionSectionItem | ViewSidebarOptionSection | ViewSidebarOptionSectionGroup;
type ViewSidebarOptions = ViewSidebarOption[];
export type ViewSidebarProps = {
  className?: string;
  separators?: {
    leading?: {
      sectionGroup?: React.ComponentType<any>;
      section?: React.ComponentType<any>;
      item?: React.ComponentType<any>;
    };
    trailing?: {
      sectionGroup?: React.ComponentType<any>;
      section?: React.ComponentType<any>;
      item?: React.ComponentType<any>;
    };
  };
  styles?: {
    wrapper?: string;
    container?: string;
    sectionGroup?: {
      className: string;
      first?: string;
      last?: string;
      separator?: string;
    };
    section?: {
      className: string;
      first?: string;
      last?: string;
      separator?: string;
    };
    item?: {
      className: string;
      separator?: string;
    };
  };
  options?: ViewSidebarOptions;
  props?: object;
};

const ViewSidebar: React.FunctionComponent<ViewSidebarProps> = props => {
  const renderOption = (
    option: ViewSidebarOption,
    index: number,
    { isFirst, isLast }: { isFirst?: boolean; isLast?: boolean } = {
      isFirst: false,
      isLast: false,
    },
  ) => {
    const {
      className = undefined,
      leadingSeparator: _1 = undefined,
      withoutLeadingSeparator: _2 = false,
      trailingSeparator: _3 = undefined,
      withoutTrailingSeparator: _4 = false,
      ...rest
    } = option.props || {};
    const classes = [];
    let leadingSeparator = _1;
    let withoutLeadingSeparator = _2;
    let trailingSeparator = _3;
    let withoutTrailingSeparator = _4;
    switch (option.type) {
      case 'sectionGroup':
        classes.push(styles['section-group']);
        if (isFirst) classes.push(styles['section-group-first']);
        if (isLast) classes.push(styles['section-group-last']);
        if (props.styles && props.styles.sectionGroup) {
          classes.push(props.styles.sectionGroup.className);
          if (isFirst) classes.push(props.styles.sectionGroup.first);
          if (isLast) classes.push(props.styles.sectionGroup.last);
        }

        if (props.separators && props.separators.leading && props.separators.leading.sectionGroup) {
          leadingSeparator = _1 || props.separators.leading.sectionGroup || (() => null);
          withoutLeadingSeparator = _2 || _.isUndefined(props.separators.leading.sectionGroup) || false;
        }
        if (props.separators && props.separators.trailing && props.separators.trailing.sectionGroup) {
          trailingSeparator = _3 || props.separators.trailing.sectionGroup || (() => null);
          withoutTrailingSeparator = _4 || _.isUndefined(props.separators.trailing.sectionGroup) || false;
        }
        break;
      case 'section':
        classes.push(styles.section);
        if (isFirst) classes.push(styles['section-first']);
        if (isLast) classes.push(styles['section-last']);
        if (props.styles && props.styles.section) {
          classes.push(props.styles.section.className);
          if (isFirst) classes.push(props.styles.section.first);
          if (isLast) classes.push(props.styles.section.last);
        }

        if (props.separators && props.separators.leading && props.separators.leading.section) {
          leadingSeparator = _1 || props.separators.leading.section || (() => null);
          withoutLeadingSeparator = _2 || _.isUndefined(props.separators.leading.section) || false;
        }
        if (props.separators && props.separators.trailing && props.separators.trailing.section) {
          trailingSeparator = _3 || props.separators.trailing.section || (() => null);
          withoutTrailingSeparator = _4 || _.isUndefined(props.separators.trailing.section) || false;
        }
        break;
      case 'item':
        classes.push(styles.item);
        if (props.styles && props.styles.item) classes.push(props.styles.item.className);

        if (props.separators && props.separators.leading && props.separators.leading.item) {
          leadingSeparator = _1 || props.separators.leading.item || (() => null);
          withoutLeadingSeparator = _2 || _.isUndefined(props.separators.leading.item) || false;
        }
        if (props.separators && props.separators.trailing && props.separators.trailing.item) {
          trailingSeparator = _3 || props.separators.trailing.item || (() => null);
          withoutTrailingSeparator = _4 || _.isUndefined(props.separators.trailing.item) || false;
        }
        break;
      default:
        break;
    }
    if (className) classes.push(className);
    const cx = classnames(classes);
    let children: React.ReactNode;
    switch (option.type) {
      case 'sectionGroup':
        if (_.isArray(option.children)) {
          // eslint-disable-next-line no-shadow
          children = _.map(option.children, (child, index) =>
            renderOption(child, index, {
              isFirst: index === 0,
              isLast: option.children && index === option.children.length - 1,
            }),
          );
        }
        return (
          <ViewSidebarSectionGroup
            key={`sectionGroup-${index + 1}`}
            className={cx}
            leadingSeparator={leadingSeparator}
            withoutLeadingSeparator={withoutLeadingSeparator}
            trailingSeparator={trailingSeparator}
            withoutTrailingSeparator={withoutTrailingSeparator}
            {...rest}
          >
            {children}
          </ViewSidebarSectionGroup>
        );
      case 'section':
        if (_.isFunction(option.children)) {
          // tslint:disable-next-line: variable-name
          const childrenTmp = option.children({
            props: props.props,
            helpers: {
              buildItem: (options = {}) => ({ type: 'item', ...options }),
            },
          });
          // eslint-disable-next-line no-shadow
          children = _.map(childrenTmp, (child, index) =>
            renderOption(child, index, {
              isFirst: index === 0,
              isLast: index === childrenTmp.length - 1,
            }),
          );
        } else if (_.isArray(option.children)) {
          // eslint-disable-next-line no-shadow
          children = _.map(option.children, (child, index) =>
            renderOption(child, index, {
              isFirst: index === 0,
              isLast: option.children && index === option.children.length - 1,
            }),
          );
        }
        return (
          <ViewSidebarSection
            key={`section-${index + 1}`}
            className={cx}
            leadingSeparator={leadingSeparator}
            withoutLeadingSeparator={withoutLeadingSeparator}
            trailingSeparator={trailingSeparator}
            withoutTrailingSeparator={withoutTrailingSeparator}
            {...rest}
          >
            {children}
          </ViewSidebarSection>
        );
      case 'item':
        if (option.Component) {
          children = React.Children.only(React.createElement(option.Component, option.componentProps));
        }
        return (
          <ViewSidebarSectionItem
            key={`item-${index + 1}`}
            className={cx}
            leadingSeparator={leadingSeparator}
            withoutLeadingSeparator={withoutLeadingSeparator}
            trailingSeparator={trailingSeparator}
            withoutTrailingSeparator={withoutTrailingSeparator}
            {...rest}
          >
            {children}
          </ViewSidebarSectionItem>
        );
      default:
        return null;
    }
  };
  /* eslint-disable @typescript-eslint/no-non-null-assertion, react/destructuring-assignment */
  return (
    <div className={classnames(styles.wrapper, props.styles!.wrapper)}>
      <div className={classnames(styles.container, props.styles!.container, props.className)}>
        {props.options &&
          _.map(props.options, (option, index) =>
            renderOption(option, index, {
              isFirst: index === 0,
              isLast: index === props.options!.length - 1,
            }),
          )}
      </div>
    </div>
  );
  /* eslint-enable @typescript-eslint/no-non-null-assertion, react/destructuring-assignment */
};
export default ViewSidebar;
