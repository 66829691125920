import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import styles from './selectorItem.module.scss';

type SelectorItemProps = {
  className?: string;
  onClick?: Function;
  value?: string;
  label?: React.ReactNode;
  isSelected?: boolean;
  isLast?: boolean;
};

const SelectorItem: React.FunctionComponent<SelectorItemProps> = ({
  className,
  onClick,
  value,
  label,
  isSelected,
  isLast,
}) => (
  <div
    className={classnames(styles.wrapper, { [styles.isSelected]: !!isSelected, [styles.isLast]: !!isLast }, className)}
    onClick={(e: any) => {
      if (_.isFunction(onClick)) onClick(value, e);
    }}
  >
    <span className={classnames(styles.label)}>{label}</span>
  </div>
);
export default SelectorItem;
