import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo-hooks';

import useSessionStorage from 'UtilHooks/useSessionStorage';
import Avatar from 'Components/avatar/avatar';
import TextOverflow from 'Components/textOverflow';

import styles from './sidebar.module.scss';

const SidebarIdentity: React.FunctionComponent<{ onlyAvatar?: boolean }> = ({ onlyAvatar }) => {
  const [token] = useSessionStorage('token');
  const {
    data: { me },
    loading,
    error,
  }: any = useQuery(
    gql`
      query me {
        me {
          id
          userName
          email
        }
      }
    `,
    { context: { headers: { Authorization: `Bearer ${token}` } } },
  );
  if (loading || error) return null;
  return (
    <div className={classnames(styles.identity)}>
      <Avatar
        size="32px"
        className={classnames(styles.avatar, { [styles.collapsed]: onlyAvatar })}
        identifier={me.userName}
        displayName={me.userName}
      />
      {!onlyAvatar && (
        <div className={classnames(styles.infos)}>
          <div className={classnames(styles.username)}>
            <TextOverflow>{me.userName}</TextOverflow>
          </div>
          <div className={classnames(styles.email)}>
            <TextOverflow>{me.email}</TextOverflow>
          </div>
        </div>
      )}
    </div>
  );
};
export default SidebarIdentity;
