import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { matchPath } from 'react-router';

import InlineSvg from 'Components/inlineSvg';
import { useStoreState } from 'Store';
import routes from 'Routes';
import useRouter from 'UtilHooks/useRouter';

import styles from './settingsLayoutHeader.module.scss';

type SettingsLayoutHeaderProps = {};

const SettingsLayoutHeader: React.FunctionComponent<SettingsLayoutHeaderProps> = () => (
  <div className={classnames(styles.wrapper)}>
    <div className={classnames(styles.container)}>
      <Breadcrumbs />
    </div>
  </div>
);
export default SettingsLayoutHeader;

const Breadcrumbs = () => {
  const pathname = useStoreState(state => state.router.location.pathname);
  const subpaths = React.useMemo(() => _.tail(pathname.split('/')), [pathname]);
  const route = React.useMemo(
    () =>
      _.head(
        _.reject(routes, r => _.isUndefined(r.path) || _.isNull(matchPath(pathname, { path: r.path, exact: true }))),
      ),
    [pathname],
  );
  React.useEffect(() => {
    if (route && route.breadcrumbs && subpaths.length !== route.breadcrumbs.length)
      throw new Error(`Breadcrumbs for pathname ${pathname} is incomplete.`);
  }, [pathname, route, subpaths.length]);
  return React.useMemo(
    () => (
      <div className={classnames(styles.breadcrumbs)}>
        {subpaths.map((s: string, i: number, array: string[]) => {
          if (route && route.breadcrumbs) {
            const crumb = route.breadcrumbs[i];
            if (_.isNull(crumb)) return null;
            if (_.isFunction(crumb)) {
              const { label, to } = crumb(s);
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className={styles.crumb}>
                  <Breadcrumb label={label} to={to} />
                  {array.length - 1 !== i && <Divider />}
                </div>
              );
            }
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} className={styles.crumb}>
                <Breadcrumb label={crumb} />
                {array.length - 1 !== i && <Divider />}
              </div>
            );
          }
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={styles.crumb}>
              <Breadcrumb label={s} />
              {array.length - 1 !== i && <Divider />}
            </div>
          );
        })}
      </div>
    ),
    [route, subpaths],
  );
};

const Breadcrumb = ({ label, to }: { label: string | null; to?: string }) => {
  const { push } = useRouter();
  const handleClick = React.useMemo(() => (!_.isUndefined(to) ? _.partial(push, to) : undefined), [push, to]);
  return React.useMemo(() => {
    if (_.isNull(label)) return null;
    return <span onClick={handleClick}>{label}</span>;
  }, [handleClick, label]);
};

const Divider = () =>
  React.useMemo(
    () => (
      <span className={styles.divider}>
        <InlineSvg src="icon-chevron-right" size="14px" />
      </span>
    ),
    [],
  );
