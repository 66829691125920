import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

import * as Table from 'Components/table';
import LoadingIndicator from 'Components/loadingIndicator';
import EmptyStateWarning from 'Components/emptyStateWarning';
import ErrorStateWarning from 'Components/errorStateWarning';
import formatError from 'Utils/formatError';

import styles from './viewTable.module.scss';

export type ViewTableProps = {
  withoutHeaders?: boolean;
  withoutMultiSort?: boolean;
  useDescendingFirst?: boolean;
  withoutNeutralOrder?: boolean;
  withoutActionsColumnHeaderLabel?: boolean;
  columns: (Table.Column & { disableSort?: boolean })[];
  rowKey: string;
  rows: any[];
  order?: { field: string; direction: string }[];
  updateOrder?: (newOrder: { field: string; direction: string }[] | null) => void;
  loading?: boolean;
  error?: Error;
  actionsColumnKey?: string;
  actions?: {
    key: string;
    Icon: React.ComponentType<any>;
    disabled?: ((rowData: any) => boolean) | true;
    onClick?: (rowData: any) => void;
  }[];
  onRow?: (rowData: any, { rowIndex, rowKey }: any) => any;
  renderers?: Table.Renderers;
};

const CLASSNAMES = {
  table: classnames(styles.table),
  header: {
    wrapper: classnames(styles['table-header']),
    row: classnames(styles['table-header-row']),
    cell: classnames(styles['table-header-cell']),
  },
  body: {
    wrapper: classnames(styles['table-body']),
    row: classnames(styles['table-body-row']),
    cell: classnames(styles['table-body-cell']),
  },
};
const DEFAULT_ACTIONS_COLUMN_KEY = '__actions__';

export const ActionButton: React.FunctionComponent<{
  key?: string;
  Icon: React.ComponentType<any>;
  onClick?: () => void;
}> = ({ key, Icon, onClick }) => (
  <div key={key} className={classnames(styles['action-button'])} onClick={onClick}>
    <Icon />
  </div>
);

export const ActionButtons: React.FC<{
  style?: any;
  actions?: {
    key: string;
    Icon: React.ComponentType<any>;
    onClick?: () => void;
  }[];
}> = ({ style, actions }) => {
  const renderAction = React.useCallback(
    ({ key, Icon, onClick }: any) => (
      <div key={key}>
        <ActionButton Icon={Icon} onClick={onClick} />
      </div>
    ),
    [],
  );
  const render = React.useCallback(() => {
    if (!_.isArray(actions)) return null;
    if (_.isEmpty(actions)) return null;
    return <div style={style}>{_.map(actions, renderAction)}</div>;
  }, [actions, renderAction, style]);
  return React.useMemo(() => render(), [render]);
};

const ViewTable: React.FunctionComponent<ViewTableProps> = ({
  withoutHeaders,
  withoutMultiSort,
  useDescendingFirst,
  withoutNeutralOrder,
  withoutActionsColumnHeaderLabel,
  columns,
  rowKey,
  rows,
  loading,
  error,
  order,
  updateOrder,
  actionsColumnKey,
  actions,
  onRow = (_1, { rowIndex }) => ({
    className: rowIndex % 2 ? classnames(styles['table-body-even-row']) : classnames(styles['table-body-odd-row']),
  }),
  renderers,
}) => {
  const getColumns = React.useCallback(() => _.map(columns, column => _.omit(column, 'disableSort')), [columns]);
  const getOrderPosition = React.useCallback(
    (property: string) => {
      const foundIndex = _.findIndex(order || [], ['field', property]);
      return foundIndex >= 0 ? foundIndex + 1 : undefined;
    },
    [order],
  );
  const getOrderDirection = React.useCallback(
    (property: string) => _.get(_.find(order || [], ['field', property]) || {}, 'direction', undefined),
    [order],
  );
  const getNextOrderDirection = React.useCallback(
    (currentDirection?: string) =>
      // eslint-disable-next-line no-nested-ternary
      _.isUndefined(currentDirection)
        ? useDescendingFirst
          ? 'DESC'
          : 'ASC'
        : _.get(
            _.merge(
              {},
              useDescendingFirst ? { DESC: 'ASC' } : { ASC: 'DESC' },
              // eslint-disable-next-line no-nested-ternary
              withoutNeutralOrder ? (useDescendingFirst ? { ASC: 'DESC' } : { DESC: 'ASC' }) : {},
            ),
            currentDirection,
          ),
    [useDescendingFirst, withoutNeutralOrder],
  );
  const onSort = React.useCallback(
    ({ property }: any) =>
      !_.isUndefined(order)
        ? () => {
            let newOrder: any[] = [];
            if (!_.isEmpty(order)) {
              let newOrderPushed = false;
              _.forEach(order, ({ field, direction }) => {
                const changeDirection = _.isEqual(property, field);
                if (!changeDirection && !_.isUndefined(direction)) {
                  if (!withoutMultiSort) newOrder.push({ field, direction });
                  else if (newOrder.length === 0) newOrder.push({ field, direction });
                  else newOrder = [{ field, direction }];
                } else {
                  const nextOrderDirection = changeDirection ? getNextOrderDirection(direction) : direction;
                  if (!_.isUndefined(nextOrderDirection)) {
                    if (!withoutMultiSort) newOrder.push({ field, direction: nextOrderDirection });
                    else if (newOrder.length === 0) newOrder.push({ field, direction: nextOrderDirection });
                    else newOrder = [{ field, direction: nextOrderDirection }];
                    newOrderPushed = true;
                  } else if (changeDirection) newOrderPushed = true;
                }
              });
              if (!newOrderPushed) {
                if (!withoutMultiSort)
                  newOrder.push({
                    field: property,
                    direction: getNextOrderDirection(),
                  });
                else if (newOrder.length === 0)
                  newOrder.push({
                    field: property,
                    direction: getNextOrderDirection(),
                  });
                else
                  newOrder = [
                    {
                      field: property,
                      direction: getNextOrderDirection(),
                    },
                  ];
              }
            } else if (!withoutMultiSort)
              newOrder.push({
                field: property,
                direction: getNextOrderDirection(),
              });
            else if (newOrder.length === 0)
              newOrder.push({
                field: property,
                direction: getNextOrderDirection(),
              });
            else
              newOrder = [
                {
                  field: property,
                  direction: getNextOrderDirection(),
                },
              ];
            if (_.isFunction(updateOrder)) updateOrder(newOrder);
          }
        : undefined,
    [getNextOrderDirection, order, updateOrder, withoutMultiSort],
  );
  const sortable = React.useCallback(
    (column: any) => (label: string) => (
      <div className={classnames(styles['sort-container'])} onClick={onSort(column)}>
        <span>{label}</span>
        {!_.isUndefined(order) ? (
          <>
            {!withoutMultiSort && <sup>{getOrderPosition(column.property)}</sup>}
            <SortIcon className={classnames(styles['sort-icon'])} direction={getOrderDirection(column.property)} />
          </>
        ) : null}
      </div>
    ),
    [getOrderDirection, getOrderPosition, onSort, order, withoutMultiSort],
  );
  const getSortableColumns = React.useCallback(
    () =>
      _.map(
        columns,
        column =>
          _.omit(
            _.update(
              _.update(column, 'header.formatters', () =>
                !_.isUndefined(order) && !column.disableSort ? [sortable(column)] : [],
              ),
              'cell.formatters',
              v => _.concat(v || [], [(value: string) => (_.isEmpty(value) ? <small>N/A</small> : value)]),
            ),
            'disableSort',
          ) as Table.Column,
      ),
    [columns, order, sortable],
  );
  const getActionsColumnKey = React.useCallback(() => {
    if (
      _.isUndefined(actionsColumnKey) &&
      _.some(getColumns(), ({ property }) => _.isEqual(property, DEFAULT_ACTIONS_COLUMN_KEY))
    )
      throw new Error(
        `Cannot use property name '${DEFAULT_ACTIONS_COLUMN_KEY}' in columns without use of 'actionsColumnsKey'`,
      );
    return actionsColumnKey || DEFAULT_ACTIONS_COLUMN_KEY;
  }, [actionsColumnKey, getColumns]);
  const getActionsColumn = React.useCallback(
    () =>
      !(_.isUndefined(actions) || _.isEmpty(actions))
        ? {
            property: getActionsColumnKey(),
            header: {
              label: !withoutActionsColumnHeaderLabel ? `Action${actions.length > 1 ? 's' : ''}` : '',
            },
            cell: {
              formatters: [
                (_1: any, { rowData }: any) => (
                  <div className={classnames(styles['action-buttons'])}>
                    {_.map(actions, ({ key, Icon, disabled, onClick }) => {
                      if (_.isBoolean(disabled) && disabled) return null;
                      if (_.isFunction(disabled) && disabled(rowData)) return null;
                      return (
                        <ActionButton
                          key={`action-${key}-${_.get(rowData, rowKey)}`}
                          Icon={Icon}
                          onClick={onClick && _.partial(onClick, rowData)}
                        />
                      );
                    })}
                  </div>
                ),
              ],
            },
          }
        : [],
    [actions, getActionsColumnKey, rowKey, withoutActionsColumnHeaderLabel],
  );
  return React.useMemo(() => {
    if (_.isEmpty(getColumns())) return null;
    if (loading)
      return (
        <Table.Provider withoutVirtualization columns={getColumns()} classNames={CLASSNAMES}>
          {({ nbColumns, getHeaderRows }) => (
            <>
              {!withoutHeaders && <Table.Header headerRows={getHeaderRows()} />}
              <tbody>
                <tr>
                  <td colSpan={nbColumns}>
                    <LoadingIndicator hideMessage className={classnames(styles['table-body-loader'])} />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </Table.Provider>
      );
    if (_.isError(error))
      return (
        <Table.Provider withoutVirtualization columns={getColumns()} classNames={CLASSNAMES}>
          {({ nbColumns, getHeaderRows }) => (
            <>
              {!withoutHeaders && <Table.Header headerRows={getHeaderRows()} />}
              <tbody>
                <tr>
                  <td colSpan={nbColumns}>
                    <ErrorStateWarning message={formatError(error)} />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </Table.Provider>
      );
    if (_.isEmpty(rows))
      return (
        <Table.Provider withoutVirtualization columns={getColumns()} classNames={CLASSNAMES}>
          {({ nbColumns, getHeaderRows }) => (
            <>
              {!withoutHeaders && <Table.Header headerRows={getHeaderRows()} />}
              <tbody>
                <tr>
                  <td colSpan={nbColumns}>
                    <EmptyStateWarning message="Aucun résultat" />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </Table.Provider>
      );
    return (
      <Table.Provider
        withoutVirtualization
        columns={_.concat(getSortableColumns(), getActionsColumn())}
        classNames={CLASSNAMES}
        renderers={renderers}
      >
        {({ resolveRows, getHeaderRows }) => (
          <>
            {!withoutHeaders && <Table.Header headerRows={getHeaderRows()} />}
            <Table.Body rowKey={rowKey} rows={resolveRows(rows)} onRow={onRow} />
          </>
        )}
      </Table.Provider>
    );
  }, [
    error,
    getActionsColumn,
    getColumns,
    getSortableColumns,
    loading,
    onRow,
    renderers,
    rowKey,
    rows,
    withoutHeaders,
  ]);
};

const SortIcon: React.FunctionComponent<{
  className?: string;
  direction?: string;
}> = ({ className, direction }) => {
  const props = { className, size: '15px' };
  // eslint-disable-next-line react/jsx-props-no-spreading
  if (_.isUndefined(direction)) return <FaSort {...props} />;
  switch (direction) {
    case 'ASC':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <FaSortUp {...props} />;
    case 'DESC':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <FaSortDown {...props} />;
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <FaSort {...props} />;
  }
};

export default ViewTable;
