import { action, Action, thunk, Thunk } from 'easy-peasy';

import { RootStoreModel } from 'Store';
import SidebarState from 'Components/sidebar/sidebarState';

export type LayoutModel = {
  sidebar: SidebarState;
  sidebarUpdated: Action<LayoutModel, SidebarState>;
  updateSidebar: Thunk<LayoutModel, SidebarState, any, RootStoreModel, void>;
};

const layoutModel: LayoutModel = {
  sidebar: SidebarState.FULL,
  sidebarUpdated: action((state, payload) => {
    state.sidebar = payload; // eslint-disable-line
  }),
  updateSidebar: thunk((actions, newState) => {
    actions.sidebarUpdated(newState);
  }),
};
export default layoutModel;
