import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import DropdownMenu, { DropdownChildrenFunctionProps } from 'Components/dropdownMenu';
import DateSummary from 'Views/components/timeRangeSelector/dateSummary';
import getRelativeSummary from 'Utils/date/getRelativeSummary';
import { DEFAULT_PERIOD } from 'Constants/date';
import HeaderItem from 'Components/headerItem';
import getDynamicText from 'Utils/getDynamicText';
import getUtcToSystem from 'Utils/date/getUtcToSystem';
import RelativeSelector from 'Views/components/timeRangeSelector/dateRange/relativeSelector';
import SelectorItem from 'Views/components/timeRangeSelector/dateRange/selectorItem';
import DateRange from 'Views/components/timeRangeSelector/dateRange';
import getLocalToSystem from 'Utils/date/getLocalToSystem';
import getDateWithTimezoneInUtc from 'Utils/date/getDateWithTimezoneInUtc';
import getInternalDate from 'Utils/date/getInternalDate';
import MultipleSelectorSubmitRow from 'Views/components/multipleSelectorSubmitRow';

import styles from './timeRangeSelector.module.scss';

const moment: any = extendMoment(Moment);

type Datetime = {
  start?: Moment.Moment | null;
  end?: Moment.Moment | null;
  relative?: string | null;
  utc?: boolean | null;
};
type TimeRangeSelectorProps = Datetime & {
  updatePeriodRange?: ({
    newPeriod,
    newPeriodRangeStart,
    newPeriodRangeEnd,
    newUtc,
  }: {
    newPeriod?: string | null;
    newPeriodRangeStart?: Moment.Moment | null;
    newPeriodRangeEnd?: Moment.Moment | null;
    newUtc?: boolean | null;
  }) => void;
  showAbsolute?: boolean;
  showRelative?: boolean;
  onChange?: () => void;
  onUpdate?: () => void;
};

const TimeRangeSelector: React.FunctionComponent<TimeRangeSelectorProps> = props => {
  const getStartFromProps = React.useCallback(
    () => (props.start ? getInternalDate(props.start, props.utc) : undefined),
    // eslint-disable-next-line react/destructuring-assignment
    [props.start, props.utc],
  );
  const [start, setStart] = React.useState(getStartFromProps);
  React.useEffect(() => {
    setStart(getStartFromProps());
    return _.noop;
  }, [getStartFromProps]);
  const updateStart = (newStart?: Datetime['start'], { onlyState }: { onlyState?: boolean } = {}) => {
    if (onlyState) setStart(newStart);
  };
  const getEndFromProps = React.useCallback(() => (props.end ? getInternalDate(props.end, props.utc) : undefined), [
    // eslint-disable-next-line react/destructuring-assignment
    props.end,
    // eslint-disable-next-line react/destructuring-assignment
    props.utc,
  ]);
  const [end, setEnd] = React.useState(getEndFromProps);
  React.useEffect(() => {
    setEnd(getEndFromProps());
    return _.noop;
  }, [getEndFromProps]);
  const updateEnd = (newEnd?: Datetime['end'], { onlyState }: { onlyState?: boolean } = {}) => {
    if (onlyState) setEnd(newEnd);
  };
  const updatePeriodRange = ({
    newPeriod,
    newStart,
    newEnd,
    newUtc,
  }: { newPeriod?: any; newStart?: any; newEnd?: any; newUtc?: any } = {}) => {
    if (_.isFunction(props.updatePeriodRange)) {
      props.updatePeriodRange({
        newPeriod,
        newUtc,
        newPeriodRangeStart: newStart,
        newPeriodRangeEnd: newEnd,
      });
    }
    if (!_.isUndefined(newStart)) setStart(newStart);
    if (!_.isUndefined(newEnd)) setEnd(newEnd);
  };
  // eslint-disable-next-line react/destructuring-assignment
  const getRelativeFromProps = React.useCallback(() => props.relative || DEFAULT_PERIOD, [props.relative]);
  const [relative, setRelative] = React.useState(getRelativeFromProps);
  React.useEffect(() => {
    setRelative(getRelativeFromProps());
    return _.noop;
  }, [getRelativeFromProps]);
  const updateRelative = (newRelative?: Datetime['relative'], { onlyState }: { onlyState?: boolean } = {}) => {
    if (onlyState) setRelative(newRelative || DEFAULT_PERIOD);
  };
  // eslint-disable-next-line react/destructuring-assignment
  const getUtcFromProps = React.useCallback(() => props.utc, [props.utc]);
  const [utc, setUtc] = React.useState(getUtcFromProps);
  React.useEffect(() => {
    setUtc(getUtcFromProps());
    return _.noop;
  }, [getUtcFromProps]);
  const updateUtc = (newUtc?: Datetime['utc'], { onlyState }: { onlyState?: boolean } = {}) => {
    if (onlyState) setUtc(newUtc);
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  const updateDatetime = (datetime: Datetime, { onlyState }: { onlyState?: boolean } = {}) => {
    if (onlyState) {
      if (!_.isUndefined(datetime.relative)) updateRelative(datetime.relative, { onlyState });
      if (!_.isUndefined(datetime.start)) updateStart(datetime.start, { onlyState });
      if (!_.isUndefined(datetime.end)) updateEnd(datetime.end, { onlyState });
      if (!_.isUndefined(datetime.utc)) updateUtc(datetime.utc, { onlyState });
    } else {
      updatePeriodRange({
        newPeriod: datetime.relative,
        newStart: datetime.start,
        newEnd: datetime.end,
        newUtc: datetime.utc,
      });
    }
  };
  const callCallback = (callback: any, datetime: Datetime) => {
    if (!_.isFunction(callback)) return;
    // Change local date into either UTC or local time (local time defined by user preference)
    callback({
      ..._.omit(datetime, ['start', 'end']),
      start: datetime.start ? getDateWithTimezoneInUtc(datetime.start, { utc }) : datetime.start,
      end: datetime.end ? getDateWithTimezoneInUtc(datetime.end, { utc }) : datetime.end,
    });
  };
  const handleOpenMenu = () => {
    setIsOpen(true);
  };
  const handleUpdate = (datetime: Datetime) => {
    const { onUpdate } = props;
    setIsOpen(false);
    setHasChanges(false);
    callCallback(onUpdate, datetime);
  };
  const handleCloseMenu = () => {
    // Only call update if we close when absolute date is selected
    if (hasChanges) {
      const newDateTime: Datetime = { start, end, utc, relative: null };
      updateDatetime(newDateTime);
      handleUpdate(newDateTime);
    }
    setIsOpen(false);
  };
  const handleClear = () => {
    const { onChange } = props;
    const newDateTime: Datetime = {
      start: null,
      end: null,
      utc: null,
      relative: null,
    };
    updateDatetime(newDateTime);
    callCallback(onChange, newDateTime);
    handleUpdate(newDateTime);
  };
  // eslint-disable-next-line no-shadow
  const handleSelectRelative = (relative: string) => {
    const { onChange } = props;
    const newDateTime: Datetime = {
      relative: relative as string,
      start: null,
      end: null,
      utc: null,
    };
    updateDatetime(newDateTime);
    callCallback(onChange, newDateTime);
    handleUpdate(newDateTime);
  };
  const handleAbsoluteClick = () => {
    const { onChange } = props;
    const newDatetime: Datetime = {
      relative: null,
      start: moment().startOf('day'),
      end: moment().endOf('day'),
      utc: null,
    };
    setHasChanges(true);
    updateDatetime(newDatetime, { onlyState: true });
    callCallback(onChange, newDatetime);
  };
  // eslint-disable-next-line no-shadow
  const handleSelectDateRange = ({ start, end }: any) => {
    const { onChange } = props;
    const newDatetime: Datetime = { start, end, utc, relative: null };
    setHasChanges(true);
    updateDatetime(newDatetime, { onlyState: true });
    callCallback(onChange, newDatetime);
  };
  const handleUseUtc = () => {
    const { onChange } = props;
    let { start: newStart, end: newEnd } = props;
    const newUtc = !utc;
    if (!newStart) {
      newStart = start ? getDateWithTimezoneInUtc(start, { utc }) : undefined;
    }
    if (!newEnd) {
      newEnd = end ? getDateWithTimezoneInUtc(end, { utc }) : undefined;
    }
    const newDateTime: Datetime = {
      relative: null,
      start: newUtc ? getLocalToSystem(newStart) : getUtcToSystem(newStart),
      end: newUtc ? getLocalToSystem(newEnd) : getUtcToSystem(newEnd),
      utc: newUtc,
    };
    setHasChanges(true);
    updateDatetime(newDateTime, { onlyState: true });
    callCallback(onChange, newDateTime);
  };

  const { showAbsolute, showRelative } = props;
  const shouldShowAbsolute = showAbsolute;
  const shouldShowRelative = showRelative;
  const isAbsoluteSelected = !(_.isUndefined(start) || _.isNull(start) || _.isUndefined(end) || _.isNull(end));

  return (
    <DropdownMenu keepMenuOpen isOpen={isOpen} onOpen={handleOpenMenu} onClose={handleCloseMenu}>
      {({ isOpen, getActorProps, getMenuProps }: DropdownChildrenFunctionProps) => (
        <div className={classnames(styles.root)}>
          <HeaderItem
            allowClear
            className={classnames(styles['header-item'])}
            icon="icon-calendar"
            isOpen={isOpen}
            hasSelected={(relative && relative !== DEFAULT_PERIOD) || isAbsoluteSelected}
            hasChanges={hasChanges}
            onClear={handleClear}
            {...getActorProps({})}
          >
            {getDynamicText({
              value: isAbsoluteSelected ? (
                <DateSummary hideTime start={start} end={end} />
              ) : (
                getRelativeSummary(relative)
              ),
              fixed: 'start to end',
            })}
          </HeaderItem>

          {isOpen && (
            <div
              className={classnames(styles.menu, {
                [styles.isAbsoluteSelected]: isAbsoluteSelected,
              })}
              {...getMenuProps({})}
            >
              <div
                className={classnames(styles['selector-list'], {
                  [styles.isAbsoluteSelected]: isAbsoluteSelected,
                })}
              >
                {shouldShowRelative && <RelativeSelector onClick={handleSelectRelative} selected={relative} />}
                {shouldShowAbsolute && (
                  <SelectorItem
                    isLast
                    onClick={handleAbsoluteClick}
                    value="absolute"
                    label="Date personnalisée"
                    isSelected={isAbsoluteSelected}
                  />
                )}
              </div>
              {isAbsoluteSelected && (
                <div className={classnames(styles['absolute-selector'])}>
                  <DateRange
                    utc={utc}
                    start={start}
                    end={end}
                    onChange={handleSelectDateRange}
                    onChangeUtc={handleUseUtc}
                  />
                  {hasChanges && <MultipleSelectorSubmitRow onSubmit={handleCloseMenu} />}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </DropdownMenu>
  );
};
TimeRangeSelector.defaultProps = {
  showAbsolute: true,
  showRelative: true,
};

export default TimeRangeSelector;
