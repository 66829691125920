import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from 'react-apollo-hooks';
import { FaSlidersH, FaDownload, FaPaperclip, FaWindowClose, FaRegFileAlt } from 'react-icons/fa';
import moment, { Moment } from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import useSessionStorage from 'UtilHooks/useSessionStorage';
import {
  INVOICES_DEFAULT_PAGE,
  INVOICES_DEFAULT_PER_PAGE,
  INVOICES_PER_PAGE_OPTIONS,
  QUERY_INVOICE_MAX_COUNT,
} from 'Constants';
import { DYNAMIC_FEATURE_FLAG } from 'Flags';
import Paginator from 'Components/paginator';
import Button from 'Components/button';
import QueryCount from 'Components/queryCount';
import CSVDownload from 'Components/csvDownload';
import ViewTable, { ViewTableProps, ActionButtons } from 'Views/components/viewTable';
import useRouter from 'UtilHooks/useRouter';
import useUrlSearchParams, { Params } from 'Utils/hooks/useUrlSearchParams';
import ViewSidebar from 'Views/components/sidebar';
import ViewSidebarFilter, { Filter } from 'Views/components/viewSidebarFilter';
import { DEFAULT_PERIOD } from 'Constants/date';
import getDateWithTimezoneInUtc from 'Utils/date/getDateWithTimezoneInUtc';
import parsePeriod from 'Utils/date/parsePeriod';
import OverlayTrigger from 'Components/overlayTrigger';
import Tooltip from 'Components/tooltip';
import { ViewSidebarOptionSectionChildrenGenerator } from 'Views/components/sidebar/viewSidebarSection';
import MultipleClientSelector from 'Views/components/multipleClientSelector';
import TimeRangeSelector from 'Views/components/timeRangeSelector';
import useModal from 'UtilHooks/useModal';
import notify from 'Utils/notify';

import styles from './invoicesView.module.scss';

enum StatusBadgeColor {
  BLUE,
  YELLOW,
  ORANGE,
  PURPLE,
  RED,
  GREEN,
  GRAY,
  BLACK,
}

const INTERCEPTED_STATUS_KEY = 'Interceptée';

const STATUS_BADGE_MAPPING: { [x: string]: [string, StatusBadgeColor] } = {
  EN_TRAITEMENT: ['En cours', StatusBadgeColor.BLUE],
  FLUX_REJETE: ['Erreur Chorus', StatusBadgeColor.GRAY],
  FLUX_INTEGRE: ['Intégré Chorus', StatusBadgeColor.GRAY],
  A_VALIDER_1: ['Attente validation', StatusBadgeColor.ORANGE],
  ERREUR_FOURNISSEUR_SUR_VALIDATEUR: ['Erreur valideur', StatusBadgeColor.PURPLE],
  REFUSEE_1: ['Refusée', StatusBadgeColor.RED],
  VALIDEE_1: ['Validée', StatusBadgeColor.BLUE],
  ABSENCE_VALIDATION_1_HORS_DELAI: ['Délai dépassé', StatusBadgeColor.YELLOW],
  A_VALIDER_2: ['À valider', StatusBadgeColor.ORANGE],
  ERREUR_COTRAITANT_SUR_VALIDATEUR: ['Erreur valideur', StatusBadgeColor.PURPLE],
  REFUSEE_2: ['Refusée', StatusBadgeColor.RED],
  VALIDEE_2: ['Validée', StatusBadgeColor.BLUE],
  ABSENCE_VALIDATION_2_HORS_DELAI: ['Délai dépassé', StatusBadgeColor.YELLOW],
  DEPOSEE: ['Déposée', StatusBadgeColor.BLUE],
  EN_COURS_ACHEMINEMENT: ['Acheminement', StatusBadgeColor.BLUE],
  MISE_A_DISPOSITION: ['À disposition', StatusBadgeColor.YELLOW],
  A_RECYCLER: ['À recycler', StatusBadgeColor.PURPLE],
  SUSPENDUE: ['Suspendue', StatusBadgeColor.ORANGE],
  COMPLETEE: ['Complétée', StatusBadgeColor.BLUE],
  REJETEE: ['Rejetée', StatusBadgeColor.RED],
  MANDATEE: ['Mandatée', StatusBadgeColor.BLUE],
  MISE_EN_PAIEMENT: ['Mise en paiement', StatusBadgeColor.GREEN],
  COMPTABILISEE: ['Comptabilisée', StatusBadgeColor.BLUE],
  MISE_A_DISPOSITION_COMPTABLE: ['Attente Comptable', StatusBadgeColor.YELLOW],

  [INTERCEPTED_STATUS_KEY]: ['Interceptée', StatusBadgeColor.ORANGE],

  EMAIL_FAILED: ['Erreur email', StatusBadgeColor.GRAY],
  EMAIL_BOUNCED: ['Erreur transmission', StatusBadgeColor.RED],
  EMAIL_DELIVERED: ['Email reçu', StatusBadgeColor.YELLOW],
  EMAIL_OPENED: ['Email lu', StatusBadgeColor.YELLOW],
  EMAIL_MARKED_AS_SPAM: ['Marqué spam', StatusBadgeColor.RED],
  TELECHARGEE: ['Téléchargée', StatusBadgeColor.ORANGE],
};

const fromString = (str?: string | null, context?: { getParams: () => Params }) => {
  if (_.isUndefined(str)) return undefined;
  return !_.isNull(str) ? getDateWithTimezoneInUtc(str, context && context.getParams()) : null;
};

const toString = (date?: Moment | null, context?: { getParams: () => Params }) => {
  if (_.isUndefined(date)) return undefined;
  return !_.isNull(date)
    ? getDateWithTimezoneInUtc(date, _.extend({}, context && context.getParams(), { stringify: true }))
    : null;
};

const InvoicesView: React.FunctionComponent<{}> = () => {
  const [token] = useSessionStorage('token');
  const { location, push, replace } = useRouter();
  const {
    params: { order, filter: filters, page, per_page: perPage, period, start, end, utc, client, statut },
    updateParams,
    errorParams: { page: errorPage, per_page: errorPerPage, period: errorPeriod },
    helpers: { getParams, toUrlSearchString },
  } = useUrlSearchParams({
    order: {
      initialValue: [{ field: 'created_at', direction: 'DESC' }],
      defaultValue: [],
      mapFromUrl: value =>
        _.map(JSON.parse(value), ({ field, direction }) => ({
          direction,
          field: _.startsWith(field, 'Facture.') ? `factureDocument.${field}` : field,
        })),
      // eslint-disable-next-line no-shadow
      mapToUrl: order =>
        JSON.stringify(
          _.map(order, ({ field, direction }) => ({
            direction,
            field: field.startsWith('factureDocument.')
              ? field.substring('factureDocument.'.length, field.length)
              : field,
          })),
        ),
    },
    filter: {
      defaultValue: { Facture: {} },
      mapFromUrl: value => ({
        Facture: JSON.parse(value),
      }),
      mapToUrl: value => JSON.stringify(_.get(value, 'Facture', {})),
    },
    page: {
      defaultValue: INVOICES_DEFAULT_PAGE,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: {
      defaultValue: INVOICES_DEFAULT_PER_PAGE,
      acceptedValues: INVOICES_PER_PAGE_OPTIONS,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
      onUpdate: ({ pathname, search, helpers: { omit } }) => ({ pathname, search: omit(search, 'page') }),
    },
    start: {
      mapFromUrl: fromString,
      mapToUrl: toString,
      dependsOn: ['utc'],
    },
    end: {
      // eslint-disable-next-line no-shadow
      validate: (end, { getParams }) => {
        if (_.isNull(end)) return;
        const params = getParams();
        if (params.start && params.start > end) throw new Error("Paramètre 'end' est invalide.");
      },
      mapFromUrl: fromString,
      mapToUrl: toString,
      dependsOn: ['start', 'utc'],
    },
    utc: {
      defaultValue: false,
      // eslint-disable-next-line no-shadow
      validate: utc => {
        if (_.isNull(utc) || _.isBoolean(utc)) return;
        throw new Error("Paramètre 'utc' est invalide.");
      },
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
    period: {
      defaultValue: DEFAULT_PERIOD,
      // eslint-disable-next-line no-shadow
      validate: period => {
        if (_.isNull(period)) return;
        try {
          parsePeriod(period);
        } catch {
          throw new Error("Paramètre 'period' est invalide.");
        }
      },
    },
    client: {
      defaultValue: null,
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
    statut: {
      defaultValue: [],
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
  });
  const updateOrder = React.useCallback(
    (newOrder: any) => updateParams({ order: _.isArray(newOrder) && newOrder.length > 0 ? newOrder : null }),
    [updateParams],
  );
  // eslint-disable-next-line no-shadow
  const updatePage = React.useCallback((page: any) => updateParams({ page }), [updateParams]);
  const updatePerPage = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/camelcase
    (per_page: any) => updateParams({ per_page }),
    [updateParams],
  );
  const updatePeriodRange = React.useCallback(
    ({ newPeriod, newPeriodRangeStart, newPeriodRangeEnd, newUtc }: any) =>
      updateParams({
        period: newPeriod,
        start: newPeriodRangeStart,
        end: newPeriodRangeEnd,
        utc: newUtc,
      }),
    [updateParams],
  );
  // eslint-disable-next-line no-shadow
  const updateClient = React.useCallback((client: any) => updateParams({ client }), [updateParams]);
  // eslint-disable-next-line no-shadow
  const updateStatut = React.useCallback(
    // eslint-disable-next-line no-shadow
    (statut: any) => updateParams({ statut: _.isArray(statut) && statut.length > 0 ? statut : null }),
    [updateParams],
  );
  const getParsedPeriod = React.useCallback(() => (!errorPeriod && period ? parsePeriod(period) : undefined), [
    errorPeriod,
    period,
  ]);
  const getQueryVariables = React.useCallback(() => {
    const parsedPeriod = getParsedPeriod();
    let startDate;
    if (!_.isUndefined(start)) startDate = moment(start);
    else if (!_.isUndefined(parsedPeriod)) startDate = parsedPeriod.start;
    let endDate;
    if (!_.isUndefined(end)) endDate = moment(end);
    else if (!_.isUndefined(parsedPeriod)) endDate = parsedPeriod.end;
    return {
      order: _.map(order, ({ field, direction }) => {
        let mappedField;
        if (field.startsWith('factureDocument.')) {
          mappedField = field.substring('factureDocument.'.length, field.length);
        } else if (field.startsWith('statutFacture.')) {
          mappedField = field.substring('statutFacture.'.length, field.length);
        } else {
          mappedField = field;
        }
        const result = {
          direction,
          field: mappedField,
        };
        return result;
      }),
      limit: errorPerPage ? 0 : perPage,
      // eslint-disable-next-line no-nested-ternary
      offset: errorPerPage ? 0 : perPage && (errorPage || page) ? perPage * ((errorPage ? 1 : page) - 1) : undefined,
      filters: _.merge(
        {},
        filters,
        // eslint-disable-next-line @typescript-eslint/camelcase
        client ? { Facture: { CodeClient_in: client } } : {},
        startDate
          ? {
              Facture: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                DateEmission_gte: startDate.format(moment.HTML5_FMT.DATE),
              },
            }
          : {},
        endDate
          ? {
              Facture: {
                // eslint-disable-next-line @typescript-eslint/camelcase
                DateEmission_lte: endDate.format(moment.HTML5_FMT.DATE),
              },
            }
          : {},
        // eslint-disable-next-line @typescript-eslint/camelcase
        statut ? _.omitBy({ statut_in: statut }, _.isEmpty) : {},
      ),
    };
  }, [getParsedPeriod, start, end, order, errorPerPage, perPage, errorPage, page, filters, client, statut]);
  const graphqlClient = useApolloClient();
  const { loading, error, data }: any = useQuery(
    gql`
      query invoices($limit: Int, $offset: Int, $order: [Order], $filters: FactureFilter) {
        facturesCount(filter: $filters)
        factures(limit: $limit, offset: $offset, order: $order, filter: $filters) {
          id
          created_at
          factureDocument
          statutFacture {
            statut
            lastUpdatedAt
          }
          client {
            id
            nom
          }
        }
        clients {
          id
          code
          nom
        }
      }
    `,
    {
      variables: getQueryVariables(),
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
    },
  );
  const getMaxPage = React.useCallback((count: number) => Math.ceil(count / perPage), [perPage]);
  const getMaxPageError = React.useCallback(() => {
    const maxPage = getMaxPage(data.facturesCount);
    return maxPage > 0 && page > maxPage
      ? new Error(
          `Paramètre 'page' doit être ${maxPage === 1 ? 'égal à 1' : `un entier inférieur ou égal à ${maxPage}`}.`,
        )
      : undefined;
  }, [data.facturesCount, getMaxPage, page]);
  const [showAttachmentsModal, closeAttachmentsModal] = useModal<{
    invoiceNumber: string;
    attachments: { id: string; Nom: string }[];
    close: () => void;
  }>(({ invoiceNumber, attachments, close }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(
      () => (
        <div className={classnames(styles['attachments-modal-wrapper'])}>
          <div className={classnames(styles.container)}>
            <div className={classnames(styles['header-wrapper'])}>
              <div className={classnames(styles['header-container'])}>
                <div className={classnames(styles['title-wrapper'])}>
                  <div className={classnames(styles['title-container'])}>
                    <div className={classnames(styles.title)}>Pièces jointes</div>
                    <div className={classnames(styles.subtitle)}>{invoiceNumber}</div>
                  </div>
                </div>
                <FaWindowClose className={classnames(styles['close-button'])} onClick={close} />
              </div>
            </div>
            <div className={classnames(styles['body-wrapper'])}>
              <div className={classnames(styles['body-container'])}>
                <div className={classnames(styles['list-wrapper'])}>
                  <div className={classnames(styles['list-container'])}>
                    {_.map(attachments, ({ id, Nom: filename }, index) => (
                      <div key={`file-${index}`} className={classnames(styles['item-wrapper'], styles.isFile)}>
                        <div className={classnames(styles['item-container'])}>
                          <div className={classnames(styles['item-content-with-padding'])}>
                            <FaRegFileAlt />
                            <span className={classnames(styles.filename)}>{filename.replace(/^PJC_/, '')}</span>
                            <FaDownload
                              className={classnames(styles['download-button'])}
                              onClick={async (e: any) => {
                                e.stopPropagation();
                                const { data, errors } = await graphqlClient.query({
                                  query: gql`
                                    query($invoiceNumber: String!, $id: ID!) {
                                      content: telechargerPJ(numeroFacture: $invoiceNumber, id: $id)
                                    }
                                  `,
                                  variables: {
                                    id,
                                    invoiceNumber,
                                  },
                                  context: { headers: { Authorization: `Bearer ${token}` } },
                                  fetchPolicy: 'network-only',
                                });
                                if (errors) {
                                  notify.error('Une erreur est survenue.');
                                } else if (_.isString(data.content)) {
                                  saveAs(
                                    await (await JSZip().loadAsync(data.content, { base64: true }))
                                      .file(filename.replace(/^PJC_/, ''))
                                      .async('blob'),
                                    filename.replace(/^PJC_/, ''),
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={classnames(styles['footer-wrapper'])}>
              <div className={classnames(styles['footer-container'])}>
                <Button
                  activable={false}
                  icon={FaDownload}
                  size="small"
                  priority="primary"
                  label="Télécharger tout"
                  onClick={async (e: any) => {
                    e.stopPropagation();
                    const { data, errors } = await graphqlClient.query({
                      query: gql`
                        query($invoiceNumber: String!) {
                          content: telechargerPJs(numeroFacture: $invoiceNumber)
                        }
                      `,
                      variables: {
                        invoiceNumber,
                      },
                      context: { headers: { Authorization: `Bearer ${token}` } },
                      fetchPolicy: 'network-only',
                    });
                    if (errors) {
                      notify.error('Une erreur est survenue.');
                    } else if (_.isString(data.content)) {
                      saveAs(
                        await (await JSZip().loadAsync(data.content, { base64: true })).generateAsync({ type: 'blob' }),
                        `${invoiceNumber}-attachments.zip`,
                      );
                    }
                  }}
                >
                  Télécharger tout
                </Button>
              </div>
            </div>
          </div>
        </div>
      ),
      [attachments, close, invoiceNumber],
    );
  });
  // const [showAttachmentsModal, closeAttachmentsModal] = useModal<{
  //   invoiceNumber: string;
  //   attachments: JSZip;
  //   close: () => void;
  // }>(({ invoiceNumber, attachments, close }) => {
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const getItemDepthFromAttachments = React.useCallback(
  //     () =>
  //       _.reduce<string, { [x: string]: number }>(
  //         _.keys(attachments.files),
  //         (acc, filename) => {
  //           acc[filename] = _.sumBy(
  //             filename.replace(new RegExp(/[^/]+$/g), '/').replace(new RegExp(/[/]$/g), ''),
  //             c => +(c === '/'),
  //           );
  //           return acc;
  //         },
  //         {},
  //       ),
  //     [attachments.files],
  //   );
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const [itemDepth, setItemDepth] = React.useState(getItemDepthFromAttachments);
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   React.useEffect(() => {
  //     setItemDepth(getItemDepthFromAttachments());
  //   }, [getItemDepthFromAttachments]);
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const [hiddenState] = React.useState(new Set<string>());
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const isHidden = React.useCallback((filename: string) => hiddenState.has(filename), [hiddenState]);
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const pathSort = React.useCallback(
  //     (paths: string[], sep = '/') =>
  //       _.get(
  //         _.reduce(
  //           _.sortBy(
  //             _.omitBy(
  //               _.map(
  //                 _.map(paths, el => el.split(sep)),
  //                 (el: string[]) => ({
  //                   directory: _.slice(el, 0, el.length - 1).join(sep),
  //                   filename: _.last(el),
  //                 }),
  //               ),
  //               _.isEmpty,
  //             ),
  //             ['directory', 'filename'],
  //           ),
  //           ({ buffer, result }, { directory, filename }, index, array) => {
  //             (_.isEmpty(directory) ? buffer : result).push(_.reject([directory, filename], _.isEmpty).join(sep));
  //             if (index + 1 >= array.length) {
  //               // eslint-disable-next-line no-param-reassign
  //               result = _.concat(result, buffer);
  //               // eslint-disable-next-line no-param-reassign
  //               buffer = [];
  //             }
  //             return { buffer, result };
  //           },
  //           { buffer: [] as string[], result: [] as string[] },
  //         ),
  //         'result',
  //         [],
  //       ),
  //     [],
  //   );
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   const render = React.useCallback(
  //     () => (
  //       <div className={classnames(styles['attachments-modal-wrapper'])}>
  //         <div className={classnames(styles.container)}>
  //           <div className={classnames(styles['header-wrapper'])}>
  //             <div className={classnames(styles['header-container'])}>
  //               <div className={classnames(styles['title-wrapper'])}>
  //                 <div className={classnames(styles['title-container'])}>
  //                   <div className={classnames(styles.title)}>Pièces jointes</div>
  //                   <div className={classnames(styles.subtitle)}>{invoiceNumber}</div>
  //                 </div>
  //               </div>
  //               <FaWindowClose className={classnames(styles['close-button'])} onClick={close} />
  //             </div>
  //           </div>
  //           <div className={classnames(styles['body-wrapper'])}>
  //             <div className={classnames(styles['body-container'])}>
  //               <div className={classnames(styles['list-wrapper'])}>
  //                 <div className={classnames(styles['list-container'])}>
  //                   {_.map(
  //                     _.reject(
  //                       _.map<string, [string, JSZip.JSZipObject]>(pathSort(_.keys(attachments.files)), filename => [
  //                         filename,
  //                         attachments.files[filename],
  //                       ]),
  //                       ([filename]) => isHidden(filename),
  //                     ),
  //                     ([filename, zip], index) => (
  //                       <div
  //                         key={`file-${index}`}
  //                         className={classnames(styles['item-wrapper'], {
  //                           [styles.isFile]: !_.isUndefined(zip),
  //                         })}
  //                       >
  //                         <div className={classnames(styles['item-container'])}>
  //                           <div
  //                             className={classnames(styles['item-content-with-padding'])}
  //                             style={
  //                               !_.isUndefined(zip)
  //                                 ? {
  //                                     paddingLeft: `${20 * _.get(itemDepth, zip.name, 0)}px`,
  //                                   }
  //                                 : undefined
  //                             }
  //                           >
  //                             {!_.isUndefined(zip) && <FaRegFileAlt />}
  //                             {_.isUndefined(zip) && <FaRegFolderOpen />}
  //                             <span className={classnames(styles.filename)}>
  //                               {filename.replace(new RegExp(/\/$/), '').replace(new RegExp(/^([^/]+\/)*/), '')}
  //                             </span>
  //                             {!_.isUndefined(zip) && (
  //                               <FaDownload
  //                                 className={classnames(styles['download-button'])}
  //                                 onClick={async (e: any) => {
  //                                   e.stopPropagation();
  //                                   saveAs(await zip.async('blob'), filename);
  //                                 }}
  //                               />
  //                             )}
  //                           </div>
  //                         </div>
  //                       </div>
  //                     ),
  //                   )}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className={classnames(styles['footer-wrapper'])}>
  //             <div className={classnames(styles['footer-container'])}>
  //               <Button
  //                 activable={false}
  //                 icon={FaDownload}
  //                 size="small"
  //                 priority="primary"
  //                 label="Télécharger tout"
  //                 onClick={async (e: any) => {
  //                   e.stopPropagation();
  //                   await attachments
  //                     .generateAsync({ type: 'blob' })
  //                     .then(blob => saveAs(blob, `${invoiceNumber}-attachments.zip`));
  //                   close();
  //                 }}
  //               >
  //                 Télécharger tout
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     ),
  //     [attachments, close, invoiceNumber, isHidden, itemDepth, pathSort],
  //   );
  //   return render();
  // });
  const currencyFormat = React.useMemo(
    () =>
      new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }),
    [],
  );
  const getTableColumns = React.useCallback(
    () =>
      [
        {
          property: 'factureDocument.Facture.NumeroFacture',
          header: { label: 'Numéro' },
          cell: {
            formatters: [
              (value, { rowData: { id: invoiceId }, rowIndex }) => (
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={{
                    modifiers: {
                      preventOverflow: { enabled: true },
                      hide: { enabled: false },
                    },
                  }}
                  overlay={innerProps => (
                    <Tooltip id={`invoices-table-invoice-number-${rowIndex}`} {...(_.omit(innerProps, 'show') as any)}>
                      Visualiser la facture
                    </Tooltip>
                  )}
                >
                  <span style={{ cursor: 'pointer' }} onClick={_.partial(push, `/invoices/${invoiceId}`)}>
                    {value}
                  </span>
                </OverlayTrigger>
              ),
            ],
          },
        },
        {
          disableSort: true,
          property: '__attachments__',
          header: { label: 'PJs' },
          cell: {
            formatters: [
              (
                _1,
                {
                  rowData: {
                    factureDocument: {
                      Facture: { NumeroFacture: invoiceNumber, PiecesJointes: attachments },
                    },
                  },
                  rowIndex,
                },
              ) => (
                <ActionButtons
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  actions={_.reject(
                    _.concat<any>(
                      [],
                      _.isArray(attachments) && !_.isEmpty(attachments)
                        ? {
                            key: `attachments-${rowIndex}`,
                            Icon: FaPaperclip,
                            onClick: _.partial(showAttachmentsModal, {
                              invoiceNumber,
                              attachments,
                              close: closeAttachmentsModal,
                              /* attachments: _.reduce(
                                attachments,
                                (zip, { Contenu }) => {
                                  zip.loadAsync(Contenu, { base64: true });
                                  return zip;
                                },
                                new JSZip(),
                              ), */
                            }),
                          }
                        : null,
                    ),
                    _.isNull,
                  )}
                />
              ),
            ],
          },
        },
        {
          property: 'factureDocument.Facture.DateEmission',
          header: { label: 'Émise le' },
        },
        {
          property: 'factureDocument.Facture.TypeFacture',
          header: { label: 'Type' },
        },
        {
          property: 'factureDocument.Facture.CategorieFacture',
          header: { label: 'Catégorie' },
        },
        {
          property: 'factureDocument.Facture.CodeClient',
          header: { label: 'Code client' },
          cell: {
            formatters: [
              (
                value,
                {
                  rowData: {
                    client: { id: clientId },
                  },
                  rowIndex,
                },
              ) => (
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={{
                    modifiers: {
                      preventOverflow: { enabled: true },
                      hide: { enabled: false },
                    },
                  }}
                  overlay={innerProps => (
                    <Tooltip id={`invoices-table-client-code-${rowIndex}`} {...(_.omit(innerProps, 'show') as any)}>
                      Voir la fiche client
                    </Tooltip>
                  )}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={_.partial(push, {
                      pathname: `/clients/${clientId}/edit`,
                      state: {
                        fromInvoices: true,
                        invoicesSearch: location.search,
                      },
                    })}
                  >
                    {value}
                  </span>
                </OverlayTrigger>
              ),
            ],
          },
        },
        {
          disableSort: true,
          property: 'client.nom',
          header: { label: 'Nom client' },
          cell: {
            formatters: [
              (
                value,
                {
                  rowData: {
                    client: { id: clientId },
                  },
                  rowIndex,
                },
              ) => (
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={{
                    modifiers: {
                      preventOverflow: { enabled: true },
                      hide: { enabled: false },
                    },
                  }}
                  overlay={innerProps => (
                    <Tooltip id={`invoices-table-client-name-${rowIndex}`} {...(_.omit(innerProps, 'show') as any)}>
                      Voir la fiche client
                    </Tooltip>
                  )}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={_.partial(push, {
                      pathname: `/clients/${clientId}/edit`,
                      state: {
                        fromInvoices: true,
                        invoicesSearch: location.search,
                      },
                    })}
                  >
                    {value}
                  </span>
                </OverlayTrigger>
              ),
            ],
          },
        },
        {
          property: 'factureDocument.Facture.ReferenceCommande',
          header: { label: 'Engagement' },
        },
        {
          property: 'factureDocument.Facture.MontantsTotal.TotalHT',
          header: { label: 'Total HT' },
          cell: {
            formatters: [currencyFormat.format],
          },
        },
        {
          property: 'factureDocument.Facture.MontantsTotal.TotalTTC',
          header: { label: 'Total TTC' },
          cell: {
            formatters: [currencyFormat.format],
          },
        },
        {
          property: 'created_at',
          header: { label: 'Intégrée SEIZE le' },
          cell: {
            formatters: [value => moment(value, 'DD-MM-YYYY HH:mm:ss.SSS').format(moment.HTML5_FMT.DATE)],
          },
        },
        {
          property: 'statutFacture.statut',
          header: { label: 'Statut' },
          cell: {
            formatters: [
              (
                value,
                {
                  rowData: {
                    created_at: createdAt,
                    statutFacture: { lastUpdatedAt },
                  },
                  rowIndex,
                },
              ) =>
                _.isNull(value) ? (
                  <small>N/A</small>
                ) : (
                  <StatusBadge id={rowIndex} status={value} created_at={createdAt} lastUpdatedAt={lastUpdatedAt} />
                ),
            ],
          },
        },
      ] as ViewTableProps['columns'],
    [closeAttachmentsModal, currencyFormat.format, location.search, push, showAttachmentsModal],
  );
  const getCSVHeaders = React.useCallback(
    () =>
      _.map(
        _.reject(getTableColumns(), column => _.includes(['__attachments__'], column.property)),
        ({ property, header: { label } }) => ({
          label,
          key: property,
        }),
      ),
    [getTableColumns],
  );
  const [sidebarVisible, setSidebarVisibility] = React.useState(false);
  const toogleSidebarVisibility = React.useCallback(() => setSidebarVisibility(!sidebarVisible), [sidebarVisible]);
  React.useEffect(() => {
    if (_.isError(getMaxPageError()))
      replace({
        pathname: location.pathname,
        search: toUrlSearchString(
          _.extend(getParams(), {
            page: getMaxPage(data.facturesCount),
          }),
        ),
      });
  }, [data.facturesCount, getMaxPage, getMaxPageError, getParams, location.pathname, replace, toUrlSearchString]);
  const renderStickyHeader = React.useCallback(
    () => (
      <div className={classnames(styles['sticky-header'])}>
        <div className={classnames(styles['header-item'])}>
          {!error && data && (
            <MultipleClientSelector
              multi
              clients={_.map(data.clients, ({ code, nom }: { code: string; nom: string }) => ({
                id: code,
                name: nom,
              }))}
              selected={client}
              onUpdate={updateClient}
            />
          )}
        </div>
        <div className={classnames(styles['header-separator'])} />
        <div className={classnames(styles['header-item'])}>
          <TimeRangeSelector
            start={start}
            end={end}
            relative={period}
            utc={utc}
            updatePeriodRange={updatePeriodRange}
          />
        </div>
      </div>
    ),
    [client, data, end, error, period, start, updateClient, updatePeriodRange, utc],
  );
  const renderTitle = React.useCallback(
    () => (
      <h1 className={classnames(styles.title)}>
        Mes factures
        {!error && data && (
          <QueryCount className={classnames(styles.counter)} count={data.facturesCount} max={QUERY_INVOICE_MAX_COUNT} />
        )}
      </h1>
    ),
    [data, error],
  );
  const renderActions = React.useCallback(
    () => (
      <div className={classnames(styles.actions)}>
        <CSVDownload
          headers={getCSVHeaders()}
          overlayTriggerProps={{ placement: 'bottom' }}
          tooltip="Exporter votre recherche au format csv."
          filename={`exportFactures-${moment().format('YYYYMMDDHHmmss')}.csv`}
          onError={() => notify.error("Impossible d'accéder au serveur.")}
          onClick={(event, done) =>
            graphqlClient
              .query({
                query: gql`
                  query($order: [Order], $filters: FactureFilter) {
                    invoices: factures(order: $order, filter: $filters) {
                      id
                      created_at
                      factureDocument
                      statutFacture {
                        statut
                        lastUpdatedAt
                      }
                      client {
                        id
                        nom
                      }
                    }
                  }
                `,
                variables: _.pick(getQueryVariables(), ['order', 'filters']),
                context: { headers: { Authorization: `Bearer ${token}` } },
                fetchPolicy: 'network-only',
              })
              .then(({ data, errors: error }) =>
                _.isUndefined(error) ? done({ data: data.invoices }) : done({ error }),
              )
          }
        />
        <Button
          activable
          invertOnActive
          priority={sidebarVisible ? 'primary' : 'default'}
          size="xsmall"
          icon={() => <FaSlidersH size="16px" />}
          onClick={toogleSidebarVisibility}
          active={sidebarVisible}
          style={{ marginLeft: '5px' }}
        />
      </div>
    ),
    [getCSVHeaders, getQueryVariables, graphqlClient, sidebarVisible, token, toogleSidebarVisibility],
  );
  const renderTable = React.useCallback(
    () => (
      <div className={classnames(styles.table)}>
        <div className={classnames(styles.container)}>
          <ViewTable
            loading={loading}
            error={error || getMaxPageError()}
            columns={getTableColumns()}
            rowKey="id"
            rows={data.factures}
            order={order}
            updateOrder={updateOrder}
          />
        </div>
      </div>
    ),
    [data.factures, error, getMaxPageError, getTableColumns, loading, order, updateOrder],
  );
  const renderPagination = React.useCallback(
    () => (
      <div className={classnames(styles.pagination)}>
        {!error && data && (
          <Paginator
            page={page}
            perPage={perPage}
            pages={getMaxPage(data.facturesCount)}
            perPageOptions={INVOICES_PER_PAGE_OPTIONS}
            updatePage={updatePage}
            updatePerPage={updatePerPage}
            errorPage={errorPage || getMaxPageError()}
            errorPerPage={errorPerPage}
          />
        )}
      </div>
    ),
    [data, error, errorPage, errorPerPage, getMaxPage, getMaxPageError, page, perPage, updatePage, updatePerPage],
  );
  const renderSidebar = React.useCallback(
    () => (
      <ViewSidebar
        styles={{
          wrapper: classnames(styles.sidebar),
        }}
        options={[
          {
            type: 'section',
            props: {
              className: classnames(styles.filters),
            },
            children: (({ helpers: { buildItem } }) =>
              _.concat(
                _.map(
                  [
                    {
                      type: 'asyncSelect',
                      property: 'Facture.NumeroFacture',
                      label: 'Numéro',
                      query: 'factures',
                      operator: 'ilike',
                      restricted: true,
                      distinct: true,
                      mapFilter: (value, { operator }) =>
                        `Facture: {NumeroFacture_${operator}:${JSON.stringify(value)}}`,
                      mapResult: () => 'factureDocument',
                      mapProp: (_1, { property }) => `factureDocument.${property}`,
                    },
                    {
                      type: 'asyncSelect',
                      property: 'Facture.TypeFacture',
                      label: 'Type',
                      query: 'factures',
                      operator: 'ilike',
                      restricted: true,
                      distinct: true,
                      mapFilter: (value, { operator }) => `Facture: {TypeFacture_${operator}:${JSON.stringify(value)}}`,
                      mapResult: () => 'factureDocument',
                      mapProp: (_1, { property }) => `factureDocument.${property}`,
                    },
                    {
                      type: 'asyncSelect',
                      property: 'Facture.CategorieFacture',
                      label: 'Catégorie',
                      query: 'factures',
                      operator: 'ilike',
                      restricted: true,
                      distinct: true,
                      mapFilter: (value, { operator }) =>
                        `Facture: {CategorieFacture_${operator}:${JSON.stringify(value)}}`,
                      mapResult: () => 'factureDocument',
                      mapProp: (_1, { property }) => `factureDocument.${property}`,
                    },
                    {
                      type: 'asyncSelect',
                      property: 'Facture.ReferenceCommande',
                      label: 'Engagement',
                      query: 'factures',
                      operator: 'ilike',
                      restricted: true,
                      distinct: true,
                      mapFilter: (value, { operator }) =>
                        `Facture: {ReferenceCommande_${operator}:${JSON.stringify(value)}}`,
                      mapResult: () => 'factureDocument',
                      mapProp: (_1, { property }) => `factureDocument.${property}`,
                    },
                  ] as Filter[],
                  definition =>
                    buildItem({
                      componentProps: { definition, filters },
                      // eslint-disable-next-line no-shadow
                      Component: ({ definition, filters = {} }) => (
                        <ViewSidebarFilter
                          options={definition}
                          filter={{
                            property: definition.type === 'input' ? definition.label : definition.property,
                            operator: (definition.type === 'asyncSelect' ? definition.operator : 'eq') as any,
                            value: _.get(
                              filters,
                              `${definition.type === 'input' ? definition.label : definition.property}_${
                                definition.type === 'asyncSelect' ? definition.operator : 'eq'
                              }`,
                            ),
                          }}
                          updateFilter={(newFilter: any) => {
                            const newFilters = _.update(
                              filters,
                              `${definition.type === 'input' ? definition.label : definition.property}_${
                                definition.type === 'asyncSelect' ? definition.operator : 'eq'
                              }`,
                              () => newFilter,
                            );
                            _.set(newFilters, 'Facture', _.omitBy(newFilters.Facture, _.isUndefined));
                            updateParams({
                              filter: newFilters,
                            });
                          }}
                        />
                      ),
                    }),
                ),
                _.map(
                  [
                    {
                      type: 'select',
                      property: 'statutFacture.statut',
                      label: 'Statut',
                      values: _.reduce<any, any[]>(
                        _.fromPairs(
                          _.map(_.entries(_.invertBy(STATUS_BADGE_MAPPING, _.partialRight(_.nth, 0))), ([k, v]) => [
                            k,
                            JSON.stringify(_.isArray(v) ? v : [v]),
                          ]),
                        ),
                        (acc, v, k) => _.concat(acc, { value: v, label: k }),
                        [],
                      ),
                    },
                  ] as Filter[],
                  definition =>
                    buildItem({
                      componentProps: { definition, statut },
                      // eslint-disable-next-line no-shadow
                      Component: ({ definition, statut }) => {
                        const { data, error, loading }: any = useQuery(
                          gql`
                            query {
                              me {
                                id
                                featureFlags
                              }
                            }
                          `,
                          {
                            context: { headers: { Authorization: `Bearer ${token}` } },
                            fetchPolicy: 'network-only',
                          },
                        );
                        if (loading || error) {
                          return null;
                        }
                        return (
                          <ViewSidebarFilter
                            options={_.merge({} as any, _.omit(definition, ['values']), {
                              values:
                                data &&
                                data.me &&
                                !_.includes(data.me.featureFlags, DYNAMIC_FEATURE_FLAG.INTERCEPTABLE_INVOICE)
                                  ? _.reject((definition as any).values, ({ value }) =>
                                      _.includes(JSON.parse(value), INTERCEPTED_STATUS_KEY),
                                    )
                                  : (definition as any).values,
                            })}
                            filter={_.omitBy(
                              { value: _.isArray(statut) && statut.length > 0 ? JSON.stringify(statut) : undefined },
                              _.isUndefined,
                            )}
                            updateFilter={(newStatut: any) => {
                              const s = _.isString(newStatut) ? JSON.parse(newStatut) : [];
                              updateStatut(_.isArray(s) ? s : []);
                            }}
                          />
                        );
                      },
                    }),
                ),
              )) as ViewSidebarOptionSectionChildrenGenerator<{
              definition: Filter;
              filters?: any;
              statut?: string;
            }>,
          },
        ]}
      />
    ),
    [filters, statut, token, updateParams, updateStatut],
  );
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        {renderStickyHeader()}
        <div className={classnames(styles.container)}>
          <div className={classnames(styles.content, { [styles.sidebarVisible]: sidebarVisible })}>
            <div className={classnames(styles.header)}>
              {renderTitle()}
              {renderActions()}
            </div>
            {renderTable()}
            {renderPagination()}
          </div>
          {sidebarVisible && renderSidebar()}
        </div>
      </div>
    ),
    [renderActions, renderPagination, renderSidebar, renderStickyHeader, renderTable, renderTitle, sidebarVisible],
  );
};

type StatusBadgeProps = {
  id: string | number;
  status: string;
  created_at: Date;
  lastUpdatedAt: number | null;
};

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = props => {
  const [status, color] = _.get(STATUS_BADGE_MAPPING, props.status, [props.status, StatusBadgeColor.BLACK]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAll = React.useCallback(
    () => (
      <>
        {_.map(_.keys(STATUS_BADGE_MAPPING), k => (
          <div
            key={k}
            className={classnames(
              styles.status,
              _.fromPairs(
                _.map(
                  _.filter(
                    _.reduce(
                      _.keys(StatusBadgeColor),
                      (arr, key) => (!_.includes(arr, key) ? _.concat(arr, _.get(StatusBadgeColor, key)) : arr),
                      [] as any[],
                    ),
                    key => _.has(styles, key),
                  ),
                  key => [
                    _.get(styles, key),
                    _.isEqual(_.get(STATUS_BADGE_MAPPING, `${k}[1]`), _.get(StatusBadgeColor, key)),
                  ],
                ),
              ),
            )}
          >
            {_.get(STATUS_BADGE_MAPPING, `${k}[0]`)}
          </div>
        ))}
      </>
    ),
    [],
  );
  const render = React.useCallback(
    () => (
      <div
        className={classnames(
          styles.status,
          _.fromPairs(
            _.map(
              _.filter(
                _.reduce(
                  _.keys(StatusBadgeColor),
                  (arr, key) => (!_.includes(arr, key) ? _.concat(arr, _.get(StatusBadgeColor, key)) : arr),
                  [] as any[],
                ),
                key => _.has(styles, key),
              ),
              key => [_.get(styles, key), _.isEqual(color, _.get(StatusBadgeColor, key))],
            ),
          ),
        )}
      >
        {status}
      </div>
    ),
    [color, status],
  );
  const overlay = React.useCallback(
    (innerProps: any) => (
      <Tooltip id={`status-${props.id}`} {...(_.omit(innerProps, 'show') as any)}>
        {`Mis à jour le ${(
          (props.lastUpdatedAt && moment(props.lastUpdatedAt, 'DD-MM-YYYY HH:mm:ss.SSS')) ||
          (props.created_at && moment(props.created_at, 'DD-MM-YYYY HH:mm:ss.SSS'))
        )
          .local()
          .format('LL')}`}
      </Tooltip>
    ),
    [props.created_at, props.id, props.lastUpdatedAt],
  );
  const renderWithTooltip = React.useCallback(
    () => (
      <OverlayTrigger
        containerClassName={classnames(styles['status-container'])}
        placement="bottom"
        popperConfig={{
          modifiers: {
            preventOverflow: { enabled: true },
            hide: { enabled: false },
          },
        }}
        overlay={overlay}
      >
        {render()}
      </OverlayTrigger>
    ),
    [overlay, render],
  );
  return renderWithTooltip();
};

export default InvoicesView;
