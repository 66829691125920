import * as React from 'react';
import _ from 'lodash';
import { RouteProps } from 'react-router-dom';

import config from 'Config';
import { AppLayoutProps } from 'Layouts';
import MainLayout from 'Layouts/mainLayout';
import LoginLayout from 'Layouts/loginLayout';
import LoginView from 'Views/loginView';
import LogoutView from 'Views/logoutView';
import DashboardView from 'Views/dashboard/dashboardView';
import NotFoundView from 'Views/notFoundView';
import InvoicesView from 'Views/invoices';
import InvoiceDetailView from 'Views/invoices/invoiceDetailView';
import ClientsView from 'Views/clients';
import ClientCreateView from 'Views/clients/clientCreateView';
import ClientEditView from 'Views/clients/clientEditView';
import SettingsSuppliersView from 'Views/settings/suppliers';
import SettingsSupplierCreateView from 'Views/settings/suppliers/settingsSupplierCreateView';
import SettingsSupplierEditView from 'Views/settings/suppliers/settingsSupplierEditView';
import SettingsPaymentMeansView from 'Views/settings/paymentMeans';
import SettingsPaymentMeanCreateView from 'Views/settings/paymentMeans/settingsPaymentMeanCreateView';
import SettingsPaymentMeanEditView from 'Views/settings/paymentMeans/settingsPaymentMeanEditView';
import SettingsAccountView from 'Views/settings/account';
import SettingsChorusView from 'Views/settings/chorus';
import ErrorView from 'Views/errorView';
import SettingsLayout from 'Layouts/settingsLayout';
import InvoicesValidationView from 'Views/invoicesValidationView';
import NotificationsView from 'Views/notificationsView';

export interface AppRouteProps extends RouteProps {
  view: React.ComponentType<any>;
  layout?: React.ComponentType<AppLayoutProps>;
  isPublic?: boolean;
  redirectTo?: string;
  breadcrumbs?: (null | string | ((arg?: string) => { label: string | null; to?: string }))[];
}

const routes: AppRouteProps[] = [];

if (_.includes(['production', 'staging', 'development'], config.ENV)) {
  routes.push(
    {
      path: '/',
      layout: MainLayout,
      view: () => null,
      exact: true,
      isPublic: true,
      redirectTo: '/login',
    },
    {
      path: '/dashboard',
      layout: MainLayout,
      view: DashboardView,
    },
    {
      path: '/login',
      layout: LoginLayout,
      view: LoginView,
      isPublic: true,
    },
    {
      path: '/logout',
      view: LogoutView,
      isPublic: true,
    },
    {
      path: '/invoices',
      layout: MainLayout,
      view: InvoicesView,
      exact: true,
    },
    {
      path: '/invoices/:id',
      layout: MainLayout,
      view: InvoiceDetailView,
      exact: true,
    },
    {
      path: '/clients',
      layout: MainLayout,
      view: ClientsView,
      exact: true,
    },
    {
      path: '/clients/new',
      layout: MainLayout,
      view: ClientCreateView,
      exact: true,
    },
    {
      path: '/clients/:id/edit',
      layout: MainLayout,
      view: ClientEditView,
      exact: true,
    },
    {
      path: '/settings/account',
      layout: SettingsLayout,
      view: SettingsAccountView,
      exact: true,
      breadcrumbs: ['Préférences', 'Compte'],
    },
    {
      path: '/settings/chorus',
      layout: SettingsLayout,
      view: SettingsChorusView,
      exact: true,
      breadcrumbs: ['Préférences', 'Chorus'],
    },
    {
      path: '/settings/suppliers',
      layout: SettingsLayout,
      view: SettingsSuppliersView,
      exact: true,
      breadcrumbs: ['Préférences', 'Emetteurs & Valideurs'],
    },
    {
      path: '/settings/suppliers/new',
      layout: SettingsLayout,
      view: SettingsSupplierCreateView,
      exact: true,
      breadcrumbs: ['Préférences', 'Emetteurs & Valideurs', 'Création'],
    },
    {
      path: '/settings/suppliers/:id/edit',
      layout: SettingsLayout,
      view: SettingsSupplierEditView,
      exact: true,
      breadcrumbs: ['Préférences', 'Emetteurs & Valideurs', null, 'Edition'],
    },
    {
      path: '/settings/payment-means',
      layout: SettingsLayout,
      view: SettingsPaymentMeansView,
      exact: true,
      breadcrumbs: ['Préférences', 'Moyens de paiement'],
    },
    {
      path: '/settings/payment-means/new',
      layout: SettingsLayout,
      view: SettingsPaymentMeanCreateView,
      exact: true,
      breadcrumbs: ['Préférences', 'Moyens de paiement', 'Création'],
    },
    {
      path: '/settings/payment-means/:id/edit',
      layout: SettingsLayout,
      view: SettingsPaymentMeanEditView,
      exact: true,
      breadcrumbs: ['Préférences', 'Moyens de paiement', null, 'Edition'],
    },
    {
      path: '/invoices-validation',
      layout: MainLayout,
      view: InvoicesValidationView,
      exact: true,
    },
    {
      path: '/notifications',
      layout: MainLayout,
      view: NotificationsView,
    },
  );
}

if (_.includes(['development'], config.ENV)) {
  routes.push({
    path: '/error',
    view: ErrorView,
    isPublic: true,
  });
}

routes.push({
  view: NotFoundView,
  isPublic: true,
});

export default routes;
