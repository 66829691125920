import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

const FieldRequiredBadge = (props: any) => {
  const { children } = props;
  return <div className={classnames(styles['field-required-badge'])}>{children}</div>;
};

export default FieldRequiredBadge;
