import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import { push } from 'connected-react-router';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';
import { useStoreDispatch } from 'Store';

import ClientForm from './clientForm';
import styles from './clientCreateView.module.scss';

const ClientCreateView = () => {
  const [token] = useSessionStorage('token');
  const dispatch = useStoreDispatch();
  const createClientMutation = useMutation<any>(
    gql`
      mutation createClient($client: ClientInput!) {
        createClient(input: $client) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { error }: any = await createClientMutation({
        variables: { client: values },
      });

      if (error) {
        notify(error.message);
      } else {
        notify('Client créé avec succès.');

        dispatch(push('/clients'));
      }
      actions.setSubmitting(false);
    } catch (e) {
      notify('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const clientForm = {
    code: '',
    typeIdentification: 'SIRET',
    identification: '',
    nom: '',
    adresse: {
      rue: '',
      codePostal: '',
      ville: '',
      pays: 'FR',
    },
    codeService: '',
    nomService: '',
    chorusDisabled: false,
    interceptionStatus: 'NONE',
    interceptionFilters: [],
  };

  return (
    <PageContent style={{ overflow: 'auto' }}>
      <PageHeading withMargins>Nouveau Client</PageHeading>
      <div className={classnames(styles['form-wrapper'])}>
        <ClientForm data={clientForm} submitLabel="Ajouter" onSubmit={onSubmit} />
      </div>
    </PageContent>
  );
};
export default ClientCreateView;
