import * as React from 'react';

import LetterAvatar from './letterAvatar';

interface AvatarProps {
  displayName: string;
  identifier: string;
  size: string;
  className?: string;
}

const Avatar = (props: AvatarProps) => {
  const { className, size, ...moreProps } = props;
  return (
    <span className={className} style={{ width: size, height: size }}>
      <LetterAvatar {...moreProps} />
    </span>
  );
};

export default Avatar;
