import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { useStoreState } from 'Store';
import SentryErrorBoundary from 'Components/sentryErrorBoundary';
import Modal from 'Components/modal';

import styles from './modalContainer.module.scss';

export type ModalContainerProps = {
  initialZIndex?: number;
};
const defaultProps: Partial<ModalContainerProps> = {
  initialZIndex: 10000,
};

const ModalContainer: React.FunctionComponent<ModalContainerProps> = ({ initialZIndex, children }) => {
  const modals = useStoreState(state => state.modals.items);
  return (
    <>
      {children}
      {modals.length > 0 && (
        <div className={classnames(styles.wrapper)} style={{ zIndex: initialZIndex || 10000 }}>
          <div className={classnames(styles.container)}>
            {_.map(modals, (modal, i) => (
              <Modal key={`modal-${i}`} zIndex={(initialZIndex || 10000) + i + 1} {...modal.modalProps}>
                <SentryErrorBoundary>
                  <modal.Cmp {...modal.props} />
                </SentryErrorBoundary>
              </Modal>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
ModalContainer.defaultProps = defaultProps;
export default ModalContainer;
