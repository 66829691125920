import { push, replace } from 'connected-react-router';

import compose from 'Utils/compose';
import { useStoreDispatch, useStoreState } from 'Store';

export default () => {
  const dispatch = useStoreDispatch();
  const location = useStoreState(state => state.router.location);
  return {
    location,
    push: compose(dispatch, push),
    replace: compose(dispatch, replace),
  };
};
