import * as React from 'react';
import classNames from 'classnames';

import InlineSvg from 'Components/inlineSvg';
import TextBlock from 'Components/textBlock';
import styles from './emptyMessage.module.scss';

type EmptyMessageProps = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  icon?: string;
  action?: React.ReactNode;
  size?: { large?: boolean };
};

const EmptyMessage: React.FunctionComponent<EmptyMessageProps> = ({
  title,
  description,
  icon,
  children,
  action,
  size,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.large]: size && size.large,
      })}
    >
      {icon && <InlineSvg className={classNames(styles.inlineSvg)} src={icon} size="36px" />}
      {title && (
        <div className={classNames(styles.title)}>
          <TextBlock className={styles.contentMargin}>{title}</TextBlock>
        </div>
      )}
      {description && <TextBlock className={styles.contentMargin}>{description}</TextBlock>}
      {children && <TextBlock noMargin>{children}</TextBlock>}
      {action && <div className={styles.contentMargin}>{action}</div>}
    </div>
  );
};

export default EmptyMessage;
