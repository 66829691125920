import * as React from 'react';
import classnames from 'classnames';

import styles from './queryCount.module.scss';

type QueryCountProps = {
  className?: string;
  count?: number;
  max?: number;
  hideIfEmpty?: boolean;
  inline?: boolean;
};

const QueryCount: React.FunctionComponent<QueryCountProps> = props => {
  const { className, count, max, hideIfEmpty, inline } = props;
  const countOrMax = typeof max !== 'undefined' && count && count >= max ? `${max}+` : count;
  const cx = classnames(styles['query-count'], className, {
    [styles.inline]: inline || true,
  });
  if (hideIfEmpty || !count) return null;
  return (
    <div className={cx}>
      <span>(</span>
      <span className={classnames(styles['query-count-value'])}>{countOrMax}</span>
      <span>)</span>
    </div>
  );
};
export default QueryCount;
