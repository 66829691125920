import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import { FaInfoCircle } from 'react-icons/fa';
import { IoIosWarning } from 'react-icons/io';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';

import { AppLayoutProps } from 'Layouts';
import Button from 'Components/button';
import Sidebar from 'Components/sidebar';
import { useStoreState, useStoreActions } from 'Store';
import useSessionStorage from 'UtilHooks/useSessionStorage';
import useObservable from 'UtilHooks/useObservable';
import notify from 'Utils/notify';

import styles from './mainLayout.module.scss';

type MainLayoutProps = AppLayoutProps;

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({ view, viewProps }) => {
  const [token] = useSessionStorage('token');
  const {
    data: { chorusSetting },
  }: any = useQuery(
    gql`
      query {
        chorusSetting {
          id
          technicalAccount
        }
      }
    `,
    { context: { headers: { Authorization: `Bearer ${token}` } }, fetchPolicy: 'network-only' },
  );
  const {
    data: { notifications, nbUnreadNotifications },
    refetch: refetchNotifications,
  }: any = useQuery(
    gql`
      query {
        nbUnreadNotifications: notificationsCount(input: { state: UNREAD })
        notifications(input: { limit: 1 }) {
          id
          html
          publicationDate
          viewDate
        }
      }
    `,
    { context: { headers: { Authorization: `Bearer ${token}` } }, fetchPolicy: 'network-only' },
  );
  const {
    data: { facturesAValider: { count: nbInvoicesToValidate } = { count: undefined } },
    refetch: refetchNbInvoicesToValidate,
  }: any = useQuery(
    gql`
      query {
        facturesAValider(input: { filter: { statut: [A_VALIDER] } }) {
          count
        }
      }
    `,
    { context: { headers: { Authorization: `Bearer ${token}` } }, fetchPolicy: 'network-only' },
  );
  const { sendMessage } = useObservable('notifications', {
    regex: new RegExp(/^refetch$/),
    onMessage: async () => {
      await refetchNotifications();
    },
  });
  useObservable('nbInvoicesToValidate', {
    regex: new RegExp(/^refetch$/),
    onMessage: async () => {
      await refetchNbInvoicesToValidate();
    },
  });
  const markNotificationAsReadMutation = useMutation<any>(
    gql`
      mutation($id: ID!) {
        markNotificationAsRead(id: $id)
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const markNotificationAsRead = React.useCallback(
    async (id: string) => {
      let error;
      try {
        const { error: _error }: any = await markNotificationAsReadMutation({
          variables: { id },
        });
        error = _error;
      } catch (err) {
        error = err;
      }
      if (error) notify.error("Impossible d'accéder au serveur");
      else sendMessage({ type: 'refetch' });
    },
    [markNotificationAsReadMutation, sendMessage],
  );
  const sidebarState = useStoreState(state => state.layout.sidebar);
  const setSidebarState = useStoreActions(actions => actions.layout.updateSidebar);
  const lastNotification = React.useMemo(() => {
    if (!_.isArray(notifications)) return null;
    if (_.isEmpty(notifications)) return null;
    const notification = _.head(notifications);
    return _.isNull(notification.viewDate) ? notification : null;
  }, [notifications]);
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.sidebar)}>
          <Sidebar
            state={sidebarState}
            changeState={setSidebarState}
            nbUnreadNotifications={nbUnreadNotifications}
            nbInvoicesToValidate={nbInvoicesToValidate}
          />
        </div>
        <div className={classnames(styles.content)}>
          <div className={classnames(styles.indicators)}>
            {_.isNull(chorusSetting) && (
              <div className={classnames(styles.indicator, { [styles.warning]: true })}>
                <IoIosWarning size="20px" />
                <span>Les paramètres d&apos;accès à Chorus Pro n&apos;ont pas été renseignés</span>
              </div>
            )}
            {!_.isNull(lastNotification) && (
              <div className={classnames(styles.indicator, { [styles.info]: true })}>
                <FaInfoCircle size="20px" />
                <div className={classnames(styles.html)}>{ReactHtmlParser(lastNotification.html)}</div>
                <Button
                  className={classnames(styles.hideOnHover)}
                  priority="link"
                  onClick={_.partial(markNotificationAsRead, lastNotification.id)}
                >
                  Marquer comme lu
                </Button>
              </div>
            )}
          </div>
          <div className={classnames(styles.view)}>{React.createElement(view, { ...viewProps })}</div>
        </div>
      </div>
    ),
    [
      chorusSetting,
      lastNotification,
      markNotificationAsRead,
      nbInvoicesToValidate,
      nbUnreadNotifications,
      setSidebarState,
      sidebarState,
      view,
      viewProps,
    ],
  );
};

export default MainLayout;
