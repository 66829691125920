import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

const FieldHelp = (props: any) => {
  const { children } = props;
  return <div className={classnames(styles['field-help'])}>{children}</div>;
};

export default FieldHelp;
