import _ from 'lodash';
import moment from 'moment';

/**
 * Sets time (hours + minutes) of the current date object
 *
 * @param {String} timeStr Time in 24hr format (HH:mm)
 */
export default (dateObj: moment.MomentInput, timeStr: string, { local }: { local?: boolean } = {}) => {
  const [hours, minutes, seconds] = _.map(timeStr.split(':'), value => parseInt(value, 10));
  const date = new Date(+dateObj);
  !!local ? date.setHours(hours, minutes) : date.setUTCHours(hours, minutes); // eslint-disable-line
  if (!_.isUndefined(seconds)) date.setSeconds(seconds);
  return moment(date);
};
