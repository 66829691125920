/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
// @ts-ignore
import * as BaseTable from 'reactabular-table';
// @ts-ignore
import * as BaseStickyTable from 'reactabular-sticky';
// @ts-ignore
import * as BaseVirtualizedTable from 'reactabular-virtualized';
// @ts-ignore
import * as resolve from 'table-resolver';
import { compose, mapProps } from 'recompose';

type ColumnHeaderFormatter = (label: string) => React.ReactNode;
type ColumnHeaderFormatters = ColumnHeaderFormatter[];
type ColumnCellTransform = (
  value: string,
  extra: { rowData: { [x: string]: any }; rowIndex: number },
) => { [x: string]: any };
type ColumnCellFormatter = (value: string, extra: { rowData: { [x: string]: any }; rowIndex: number }) => void;
type ColumnCellTransforms = ColumnCellTransform[];
type ColumnCellFormatters = ColumnCellFormatter[];
export type Column = {
  property: string;
  header: {
    label: string;
    formatters?: ColumnHeaderFormatters;
  };
  cell?: {
    transforms?: ColumnCellTransforms;
    formatters?: ColumnCellFormatters;
  };
  children?: Columns;
  props?: { [x: string]: any };
};
type ResolvedColumn = Column;
type Columns = Column[];
type ResolvedColumns = ResolvedColumn[];
type Row = any;
type HeaderRow = Row;
type ResolvedRow = Row;
type Rows = Row[];
type HeaderRows = HeaderRow[];
type ResolvedRows = ResolvedRow[];
type ProviderChildrenOptions = {
  resolveRows: (rows: Rows) => ResolvedRows;
  getHeaderRows: () => HeaderRows;
  nbColumns: number;
};
type RendererProps = {
  className?: string;
  ref: React.Ref<any>;
  [x: string]: any;
};
export type Renderers = {
  body?: {
    row?: React.ComponentType<RendererProps>;
  };
};
type ProviderProps = {
  withoutVirtualization?: boolean;
  columns: Columns;
  classNames?: {
    table?: string;
    header?: {
      wrapper?: string;
      row?: string;
      cell?: string;
    };
    body?: {
      wrapper?: string;
      row?: string;
      cell?: string;
    };
  };
  renderers?: Renderers;
  children?: (options: ProviderChildrenOptions) => React.ReactNode;
};

class VirtualizedTableBodyRow extends BaseVirtualizedTable.BodyRow {
  private Cmp: React.ComponentType<RendererProps> | 'tr';

  public constructor({ __Cmp__, ...props }: { __Cmp__?: React.ComponentType<RendererProps>; [x: string]: any }) {
    super(props);
    this.Cmp = __Cmp__ || 'tr';
  }

  public render() {
    return React.createElement(this.Cmp, {
      // @ts-ignore
      ...this.props,
      ref: (e: any) => {
        if (e) {
          // @ts-ignore
          this.ref = e;
        }
      },
    });
  }
}

const Provider: React.FunctionComponent<ProviderProps> = ({
  withoutVirtualization,
  columns,
  classNames,
  renderers,
  children,
}) => {
  const getColumns = React.useCallback(() => columns, [columns]);
  // eslint-disable-next-line no-shadow
  const resolveColumns = React.useCallback((columns: Columns) => resolve.columnChildren({ columns }), []);
  const resolveRows = React.useCallback(
    (rows: Rows) =>
      resolve.resolve({
        columns: resolveColumns(getColumns()),
        method: resolve.nested,
      })(rows),
    [getColumns, resolveColumns],
  );
  const getHeaderRows = React.useCallback(() => resolve.headerRows({ columns: getColumns() }), [getColumns]);
  const getRenderers = React.useCallback(
    () => ({
      table:
        classNames && classNames.table
          ? React.forwardRef((p: any, ref: any) => <table ref={ref} {...p} className={classnames(classNames.table)} />)
          : 'table',
      header: {
        wrapper:
          classNames && classNames.header && classNames.header.wrapper
            ? React.forwardRef((p: any, ref: any) => (
                <thead ref={ref} {...p} className={classnames(classNames.header!.wrapper)} />
              ))
            : 'thead',
        row:
          classNames && classNames.header && classNames.header.row
            ? React.forwardRef((p: any, ref: any) => (
                <tr ref={ref} {...p} className={classnames(classNames.header!.row)} />
              ))
            : 'tr',
        cell:
          classNames && classNames.header && classNames.header.cell
            ? React.forwardRef((p: any, ref: any) => (
                <th ref={ref} {...p} className={classnames(classNames.header!.cell)} />
              ))
            : 'th',
      },
      body: {
        wrapper:
          // eslint-disable-next-line no-nested-ternary
          classNames && classNames.body && classNames.body.wrapper
            ? React.forwardRef(({ children, ...p }: any, ref: any) =>
                React.createElement(
                  withoutVirtualization ? 'tbody' : BaseVirtualizedTable.BodyWrapper,
                  { ...p, ref, className: classnames(classNames.body!.wrapper) },
                  children,
                ),
              )
            : withoutVirtualization
            ? 'tbody'
            : BaseVirtualizedTable.BodyWrapper,
        row:
          // eslint-disable-next-line no-nested-ternary
          classNames && classNames.body && classNames.body.row
            ? React.forwardRef(({ children, ...p }: any, ref: any) =>
                React.createElement(
                  // eslint-disable-next-line no-nested-ternary
                  withoutVirtualization
                    ? renderers && renderers.body && renderers.body.row
                      ? renderers.body.row
                      : 'tr'
                    : compose<any, any>(
                        mapProps((props: any) =>
                          _.omitBy(
                            {
                              __Cmp__:
                                renderers && renderers.body && renderers.body.row ? renderers.body.row : undefined,
                              ...props,
                            } as any,
                            _.isUndefined,
                          ),
                        ),
                      )(VirtualizedTableBodyRow as any),
                  { ...p, ref, className: classnames(classNames.body!.row) },
                  children,
                ),
              )
            : // eslint-disable-next-line no-nested-ternary
            withoutVirtualization
            ? renderers && renderers.body && renderers.body.row
              ? renderers.body.row
              : 'tr'
            : compose<any, any>(
                mapProps((props: any) =>
                  _.omitBy(
                    {
                      __Cmp__: renderers && renderers.body && renderers.body.row ? renderers.body.row : undefined,
                      ...props,
                    } as any,
                    _.isUndefined,
                  ),
                ),
              )(VirtualizedTableBodyRow as any),
        cell:
          classNames && classNames.body && classNames.body.cell
            ? React.forwardRef((p: any, ref: any) => (
                <td ref={ref} {...p} className={classnames(classNames.body!.cell)} />
              ))
            : 'td',
      },
    }),
    [classNames, renderers, withoutVirtualization],
  );
  const getChildrenOptions = React.useCallback(() => ({ resolveRows, getHeaderRows, nbColumns: getColumns().length }), [
    getHeaderRows,
    resolveRows,
    getColumns,
  ]);
  const renderChildren = React.useCallback(
    (options: ProviderChildrenOptions) => {
      if (!_.isFunction(children)) return null;
      return children(options);
    },
    [children],
  );
  return (
    <BaseTable.Provider columns={resolveColumns(getColumns())} renderers={getRenderers()}>
      {renderChildren(getChildrenOptions())}
    </BaseTable.Provider>
  );
};
export { Provider };

type HeaderProps = {
  headerRows: HeaderRows;
  onRow?: (row: Columns, options: { rowIndex: number }) => object;
};

const Header = React.forwardRef((props: HeaderProps, ref: any) => <BaseTable.Header ref={ref} {...props} />);
export { Header };

type StickyHeaderProps = HeaderProps & {
  width?: number;
  tableBody: React.Ref<any>;
  updateRef: (ref: any) => void;
};

const InnerStickyHeader = React.forwardRef((props: any, ref: any) => <BaseStickyTable.Header ref={ref} {...props} />);
const StickyHeader: React.FunctionComponent<StickyHeaderProps> = ({ width, updateRef, ...props }) =>
  React.useMemo(
    () => (
      <InnerStickyHeader
        style={{ maxWidth: width }}
        ref={(tableHeader: { getRef: () => React.Ref<any> }) => {
          updateRef(tableHeader && tableHeader.getRef());
        }}
        {...props}
      />
    ),
    [props, updateRef, width],
  );
export { StickyHeader };

type BodyProps = {
  rowKey: string;
  rows: Rows;
  onRow?: (row: object, options: { rowIndex: number; rowKey: string }) => object;
};

const Body = React.forwardRef((props: BodyProps, ref: any) => <BaseTable.Body ref={ref} {...props} />);
export { Body };

type StickyBodyProps = BodyProps & {
  width?: number;
  height?: number;
  updateRef: (ref: any) => void;
  tableHeader: React.Ref<any>;
};

const InnerStickyBody = React.forwardRef((props: any, ref: any) => <BaseStickyTable.Body ref={ref} {...props} />);
const StickyBody: React.FunctionComponent<StickyBodyProps> = ({ width, height, updateRef, tableHeader, ...props }) =>
  React.useMemo(
    () => (
      <InnerStickyBody
        style={{ maxWidth: width }}
        ref={(tableBody: { getRef: () => React.Ref<any> }) => {
          updateRef(tableBody && tableBody.getRef());
        }}
        tableHeader={tableHeader}
        height={height}
        {...props}
      />
    ),
    [height, props, tableHeader, updateRef, width],
  );
export { StickyBody };

type VirtualizedBodyProps = BodyProps & {
  width: number;
  height: number;
  updateRef: (ref: any) => void;
  getContainer?: () => any;
};

const InnerVirtualizedBody = React.forwardRef((props: any, ref: any) => (
  <BaseVirtualizedTable.Body ref={ref} {...props} />
));
const VirtualizedBody: React.FunctionComponent<VirtualizedBodyProps> = ({
  width,
  height,
  updateRef,
  getContainer,
  ...props
}) =>
  React.useMemo(
    () => (
      <InnerVirtualizedBody
        style={{ maxWidth: width }}
        ref={(tableBody: { getRef: () => React.Ref<any> }) => {
          updateRef(tableBody && tableBody.getRef());
        }}
        container={getContainer}
        height={height}
        {...props}
      />
    ),
    [getContainer, height, props, updateRef, width],
  );
export { VirtualizedBody };
