import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';

import { Panel, PanelBody, PanelHeader, PanelItem, PanelAlert } from 'Components/panels';
import Button from 'Components/button';

import Field from 'Views/settings/field/field';
import InputControl from 'Views/settings/field/inputControl';
import styles from './passwordForm.module.scss';

const passwordSchema = Yup.object().shape({
  password: Yup.string().required('Obligatoire'),
  confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
});

const PasswordForm = () => {
  const [token] = useSessionStorage('token');
  const updatePasswordMutation = useMutation<any>(
    gql`
      mutation updatePassword($newPassword: String!) {
        updatePassword(newPassword: $newPassword) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const onSubmit = async (values: any, actions: any) => {
    try {
      const { error }: any = await updatePasswordMutation({
        variables: { newPassword: values.password },
      });
      if (error) notify.error(error.message);
      else notify('Mot de passe mis à jour');
      actions.setSubmitting(false);
    } catch (e) {
      notify.error('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const passwordForm = { password: '', confirmation: '' };
  return (
    <Panel>
      <PanelHeader>Mot de passe</PanelHeader>
      <PanelBody disablePadding>
        <PanelAlert type="info">Changer de mot de passe affecte les accès API</PanelAlert>
        <Formik initialValues={passwordForm} validationSchema={passwordSchema} onSubmit={onSubmit}>
          {({ errors, touched, isSubmitting, isValidating }) => (
            <Form>
              <Field label="Mot de passe" help="Le nouveau mot de passe de votre compte" required inline>
                <InputControl type="password" name="password" errors={errors} touched={touched} />
              </Field>
              <Field label="Confirmation mot de passe" help="Saisissez le mot de passe à nouveau" required inline>
                <InputControl type="password" name="confirmation" errors={errors} touched={touched} />
              </Field>
              <PanelItem className={classnames(styles.actions)}>
                <Button size="small" priority="primary" disabled={isValidating || isSubmitting}>
                  {isSubmitting ? 'En cours ...' : 'Changer de mot de passe'}
                </Button>
              </PanelItem>
            </Form>
          )}
        </Formik>
      </PanelBody>
    </Panel>
  );
};

export default PasswordForm;
