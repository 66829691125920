import * as React from 'react';
import { ToastContainer as BaseToasterContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { TOAST_POSITION, TOAST_TYPE } from 'Constants';
import ToastCloseButton from './toastCloseButton';
import { Slide } from './toastTransitions';

export type ToastContainerProps = {
  /**
   * Set toast position
   */
  position?: TOAST_POSITION;

  /**
   * Disable or set autoClose delay
   */
  autoClose?: false | number;

  /**
   * Disable or set a custom react element for the close button
   */
  closeButton?: React.ReactNode | boolean;

  /**
   * Hide or not progress bar when autoClose is enabled
   */
  hideProgressBar?: boolean;

  /**
   * Pause toast duration on hover
   */
  pauseOnHover?: boolean;

  /**
   * Dismiss toast on click
   */
  closeOnClick?: boolean;

  /**
   * Newest on top
   */
  newestOnTop?: boolean;

  /**
   * An optional className
   */
  className?: string | React.CSSProperties;

  /**
   * An optional style
   */
  style?: React.CSSProperties;

  /**
   * An optional className for the toast
   */
  toastClassName?: string | React.CSSProperties;

  /**
   * An optional className for the toast body
   */
  bodyClassName?: string | React.CSSProperties;

  /**
   * An optional className for the toast progress bar
   */
  progressClassName?: string | React.CSSProperties;

  /**
   * An optional style for the toast progress bar
   */
  progressStyle?: React.CSSProperties;

  /**
   * Define enter and exit transition using react-transition-group
   */
  transition?: React.ComponentType<any>;

  /**
   * Support rtl display
   */
  rtl?: boolean;

  /**
   * Allow toast to be draggable
   */
  draggable?: boolean;

  /**
   * The percentage of the toast's width it takes for a drag to dismiss a toast
   */
  draggablePercent?: number;

  /**
   * Pause the toast on focus loss
   */
  pauseOnFocusLoss?: boolean;
};
export const defaultProps: Partial<ToastContainerProps> = {
  position: TOAST_POSITION.BOTTOM_RIGHT,
  transition: Slide,
  rtl: false,
  autoClose: 5000,
  hideProgressBar: false,
  closeButton: <ToastCloseButton type={TOAST_TYPE.DEFAULT} />,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  closeOnClick: true,
  newestOnTop: false,
  draggable: true,
  draggablePercent: 80,
  className: undefined,
  style: undefined,
  toastClassName: undefined,
  bodyClassName: undefined,
  progressClassName: undefined,
  progressStyle: undefined,
};

const ToastContainer: React.FunctionComponent<ToastContainerProps> = props => <BaseToasterContainer {...props} />;
ToastContainer.defaultProps = defaultProps;
export default ToastContainer;
