import * as React from 'react';
import classnames from 'classnames';

import EmptyStateWarning from 'Components/emptyStateWarning';

import styles from './dashboard.module.scss';

type ListProps = {
  title: string;
  data: {
    description: string;
    value: string;
  }[];
  withoutPosition?: boolean;
  fullHeight?: boolean;
};

const List = (props: ListProps) => {
  const { title, data } = props;
  return (
    <div className={styles.list}>
      <h3>{title}</h3>
      {data.length > 0 ? (
        data.map((item: any, i: any) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${i}`}
            className={classnames(styles.item, {
              [styles.fullHeight]: props.fullHeight,
            })}
          >
            {!props.withoutPosition && <div className={styles.position}>{i + 1}</div>}
            <div className={styles['item-content']}>
              <div className={styles.description}>{item.description}</div>
              <div className={styles.value}>{item.value}</div>
            </div>
          </div>
        ))
      ) : (
        <EmptyStateWarning message="Aucun résultat" />
      )}
    </div>
  );
};

export default List;
