import * as React from 'react';
import { useStoreActions } from 'Store';

export default function <T = any>(Cmp: React.ComponentType<T>, modalProps: any = {}) {
  const push = useStoreActions(actions => actions.modals.push);
  const pop = useStoreActions(actions => actions.modals.pop);
  const showModal = React.useCallback(
    (props?: T) => {
      push({ Cmp, props, modalProps });
    },
    [Cmp, modalProps, push],
  );
  return [showModal, pop] as [(props?: T) => void, () => Promise<void>];
}
