import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import { push } from 'connected-react-router';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';
import { useStoreDispatch } from 'Store';

import SupplierForm from './settingsSupplierForm';
import styles from './settingsSupplierCreateView.module.scss';

const SettingsSupplierCreateView = () => {
  const [token] = useSessionStorage('token');
  const dispatch = useStoreDispatch();
  const createSupplierMutation = useMutation<any>(
    gql`
      mutation createSupplier($supplier: FournisseurInput!) {
        createFournisseur(input: $supplier) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { error }: any = await createSupplierMutation({
        variables: { supplier: values },
      });

      if (error) {
        notify(error.message);
      } else {
        notify('Emetteur créé avec succès.');

        dispatch(push('/settings/suppliers'));
      }
      actions.setSubmitting(false);
    } catch (e) {
      notify('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const supplierForm = {
    code: '',
    type: 'FOURNISSEUR',
    typeIdentification: 'SIRET',
    identification: '',
    nom: '',
    adresse: {
      rue: '',
      codePostal: '',
      ville: '',
      pays: 'FR',
    },
    codeService: '',
    nomService: '',
    nomLegal: '',
    adresseLegal: {
      rue: '',
      codePostal: '',
      ville: '',
      pays: 'FR',
    },
    contact: {
      nom: '',
      telephone: '',
      telecopie: '',
      courriel: '',
    },
  };

  return (
    <PageContent style={{ overflow: 'auto' }}>
      <PageHeading withMargins>Nouvel émetteur ou valideur de facture</PageHeading>
      <div className={classnames(styles['form-wrapper'])}>
        <SupplierForm data={supplierForm} submitLabel="Ajouter" onSubmit={onSubmit} />
      </div>
    </PageContent>
  );
};
export default SettingsSupplierCreateView;
