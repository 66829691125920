import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo-hooks';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';

import { Panel, PanelBody, PanelHeader, PanelItem, PanelAlert } from 'Components/panels';
import Button from 'Components/button';
import LoadingIndicator from 'Components/loadingIndicator';

import Field from 'Views/settings/field/field';
import InputControl from 'Views/settings/field/inputControl';

import styles from './chorusForm.module.scss';

const chorusSchema = Yup.object().shape({
  technicalAccount: Yup.string().required('Obligatoire').email('Email invalide'),
  password: Yup.string().required('Obligatoire'),
  confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent être identiques'),
});

const ChorusForm = () => {
  const [token] = useSessionStorage('token');
  const updateChorusSettingMutation = useMutation<any>(
    gql`
      mutation updateChorusSetting($technicalAccount: String!, $password: String!) {
        updateChorusSetting(technicalAccount: $technicalAccount, password: $password) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    if (!chorusSchema.isValid(values)) {
      return;
    }
    try {
      const { error }: any = await updateChorusSettingMutation({
        variables: {
          technicalAccount: values.technicalAccount,
          password: values.password,
        },
      });
      if (error) notify.error(error.message);
      else notify('Informations du compte technique mises à jour');
      actions.setSubmitting(false);
    } catch (e) {
      notify.error('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const { data, loading } = useQuery(gql('{ chorusSetting { technicalAccount } }'), {
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <LoadingIndicator />;
  }
  const { chorusSetting } = data;
  const chorusForm = {
    technicalAccount: '',
    password: '',
    confirmation: '',
    ...chorusSetting,
  };

  return (
    <Panel>
      <PanelHeader>Compte technique</PanelHeader>
      <PanelBody disablePadding>
        <PanelAlert type="info">Saisissez les informations du compte technique Chorus Pro.</PanelAlert>
        <Formik initialValues={chorusForm} validationSchema={chorusSchema} onSubmit={onSubmit}>
          {({ errors, touched }) => (
            <Form>
              <Field label="Email" help="Adresse email du compte technique" required inline>
                <InputControl type="text" name="technicalAccount" errors={errors} touched={touched} />
              </Field>
              <Field label="Mot de passe" help="Le mot de passe de votre compte technique" required inline>
                <InputControl type="password" name="password" errors={errors} touched={touched} />
              </Field>
              <Field label="Confirmation mot de passe" help="Saisissez le mot de passe à nouveau" required inline>
                <InputControl type="password" name="confirmation" errors={errors} touched={touched} />
              </Field>
              <PanelItem className={classnames(styles.actions)}>
                <Button size="small" priority="primary">
                  Enregistrer
                </Button>
              </PanelItem>
            </Form>
          )}
        </Formik>
      </PanelBody>
    </Panel>
  );
};

export default ChorusForm;
