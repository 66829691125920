export default (error: Error, fallbackMessage?: string) => {
  if (error.message.startsWith('GraphQL error: 503 -'))
    return `Le service ${error.message.replace(
      new RegExp(/^GraphQL error: 503 -/),
      '',
    )} est temporairement indisponible ou en maintenance.`;
  if (error.message.startsWith('GraphQL error: 408 -'))
    return `Le service ${error.message.replace(new RegExp(/^GraphQL error: 408 -/), '')} est temporairement inaccessible
    `;
  return fallbackMessage || error.message;
};
