import * as React from 'react';
import classnames from 'classnames';

import HeaderTitle from 'Components/headerTitle';

import styles from './settingsAccountView.module.scss';
import AccountForm from './accountForm';
import PasswordForm from './passwordForm';

const SettingsAccountView: React.FunctionComponent = () =>
  React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <HeaderTitle className={classnames(styles.title)}>Compte</HeaderTitle>
        <div className={classnames(styles.content)}>
          <AccountForm />
          <PasswordForm />
        </div>
      </div>
    ),
    [],
  );

export default SettingsAccountView;
