import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

import FieldWrapper from './fieldWrapper';
import FieldDescription from './fieldDescription';
import FieldLabel from './fieldLabel';
import FieldHelp from './fieldHelp';
import FieldRequiredBadge from './fieldRequiredBadge';

type FieldPropsType = {
  alignRight?: boolean;
  highlighted?: boolean;
  required?: boolean;
  visible?: boolean;
  disabled?: boolean;
  disabledReason?: string;
  flexibleControlStateSize?: boolean;
  label?: string;
  help?: string;
  inline?: boolean;
  stacked?: boolean;
  id?: string;
  children?: React.ReactNode;
  controlClassName?: string;
  style?: { [x: string]: any };
  className?: string;
  withoutSeparator?: boolean;
};

const defaultProps = {
  alignRight: false,
  inline: true,
  disabled: false,
  required: false,
  visible: true,
};

const Field = (props: FieldPropsType) => {
  const {
    className,
    /* controlClassName,
    alignRight, */
    inline,
    highlighted,
    required,
    visible,
    disabled,
    // disabledReason,
    flexibleControlStateSize,
    label,
    help,
    id,
    stacked,
    children,
    style,
    withoutSeparator,
  } = props;
  const isDisabled = disabled;
  const isVisible = visible;
  // let Control;

  if (!isVisible) {
    return null;
  }

  /* const controlProps = {
    inline,
    alignRight,
    disabledReason,
    flexibleControlStateSize,
    className: controlClassName,
    disabled: isDisabled
  }; */

  // Control = <FieldControl {...controlProps}>{children}</FieldControl>;

  return (
    <FieldWrapper
      className={className}
      inline={inline}
      stacked={stacked}
      highlighted={highlighted}
      hasControlState={!flexibleControlStateSize}
      style={style}
      withoutSeparator={withoutSeparator}
    >
      {(label || help) && (
        <FieldDescription inline={inline} htmlFor={id}>
          {label && (
            <FieldLabel disabled={isDisabled}>
              {label}
              {required && <FieldRequiredBadge />}
            </FieldLabel>
          )}
          {help && <FieldHelp>{help}</FieldHelp>}
        </FieldDescription>
      )}
      <div
        className={classnames(styles['field-control-wrapper'], {
          [styles.inline]: inline,
        })}
      >
        {children}
      </div>
    </FieldWrapper>
  );
};

Field.defaultProps = defaultProps;
export default Field;
