import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import Link from 'Components/link';
import { useStoreState } from 'Store';

import styles from './settingsLayoutSidebar.module.scss';

type SettingsLayoutSidebarProps = {};

const SettingsLayoutSidebar: React.FunctionComponent<SettingsLayoutSidebarProps> = () => (
  <div className={classnames(styles.sidebar)}>
    <NavSection>
      <NavHeading>Compte</NavHeading>
      <NavItem key={1} to="/settings/account" label="Détail du compte" index={1} />
      <NavItem key={2} to="/settings/suppliers" label="Emetteurs & Valideurs" index={2} />
      <NavItem key={3} to="/settings/payment-means" label="Moyens de paiement" index={3} />
      <NavItem key={4} to="/settings/chorus" label="Chorus" index={4} />
    </NavSection>
  </div>
);
export default SettingsLayoutSidebar;

const NavSection = (props: any) => {
  const { children } = props;
  return <div className={classnames(styles.section)}>{children}</div>;
};

const NavHeading = (props: any) => {
  const { children } = props;
  return <div className={classnames(styles.heading)}>{children}</div>;
};

const NavItem = (props: any) => {
  const { pathname } = useStoreState(state => state.router.location);
  const { to, label } = props;
  const isActive = !_.isNull(pathname.match(new RegExp(`^${to.replace('/', '\\/')}(\\/.+)?$`)));
  return (
    <Link className={classnames(styles.item, { [styles.active]: isActive })} {...props}>
      {label}
    </Link>
  );
};
