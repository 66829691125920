import * as React from 'react';
import classnames from 'classnames';

import HeaderTitle from 'Components/headerTitle';

import styles from './settingsChorusView.module.scss';
import ChorusForm from './chorusForm';

type SettingsChorusViewProps = {};

const SettingsChorusView: React.FunctionComponent<SettingsChorusViewProps> = () =>
  React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <HeaderTitle className={classnames(styles.title)}>Chorus</HeaderTitle>
        <div className={classnames(styles.content)}>
          <ChorusForm />
        </div>
      </div>
    ),
    [],
  );

export default SettingsChorusView;
