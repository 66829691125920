import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import InlineSvg from 'Components/inlineSvg';
import Link from 'Components/link';
import Tooltip from 'Components/tooltip';
import OverlayTrigger from 'Components/overlayTrigger';

import styles from './headerItem.module.scss';

type HeaderItemProps = {
  className?: string;
  allowClear?: boolean;
  icon?: string;
  onClear?: () => void;
  hasChanges?: boolean;
  hasSelected?: boolean;
  isOpen?: boolean;
  locked?: boolean;
  lockedMessage?: string;
  settingsLink?: string;
  forwardedRef?: React.Ref<any>;
  [x: string]: any;
};

const HeaderItem: React.FunctionComponent<HeaderItemProps> = ({
  className,
  children,
  isOpen,
  hasSelected,
  allowClear,
  icon,
  locked,
  lockedMessage,
  settingsLink,
  onClear,
  forwardedRef,
  ...props
}) => {
  const handleClear = (e: any) => {
    e.stopPropagation();
    if (_.isFunction(onClear)) onClear();
  };

  return (
    <div
      className={classnames(styles.wrapper, className, {
        [styles.isOpen]: isOpen,
        [styles.hasSelected]: hasSelected,
        [styles.locked]: locked,
      })}
      ref={forwardedRef}
      {..._.omit(props, ['hasChanges'])}
    >
      {icon && (
        <span
          className={classnames(styles['icon-container'], {
            [styles.hasSelected]: hasSelected,
          })}
        >
          <InlineSvg size="24px" className={classnames(styles.icon)} src={icon} />
        </span>
      )}
      <div className={classnames(styles.content)}>{children}</div>
      {hasSelected && !locked && allowClear && (
        <InlineSvg
          size="14px"
          className={classnames(styles.close, {
            [styles.locked]: locked,
            [styles.hasSelected]: hasSelected,
          })}
          src="icon-close-lg"
          onClick={handleClear}
        />
      )}
      {settingsLink && (
        <Link className={classnames(styles.link)} to={settingsLink}>
          <InlineSvg size="24px" className={classnames(styles['icon-settings'])} src="icon-settings" />
        </Link>
      )}
      {!locked && (
        <InlineSvg
          size="24px"
          className={classnames(styles.chevron, { [styles.isOpen]: isOpen })}
          src="icon-chevron-down"
        />
      )}
      {locked && (
        <OverlayTrigger
          placement="bottom"
          overlay={overlayProps => (
            <Tooltip id="header-item-lock-tooltip" {...overlayProps}>
              {lockedMessage || 'Cette sélection est bloquée'}
            </Tooltip>
          )}
        >
          <InlineSvg size="24px" className={classnames(styles.lock)} src="icon-lock" />
        </OverlayTrigger>
      )}
    </div>
  );
};
HeaderItem.defaultProps = {
  allowClear: true,
};
export default React.forwardRef<any, HeaderItemProps>((props, ref) => <HeaderItem {...props} forwardedRef={ref} />);
