import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import Button from 'Components/button';

import styles from './errorStateWarning.module.scss';

type ErrorStateWarningProps = {
  className?: string;
  message: string;
  onRetry?: () => void;
};

const ErrorStateWarning: React.FunctionComponent<ErrorStateWarningProps> = ({ className, message, onRetry }) =>
  React.useMemo(
    () => (
      <div className={classnames(styles.wrapper, className)}>
        <div className={classnames(styles.container)}>
          <div className={classnames(styles.message)}>
            <p>{message}</p>
          </div>
          {_.isFunction(onRetry) && (
            <div className={classnames(styles.button)}>
              <Button size="small" onClick={onRetry}>
                Réessayer
              </Button>
            </div>
          )}
        </div>
      </div>
    ),
    [className, message, onRetry],
  );

export default React.memo(ErrorStateWarning);
