import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { Field, FieldProps, ErrorMessage } from 'formik';

import Switch, { SwitchProps } from 'Components/switch';
import styles from './field.module.scss';

const SwitchField: React.SFC<{ inverted: boolean } & SwitchProps & FieldProps> = props => {
  const { field, form, isDisabled, id, size, inverted } = props;
  const active = inverted ? !field.value : field.value;
  return (
    <Switch
      id={id}
      isActive={active}
      isDisabled={isDisabled}
      size={size}
      toggle={() => form.setFieldValue(field.name, !field.value)}
    />
  );
};

const SwitchControl = (props: any) => {
  const { name, errors, touched, isDisabled, id, size, inverted } = props;
  const hasError = _.get(errors, name) && _.get(touched, name);
  return (
    <div className={classnames(styles['field-container'])}>
      <Field name={name} component={SwitchField} isDisabled={isDisabled} id={id} size={size} inverted={inverted} />
      <ErrorMessage
        name={name}
        render={(msg: string) => <div className={classnames({ 'invalid-feedback': hasError })}>{msg}</div>}
      />
    </div>
  );
};

export default SwitchControl;
