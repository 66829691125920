import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import Button from 'Components/button';
import LoadingIndicator from 'Components/loadingIndicator';

import styles from './confirmationViewModal.module.scss';

type ConfirmationViewModalAction = {
  label: string;
  onClick?: () => Promise<void>;
  priority?: 'default' | 'danger' | 'primary';
};
export type ConfirmationViewModalActions = ConfirmationViewModalAction[];
type ConfirmationViewModalProps = {
  close: () => Promise<void>;
  title?: string;
  permanent?: boolean;
  loadingMessage?: string;
  actions?: ConfirmationViewModalActions;
};

const ConfirmationViewModal: React.FunctionComponent<ConfirmationViewModalProps> = ({
  close,
  title,
  permanent,
  loadingMessage,
  actions,
}) => {
  const loadingRef = React.useRef<boolean>(false);
  const [loading, _setLoading] = React.useState<boolean>(loadingRef.current);
  // eslint-disable-next-line no-shadow
  const setLoading = React.useCallback(loading => {
    _setLoading(loading);
    loadingRef.current = loading;
  }, []);
  const nodeRef = React.useRef<any>();
  const handleClick = React.useCallback((e: any) => {
    if (nodeRef && nodeRef.current.contains(e.target)) return;
    if (loadingRef.current) {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);
  const handleAction = React.useCallback(
    async onClick => {
      setLoading(true);
      window.addEventListener('click', handleClick, true);
      try {
        if (_.isFunction(onClick)) await onClick();
        await close();
      } finally {
        window.removeEventListener('click', handleClick, true);
      }
      setLoading(false);
    },
    [close, handleClick, setLoading],
  );
  React.useEffect(
    () => () => {
      window.removeEventListener('click', handleClick, true);
    },
    [handleClick],
  );
  return React.useMemo(
    () => (
      <div ref={nodeRef} className={classnames(styles.wrapper, { [styles.loading]: loading })}>
        {loading ? (
          <>
            <LoadingIndicator />
            {loadingMessage && <h4>{loadingMessage}</h4>}
          </>
        ) : (
          <>
            <div className={classnames(styles['message-wrapper'])}>
              <div className={classnames(styles.message)}>
                <span className={classnames(styles.title)}>{`Êtes vous sûr ${title} ?`}</span>
                {permanent && <div className={classnames(styles.subtitle)}>Cette action est définitive</div>}
              </div>
            </div>
            <div className={classnames(styles.actions)}>
              {_.isArray(actions) &&
                // eslint-disable-next-line no-shadow
                _.map(actions, ({ priority, onClick, label }, index) => (
                  <Button
                    key={`action-${index}`}
                    className={classnames(styles.action)}
                    size="small"
                    priority={priority}
                    onClick={_.partial(handleAction, onClick)}
                  >
                    {label}
                  </Button>
                ))}
            </div>
          </>
        )}
      </div>
    ),
    [loading, loadingMessage, title, permanent, actions, handleAction],
  );
};

export default ConfirmationViewModal;
