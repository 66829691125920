import * as React from 'react';
import classNames from 'classnames';

import styles from './textBlock.module.scss';

interface Props {
  noMargin?: boolean;
  children: React.ReactNode;
  className?: string;
}
const TextBlock = (props: Props) => {
  const { className, noMargin, children, ...moreProps } = props;
  return (
    <div
      className={classNames(className, styles.textBlock, {
        [styles.noMargin]: noMargin,
      })}
      {...moreProps}
    >
      {children}
    </div>
  );
};

export default TextBlock;
