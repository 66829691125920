import * as React from 'react';
import classNames from 'classnames';

import styles from './panel.module.scss';
import textStyles from '../textStyles.module.scss';

interface PanelBodyProps {
  className?: string;
  disablePadding?: boolean;
  children: React.ReactNode;
  [propName: string]: any;
}

const PanelBody = (props: PanelBodyProps) => {
  const { className, disablePadding, children, ...moreProps } = props;

  return (
    <div
      className={classNames(className, textStyles.textStyles, styles.panelBody, {
        [styles.withPadding]: !disablePadding,
      })}
      {...moreProps}
    >
      {children}
    </div>
  );
};

export default PanelBody;
