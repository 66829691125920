import * as React from 'react';
import classnames from 'classnames';

import Button from 'Components/button';
import styles from './multipleSelectorSubmitRow.module.scss';

type MultipleSelectorSubmitRowProps = {
  onSubmit?: () => void;
};

const MultipleSelectorSubmitRow: React.FunctionComponent<MultipleSelectorSubmitRowProps> = props => {
  const render = () => {
    const { onSubmit } = props;
    return (
      <div className={classnames(styles['submit-row'])}>
        <div className={classnames(styles['submit-container'])}>
          <Button className={classnames(styles['submit-button'])} onClick={onSubmit} size="xsmall" priority="primary">
            Appliquer
          </Button>
        </div>
      </div>
    );
  };
  return render();
};
export default MultipleSelectorSubmitRow;
