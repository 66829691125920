import * as React from 'react';
import classnames from 'classnames';

import styles from './loadingIndicator.module.scss';

type LoadingIndicatorProps = {
  overlay?: boolean;
  dark?: boolean;
  mini?: boolean;
  // triangle?: boolean;
  finished?: boolean;
  relative?: boolean;
  hideMessage?: boolean;
  size?: number;
  hideSpinner?: boolean;
  className?: string;
  style?: object;
};

const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = props => {
  const {
    hideMessage,
    mini,
    // triangle,
    overlay,
    dark,
    children,
    finished,
    className,
    style,
    relative,
    size,
    hideSpinner,
  } = props;

  const cx = classnames(className, styles.loading, {
    [styles.relative]: relative,
    [styles.overlay]: overlay,
    [styles.dark]: dark,
    [styles.mini]: mini,
    // triangle,
  });

  const loadingCx = classnames({
    [styles['loading-indicator']]: true,
    [styles['load-complete']]: finished,
  });

  let loadingStyle = {};
  if (size) {
    loadingStyle = {
      width: size,
      height: size,
    };
  }
  return (
    <div className={cx} style={style}>
      {!hideSpinner && (
        <div className={loadingCx} style={loadingStyle}>
          {finished ? <div className="checkmark draw" /> : null}
        </div>
      )}
      {!hideMessage && <div className={classnames([styles['loading-message']])}>{children}</div>}
    </div>
  );
};
export default LoadingIndicator;
