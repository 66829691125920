import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {
  FaFileInvoiceDollar,
  FaCog,
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
  FaSignOutAlt,
  FaHandshake,
  FaChartLine,
  FaFileImport,
  FaBell,
} from 'react-icons/fa';

import styles from './sidebar.module.scss';
import SidebarItem from './sidebarItem';
import SidebarState from './sidebarState';
import SidebarIdentity from './sidebarIdentity';

type SidebarProps = {
  state?: SidebarState;
  changeState?: (newState: SidebarState) => void;
  nbUnreadNotifications?: number;
  nbInvoicesToValidate?: number;
};
const defaultProps: Partial<SidebarProps> = {
  state: SidebarState.FULL,
  changeState: _.noop,
};

const Sidebar: React.FunctionComponent<SidebarProps> = ({
  state,
  changeState,
  nbUnreadNotifications,
  nbInvoicesToValidate,
}) => {
  const isCollapsed = state === SidebarState.COLLAPSED;
  const hasBadges = _.some([nbInvoicesToValidate, nbUnreadNotifications], n => _.isFinite(n) && _.gt(n, 0));
  return (
    <StyledSidebar collapsed={isCollapsed} hasBadges={hasBadges}>
      <SidebarSectionGroup>
        <SidebarSection>
          <SidebarIdentity onlyAvatar={isCollapsed} />
        </SidebarSection>
        <SidebarSection nav noMargin>
          <SidebarSectionGroup>
            <SidebarSection>
              <SidebarItem to="/dashboard" collapsed={isCollapsed} icon={<FaChartLine />} label="Tableau de bord" />
              <SidebarItem
                to="/invoices"
                collapsed={isCollapsed}
                icon={<FaFileInvoiceDollar />}
                label="Factures"
                activePath="/invoices"
              />
              <SidebarItem
                to="/invoices-validation"
                collapsed={isCollapsed}
                icon={<FaFileImport />}
                label="Validation"
                badge={nbInvoicesToValidate}
              />
              <SidebarItem
                to="/clients"
                collapsed={isCollapsed}
                icon={<FaHandshake />}
                label="Clients"
                activePath="/clients"
              />
            </SidebarSection>
            <SidebarSection>
              <SidebarItem
                to="/settings/account"
                activePath="/settings"
                collapsed={isCollapsed}
                icon={<FaCog size="22" />}
                label="Préférences"
              />
            </SidebarSection>
          </SidebarSectionGroup>
        </SidebarSection>
      </SidebarSectionGroup>
      <SidebarSectionGroup>
        <SidebarSection>
          <SidebarItem
            to="/notifications"
            collapsed={isCollapsed}
            icon={<FaBell size="22" />}
            label="Notifications"
            badge={nbUnreadNotifications}
          />
          <SidebarItem
            collapsed={isCollapsed}
            icon={isCollapsed ? <FaRegArrowAltCircleRight /> : <FaRegArrowAltCircleLeft />}
            label="Réduire"
            onClick={
              _.isFunction(changeState)
                ? () =>
                    state !== SidebarState.COLLAPSED
                      ? changeState(SidebarState.COLLAPSED)
                      : changeState(SidebarState.FULL)
                : undefined
            }
          />
          <SidebarItem to="/logout" collapsed={isCollapsed} icon={<FaSignOutAlt size="22" />} label="Déconnexion" />
        </SidebarSection>
      </SidebarSectionGroup>
    </StyledSidebar>
  );
};
Sidebar.defaultProps = defaultProps;

const StyledSidebar = ({ collapsed, hasBadges, children }: any) => (
  <div
    className={classNames(styles.sidebar, {
      [styles.collapsed]: collapsed,
      [styles.hasBadges]: hasBadges,
    })}
  >
    {children}
  </div>
);

const SidebarSectionGroup = ({ children }: any) => <div className={classNames(styles.sectionGroup)}>{children}</div>;

const SidebarSection = ({ nav, noMargin, children }: any) => (
  <div
    className={classNames(styles.section, {
      [styles.nav]: nav,
      [styles.noMargin]: noMargin,
    })}
  >
    {children}
  </div>
);

export default Sidebar;
