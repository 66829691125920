export const MENU_CLOSE_DELAY = 200;

// Special Search characters
export const NEGATION_OPERATOR = '!';
export const SEARCH_WILDCARD = '*';

export const RECENT_SEARCH_TYPES = {
  INVOICE: 0,
};

export enum TOAST_POSITION {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
}

export enum TOAST_TYPE {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  DEFAULT = 'default',
}

export const QUERY_INVOICE_MAX_COUNT = 100;
export const INVOICES_DEFAULT_PAGE = 1;
export const INVOICES_DEFAULT_PER_PAGE = 10;
export const INVOICES_PER_PAGE_OPTIONS = [10, 20, 50];

export const QUERY_CLIENT_MAX_COUNT = 100;
export const CLIENTS_DEFAULT_PAGE = 1;
export const CLIENTS_DEFAULT_PER_PAGE = 10;
export const CLIENTS_PER_PAGE_OPTIONS = [10, 20, 50];

export const QUERY_SUPPLIER_MAX_COUNT = 100;
export const SUPPLIERS_DEFAULT_PAGE = 1;
export const SUPPLIERS_DEFAULT_PER_PAGE = 10;
export const SUPPLIERS_PER_PAGE_OPTIONS = [10, 20, 50];

export const QUERY_PAYMENT_MEAN_MAX_COUNT = 100;
export const PAYMENT_MEANS_DEFAULT_PAGE = 1;
export const PAYMENT_MEANS_DEFAULT_PER_PAGE = 10;
export const PAYMENT_MEANS_PER_PAGE_OPTIONS = [10, 20, 50];
