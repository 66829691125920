import * as React from 'react';
import classnames from 'classnames';
import { useQuery, useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import _ from 'lodash';
import moment from 'moment';
// @ts-ignore
import ReactHtmlParser from 'react-html-parser';

import notify from 'Utils/notify';
import useRouter from 'UtilHooks/useRouter';
import useSessionStorage from 'UtilHooks/useSessionStorage';
import useUrlSearchParams from 'UtilHooks/useUrlSearchParams';
import useObservable from 'UtilHooks/useObservable';

import Button from 'Components/button';
import Paginator from 'Components/paginator';
import QueryCount from 'Components/queryCount';
import ViewTable from 'Views/components/viewTable';

import styles from './notificationsView.module.scss';

type NotificationsViewProps = {};

const NotificationsView: React.FunctionComponent<NotificationsViewProps> = () => {
  const [token] = useSessionStorage('token');
  const { location, replace } = useRouter();
  const {
    params: { page, per_page: perPage },
    updateParams,
    errorParams: { page: errorPage, per_page: errorPerPage },
    helpers: { getParams, toUrlSearchString },
  } = useUrlSearchParams({
    page: {
      defaultValue: 1,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: {
      defaultValue: 10,
      acceptedValues: [10, 20, 50],
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
      onUpdate: ({ pathname, search, helpers: { omit } }) => ({ pathname, search: omit(search, 'page') }),
    },
  });
  // eslint-disable-next-line no-shadow
  const updatePage = React.useCallback((page: any) => updateParams({ page }), [updateParams]);
  const updatePerPage = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/camelcase
    (per_page: any) => updateParams({ per_page }),
    [updateParams],
  );
  const queryVariables = React.useMemo(() => undefined, []);
  const {
    data: { notifications, notificationsCount, unreadNotificationsCount },
    error,
    loading,
    refetch,
  }: any = useQuery(
    gql`
      query {
        unreadNotificationsCount: notificationsCount(input: { state: UNREAD })
        notificationsCount(input: {})
        notifications(input: {}) {
          id
          html
          publicationDate
          viewDate
        }
      }
    `,
    {
      variables: queryVariables,
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
    },
  );
  const { sendMessage } = useObservable('notifications', {
    regex: new RegExp(/^refetch$/),
    onMessage: async () => {
      await refetch();
    },
  });
  const markNotificationAsReadMutation = useMutation<any>(
    gql`
      mutation($id: ID!) {
        markNotificationAsRead(id: $id)
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const markNotificationAsRead = React.useCallback(
    async (id: string) => {
      let error;
      try {
        const { error: _error }: any = await markNotificationAsReadMutation({
          variables: { id },
        });
        error = _error;
      } catch (err) {
        error = err;
      }
      if (error) notify.error("Impossible d'accéder au serveur");
      else sendMessage({ type: 'refetch' });
    },
    [markNotificationAsReadMutation, sendMessage],
  );
  const maxPage = React.useMemo(() => Math.ceil(notificationsCount / perPage), [notificationsCount, perPage]);
  const maxPageError = React.useMemo(() => {
    return maxPage > 0 && page > maxPage
      ? new Error(
          `Paramètre 'page' doit être ${maxPage === 1 ? 'égal à 1' : `un entier inférieur ou égal à ${maxPage}`}.`,
        )
      : undefined;
  }, [maxPage, page]);
  const tableColumns = React.useMemo(
    () => [
      {
        disableSort: true,
        property: 'id',
        header: { label: 'ID' },
        cell: {
          formatters: [
            (id: any, { rowData: { html, publicationDate, viewDate } }: any) => {
              return (
                <div className={classnames(styles['notification-wrapper'])}>
                  <div
                    className={classnames(styles['notification-container'], { [styles.unread]: _.isNull(viewDate) })}
                  >
                    <div className={classnames(styles.html)}>{ReactHtmlParser(html)}</div>
                    <div className={classnames(styles['additional-wrapper'])}>
                      <div className={classnames(styles['additional-container'])}>
                        <div className={classnames(styles.actions)}>
                          <Button priority="link" onClick={_.partial(markNotificationAsRead, id)}>
                            Marquer comme lu
                          </Button>
                        </div>
                        <div className={classnames(styles.infos)}>
                          <span className={classnames(styles.info)}>
                            {`Publiée ${moment(publicationDate, 'DD-MM-YYYY HH:mm:ss.SSS').fromNow()}`}
                          </span>
                          {!_.isNull(viewDate) && (
                            <span className={classnames(styles.info)}>
                              {` - Vue ${moment(viewDate, 'DD-MM-YYYY HH:mm:ss.SSS').fromNow()}`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            },
          ],
        },
      },
    ],
    [markNotificationAsRead],
  );
  React.useEffect(() => {
    if (_.isError(maxPageError))
      replace({
        pathname: location.pathname,
        search: toUrlSearchString(
          _.extend(getParams(), {
            page: maxPage,
          }),
        ),
      });
  }, [getParams, location.pathname, maxPage, maxPageError, replace, toUrlSearchString]);
  const renderTitle = React.useCallback(
    () => (
      <h1 className={classnames(styles.title)}>
        Mes notifications
        {!error && <QueryCount className={classnames(styles.counter)} count={unreadNotificationsCount} max={100} />}
      </h1>
    ),
    [error, unreadNotificationsCount],
  );
  const renderTable = React.useCallback(
    () => (
      <div className={classnames(styles.table)}>
        <div className={classnames(styles.container)}>
          <ViewTable
            withoutHeaders
            loading={loading}
            error={error || maxPageError}
            columns={tableColumns}
            rowKey="id"
            rows={notifications}
          />
        </div>
      </div>
    ),
    [error, loading, maxPageError, notifications, tableColumns],
  );
  const renderPagination = React.useCallback(
    () => (
      <div className={classnames(styles.pagination)}>
        {!error && notifications && (
          <Paginator
            page={page}
            perPage={perPage}
            pages={maxPage}
            perPageOptions={[10, 20, 50]}
            updatePage={updatePage}
            updatePerPage={updatePerPage}
            errorPage={errorPage || maxPageError}
            errorPerPage={errorPerPage}
          />
        )}
      </div>
    ),
    [error, errorPage, errorPerPage, maxPage, maxPageError, notifications, page, perPage, updatePage, updatePerPage],
  );
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.content)}>
          <div className={classnames(styles.header)}>{renderTitle()}</div>
          {renderTable()}
          {renderPagination()}
        </div>
      </div>
    ),
    [renderPagination, renderTable, renderTitle],
  );
};

export default React.memo(NotificationsView);
