import * as React from 'react';
import classnames from 'classnames';

import styles from './field.module.scss';

const FieldLabel = (props: any) => {
  const { children, disabled } = props;
  return (
    <div
      className={classnames(styles['field-label'], {
        [styles.disabled]: disabled,
      })}
    >
      {children}
    </div>
  );
};

export default FieldLabel;
