import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import styles from './highlight.module.scss';

type HighlightProps = {
  className?: string;
  /**
   * The text to highlight
   */
  text?: string;
  /**
   * Should highlighting be disabled?
   */
  disabled?: boolean;
  /**
   * The original text (MUST be a string)
   */
  children?: string;
};

const Highlight: React.FunctionComponent<HighlightProps> = props => {
  const render = () => {
    const { className, children, disabled, text } = props;
    if (_.isUndefined(children)) return null;
    if (_.isUndefined(text) || !!disabled) return <>{children}</>;
    const highlightText = text.toLowerCase();
    const idx = children.toLowerCase().indexOf(highlightText);
    if (idx < 0) return <>{children}</>;
    return (
      <div className={classnames(styles.wrapper)}>
        {children.substr(0, idx)}
        <span className={classnames(styles.highlighted, className)}>{children.substr(idx, highlightText.length)}</span>
        {children.substr(idx + highlightText.length)}
      </div>
    );
  };
  return render();
};
export default Highlight;
