import * as React from 'react';

import { TOAST_TYPE } from 'Constants';

type ToastCloseButtonProps = {
  type: TOAST_TYPE;
  closeToast?: (...args: any[]) => any;
  ariaLabel?: string;
};
const defaultProps: Partial<ToastCloseButtonProps> = {
  ariaLabel: 'close',
};

const ToastCloseButton: React.FunctionComponent<ToastCloseButtonProps> = ({ type, closeToast, ariaLabel }) => (
  <button
    className={`Toastify__close-button Toastify__close-button--${type}`}
    type="button"
    onClick={closeToast}
    aria-label={ariaLabel}
  >
    ✖
  </button>
);
ToastCloseButton.defaultProps = defaultProps;
export default ToastCloseButton;
