import * as React from 'react';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { push } from 'connected-react-router';
// @ts-ignore
import omitDeep from 'omit-deep-lodash';

import { useStoreState, useStoreDispatch } from 'Store';
import useSessionStorage from 'Utils/hooks/useSessionStorage';
import notify from 'Utils/notify';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';
import LoadingIndicator from 'Components/loadingIndicator';

import PaymentMeanForm from './settingsPaymentMeanForm';
import styles from './settingsPaymentMeanEditView.module.scss';

const SettingsPaymentMeanEditView = () => {
  const [token] = useSessionStorage('token');
  const dispatch = useStoreDispatch();
  const pathname = useStoreState(state => state.router.location.pathname, []);
  const match: any = matchPath(pathname, {
    path: '/settings/payment-means/:id/edit',
    exact: true,
  });

  let id: any;
  if (!match) {
    id = null;
  } else {
    // eslint-disable-next-line prefer-destructuring
    id = match.params.id;
  }

  const updatePaymentMeanMutation = useMutation<any>(
    gql`
      mutation updateMoyenPaiement($id: ID!, $paymentMean: MoyenPaiementInput!) {
        updateMoyenPaiement(id: $id, input: $paymentMean) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    const updateValues = omitDeep(values, '__typename');
    try {
      const { error }: any = await updatePaymentMeanMutation({
        variables: { id, paymentMean: updateValues },
      });

      if (error) {
        notify(error.message);
      } else {
        notify('Moyen de paiement modifié avec succès.');

        dispatch(push('/settings/payment-means'));
      }
      actions.setSubmitting(false);
    } catch (e) {
      notify('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const { data, loading } = useQuery(
    gql(`query moyenPaiementById($id: ID!) {
      moyenPaiementById(id: $id) {
        code, modePaiement, typeReferenceBancaire,
        referenceBancaire, referenceEtablissementBancaire
      }
    }`),
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    },
  );

  if (loading) {
    return <LoadingIndicator />;
  }

  const formData = data.moyenPaiementById;

  return (
    <PageContent style={{ overflow: 'auto' }}>
      <PageHeading withMargins>{`Edition Moyen de paiement ${formData.code}`}</PageHeading>
      <div className={classnames(styles['form-wrapper'])}>
        <PaymentMeanForm data={formData} submitLabel="Enregistrer" onSubmit={onSubmit} />
      </div>
    </PageContent>
  );
};
export default SettingsPaymentMeanEditView;
