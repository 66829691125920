import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import classnames from 'classnames';
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa';

import { DYNAMIC_FEATURE_FLAG } from 'Flags';
import { Panel, PanelBody, PanelHeader } from 'Components/panels';
import Button from 'Components/button';
import CheckboxFancy from 'Components/checkboxFancy';
import Switch from 'Components/switch';
import useRouter from 'UtilHooks/useRouter';
import withDynamicFeatureFlag from 'UtilHOC/withDynamicFeatureFlag';
import useModal from 'UtilHooks/useModal';

import Field from 'Views/settings/field/field';
import InputControl from 'Views/settings/field/inputControl';
import SwitchControl from 'Views/settings/field/switchControl';
import SelectControl from 'Views/settings/field/selectControl';

import styles from './clientForm.module.scss';

const TYPE_IDENTIFICATIONS: string[] = [
  'SIRET',
  'UE_HORS_FRANCE',
  'HORS_UE',
  'RIDET',
  'TAHITI',
  'AUTRE',
  'PARTICULIER',
];

const clientSchema = Yup.object().shape({
  code: Yup.string().required('Obligatoire'),
  typeIdentification: Yup.string().required('Obligatoire').oneOf(TYPE_IDENTIFICATIONS),
  identification: Yup.string().required('Obligatoire'),
  nom: Yup.string().required('Obligatoire'),
  adresse: Yup.object().shape({
    rue: Yup.string().required('Obligatoire'),
    codePostal: Yup.string().length(5, 'Le code postal doit avoir exactement 5 caractères.'),
    ville: Yup.string().required('Obligatoire'),
    pays: Yup.string().required('Obligatoire'),
  }),
  codeService: Yup.string(),
  service: Yup.string(),
  chorusDisabled: Yup.boolean(),
  contact: Yup.mixed().when('chorusDisabled', {
    is: true,
    then: Yup.object().shape({
      nom: Yup.string(),
      prenom: Yup.string(),
      courriel: Yup.string().email('Email invalide').required('Obligatoire'),
    }),
    otherwise: Yup.object()
      .transform(current => {
        if (current == null) {
          return undefined;
        }
        return current;
      })
      .shape({
        nom: Yup.string(),
        prenom: Yup.string(),
        courriel: Yup.string().email('Email invalide'),
      })
      .notRequired()
      .default(undefined),
  }),
});

const ClientForm = (props: any) => {
  const {
    replace,
    location: { state: locationState },
  }: any = useRouter();

  const { interceptionStatus, interceptionFilters, ...clientForm } = props.data;

  const [currentInterceptionStatus, updateCurrentInterceptionStatus] = React.useState<string>(interceptionStatus);

  const [currentInterceptionFilters, updateCurrentInterceptionFilters] = React.useState<
    { value: string; enabled: boolean }[]
  >(interceptionFilters);

  const onSubmit = React.useCallback(
    async (values: any, actions: any) => {
      const toSubmit = await clientSchema.validate(values);
      props.onSubmit(
        _.merge({}, toSubmit, {
          interceptionStatus: currentInterceptionStatus,
          interceptionFilters: currentInterceptionFilters,
        }),
        actions,
      );
    },
    [currentInterceptionFilters, currentInterceptionStatus, props],
  );

  return React.useMemo(
    () => (
      <Formik initialValues={clientForm} validationSchema={clientSchema} onSubmit={onSubmit}>
        {({ errors, touched, values }) => (
          <Form>
            <Panel>
              <PanelHeader>Client</PanelHeader>
              <PanelBody disablePadding>
                <Field
                  label="Code client"
                  help="Un code interne identifiant de manière unique ce client ou établissement public"
                  required
                  inline
                >
                  <InputControl type="text" name="code" errors={errors} touched={touched} />
                </Field>
                <Field
                  label="Identification"
                  help="L'identifiant de la société ou de l'établissement public. Par exemple son SIRET"
                  required
                  inline
                >
                  <SelectControl
                    name="typeIdentification"
                    options={TYPE_IDENTIFICATIONS.map(v => ({
                      value: v,
                      label: v,
                    }))}
                  />
                  <InputControl type="text" name="identification" errors={errors} touched={touched} />
                </Field>
                <Field label="Nom client" help="Le nom de la société ou de l'établissement public" required inline>
                  <InputControl type="text" name="nom" errors={errors} touched={touched} />
                </Field>
                <Field label="Code Service" help="Le code du service auquel est destiné votre facture" inline>
                  <InputControl type="text" name="codeService" errors={errors} touched={touched} />
                </Field>
                <Field label="Nom du Service" help="Le nom du service auquel est destiné votre facture" inline>
                  <InputControl type="text" name="nomService" errors={errors} touched={touched} />
                </Field>
                <Field
                  label="Facturation chorus"
                  help="Activez si les factures pour ce client sont envoyées à Chorus Pro"
                  inline
                >
                  <SwitchControl
                    id="chorusEnabled"
                    size="lg"
                    name="chorusDisabled"
                    inverted
                    errors={errors}
                    touched={touched}
                  />
                </Field>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader>Adresse</PanelHeader>
              <PanelBody disablePadding>
                <Field label="Rue" help="L'adresse de la société ou de l'établissement public" required inline>
                  <InputControl type="text" name="adresse.rue" errors={errors} touched={touched} />
                </Field>
                <Field label="Code postal" required inline>
                  <InputControl type="text" name="adresse.codePostal" errors={errors} touched={touched} />
                </Field>
                <Field label="Ville" required inline>
                  <InputControl type="text" name="adresse.ville" errors={errors} touched={touched} />
                </Field>
                <Field label="Pays" help="Le code du pays. Par exemple FR" required inline>
                  <InputControl type="text" name="adresse.pays" errors={errors} touched={touched} />
                </Field>
              </PanelBody>
            </Panel>
            <Panel>
              <PanelHeader>Contact</PanelHeader>
              <PanelBody disablePadding>
                <Field label="Nom" inline>
                  <InputControl type="text" name="contact.nom" errors={errors} touched={touched} />
                </Field>
                <Field label="Prénom" inline>
                  <InputControl type="text" name="contact.prenom" errors={errors} touched={touched} />
                </Field>
                <Field label="Courriel" required={values.chorusDisabled} inline>
                  <InputControl type="text" name="contact.courriel" errors={errors} touched={touched} />
                </Field>
              </PanelBody>
            </Panel>
            <ClientFormInterceptionPanel
              interceptionStatus={currentInterceptionStatus}
              interceptionFilters={currentInterceptionFilters}
              updateInterceptionStatus={updateCurrentInterceptionStatus}
              updateInterceptionFilters={updateCurrentInterceptionFilters}
            />
            <div className={classnames(styles.actions)}>
              <div>
                <Button
                  size="small"
                  priority="default"
                  style={{ marginRight: '8px' }}
                  onClick={_.partial(
                    replace,
                    locationState && locationState.fromInvoices
                      ? {
                          pathname: '/invoices',
                          search: locationState.invoicesSearch,
                        }
                      : '/clients',
                  )}
                >
                  Annuler
                </Button>
                <Button type="submit" size="small" priority="primary">
                  {props.submitLabel}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    ),
    [
      clientForm,
      currentInterceptionFilters,
      currentInterceptionStatus,
      locationState,
      onSubmit,
      props.submitLabel,
      replace,
    ],
  );
};

const ClientFormInterceptionPanel = withDynamicFeatureFlag(DYNAMIC_FEATURE_FLAG.INTERCEPTABLE_INVOICE)(
  ({ interceptionStatus, interceptionFilters, updateInterceptionStatus, updateInterceptionFilters }: any) => {
    const checked = React.useMemo(() => interceptionStatus !== 'NONE', [interceptionStatus]);
    const isGlobal = React.useMemo(() => interceptionStatus === 'ALL', [interceptionStatus]);
    const toggleChecked = React.useCallback(() => {
      if (_.isFunction(updateInterceptionStatus)) {
        if (checked) updateInterceptionStatus('NONE');
        else updateInterceptionStatus('FILTER');
      }
    }, [checked, updateInterceptionStatus]);
    const toggleIsGlobal = React.useCallback(() => {
      if (_.isFunction(updateInterceptionStatus)) {
        if (isGlobal) updateInterceptionStatus('FILTER');
        else updateInterceptionStatus('ALL');
      }
    }, [isGlobal, updateInterceptionStatus]);
    const updateFilter = React.useCallback(
      (index: any, newFilter: any) => {
        updateInterceptionFilters(
          _.concat(_.slice(interceptionFilters, 0, index), newFilter, _.slice(interceptionFilters, index + 1)),
        );
      },
      [interceptionFilters, updateInterceptionFilters],
    );
    const deleteFilter = React.useCallback(
      (index: any) => {
        updateInterceptionFilters(_.filter(interceptionFilters, (_1, i) => i !== index));
      },
      [interceptionFilters, updateInterceptionFilters],
    );
    const addFilter = React.useCallback(
      (filter: any) => {
        updateInterceptionFilters(_.concat(interceptionFilters, filter));
      },
      [interceptionFilters, updateInterceptionFilters],
    );
    const [showNewFilterModal, closeNewFilterModal] = useModal<{
      interceptionFilters: any;
      addFilter: (filter: any) => void;
      close: () => void;
    }>(({ close }) => {
      const newFilterSchema = React.useMemo(
        () =>
          Yup.object().shape({
            value: Yup.string()
              .required('Obligatoire')
              .notOneOf(_.map(interceptionFilters, 'value'), 'Ce filtre existe déjà'),
            enabled: Yup.boolean(),
          }),
        [],
      );
      const initialNewFilterValues = React.useMemo(() => ({ value: '', enabled: true }), []);
      const onSubmitNewFilter = React.useCallback(
        (values: any, actions: any) => {
          if (!newFilterSchema.isValid(values)) return;
          addFilter(values);
          close();
          actions.setSubmitting(false);
        },
        [close, newFilterSchema],
      );
      return React.useMemo(
        () => (
          <Formik
            initialValues={initialNewFilterValues}
            validationSchema={newFilterSchema}
            onSubmit={onSubmitNewFilter}
          >
            {({ errors, touched, isValid }) => (
              <Form>
                <Panel style={{ borderRadius: 0 }}>
                  <PanelHeader>Nouveau filtre d&apos;interception</PanelHeader>
                  <PanelBody disablePadding>
                    <Field label="Référence commande" help="La référence commande sur laquelle filtrer" inline>
                      <InputControl type="text" name="value" errors={errors} touched={touched} />
                    </Field>
                    <Field label="Activation" help="Activer ou non le filtre" inline>
                      <SwitchControl id="enabled" size="lg" name="enabled" errors={errors} touched={touched} />
                    </Field>
                  </PanelBody>
                </Panel>
                <div className={classnames(styles.actions)} style={{ marginRight: '20px' }}>
                  <div>
                    <Button size="small" priority="default" style={{ marginRight: '8px' }} onClick={close}>
                      Annuler
                    </Button>
                    <Button type="submit" size="small" priority="primary" disabled={!isValid}>
                      Ajouter
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ),
        [close, initialNewFilterValues, newFilterSchema, onSubmitNewFilter],
      );
    });
    const renderFilter = React.useCallback(
      (index: any) => {
        const filter = _.nth<any>(interceptionFilters, index);
        return (
          <div
            key={`interception-filter-${index}`}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
          >
            <h4 style={{ margin: 0 }}>{filter.value}</h4>
            <div style={{ display: 'flex', marginLeft: 'auto' }}>
              <Switch
                size="lg"
                isActive={filter.enabled}
                toggle={() => {
                  updateFilter(index, { ...filter, enabled: !filter.enabled });
                }}
              />
              <FaTimesCircle
                className={classnames(styles['close-button'])}
                size="25px"
                onClick={() => {
                  deleteFilter(index);
                }}
              />
            </div>
          </div>
        );
      },
      [deleteFilter, interceptionFilters, updateFilter],
    );
    const renderFilters = React.useCallback(() => {
      if (!(checked && !isGlobal)) return null;
      return (
        <PanelBody className={classnames(styles.interceptionFilters)} disablePadding={interceptionFilters.length > 0}>
          {_.map(_.range(interceptionFilters.length), (index: any) => renderFilter(index))}
          <FaPlusCircle
            className={classnames(styles.newFilterIcon)}
            size="35px"
            onClick={_.partial(showNewFilterModal, { interceptionFilters, addFilter, close: closeNewFilterModal })}
          />
        </PanelBody>
      );
    }, [addFilter, checked, closeNewFilterModal, interceptionFilters, isGlobal, renderFilter, showNewFilterModal]);
    return React.useMemo(
      () => (
        <Panel>
          <PanelHeader>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }} onClick={toggleChecked}>
                <div
                  className={classnames(styles['checkbox-wrapper'], {
                    [styles.checked]: checked,
                  })}
                >
                  <CheckboxFancy className={classnames(styles.checkbox)} checked={checked} />
                </div>
                <div>Filtres d&apos;interception (cocher pour activer)</div>
              </div>
              {checked && (
                <div
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    marginLeft: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={toggleIsGlobal}
                >
                  <span style={{ marginRight: '5px' }}>{isGlobal ? 'Global' : 'Personnalisé'}</span>
                  <Switch isActive={!isGlobal} isDisabled={!checked} />
                </div>
              )}
            </div>
          </PanelHeader>
          {renderFilters()}
        </Panel>
      ),
      [checked, isGlobal, renderFilters, toggleChecked, toggleIsGlobal],
    );
  },
);

export default ClientForm;
