import * as React from 'react';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo-hooks';
import { push } from 'connected-react-router';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';
import { useStoreDispatch } from 'Store';

import PaymentMeanForm from './settingsPaymentMeanForm';
import styles from './settingsPaymentMeanCreateView.module.scss';

const SettingsPaymentMeanCreateView = () => {
  const [token] = useSessionStorage('token');
  const dispatch = useStoreDispatch();
  const createPaymentMeanMutation = useMutation<any>(
    gql`
      mutation createMoyenPaiement($paymentMean: MoyenPaiementInput!) {
        createMoyenPaiement(input: $paymentMean) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { error }: any = await createPaymentMeanMutation({
        variables: { paymentMean: values },
      });

      if (error) {
        notify(error.message);
      } else {
        notify('Moyen de paiement créé avec succès.');

        dispatch(push('/settings/payment-means'));
      }
      actions.setSubmitting(false);
    } catch (e) {
      notify('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const paymentMeanForm = {
    code: '',
    modePaiement: 'VIREMENT',
    typeReferenceBancaire: 'IBAN',
    referenceBancaire: '',
    referenceEtablissementBancaire: '',
  };

  return (
    <PageContent style={{ overflow: 'auto' }}>
      <PageHeading withMargins>Nouveau Moyen de paiement</PageHeading>
      <div className={classnames(styles['form-wrapper'])}>
        <PaymentMeanForm data={paymentMeanForm} submitLabel="Ajouter" onSubmit={onSubmit} />
      </div>
    </PageContent>
  );
};
export default SettingsPaymentMeanCreateView;
