import moment from 'moment';

import getPeriodAgo from 'Utils/date/getPeriodAgo';
import getStartOfDay from 'Utils/date/getStartOfDay';

// Get the start of the day (midnight) for a period ago
//
// e.g. 2 weeks ago at midnight
export default (period: moment.DurationInputArg1, unit: moment.DurationInputArg2) =>
  getStartOfDay(getPeriodAgo(period, unit));
