import * as React from 'react';
import _ from 'lodash';

import { Client } from 'src/types';
import ClientBadge from 'Components/idBadge/clientBadge';
import { BaseBadgeProps } from 'Components/idBadge/baseBadge';

const COMPONENT_MAP = new Map([['client', ClientBadge]]);

type IdBadgeProps = BaseBadgeProps & {
  client?: Client;
};
/**
 * Public interface for all "id badges":
 * Client
 */
const IdBadge: React.FunctionComponent<IdBadgeProps> = props => {
  const render = () => {
    // Given the set of sentry types, find the prop name that was passed to this component,
    // of which we have a mapped component for
    const propNameWithData = Object.keys(props).find(key => COMPONENT_MAP.has(key));

    if (_.isUndefined(propNameWithData)) {
      throw new Error('IdBadge: required property missing (client) or misconfigured');
    }

    const Component = COMPONENT_MAP.get(propNameWithData);

    return !_.isUndefined(Component) ? <Component {...props} /> : null;
    /* return (
      <ErrorBoundary className mini>
        <Component {...props} />
      </ErrorBoundary>
    ); */
  };
  return render();
};
export default IdBadge;
