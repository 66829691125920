import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useQuery, useMutation, useApolloClient } from 'react-apollo-hooks';
import { FaSlidersH, FaPen, FaTrashAlt } from 'react-icons/fa';
import moment from 'moment';

import useSessionStorage from 'UtilHooks/useSessionStorage';
import {
  PAYMENT_MEANS_DEFAULT_PAGE,
  PAYMENT_MEANS_DEFAULT_PER_PAGE,
  PAYMENT_MEANS_PER_PAGE_OPTIONS,
  QUERY_CLIENT_MAX_COUNT,
} from 'Constants';
import CSVDownload from 'Components/csvDownload';
import Paginator from 'Components/paginator';
import Button from 'Components/button';
import QueryCount from 'Components/queryCount';
import notify from 'Utils/notify';
import ViewTable, { ViewTableProps } from 'Views/components/viewTable';
import useRouter from 'UtilHooks/useRouter';
import useModal from 'UtilHooks/useModal';
import useUrlSearchParams from 'Utils/hooks/useUrlSearchParams';
import ViewSidebar from 'Views/components/sidebar';
import ViewSidebarFilter from 'Views/components/viewSidebarFilter';
import DeleteConfirmationViewModal from 'Views/components/modals/deleteConfirmationViewModal';

import styles from './settingsPaymentMeansView.module.scss';

const SettingsPaymentMeansView: React.FunctionComponent<{}> = () => {
  const [token] = useSessionStorage('token');
  const { location, push, replace } = useRouter();
  const {
    params: { order, filter: filters, page, per_page: perPage },
    updateParams,
    errorParams: { page: errorPage, per_page: errorPerPage },
    helpers: { getParams, toUrlSearchString },
  } = useUrlSearchParams({
    order: {
      defaultValue: [],
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
    filter: {
      defaultValue: {},
      mapFromUrl: value => JSON.parse(value),
      mapToUrl: value => JSON.stringify(value),
    },
    page: {
      defaultValue: PAYMENT_MEANS_DEFAULT_PAGE,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: {
      defaultValue: PAYMENT_MEANS_DEFAULT_PER_PAGE,
      acceptedValues: PAYMENT_MEANS_PER_PAGE_OPTIONS,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
      onUpdate: ({ pathname, search, helpers: { omit } }) => ({
        pathname,
        search: omit(search, 'page'),
      }),
    },
  });
  const updateOrder = React.useCallback(
    (newOrder: any) => updateParams({ order: newOrder.length > 0 ? newOrder : null }),
    [updateParams],
  );
  // eslint-disable-next-line no-shadow
  const updatePage = React.useCallback((page: any) => updateParams({ page }), [updateParams]);
  const updatePerPage = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/camelcase
    (per_page: any) => updateParams({ per_page }),
    [updateParams],
  );
  const getQueryVariables = React.useCallback(
    () => ({
      order,
      filters,
      limit: errorPerPage ? 0 : perPage,
      // eslint-disable-next-line no-nested-ternary
      offset: errorPerPage ? 0 : perPage && (errorPage || page) ? perPage * ((errorPage ? 1 : page) - 1) : undefined,
    }),
    [errorPage, errorPerPage, filters, order, page, perPage],
  );
  const QUERY = gql`
    query paymentMeans($limit: Int, $offset: Int, $order: [Order], $filters: MoyenPaiementFilter) {
      moyenPaiementsCount(filter: $filters)
      moyenPaiements(limit: $limit, offset: $offset, order: $order, filter: $filters) {
        id
        code
        modePaiement
        typeReferenceBancaire
        referenceBancaire
        referenceEtablissementBancaire
      }
    }
  `;
  const graphqlClient = useApolloClient();
  const { loading, error, data, refetch }: any = useQuery(QUERY, {
    variables: getQueryVariables(),
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const getMaxPage = React.useCallback((count: number) => Math.ceil(count / perPage), [perPage]);
  const deleteMutation = useMutation<any>(
    gql`
      mutation deletePaymentMean($id: ID!) {
        deleteMoyenPaiement(id: $id) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      update: (proxy, { data: { id } }) => {
        // eslint-disable-next-line no-shadow
        const data: any = proxy.readQuery({
          query: QUERY,
          variables: getQueryVariables(),
        });
        if (data) {
          if (data.moyenPaiements) _.remove(data.moyenPaiements, (paymentMean: any) => paymentMean.id === id);
          if (data.moyenPaiementsCount) data.moyenPaiementsCount -= 1;
        }
        proxy.writeQuery({
          data,
          query: QUERY,
          variables: getQueryVariables(),
        });
      },
    },
  );
  const deletePaymentMean = React.useCallback(
    async (id: string) => {
      // eslint-disable-next-line no-shadow
      let error;
      try {
        const { error: _error }: any = await deleteMutation({
          variables: { id },
        });
        error = _error;
      } catch (err) {
        error = err;
      }
      if (error) {
        notify.error("Impossible d'accéder au serveur");
      } else {
        await refetch(getQueryVariables());
        notify('Moyen de paiement supprimé');
      }
    },
    [deleteMutation, getQueryVariables, refetch],
  );
  const [showModal, closeModal] = useModal<{
    id: string;
    label?: string;
    close: () => Promise<void>;
  }>(({ id, label, close }) => (
    <DeleteConfirmationViewModal
      title={`le moyen de paiement ${label || id}`}
      close={close}
      onConfirm={_.partial(deletePaymentMean, id)}
    />
  ));
  const getMaxPageError = React.useCallback(() => {
    const maxPage = getMaxPage(data.moyenPaiementsCount);
    return maxPage > 0 && page > maxPage
      ? new Error(
          `Paramètre 'page' doit être ${maxPage === 1 ? 'égal à 1' : `un entier inférieur ou égal à ${maxPage}`}.`,
        )
      : undefined;
  }, [data.moyenPaiementsCount, getMaxPage, page]);
  const getTableColumns = React.useCallback(
    () =>
      [
        {
          property: 'code',
          header: { label: 'Code moyen de paiement' },
        },
        {
          property: 'modePaiement',
          header: { label: 'Mode de paiement' },
        },
        {
          property: 'typeReferenceBancaire',
          header: { label: 'Type de référence bancaire' },
        },
        {
          property: 'referenceBancaire',
          header: { label: 'Référence bancaire' },
        },
        {
          property: 'referenceEtablissementBancaire',
          header: { label: "Référence d'établissement bancaire" },
        },
      ] as ViewTableProps['columns'],
    [],
  );
  const getCSVHeaders = React.useCallback(
    () =>
      _.map(getTableColumns(), ({ property, header: { label } }) => ({
        label,
        key: property,
      })),
    [getTableColumns],
  );
  const [sidebarVisible, setSidebarVisibility] = React.useState(false);
  const toogleSidebarVisibility = React.useCallback(() => setSidebarVisibility(!sidebarVisible), [sidebarVisible]);
  React.useEffect(() => {
    if (_.isError(getMaxPageError()))
      replace({
        pathname: location.pathname,
        search: toUrlSearchString(
          _.extend(getParams(), {
            page: getMaxPage(data.moyenPaiementsCount),
          }),
        ),
      });
  }, [data.moyenPaiementsCount, getMaxPage, getMaxPageError, getParams, location.pathname, replace, toUrlSearchString]);
  const renderTitle = React.useCallback(
    () => (
      <h1 className={classnames(styles.title)}>
        Mes moyens de paiement
        {!error && data && (
          <QueryCount
            className={classnames(styles.counter)}
            count={data.moyenPaiementsCount}
            max={QUERY_CLIENT_MAX_COUNT}
          />
        )}
        <Button
          style={{ marginLeft: '4px' }}
          icon="icon-circle-add"
          size="xsmall"
          to="/settings/payment-means/new"
          priority="primary"
          label="Ajouter"
        >
          Ajouter
        </Button>
      </h1>
    ),
    [data, error],
  );
  const renderActions = React.useCallback(
    () => (
      <div className={classnames(styles.actions)}>
        <CSVDownload
          headers={getCSVHeaders()}
          overlayTriggerProps={{ placement: 'bottom' }}
          tooltip="Exporter votre recherche au format csv."
          filename={`exportMoyensPaiement-${moment().format('YYYYMMDDHHmmss')}.csv`}
          onError={() => notify.error("Impossible d'accéder au serveur.")}
          onClick={(event, done) =>
            graphqlClient
              .query({
                query: gql`
                  query($order: [Order], $filters: MoyenPaiementFilter) {
                    paymentMeans: moyenPaiements(order: $order, filter: $filters) {
                      id
                      code
                      modePaiement
                      typeReferenceBancaire
                      referenceBancaire
                      referenceEtablissementBancaire
                    }
                  }
                `,
                variables: _.pick(getQueryVariables(), ['order', 'filters']),
                context: { headers: { Authorization: `Bearer ${token}` } },
                fetchPolicy: 'network-only',
              })
              .then(({ data, errors: error }) =>
                _.isUndefined(error) ? done({ data: data.paymentMeans }) : done({ error }),
              )
          }
        />
        <Button
          activable
          invertOnActive
          priority={sidebarVisible ? 'primary' : 'default'}
          size="xsmall"
          icon={() => <FaSlidersH size="16px" />}
          onClick={toogleSidebarVisibility}
          active={sidebarVisible}
          style={{ marginLeft: '5px' }}
        />
      </div>
    ),
    [getCSVHeaders, getQueryVariables, graphqlClient, sidebarVisible, token, toogleSidebarVisibility],
  );
  const renderTable = React.useCallback(
    () => (
      <div className={classnames(styles.table)}>
        <div className={classnames(styles.container)}>
          <ViewTable
            loading={loading}
            error={error || getMaxPageError()}
            columns={getTableColumns()}
            rowKey="id"
            rows={data.moyenPaiements}
            order={order}
            updateOrder={updateOrder}
            actions={[
              {
                key: 'edit',
                Icon: FaPen,
                onClick: ({ id }: any) => push(`/settings/payment-means/${id}/edit`),
              },
              {
                key: 'delete',
                Icon: FaTrashAlt,
                onClick: ({ id, code }: any) =>
                  showModal({
                    id,
                    label: code,
                    close: closeModal,
                  }),
              },
            ]}
          />
        </div>
      </div>
    ),
    [
      closeModal,
      data.moyenPaiements,
      error,
      getMaxPageError,
      getTableColumns,
      loading,
      order,
      push,
      showModal,
      updateOrder,
    ],
  );
  const renderPagination = React.useCallback(
    () => (
      <div className={classnames(styles.pagination)}>
        {!error && data && (
          <Paginator
            page={page}
            perPage={perPage}
            pages={getMaxPage(data.moyenPaiementsCount)}
            perPageOptions={PAYMENT_MEANS_PER_PAGE_OPTIONS}
            updatePage={updatePage}
            updatePerPage={updatePerPage}
            errorPage={errorPage || getMaxPageError()}
            errorPerPage={errorPerPage}
          />
        )}
      </div>
    ),
    [data, error, errorPage, errorPerPage, getMaxPage, getMaxPageError, page, perPage, updatePage, updatePerPage],
  );
  const renderSidebar = React.useCallback(
    () => (
      <ViewSidebar
        styles={{
          wrapper: classnames(styles.sidebar),
        }}
        options={[
          {
            type: 'section',
            props: {
              className: classnames(styles.filters),
            },
            children: ({ helpers: { buildItem } }) =>
              _.map(
                [
                  {
                    type: 'asyncSelect',
                    property: 'code',
                    label: 'Code moyen de paiement',
                    query: 'moyenPaiements',
                    operator: 'ilike',
                    restricted: true,
                    distinct: true,
                  },
                  {
                    type: 'select',
                    property: 'modePaiement',
                    label: 'Mode de paiement',
                    values: ['ESPECE', 'CHEQUE', 'VIREMENT', 'PRELEVEMENT', 'REPORT'],
                  },
                  {
                    type: 'select',
                    property: 'typeReferenceBancaire',
                    label: 'Type de référence bancaire',
                    values: ['IBAN', 'BIC'],
                  },
                  {
                    type: 'asyncSelect',
                    property: 'referenceBancaire',
                    label: 'Référence bancaire',
                    query: 'moyenPaiements',
                    operator: 'ilike',
                    restricted: true,
                    distinct: true,
                  },
                  {
                    type: 'asyncSelect',
                    property: 'referenceEtablissementBancaire',
                    label: "Référence d'établissement bancaire",
                    query: 'moyenPaiements',
                    operator: 'ilike',
                    restricted: true,
                    distinct: true,
                  },
                ],
                definition =>
                  buildItem({
                    componentProps: { definition, filters },
                    // eslint-disable-next-line no-shadow
                    Component: ({ definition, filters = {} }) => (
                      <ViewSidebarFilter
                        options={definition}
                        filter={{
                          property: definition.type === 'input' ? definition.label : definition.property,
                          operator: definition.type === 'asyncSelect' ? definition.operator : 'eq',
                          value: _.get(
                            filters,
                            `${definition.type === 'input' ? definition.label : definition.property}_${
                              definition.type === 'asyncSelect' ? definition.operator : 'eq'
                            }`,
                          ),
                        }}
                        updateFilter={(newFilter: any) =>
                          updateParams({
                            filter: _.omitBy(
                              _.update(
                                filters,
                                `${definition.type === 'input' ? definition.label : definition.property}_${
                                  definition.type === 'asyncSelect' ? definition.operator : 'eq'
                                }`,
                                () => newFilter,
                              ),
                              _.isUndefined,
                            ),
                          })
                        }
                      />
                    ),
                  }),
              ),
          },
        ]}
      />
    ),
    [filters, updateParams],
  );
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.content, { [styles.sidebarVisible]: sidebarVisible })}>
          <div className={classnames(styles.header)}>
            {renderTitle()}
            {renderActions()}
          </div>
          {renderTable()}
          {renderPagination()}
        </div>
        {sidebarVisible && renderSidebar()}
      </div>
    ),
    [renderActions, renderPagination, renderSidebar, renderTable, renderTitle, sidebarVisible],
  );
};

export default SettingsPaymentMeansView;
