import * as React from 'react';
import _ from 'lodash';

const MENU_CLOSE_DELAY = 200;

export type DropdownChildrenFunctionProps = {
  isOpen: boolean;
  getRootProps: () => DropdownMenuProps;
  getActorProps: (props: {
    onClick?: (e: any) => void;
    onMouseEnter?: (e: any) => void;
    onMouseLeave?: (e: any) => void;
    onKeyDown?: (e: any) => void;
    isStyled?: boolean;
    style?: object;
    [x: string]: any;
  }) => object;
  getMenuProps: (props: {
    onClick?: (e: any) => void;
    onMouseLeave?: (e: any) => void;
    onMouseEnter?: (e: any) => void;
    isStyled?: boolean;
    [x: string]: any;
  }) => object;
};

interface DropdownMenuProps {
  onOpen?: Function;
  onClose?: Function;
  /**
   * Callback for when we get a click outside of dropdown menus.
   * Useful for when menu is controlled.
   */
  onClickOutside?: Function;

  /**
   * Callback function to check if we should ignore click outside to
   * hide dropdown menu
   */
  shouldIgnoreClickOutside?: Function;

  /**
   * If this is set, then this will become a "controlled" component.
   * It will no longer set local state and dropdown visiblity will
   * only follow `isOpen`.
   */
  isOpen?: boolean;

  /** Keeps dropdown menu open when menu is clicked */
  keepMenuOpen?: boolean;

  // Compatibility for <DropdownLink>
  // This will change where we attach event handlers
  alwaysRenderMenu?: boolean;

  // closes menu on "Esc" keypress
  closeOnEscape?: boolean;

  /**
   * If this is set to true, the dropdown behaves as a "nested dropdown" and is
   * triggered on mouse enter and mouse leave
   */
  isNestedDropdown?: boolean;

  children?: React.ReactNode | Function;
}

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = props => {
  const getIsOpenFromProps = React.useCallback(() => props.isOpen, [props.isOpen]);
  const [_isOpen, setIsOpen] = React.useState(getIsOpenFromProps); // tslint:disable-line: variable-name
  React.useEffect(() => {
    setIsOpen(getIsOpenFromProps());
    return _.noop;
  }, [getIsOpenFromProps]);
  const dropdownMenu = React.useRef<any>();
  const dropdownActor = React.useRef<any>();
  const [mouseEnterId, setMouseEnterId] = React.useState<number>();
  const [mouseLeaveId, setMouseLeaveId] = React.useState<number>();

  // Gets open state from props or local state when appropriate
  const getIsOpen = () => {
    const { isOpen } = props;
    const isControlled = !_.isUndefined(isOpen);
    return (isControlled && isOpen) || _isOpen;
  };

  // Callback function from <DropdownMenu> to see if we should close menu
  const shouldIgnoreClickOutside = React.useCallback(
    (e: any) => {
      const { shouldIgnoreClickOutside } = props;
      return (
        (dropdownActor && dropdownActor.current && dropdownActor.current.contains(e.target)) ||
        (_.isFunction(shouldIgnoreClickOutside) && shouldIgnoreClickOutside(e))
      );
    },
    [props],
  );

  // Checks if click happens inside of dropdown menu (or its button)
  // Closes dropdownmenu if it is "outside"
  const checkClickOutside = React.useCallback(
    (e: any) => {
      const { onClickOutside } = props;

      if (!dropdownMenu || !dropdownMenu.current) return;

      // Dropdown menu itself
      if (dropdownMenu && dropdownMenu.current && dropdownMenu.current.contains(e.target)) return;

      if (dropdownActor && dropdownActor.current && dropdownActor.current.contains(e.target)) {
        // Button that controls visibility of dropdown menu
        return;
      }

      if (shouldIgnoreClickOutside(e)) return;

      if (_.isFunction(onClickOutside)) onClickOutside(e);

      const { onClose, isOpen, alwaysRenderMenu } = props;
      const isControlled = !_.isUndefined(isOpen);
      if (!isControlled) setIsOpen(false);

      // Clean up click handlers when the menu is closed for menus that are always rendered,
      // otherwise the click handlers get cleaned up when menu is unmounted
      if (alwaysRenderMenu) document.removeEventListener('click', checkClickOutside, true);

      if (_.isFunction(onClose)) onClose(e);
    },
    [props, shouldIgnoreClickOutside],
  );

  const removeEventListener = React.useCallback(() => document.removeEventListener('click', checkClickOutside, true), [
    checkClickOutside,
  ]);

  // Closes dropdown menu
  const handleClose = React.useCallback(
    (e: any) => {
      const { onClose, isOpen, alwaysRenderMenu } = props;
      const isControlled = !_.isUndefined(isOpen);
      if (!isControlled) setIsOpen(false);

      // Clean up click handlers when the menu is closed for menus that are always rendered,
      // otherwise the click handlers get cleaned up when menu is unmounted
      if (alwaysRenderMenu) removeEventListener();

      if (_.isFunction(onClose)) onClose(e);
    },
    [removeEventListener, props],
  );

  React.useEffect(() => {
    document.addEventListener('click', checkClickOutside, true);
    return () => {
      removeEventListener();
    };
  }, [checkClickOutside, removeEventListener]);

  // Opens dropdown menu
  const handleOpen = (e: any) => {
    const { onOpen, isOpen, alwaysRenderMenu } = props;
    const isControlled = !_.isUndefined(isOpen);
    if (!isControlled) setIsOpen(true);

    if (mouseLeaveId) window.clearTimeout(mouseLeaveId);

    // If we always render menu (e.g. DropdownLink), then add the check click outside handlers when we open the menu
    // instead of when the menu component mounts. Otherwise we will have many click handlers attached on initial load.
    if (alwaysRenderMenu) document.addEventListener('click', checkClickOutside, true);

    if (_.isFunction(onOpen)) onOpen(e);
  };

  // Decide whether dropdown should be closed when mouse leaves element
  // Only for nested dropdowns
  const handleMouseLeave = (e: any) => {
    const { isNestedDropdown } = props;
    if (!isNestedDropdown) return;

    const toElement = e.toElement || e.relatedTarget;

    if (dropdownMenu && !dropdownMenu.current.contains(toElement)) {
      setMouseLeaveId(
        window.setTimeout(() => {
          handleClose(e);
        }, MENU_CLOSE_DELAY),
      );
    }
  };

  const handleToggle = (e: any) => (getIsOpen() ? handleClose(e) : handleOpen(e));

  // Control whether we should hide dropdown menu when it is clicked
  const handleDropdownMenuClick = (e: any) => {
    if (props.keepMenuOpen) return;

    handleClose(e);
  };

  const getRootProps: DropdownChildrenFunctionProps['getRootProps'] = () => props;

  // Actor is the component that will open the dropdown menu
  const getActorProps: DropdownChildrenFunctionProps['getActorProps'] = ({
    onClick,
    onMouseEnter,
    onMouseLeave,
    onKeyDown,
    isStyled,
    style,
    ...innerProps
  } = {}) => {
    const { isNestedDropdown, closeOnEscape } = props;

    // Props that the actor needs to have <DropdownMenu> work
    //
    // `isStyled`: with styled-components we need to pass `innerRef` to get DOM el's ref vs `ref` otherwise
    return {
      ...innerProps,
      ...((isStyled && { innerRef: dropdownActor }) || {}),
      style: {
        ...(style || {}),
        outline: 'none',
      },
      ref: !isStyled ? dropdownActor : undefined,
      tabIndex: -1,
      onKeyDown: (e: any) => {
        if (_.isFunction(onKeyDown)) onKeyDown(e);

        if (closeOnEscape && e.key === 'Escape') handleClose(e);
      },
      onMouseEnter: (e: any) => {
        if (_.isFunction(onMouseEnter)) onMouseEnter(e);

        // Only handle mouse enter for nested dropdowns
        if (!isNestedDropdown) return;

        if (mouseLeaveId) window.clearTimeout(mouseLeaveId);

        setMouseEnterId(
          window.setTimeout(() => {
            handleOpen(e);
          }, MENU_CLOSE_DELAY),
        );
      },
      onMouseLeave: (e: any) => {
        if (_.isFunction(onMouseLeave)) onMouseLeave(e);

        if (mouseEnterId) window.clearTimeout(mouseEnterId);
        handleMouseLeave(e);
      },
      onClick: (e: any) => {
        // Note: clicking on an actor that has a nested menu will close the dropdown menus
        // This is because we currently do not try to find the deepest non-nested dropdown menu
        handleToggle(e);

        if (_.isFunction(onClick)) onClick(e);
      },
    };
  };

  // Menu is the menu component that <DropdownMenu> will control
  const getMenuProps: DropdownChildrenFunctionProps['getMenuProps'] = ({
    onClick,
    onMouseLeave,
    onMouseEnter,
    isStyled,
    ...innerProps
  } = {}) => {
    // Props that the menu needs to have <DropdownMenu> work
    //
    // `isStyled`: with styled-components we need to pass `innerRef` to get DOM el's ref vs `ref` otherwise
    return {
      ...innerProps,
      ...((isStyled && { innerRef: dropdownMenu }) || {}),
      ref: !isStyled ? dropdownMenu : undefined,
      onMouseEnter: (e: any) => {
        if (_.isFunction(onMouseEnter)) onMouseEnter(e);

        // There is a delay before closing a menu on mouse leave, cancel this action if mouse enters menu again
        if (mouseLeaveId) window.clearTimeout(mouseLeaveId);
      },
      onMouseLeave: (e: any) => {
        if (_.isFunction(onMouseLeave)) onMouseLeave(e);

        handleMouseLeave(e);
      },
      onClick: (e: any) => {
        // Note: clicking on an actor that has a nested menu will close the dropdown menus
        // This is because we currently do not try to find the deepest non-nested dropdown menu
        handleDropdownMenuClick(e);

        if (_.isFunction(onClick)) onClick(e);
      },
    };
  };
  const { children } = props;
  if (_.isFunction(children)) {
    return children({
      getRootProps,
      getActorProps,
      getMenuProps,
      isOpen: getIsOpen(),
    });
  }
  return children;
};

export default DropdownMenu;
