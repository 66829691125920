import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { useStoreState } from 'Store';
import Link from '../link';
import TextOverflow from '../textOverflow';

import styles from './sidebar.module.scss';

interface SidebarItemProps {
  to?: string;
  onClick?: Function;
  label: string;
  collapsed?: boolean;
  className?: string;
  badge?: number;
  icon: React.ReactNode;
  activePath?: string;
}

const SidebarItem: React.FunctionComponent<SidebarItemProps> = props => {
  const { pathname } = useStoreState(state => state.router.location);
  const handleClick = (e: any) => {
    const { onClick } = props;
    if (_.isFunction(onClick)) {
      onClick(e);
    }
  };
  const { className, to, label, collapsed, badge, icon, activePath } = props;
  const isActive = activePath
    ? !_.isNull(pathname.match(new RegExp(`^${activePath.replace('/', '\\/')}(\\/.+)?$`)))
    : pathname === to;
  return (
    <Link
      to={to}
      className={classNames(styles.item, className, {
        [styles.active]: isActive,
      })}
      onClick={handleClick}
    >
      <SidebarItemWrapper>
        <SidebarItemIcon>{icon}</SidebarItemIcon>
        {!collapsed && (
          <SidebarItemLabel>
            <TextOverflow>{label}</TextOverflow>
          </SidebarItemLabel>
        )}
        {_.isFinite(badge) && _.gt(badge, 0) && (
          <SidebarItemBadge collapsed={collapsed}>{_.gt(badge, 100) ? '+100' : badge}</SidebarItemBadge>
        )}
      </SidebarItemWrapper>
    </Link>
  );
};

const SidebarItemWrapper = ({ children }: any) => <div className={classNames(styles.wrapper)}>{children}</div>;

const SidebarItemIcon = ({ children }: any) => <span className={classNames(styles.icon)}>{children}</span>;

const SidebarItemLabel = ({ children }: any) => <span className={classNames(styles.label)}>{children}</span>;

const SidebarItemBadge = (props: any) => {
  const { children, collapsed } = props;
  return <span className={classNames(styles.badge, { [styles.collapsed]: collapsed })}>{children}</span>;
};

export default SidebarItem;
