import * as React from 'react';
import classnames from 'classnames';

import styles from './timePicker.module.scss';

type TimePickerProps = {
  className?: string;
  onChangeStart: (e: any) => void;
  onChangeEnd: (e: any) => void;
  // Takes string in 24 hour format
  start?: string | null;
  // Takes string in 24 hour format
  end?: string | null;
  // Should inputs be disabled
  disabled?: boolean;
};

const TimePicker: React.FunctionComponent<TimePickerProps> = ({
  className,
  start,
  end,
  disabled,
  onChangeStart,
  onChangeEnd,
}) => (
  <div className={classnames(styles.wrapper, className)}>
    <div>
      {start && (
        <input
          type="time"
          key={start}
          defaultValue={start}
          className={classnames(styles.item)}
          disabled={disabled}
          onBlur={onChangeStart}
        />
      )}
    </div>

    <div>
      {end && (
        <input
          type="time"
          defaultValue={end}
          key={end}
          className={classnames(styles.item)}
          disabled={disabled}
          onBlur={onChangeEnd}
        />
      )}
    </div>
  </div>
);
export default TimePicker;
