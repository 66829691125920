import React from 'react';
import classnames from 'classnames';

type InlineSvgProps = {
  className?: string;
  src: string;
  size?: string;
  width?: string;
  height?: string;
  viewBox?: string;
  [x: string]: any;
};

const InlineSvg: React.FunctionComponent<InlineSvgProps> = ({
  className,
  src,
  size,
  width,
  height,
  viewBox,
  ...props
}) => {
  const getSvg = React.useCallback(() => require(`!svg-react-loader!../icons/${src}.svg`), [src]); // eslint-disable-line
  const svgRef = React.useRef(getSvg());
  React.useEffect(() => {
    svgRef.current = getSvg();
  }, [getSvg]);
  const SVG = svgRef.current;
  return (
    <SVG
      className={classnames(className, { verticalAlign: 'middle' })}
      width={width || size || '1em'}
      height={height || size || '1em'}
      viewBox={viewBox || '0 0 24 24'}
      {...props}
    />
  );
};

export default InlineSvg;
