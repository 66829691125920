import * as React from 'react';

import { ViewSidebarOptionSection } from './viewSidebarSection';

export type ViewSidebarOptionSectionGroup = {
  type: 'sectionGroup';
  props?: ViewSidebarSectionGroupProps;
  children?: ViewSidebarOptionSection[];
};
type ViewSidebarSectionGroupProps = {
  className?: string;
  leadingSeparator?: React.ComponentType<any>;
  withoutLeadingSeparator?: boolean;
  trailingSeparator?: React.ComponentType<any>;
  withoutTrailingSeparator?: boolean;
};

const ViewSidebarSectionGroup: React.FunctionComponent<ViewSidebarSectionGroupProps> = ({
  className,
  leadingSeparator,
  withoutLeadingSeparator,
  trailingSeparator,
  withoutTrailingSeparator,
  children,
}) => (
  <>
    {!withoutLeadingSeparator && leadingSeparator && React.createElement(leadingSeparator)}
    {React.createElement('div', { className }, children)}
    {!withoutTrailingSeparator && trailingSeparator && React.createElement(trailingSeparator)}
  </>
);
export default ViewSidebarSectionGroup;
