import _ from 'lodash';

import FEATURE_FLAG from 'Flags';

export type Config = {
  ENV: string;
  GRAPHQL_SERVER_URI?: string;
  SENTRY_DSN?: string;
  SENTRY_ENABLED: boolean;
  IS_DRONE: boolean;
  [x: string]: any;
};

export function getConfig(): Config {
  const config: Config = {
    ENV: process.env.REACT_APP_ENV || process.env.TARGET_ENV || process.env.NODE_ENV,
    GRAPHQL_SERVER_URI: process.env.REACT_APP_GRAPHQL_SERVER_URI,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    SENTRY_ENABLED: JSON.parse(process.env.REACT_APP_SENTRY_ENABLED || 'false'),
    IS_DRONE: JSON.parse(process.env.REACT_APP_IS_DRONE || 'false'),
  };
  _.extend(
    config,
    _.transform(
      Object.keys(FEATURE_FLAG).filter(k => typeof FEATURE_FLAG[k as any] === 'number'),
      (result, flag) =>
        _.updateWith(
          result,
          `FEATURE_${flag}`,
          _.constant(_.get(process.env, `REACT_APP_FEATURE_${flag}`) === 'true'),
          Object,
        ),
      {},
    ),
  );
  // eslint-disable-next-line no-console
  if (config.ENV === 'development') console.log('config: ', config);
  return config;
}

export default _.once(getConfig)();
