import _ from 'lodash';
import * as React from 'react';
import classnames from 'classnames';
import createComponent from 'Utils/createComponent';

type TooltipProps = {
  /**
   * @default 'tooltip'
   */
  bsPrefix?: string;
  /**
   * An html id attribute, necessary for accessibility
   * @type {string|number}
   * @required
   */
  id: string | number;
  /**
   * Sets the direction the Tooltip is positioned towards.
   *
   * > This is generally provided by the `Overlay` component positioning the tooltip
   */
  placement:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  /**
   * An Overlay injected set of props for positioning the tooltip arrow.
   *
   * > This is generally provided by the `Overlay` component positioning the tooltip
   *
   * @type {{ ref: ReactRef, style: Object }}
   */
  arrowProps?: { className?: string; ref?: any; style?: object };

  /** @private */
  innerRef?: any;

  /** @private */
  scheduleUpdate?: Function;

  /** @private */
  outOfBoundaries?: any;

  className?: string;
  innerProps?: {
    className?: string;
    [x: string]: any;
  };
  style?: object;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  id,
  bsPrefix,
  innerRef,
  placement,
  className,
  style,
  children,
  arrowProps,
  innerProps,
  ...props
}) => (
  <div
    id={id ? id.toString() : undefined}
    ref={innerRef}
    style={style}
    role="tooltip"
    x-placement={placement}
    className={classnames(className, bsPrefix, `bs-tooltip-${placement}`)}
    {..._.omit(props, ['scheduleUpdate', 'outOfBoundaries'])}
  >
    <div className={classnames(arrowProps && arrowProps.className, 'arrow')} {...arrowProps} />
    <div className={classnames(innerProps && innerProps.className, `${bsPrefix}-inner`)} {...innerProps}>
      {children}
    </div>
  </div>
);
Tooltip.defaultProps = { placement: 'right' };
export default createComponent<TooltipProps>(Tooltip, 'tooltip');
