import * as React from 'react';
import { Action, Thunk, action, thunk, listen, Listen } from 'easy-peasy';

import { RootStoreModel } from 'Store';
import { LOCATION_CHANGE } from 'connected-react-router';

export type Modal = {
  Cmp: React.ComponentType<any>;
  props?: any;
  modalProps?: any;
};

export type Modals = Modal[];

export type ModalsModel = {
  items: Modals;
  reseted: Action<ModalsModel, void>;
  pushed: Action<ModalsModel, Modal>;
  popped: Action<ModalsModel, void>;
  push: Thunk<ModalsModel, Modal, void, RootStoreModel, void>;
  pop: Thunk<ModalsModel, void, void, RootStoreModel, void>;
  listeners: Listen<ModalsModel, any, RootStoreModel>;
};

const modalsModel: ModalsModel = {
  items: [],
  reseted: action(state => {
    state.items = []; // eslint-disable-line
  }),
  pushed: action((state, modal) => {
    state.items.push(modal);
  }),
  popped: action(state => {
    state.items.pop();
  }),
  push: thunk((actions, modal) => {
    actions.pushed(modal);
  }),
  pop: thunk(actions => {
    actions.popped();
  }),
  listeners: listen(on => {
    on(
      LOCATION_CHANGE,
      thunk(actions => {
        actions.reseted();
      }),
    );
  }),
};
export default modalsModel;
