import _ from 'lodash';
import { action, Action, thunk, Thunk, Listen, listen } from 'easy-peasy';

import { RootStoreModel } from 'Store';
import SidebarState from 'Components/sidebar/sidebarState';
import { LOCATION_CHANGE } from 'connected-react-router';

export type ViewModel = {
  sidebar: { [x: string]: SidebarState };
  sidebarUpdated: Action<ViewModel, { view: string; newState: SidebarState }>;
  sidebarReseted: Action<ViewModel, void>;
  updateSidebar: Thunk<ViewModel, { view: string; newState: SidebarState }, any, RootStoreModel, void>;
  toggleSidebar: Thunk<ViewModel, string, any, RootStoreModel, void>;
  listeners: Listen<ViewModel, any, RootStoreModel>;
};

const viewModel: ViewModel = {
  sidebar: {},
  sidebarUpdated: action((state, { view, newState }) => {
    _.set(state.sidebar, view, newState);
  }),
  sidebarReseted: action(() => ({ sidebar: {} })),
  updateSidebar: thunk((actions, payload) => {
    actions.sidebarUpdated(payload);
  }),
  toggleSidebar: thunk((actions, view, { getState }) => {
    const {
      sidebar: { [view]: state },
    } = getState();
    actions.updateSidebar({
      view,
      newState: _.get(
        {
          [SidebarState.FULL]: SidebarState.HIDDEN,
          [SidebarState.HIDDEN]: SidebarState.FULL,
        },
        state || SidebarState.HIDDEN,
      ),
    });
  }),
  listeners: listen(on => {
    on(
      LOCATION_CHANGE,
      // eslint-disable-next-line no-shadow
      thunk((actions, { action }) => {
        switch (action) {
          case 'REPLACE':
            return;
          default:
            actions.sidebarReseted();
        }
      }),
    );
  }),
};
export default viewModel;
