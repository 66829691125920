import * as React from 'react';

type ExternalLinkProps = {
  href: string;
  className?: string;
};

const ExternalLink: React.FunctionComponent<ExternalLinkProps> = ({ href, className, children }) => (
  <a className={className} target="_blank" rel="noreferrer noopener" href={href}>
    {children}
  </a>
);
export default ExternalLink;
