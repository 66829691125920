import * as React from 'react';
import classnames from 'classnames';

import { Client } from 'src/types';

import styles from './baseBadge.module.scss';

export type BaseBadgeProps = {
  client?: Client;
  /**
   * Avatar size
   */
  avatarSize?: number;
  /**
   * Hides the avatar
   */
  hideAvatar?: boolean;
  /**
   * Additional props for Avatar component
   */
  avatarProps?: object;
  /**
   * Hides the main display name
   */
  hideName?: boolean;
  className?: string;
  displayName?: React.ReactNode;
  description?: React.ReactNode;
  avatarClassName?: string;
};

const BaseBadge: React.FunctionComponent<BaseBadgeProps> = props => {
  const render = () => {
    const {
      className,
      // hideAvatar,
      hideName,
      // avatarSize,
      // avatarClassName,
      displayName,
      // avatarProps,
      // client,
      description,
    } = props;

    /* const data = {
      client
    }; */

    return (
      <div className={classnames(styles.wrapper, className)}>
        {/* !hideAvatar && (
        <StyledAvatar css={avatarClassName} size={avatarSize} hideName={hideName} {...avatarProps || {}} {...data} />
      ) */}
        <div className={classnames(styles.container)}>
          {!hideName && <span className={classnames(styles.name)}>{displayName}</span>}
          {!!description && <div className={classnames(styles.description)}>{description}</div>}
        </div>
      </div>
    );
  };
  return render();
};
BaseBadge.defaultProps = { avatarProps: {}, avatarSize: 24, hideAvatar: false };
export default BaseBadge;
