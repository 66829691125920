import * as React from 'react';
import classnames from 'classnames';

import styles from './switch.module.scss';

export interface SwitchProps {
  size: 'sm' | 'lg';
  isActive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  toggle?: any;
  className?: string;
  id?: string;
}

const Switch = (props: SwitchProps) => {
  const { size, isActive, isLoading, isDisabled, toggle, id, className } = props;
  const sizeStyle = size === 'sm' ? styles.sm : styles.lg;
  return (
    <div
      id={id}
      className={classnames(className, styles.container, sizeStyle, {
        [styles.isActive]: isActive,
        [styles.isDisabled]: isDisabled,
        [styles.isLoading]: isLoading,
      })}
      onClick={isDisabled ? undefined : toggle}
    >
      <span
        className={classnames(styles.toggle, sizeStyle, {
          [styles.isActive]: isActive,
          [styles.isDisabled]: isDisabled,
        })}
      />
    </div>
  );
};

Switch.defaultProps = {
  size: 'sm',
};

export default Switch;
