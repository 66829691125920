import * as React from 'react';
import classnames from 'classnames';

import InlineSvg from './inlineSvg';
import styles from './alert.module.scss';

interface AlertPropsType {
  children?: React.ReactNode;
  className?: string;
  level: string;
  icon?: string;
  iconSize?: string;
}

const defaultProps: Partial<AlertPropsType> = {
  iconSize: '24px',
};

const Alert = (props: AlertPropsType) => {
  const { children, className, level, icon, iconSize } = props;
  const levelStyle = styles[`alert-${level}`] || styles['alert-info'];
  return (
    <div className={classnames(styles.alert, className, levelStyle)}>
      {icon && <InlineSvg className={classnames(styles.icon)} src={icon} size={iconSize} />}
      {children}
    </div>
  );
};

Alert.defaultProps = defaultProps;

export default Alert;
