import _ from 'lodash';
import moment from 'moment-timezone';

import getUtcToSystem from 'Utils/date/getUtcToSystem';
import getUserTimezone from 'Utils/date/getUserTimezone';

export default (date?: moment.MomentInput | null, utc?: boolean | null) => {
  if (_.isNull(date) || _.isUndefined(date)) return date;
  return _.isUndefined(utc) || _.isNull(utc) || !!utc
    ? getUtcToSystem(date)
    : moment.tz(moment.utc(date), getUserTimezone());
};
