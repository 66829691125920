import * as React from 'react';
import classnames from 'classnames';

import styles from './headerSeparator.module.scss';

const HeaderSeparator: React.FunctionComponent<{
  style?: { [x: string]: any };
}> = ({ style }) => (
  <div className={classnames(styles.separator)} style={style}>
    &nbsp;
  </div>
); // eslint-disable-line

export default HeaderSeparator;
