import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import Select from 'react-select';

import { Field, FieldProps, ErrorMessage } from 'formik';

import styles from './field.module.scss';

const SelectField: React.SFC<{ options: any } & FieldProps> = ({ options, field, form }) => (
  <Select
    options={options}
    name={field.name}
    value={options ? options.find((option: any) => option.value === field.value) : ''}
    onChange={(option: any) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
  />
);

const SelectControl = (props: any) => {
  const { name, errors, touched, options } = props;
  const hasError = _.get(errors, name) && _.get(touched, name);
  return (
    <div className={classnames(styles['field-container'])}>
      <Field name={name} component={SelectField} options={options} />
      <ErrorMessage
        name={name}
        render={(msg: string) => <div className={classnames({ 'invalid-feedback': hasError })}>{msg}</div>}
      />
    </div>
  );
};

export default SelectControl;
