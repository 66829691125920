export default <T = any>(arr: T[], scoreFn: (x: T) => any[]) => {
  arr.sort((a, b) => {
    const aScore = scoreFn(a); // tslint:disable-line: variable-name
    const bScore = scoreFn(b); // tslint:disable-line: variable-name

    for (let i = 0; i < aScore.length; i += 1) {
      if (aScore[i] > bScore[i]) {
        return 1;
      }
      if (aScore[i] < bScore[i]) {
        return -1;
      }
    }
    return 0;
  });
  return arr;
};
