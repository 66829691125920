import * as React from 'react';
import classnames from 'classnames';

import styles from './inputControl.module.scss';

type InputControlProps = {
  className?: string;
  readOnly?: boolean;
  monospace?: boolean;
  disabled?: boolean;
  [x: string]: any;
};

const InputControl: React.FunctionComponent<InputControlProps> = ({
  className,
  readOnly,
  monospace,
  disabled,
  ...props
}) => (
  <input
    readOnly={readOnly}
    disabled={disabled}
    className={classnames(
      styles.input,
      {
        [styles.readOnly]: readOnly,
        [styles.monospace]: monospace,
        [styles.disabled]: disabled,
      },
      className,
    )}
    {...props}
  />
);
export default InputControl;
