import * as React from 'react';
import classNames from 'classnames';

import styles from './avatar.module.scss';

interface LetterAvatarProps {
  identifier: string;
  displayName: string;
  round?: boolean;
  className?: string;
}

const COLORS = [
  '#4674ca', // blue
  '#315cac', // blue_dark
  '#57be8c', // green
  '#3fa372', // green_dark
  '#f9a66d', // yellow_orange
  '#ec5e44', // red
  '#e63717', // red_dark
  '#f868bc', // pink
  '#6c5fc7', // purple
  '#4e3fb4', // purple_dark
  '#57b1be', // teal
  '#847a8c', // gray
];

class LetterAvatar extends React.Component<LetterAvatarProps> {
  public getColor() {
    const { identifier } = this.props;
    const id = this.hashIdentifier(identifier);
    return COLORS[id % COLORS.length];
  }

  public getInitials() {
    const { displayName } = this.props;
    const names = (displayName.trim() || '?').split(' ');
    // Use Array.from as slicing and substring() work on ucs2 segments which
    // results in only getting half of any 4+ byte character.
    let initials = Array.from(names[0])[0];
    if (names.length > 1) {
      initials += Array.from(names[names.length - 1])[0];
    }
    return initials.toUpperCase();
  }

  // eslint-disable-next-line class-methods-use-this
  public hashIdentifier(identifier: string) {
    let hash = 0;
    for (let i = 0; i < identifier.length; i += 1) {
      hash += identifier.charCodeAt(i);
    }
    return hash;
  }

  public render() {
    const { className, round } = this.props;
    return (
      <svg
        viewBox="0 0 120 120"
        className={classNames(className, styles.imageStyle, {
          [styles.round]: round,
        })}
      >
        <rect x="0" y="0" width="120" height="120" rx="15" ry="15" fill={this.getColor()} />
        <text x="50%" y="50%" fontSize="65" style={{ dominantBaseline: 'central' }} textAnchor="middle" fill="#FFFFFF">
          {this.getInitials()}
        </text>
      </svg>
    );
  }
}

export default LetterAvatar;
