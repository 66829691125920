import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useApolloClient } from 'react-apollo-hooks';

import notify from 'Utils/notify';

import useSessionStorage from 'UtilHooks/useSessionStorage';

const LogoutView = () => {
  const [, , clearToken] = useSessionStorage('token');
  const client = useApolloClient();
  const clearStore = async () => client.resetStore();
  clearToken();
  clearStore();
  notify('Vous êtes déconnecté');
  return <Redirect to="/login" />;
};

export default LogoutView;
