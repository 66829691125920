import * as React from 'react';
import * as ReactDOM from 'react-dom';
import _ from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import warning from 'warning';
import contains from 'dom-helpers/contains';
import classnames from 'classnames';

import Overlay from 'Components/overlay';

class RefHolder extends React.Component {
  public render() {
    const { children } = this.props;
    return children;
  }
}

type TriggerType = 'hover' | 'click' | 'focus';
export type OverlayTriggerProps = {
  /**
   * Specify which action or actions trigger Overlay visibility
   *
   * @type {'hover' | 'click' |'focus' | Array<'hover' | 'click' |'focus'>}
   */
  trigger?: TriggerType | TriggerType[];
  /**
   * A millisecond delay amount to show and hide the Overlay once triggered
   */
  delay?: number | { show?: number; hide?: number };
  /**
   * The initial visibility state of the Overlay. For more nuanced visibility
   * control, consider using the Overlay component directly.
   */
  defaultShow?: boolean;
  /**
   * An element or text to overlay next to the target.
   */
  overlay?: ((overlayProps: any) => React.ReactNode) | React.ReactElement;
  /**
   * A Popper.js config object passed to the the underlying popper instance.
   */
  popperConfig?: any;
  // Overridden props from `<Overlay>`.
  /**
   * @private
   */
  target?: undefined;
  /**
   * @private
   */
  onHide?: undefined;
  /**
   * @private
   */
  show?: undefined;
  containerClassName?: string;
  as?: string;
  [x: string]: any;
};
const defaultProps: Partial<OverlayTriggerProps> = {
  defaultShow: false,
  trigger: ['hover', 'focus'],
  as: 'div',
};

const OverlayTrigger: React.FunctionComponent<OverlayTriggerProps> = props => {
  const trigger = React.useRef<any>();
  const node = React.useRef<any>();
  const [hoverState, setHoverState] = React.useState<string>();
  const [timeoutState, setTimeoutState] = React.useState<NodeJS.Timeout>();
  const [show, setShow] = React.useState(!!props.defaultShow);
  React.useEffect(() => {
    return () => {
      if (timeoutState) clearTimeout(timeoutState);
    };
  }, [timeoutState]);
  const getChildProps = React.useCallback(() => React.Children.only(props.children as any).props, [props.children]);
  // eslint-disable-next-line react/no-find-dom-node
  const getTarget: any = React.useCallback(() => ReactDOM.findDOMNode(trigger.current), [trigger]);
  const normalizeDelay = React.useCallback(
    (delay: any) => (delay && typeof delay === 'object' ? delay : { show: delay, hide: delay }),
    [],
  );
  const handleShow = React.useCallback(() => {
    if (timeoutState) clearTimeout(timeoutState);
    setHoverState('show');
    const delay = normalizeDelay(props.delay);
    if (!delay.show) {
      setShow(true);
      return;
    }
    setTimeoutState(
      setTimeout(() => {
        if (hoverState === 'show') setShow(true);
      }, delay.show),
    );
  }, [hoverState, normalizeDelay, props.delay, timeoutState]);
  const handleHide = React.useCallback(() => {
    if (timeoutState) clearTimeout(timeoutState);
    setHoverState('hide');
    const delay = normalizeDelay(props.delay);
    if (!delay.hide) {
      setShow(false);
      return;
    }
    setTimeoutState(
      setTimeout(() => {
        if (hoverState === 'hide') setShow(false);
      }, delay.hide),
    );
  }, [hoverState, normalizeDelay, props.delay, timeoutState]);
  const handleClick = React.useCallback(
    (e?: any) => {
      setShow(!show);
      const { onClick } = getChildProps();
      if (_.isFunction(onClick)) onClick(e);
    },
    [getChildProps, show],
  );
  // Simple implementation of mouseEnter and mouseLeave.
  // React's built version is broken: https://github.com/facebook/react/issues/4251
  // for cases when the trigger is disabled and mouseOut/Over can cause flicker
  // moving from one child element to another.
  const handleMouseOverOut = React.useCallback((handler: any, e: any, relatedNative: any) => {
    const target = e.currentTarget;
    const related = e.relatedTarget || e.nativeEvent[relatedNative];
    if ((!related || related !== target) && !contains(target, related)) handler(e);
  }, []);
  const handleMouseOver = React.useCallback((e: any) => handleMouseOverOut(handleShow, e, 'fromElement'), [
    handleMouseOverOut,
    handleShow,
  ]);
  const handleMouseOut = React.useCallback((e: any) => handleMouseOverOut(handleHide, e, 'toElement'), [
    handleHide,
    handleMouseOverOut,
  ]);
  const render = React.useCallback(() => {
    const { overlay, children, containerClassName, popperConfig = {}, ...otherProps } = _.omit(props, ['trigger']);
    delete otherProps.delay;
    delete otherProps.defaultShow;
    const child: any = React.Children.only(children);
    const triggerProps: any = {};
    const triggers: string[] = props.trigger ? Array<string>().concat(props.trigger) : [];
    if (triggers.indexOf('click') !== -1) {
      triggerProps.onClick = handleClick;
    }
    if (triggers.indexOf('focus') !== -1) {
      triggerProps.onFocus = handleShow;
      triggerProps.onBlur = handleHide;
    }
    if (triggers.indexOf('hover') !== -1) {
      warning(
        triggers.length >= 1,
        '[react-bootstrap] Specifying only the `"hover"` trigger limits the ' +
          'visibility of the overlay to just mouse users. Consider also ' +
          'including the `"focus"` trigger so that touch and keyboard only ' +
          'users can see the overlay as well.',
      );
      triggerProps.onMouseOver = handleMouseOver;
      triggerProps.onMouseOut = handleMouseOut;
    }
    const ariaModifier: any = {
      enabled: true,
      order: 900,
      fn: (data: any) => {
        const { popper } = data.instance;
        const target = getTarget();
        if (!show || !target) return data;
        const role = popper.getAttribute('role') || '';
        if (popper.id && role.toLowerCase() === 'tooltip') target.setAttribute('aria-describedby', popper.id);
        return data;
      },
    };
    return React.createElement(
      props.as || 'div',
      { ref: node, className: classnames(containerClassName) },
      <>
        <RefHolder ref={trigger}>{React.cloneElement(child, triggerProps)}</RefHolder>
        <Overlay
          {...otherProps}
          container={node.current}
          popperConfig={{
            modifiers: {
              ariaModifier,
              applyStyle: { enabled: false },
              ...popperConfig.modifiers,
            },
            ...popperConfig,
          }}
          show={show}
          onHide={handleHide}
          target={getTarget}
        >
          {overlay}
        </Overlay>
      </>,
    );
  }, [getTarget, handleClick, handleHide, handleMouseOut, handleMouseOver, handleShow, node, props, show, trigger]);
  return render();
};
OverlayTrigger.defaultProps = defaultProps;
export default OverlayTrigger;
