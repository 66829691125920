import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps {
  to?: string;
  href?: string;
  [propName: string]: any;
}

// eslint-disable-next-line
class Link extends React.Component<LinkProps> {
  public render() {
    const { to, href, children, ...props } = this.props;

    if (to) {
      return (
        <RouterLink {...props} to={to}>
          {children}
        </RouterLink>
      );
    }
    return (
      <a {...props} href={href}>
        {children}
      </a>
    );
  }
}

export default Link;
