import * as React from 'react';
import classnames from 'classnames';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import getStartOfPeriodAgo from 'Utils/date/getStartOfPeriodAgo';
// eslint-disable-next-line import/no-named-default
import { default as DateRangeBase } from 'Components/dateRange';
import theme from 'Utils/theme';
import TimePicker from 'Views/components/timeRangeSelector/timePicker';
import Checkbox from 'Components/checkbox';
import getEndOfDay from 'Utils/date/getEndOfDay';
import notify from 'Utils/notify';
import isValidTime from 'Utils/date/isValidTime';
import setDateToTime from 'Utils/date/setDateToTime';
import getStartOfDay from 'Utils/date/getStartOfDay';

import styles from './dateRange.module.scss';

type DateRangeProps = {
  className?: string;
  showAbsolute?: boolean;
  showRelative?: boolean;
  start?: Moment | null;
  end?: Moment | null;
  showTimePicker?: boolean;
  maxPickableDays?: number;
  utc?: boolean | null;
  onChangeUtc?: Function;
  onChange?: Function;
};

const getTimeStringFromDate = (date?: moment.MomentInput | null) => {
  if (_.isNull(date) || _.isUndefined(date)) return date;
  return moment(date).local().format('HH:mm');
};

const DateRange: React.FunctionComponent<DateRangeProps> = props => {
  const getStartFromProps = React.useCallback(
    () => (props.start && getStartOfDay(props.start)) || getStartOfDay(moment()),
    // eslint-disable-next-line react/destructuring-assignment
    [props.start],
  );
  const [start, setStart] = React.useState(getStartFromProps);
  React.useEffect(() => {
    setStart(getStartFromProps());
    return _.noop;
  }, [getStartFromProps]);
  const getEndFromProps = React.useCallback(() => (props.end && getEndOfDay(props.end)) || getEndOfDay(moment()), [
    // eslint-disable-next-line react/destructuring-assignment
    props.end,
  ]);
  const [end, setEnd] = React.useState(getEndFromProps);
  React.useEffect(() => {
    setEnd(getEndFromProps());
    return _.noop;
  }, [getEndFromProps]);
  const handleSelectDateRange = ({ selection: { startDate, endDate } }: any) => {
    const { onChange } = props;
    // eslint-disable-next-line no-shadow
    const start = startDate ? getStartOfDay(startDate) : startDate;
    // eslint-disable-next-line no-shadow
    const end = endDate ? getEndOfDay(endDate) : endDate;
    if (_.isFunction(onChange)) {
      onChange({
        start,
        end,
      });
    }
  };
  const handleChangeStart = (e: any) => {
    // Safari does not support "time" inputs, so we don't have access to
    // `e.target.valueAsDate`, must parse as string
    //
    // Time will be in 24hr e.g. "21:00"
    const { onChange } = props;
    const startTime = e.target.value;
    try {
      if (!startTime || !isValidTime(startTime)) throw new Error('Invalid start time');
      if (start) {
        const newTime = setDateToTime(start, startTime, { local: true });
        if (_.isFunction(onChange)) {
          onChange({
            end,
            start: newTime,
          });
        }
      }
    } catch {
      notify.error('Heure de départ invalide');
    }
  };
  const handleChangeEnd = (e: any) => {
    const { onChange } = props;
    const endTime = e.target.value;
    try {
      if (!endTime || !isValidTime(endTime)) throw new Error('Invalid end time');
      if (end) {
        const newTime = setDateToTime(end, endTime, { local: true });
        if (_.isFunction(onChange)) {
          onChange({
            start,
            end: newTime,
          });
        }
      }
    } catch {
      notify.error("Heure d'arrivée invalide");
    }
  };

  const { className, maxPickableDays, utc, showTimePicker, onChangeUtc } = props;

  const startTime = getTimeStringFromDate(start);
  const endTime = getTimeStringFromDate(end);

  // Restraints on the time range that you can select
  // Can't select dates in the future b/c we're not fortune tellers (yet)
  //
  // We want `maxPickableDays` - 1 (if today is Jan 5, max is 3 days, the minDate should be Jan 3)
  // Subtract additional day  because we force the end date to be inclusive,
  // so when you pick Jan 1 the time becomes Jan 1 @ 23:59:59,
  // (or really, Jan 2 @ 00:00:00 - 1 second), while the start time is at 00:00
  const minDate = !_.isUndefined(maxPickableDays) ? getStartOfPeriodAgo(maxPickableDays - 2, 'days') : undefined;
  const maxDate = new Date();

  return (
    <div className={classnames(styles.wrapper, className)}>
      <DateRangeBase
        className={classnames('date-range-picker')}
        rangeColors={[theme.purple]}
        ranges={[
          {
            startDate: start || getStartOfDay(new Date()),
            endDate: end || getEndOfDay(new Date()),
            key: 'selection',
          },
        ]}
        minDate={minDate}
        maxDate={maxDate}
        onChange={handleSelectDateRange}
        showDateDisplay={false}
      />
      {showTimePicker && (
        <div className={classnames(styles['options-container'])}>
          <TimePicker start={startTime} end={endTime} onChangeStart={handleChangeStart} onChangeEnd={handleChangeEnd} />
          <div className={classnames(styles['utc-picker'])}>
            UTC ?
            <Checkbox
              onChange={onChangeUtc}
              checked={utc || false}
              style={{
                margin: '0 0 0 0.5em',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
DateRange.defaultProps = { showAbsolute: true, showRelative: false };
export default DateRange;
