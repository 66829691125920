import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { AutoSizer, List } from 'react-virtualized';

import AutoComplete from 'Components/autoComplete';
import LoadingIndicator from 'Components/loadingIndicator';
import InputControl from 'Components/forms/inputControl';

import styles from './dropdownAutoCompleteMenu.module.scss';

type Item = {
  value?: any;
  searchKey?: string;
  label?: React.ComponentType<{ inputValue?: string }> | React.ReactNode;
  items?: Items;
  hideGroupLabel?: boolean;
  groupLabel?: boolean;
};
type Items = Item[];
export type DropdownAutoCompleteMenuProps = {
  items?: Items;

  /**
   * If this is undefined, autocomplete filter will use this value instead of the
   * current value in the filter input element.
   *
   * This is useful if you need to strip characters out of the search
   */
  filterValue?: string;

  /**
   * Used to control dropdown state (optional)
   */
  isOpen?: boolean;

  /**
   * Show loading indicator next to input
   */
  busy?: boolean;

  /**
   * Hide's the input when there are no items. Avoid using this when querying
   * results in an async fashion.
   */
  emptyHidesInput?: boolean;

  /**
   * When an item is selected (via clicking dropdown, or keyboard navigation)
   */
  onSelect?: (...args: any[]) => void;
  /**
   * When AutoComplete input changes
   */
  onChange?: () => void;

  /**
   * Callback for when dropdown menu opens
   */
  onOpen?: () => void;

  /**
   * Callback for when dropdown menu closes
   */
  onClose?: () => void;

  /**
   * Message to display when there are no items initially
   */
  emptyMessage?: React.ReactNode;

  /**
   * Message to display when there are no items that match the search
   */
  noResultsMessage?: React.ReactNode;

  /**
   * Presentational properties
   */

  /**
   * Dropdown menu alignment.
   */
  alignMenu?: 'left' | 'right';

  /**
   * Should menu visually lock to a direction (so we don't display a rounded corner)
   */
  blendCorner?: boolean;

  /**
   * Hides the default filter input
   */
  hideInput?: boolean;

  /**
   * Max height of dropdown menu. Units are assumed as `px` if number, otherwise will assume string has units
   */
  maxHeight?: number | string;

  /**
   * Supplying this height will force the dropdown menu to be a virtualized list.
   * This is very useful (and probably required) if you have a large list. e.g. Project selector with many projects.
   *
   * Currently, our implementation of the virtualized list requires a fixed height.
   */
  virtualizedHeight?: number;

  /**
   * If you use grouping with virtualizedHeight, the labels will be that height unless specified here
   */
  virtualizedLabelHeight?: number;

  /**
   * Search input's placeholder text
   */
  searchPlaceholder?: string;

  /**
   * Size for dropdown items
   */
  itemSize?: 'zero' | 'small';

  /**
   * Changes the menu style to have an arrow at the top
   */
  menuWithArrow?: boolean;

  menuFooter?: ((...args: any[]) => React.ReactNode) | React.ReactNode;
  menuHeader?: React.ReactNode;
  /**
   * Props to pass to menu component
   */
  menuProps?: object;

  /**
   * for passing simple styles to the root container
   */
  rootClassName?: string;

  /**
   * Props to pass to input/filter component
   */
  inputProps?: object;

  /**
   * renderProp for the end (right side) of the search input
   */
  inputActions?: ((...args: any[]) => React.ReactNode) | React.ReactNode;

  css?: object;
  style?: object;
  className?: string;
  children: (props: any) => React.ReactNode;
};

const DropdownAutoCompleteMenu: React.FunctionComponent<DropdownAutoCompleteMenuProps> = props => {
  const filterItems = (items: Items, inputValue: string) =>
    items.filter(item => {
      return (item.searchKey || `${item.value} ${item.label}`).toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
    });
  const filterGroupedItems = (groups: Items, inputValue: string) =>
    groups
      .map(group => {
        return {
          ...group,
          items: filterItems(group.items || [], inputValue),
        };
      })
      .filter(group => group.items.length > 0);
  const autoCompleteFilter = (items: Items, inputValue: string) => {
    let itemCount = 0;
    if (!items) return [];
    if (items[0] && items[0].items) {
      // if the first item has children, we assume it is a group
      return _.flatMap(filterGroupedItems(items, inputValue), item => {
        const groupItems = item.items.map(groupedItem => {
          itemCount += 1;
          return {
            ...groupedItem,
            index: itemCount - 1,
          };
        });
        // Make sure we don't add the group label to list of items
        // if we try to hide it, otherwise it will render if the list
        // is using virtualized rows (because of fixed row heights)
        if (item.hideGroupLabel) return groupItems;
        return [{ ...item, groupLabel: true }, ...groupItems];
      });
    }
    return filterItems(items, inputValue).map((item: Item, index: number) => ({
      ...item,
      index,
    }));
  };
  const getHeight = (items: Items) => {
    const { maxHeight, virtualizedHeight, virtualizedLabelHeight } = props;
    const minHeight = virtualizedLabelHeight
      ? items.reduce((a, r) => a + (r.groupLabel ? virtualizedLabelHeight : virtualizedHeight || 0), 0)
      : items.length * (virtualizedHeight || 0);
    return _.isNumber(maxHeight) ? Math.min(minHeight, maxHeight) : minHeight;
  };
  const renderRow = ({ item, index, style, itemSize, key, highlightedIndex, inputValue, getItemProps }: any) => {
    return item.groupLabel ? (
      <div className={classnames(styles['label-with-border'])} style={style} key={item.id}>
        {item.label && <div className={classnames(styles['group-label'])}>{item.label}</div>}
      </div>
    ) : (
      <div
        className={classnames(styles['autocomplete-item'], {
          [styles.small]: itemSize === 'small',
          [styles.zero]: itemSize === 'zero',
          [styles.highlightedIndex]: index === highlightedIndex,
        })}
        key={key}
        {...getItemProps({ item, index, style })}
      >
        {_.isFunction(item.label) ? item.label({ inputValue }) : item.label}
      </div>
    );
  };
  const renderList = ({ items, ...otherProps }: { items: Items; [x: string]: any }) => {
    const { virtualizedHeight, virtualizedLabelHeight } = props;

    // If `virtualizedHeight` is defined, use a virtualized list
    if (!_.isUndefined(virtualizedHeight)) {
      return (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              width={width}
              style={{ outline: 'none' }}
              height={getHeight(items)}
              rowCount={items.length}
              rowHeight={({ index }) => {
                return items[index].groupLabel && virtualizedLabelHeight ? virtualizedLabelHeight : virtualizedHeight;
              }}
              rowRenderer={({ key, index, style }) => {
                const item = items[index];
                return renderRow({
                  item,
                  index,
                  style,
                  key,
                  ...otherProps,
                });
              }}
            />
          )}
        </AutoSizer>
      );
    }

    return items.map((item, index) => {
      const key = `${item.value}-${index}`;
      return renderRow({ item, index, key, ...otherProps });
    });
  };
  const render = () => {
    const {
      onSelect,
      onChange,
      onOpen,
      onClose,
      children,
      items,
      menuProps,
      inputProps,
      alignMenu,
      blendCorner,
      maxHeight,
      emptyMessage,
      noResultsMessage,
      style,
      rootClassName,
      className,
      menuHeader,
      menuFooter,
      inputActions,
      menuWithArrow,
      searchPlaceholder,
      itemSize,
      busy,
      hideInput,
      filterValue,
      emptyHidesInput,
      ...innerProps
    } = props;

    return (
      <AutoComplete
        resetInputOnClose
        itemToString={() => ''}
        onSelect={onSelect}
        inputIsActor={false}
        onOpen={onOpen}
        onClose={onClose}
        {...innerProps}
      >
        {({
          getActorProps,
          getRootProps,
          getInputProps,
          getMenuProps,
          getItemProps,
          inputValue,
          selectedItem,
          highlightedIndex,
          isOpen,
          actions,
        }) => {
          // This is the value to use to filter (default to value in filter input)
          const filterValueOrInput = !_.isUndefined(filterValue) ? filterValue : inputValue;
          // Only filter results if menu is open and there are items
          const autoCompleteResults = (isOpen && items && autoCompleteFilter(items, filterValueOrInput || '')) || [];
          // Can't search if there are no items
          const hasItems = items && !!items.length;
          // Items are loading if null
          const itemsLoading = _.isNull(items);
          // Has filtered results
          const hasResults = !!autoCompleteResults.length;
          // No items to display
          const showNoItems = !busy && !filterValueOrInput && !hasItems;
          // Results mean there was an attempt to search
          const showNoResultsMessage = !busy && filterValueOrInput && !hasResults;
          // Hide the input when we have no items to filter, only if
          // emptyHidesInput is set to true.
          const showInput = !hideInput && (hasItems || !emptyHidesInput);
          const renderedFooter = _.isFunction(menuFooter) ? menuFooter({ actions }) : menuFooter;
          const renderedInputActions = _.isFunction(inputActions) ? inputActions() : inputActions;

          return (
            <AutoCompleteRoot {...getRootProps()} className={rootClassName}>
              {children({
                getInputProps,
                getActorProps,
                actions,
                isOpen,
                selectedItem,
              })}
              {isOpen && (
                <Menu
                  className={classnames(className)}
                  {...getMenuProps({
                    ...menuProps,
                    style,
                    blendCorner,
                    alignMenu,
                    menuWithArrow,
                    css: props.css,
                  })}
                >
                  {itemsLoading && <LoadingIndicator mini />}
                  {showInput && (
                    <div className={classnames(styles['input-wrapper'])}>
                      <InputControl
                        className={classnames(styles.input)}
                        placeholder={searchPlaceholder}
                        {...getInputProps({ ...inputProps, onChange })}
                      />
                      <div className={classnames(styles['input-loading-wrapper'])}>
                        {busy && <LoadingIndicator size={16} mini />}
                      </div>
                      {renderedInputActions}
                    </div>
                  )}
                  <div>
                    {menuHeader && (
                      <Label withBorder withPadding>
                        {menuHeader}
                      </Label>
                    )}

                    <div
                      style={{
                        overflowY: 'auto',
                        maxHeight: _.isNumber(maxHeight) ? `${maxHeight}px` : maxHeight,
                      }}
                    >
                      {showNoItems && <EmptyMessage>{emptyMessage}</EmptyMessage>}
                      {showNoResultsMessage && (
                        <EmptyMessage>{noResultsMessage || `${emptyMessage} trouvé`}</EmptyMessage>
                      )}
                      {busy && (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 1 }}>
                          <EmptyMessage>Recherche en cours...</EmptyMessage>
                        </div>
                      )}
                      {!busy &&
                        renderList({
                          itemSize,
                          highlightedIndex,
                          inputValue,
                          getItemProps,
                          items: autoCompleteResults,
                        })}
                    </div>

                    {renderedFooter && <Label withBorder>{renderedFooter}</Label>}
                  </div>
                </Menu>
              )}
            </AutoCompleteRoot>
          );
        }}
      </AutoComplete>
    );
  };
  return render();
};
DropdownAutoCompleteMenu.defaultProps = {
  onSelect: _.noop,
  maxHeight: 300,
  blendCorner: true,
  emptyMessage: 'Aucun résultat',
  searchPlaceholder: 'Rechercher',
};
export default DropdownAutoCompleteMenu;

type AutoCompleteRootProps = {
  isOpen: boolean;
  onClickOutside: () => void;
  onOpen: () => void;
  className?: string;
};
const AutoCompleteRoot: React.FunctionComponent<AutoCompleteRootProps> = ({ className, ...props }) => (
  <div
    className={classnames(styles['autocomplete-root'], className)}
    {..._.omit(props, ['isOpen', 'onClickOutside', 'onOpen'])}
  />
);

type EmptyMessageProps = {};
const EmptyMessage: React.FunctionComponent<EmptyMessageProps> = ({ children }) => (
  <div className={classnames(styles['empty-message'])}>{children}</div>
);

type LabelProps = {
  className?: string;
  withBorder?: boolean;
  withPadding?: boolean;
};
const Label: React.FunctionComponent<LabelProps> = ({ className, withBorder, withPadding, ...props }) => (
  <div
    className={classnames(
      styles.label,
      { [styles.withBorder]: withBorder, [styles.withPadding]: withPadding },
      className,
    )}
    {...props}
  />
);

type MenuProps = {
  className?: string;
  blendCorner?: boolean;
  alignMenu?: 'left' | 'right';
  menuWithArrow?: boolean;
  [x: string]: any;
};
const Menu: React.FunctionComponent<MenuProps> = React.forwardRef<any, MenuProps>((props, ref) => {
  const { className, blendCorner, alignMenu, menuWithArrow, ...innerProps } = props;
  return (
    <div
      className={classnames(
        styles.menu,
        {
          [styles.blendCorner]: blendCorner,
          [styles.alignLeft]: alignMenu && alignMenu === 'left',
          [styles.alignRight]: alignMenu && alignMenu === 'right',
          [styles.menuWithArrow]: menuWithArrow,
        },
        className,
      )}
      ref={ref}
      {...innerProps}
    />
  );
});
