export type Search = {
  id: string;
  query: string;
  name: string;
};

export type SearchList = Search[];

export type SearchListModel = {
  data: SearchList;
};

const searchListModel: SearchListModel = {
  data: [],
};
export default searchListModel;
