import * as React from 'react';
import { once } from 'lodash';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import 'moment/locale/fr';
import * as Vega from 'vega';

import './app.scss';

import configureStore, { history } from 'Store';
import AppProvider from 'Components/app/appProvider';
import routes from 'Routes';
import config from 'Config';
import { createGraphQLClient } from 'GraphqlClient';

moment.locale('fr');
Vega.formatLocale({
  decimal: ',',
  thousands: '\u00a0',
  grouping: [3],
  currency: ['', '\u00a0€'],
  percent: '\u202f%',
});
Vega.timeFormatLocale({
  dateTime: '%A, le %e %B %Y, %X',
  date: '%d/%m/%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  shortDays: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
  months: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  shortMonths: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin', 'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
});

if (config.SENTRY_ENABLED) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
  });
}

const graphQLClient = once(createGraphQLClient)(config.GRAPHQL_SERVER_URI);

export const store = once(configureStore)({});
export const { useStoreActions, useStoreDispatch, useStoreState } = store;

const App: React.FunctionComponent<any> = () => (
  <AppProvider store={store} graphQLClient={graphQLClient} routerProps={{ history, routes }} />
);

export default App;
