import _ from 'lodash';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';

const moment: any = extendMoment(Moment);

const parsePreviousPeriod = (period: string) => {
  const periodRegex = /^P\|([MY])$/i;
  const result = periodRegex.exec(period);
  if (_.isNull(result)) throw new Error('Invalid Period');
  switch (result[1]) {
    case 'M':
      return moment.rangeFromInterval('months', 0, moment().subtract(1, 'months')).snapTo('months');
    case 'Y':
      return moment.rangeFromInterval('years', 0, moment().subtract(1, 'years')).snapTo('years');
    default:
      throw new Error('Not Implemented');
  }
};

const parseCurrentPeriod = (period: string) => {
  const periodRegex = /^C\|([MQSY])$/i;
  const result = periodRegex.exec(period);
  if (_.isNull(result)) return parsePreviousPeriod(period);
  let range: DateRange | undefined;
  switch (result[1]) {
    case 'M':
      return moment.rangeFromInterval('months', 0, moment()).snapTo('months');
    case 'Q':
      range = _.head<DateRange>(
        _.filter(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          _.map(_.range(1, 5), v => parseFixedPeriod(`F|Q${v}`)),
          range => range.contains(moment()),
        ),
      );
      if (_.isUndefined(range)) break;
      range.end = _.min([moment(), range.end]);
      return range;
    case 'S':
      range = _.head<DateRange>(
        _.filter(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          _.map(_.range(1, 3), v => parseFixedPeriod(`F|S${v}`)),
          range => range.contains(moment()),
        ),
      );
      if (_.isUndefined(range)) break;
      range.end = _.min([moment(), range.end]);
      return range;
    case 'Y':
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      range = parseFixedPeriod('F|Y1');
      if (_.isUndefined(range)) break;
      range.end = _.min([moment(), range.end]);
      return range;
    default:
      throw new Error('Not Implemented');
  }
  return null;
};

const parseFixedPeriod: (period: string) => DateRange = period => {
  // tslint:disable-next-line: max-line-length
  const periodRegex = /^F\|(?<month>M(?<monthValue>((1[0-2])|([1-9]))))|(?<quarter>Q(?<quarterValue>[1-4]))|(?<semester>S(?<semesterValue>[1-2]))|(?<year>Y(?<yearValue>[1-9]+[0-9]*))$/i;
  const result = periodRegex.exec(period);
  if (_.isNull(result) || _.isUndefined(result.groups)) return parseCurrentPeriod(period);
  if (!_.isUndefined(result.groups.yearValue)) {
    const range = moment.rangeFromInterval('years', 0, moment()).snapTo('years');
    return range.add(
      moment.range(
        range.start.subtract(Math.max(1, parseInt(result.groups.yearValue, 10)) - 1, 'year'),
        range.end.subtract(Math.max(1, parseInt(result.groups.yearValue, 10)) - 1, 'year'),
      ),
    );
  }
  if (!_.isUndefined(result.groups.semesterValue)) {
    const range = moment.rangeFromInterval('months', 5, moment().startOf('year')).snapTo('months');
    return range.add(
      moment.range(
        range.start.add(6 * (Math.max(1, parseInt(result.groups.semesterValue, 10)) - 1), 'month'),
        range.end.add(6 * (Math.max(1, parseInt(result.groups.semesterValue, 10)) - 1), 'month'),
      ),
    );
  }
  if (!_.isUndefined(result.groups.quarterValue)) {
    const range = moment.rangeFromInterval('months', 2, moment().startOf('year')).snapTo('months');
    return range.add(
      moment.range(
        range.start.add(3 * (Math.max(1, parseInt(result.groups.quarterValue, 10)) - 1), 'month'),
        range.end.add(3 * (Math.max(1, parseInt(result.groups.quarterValue, 10)) - 1), 'month'),
      ),
    );
  }
  if (!_.isUndefined(result.groups.monthValue)) {
    const range = moment.rangeFromInterval('months', 0, moment().startOf('year')).snapTo('months');
    return range.add(
      moment.range(
        range.start.add(Math.min(12, Math.max(1, parseInt(result.groups.monthValue, 10))) - 1, 'month'),
        range.end.add(Math.min(12, Math.max(1, parseInt(result.groups.monthValue, 10))) - 1, 'month'),
      ),
    );
  }
  throw new Error('Not Implemented');
};

/**
 * Converts a relative period, e.g. `1h` to an object containing a start
 * and end date, with the end date as the current time and the start date as the
 * time that is the current time less the period.
 *
 * @param {String} period Relative period
 * @return {Object} Object containing start and end date as YYYY-MM-DDTHH:mm:ss
 *
 */
export default (period: string) => {
  const periodRegex = /^(\d+)([smhdwMy]{1})$/;
  const result = periodRegex.exec(period);
  if (_.isNull(result)) return parseFixedPeriod(period);
  const value: number = Math.max(1, parseInt(result[1], 10));
  const unit: Moment.unitOfTime.DurationConstructor = _.get(
    {
      y: 'years',
      M: 'months',
      w: 'weeks',
      d: 'days',
      h: 'hours',
      s: 'seconds',
      m: 'minutes',
    },
    result[2],
  );
  return moment.range(moment().subtract(value, unit), moment());
};
