import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import moment, { Moment } from 'moment';

import { DEFAULT_DAY_START_TIME, DEFAULT_DAY_END_TIME } from 'Constants/date';
import styles from './dateSummary.module.scss';

type DateSummaryProps = {
  className?: string;
  start?: Moment | null;
  end?: Moment | null;
  hideTime?: boolean;
};

const DateSummary: React.FunctionComponent<DateSummaryProps> = props => {
  const getFormattedDate = (date: any, format: string) => moment(date).local().format(format);
  const formatDate = (date: any) => getFormattedDate(date, 'll');
  const formatTime = (date: any, withSeconds = false) => getFormattedDate(date, `HH:mm${withSeconds ? ':ss' : ''}`);

  const { className, start, end } = props;
  const startTimeFormatted = formatTime(start, true);
  const endTimeFormatted = formatTime(end, true);
  // Show times if either start or end date contain a time that is not midnight
  const shouldShowTimes =
    // eslint-disable-next-line react/destructuring-assignment
    !props.hideTime && (startTimeFormatted !== DEFAULT_DAY_START_TIME || endTimeFormatted !== DEFAULT_DAY_END_TIME);
  const isSameDay =
    _.isUndefined(start) ||
    _.isNull(start) ||
    _.isUndefined(end) ||
    _.isNull(end) ||
    (start && end && !moment(start).isBefore(end, 'day') && !moment(end).isAfter(start, 'day'));

  return (
    <div
      className={classnames(styles.wrapper, className, {
        [styles.hasTime]: shouldShowTimes,
      })}
    >
      {isSameDay && !shouldShowTimes && (
        <>
          <span className={classnames(styles.divider)}>le</span>
          <div className={classnames(styles['date-group'])}>
            <div
              className={classnames(styles.date, {
                [styles.hasTime]: shouldShowTimes,
              })}
            >
              {(start && formatDate(start)) || (end && formatDate(end))}
            </div>
          </div>
        </>
      )}
      {!isSameDay && start && (
        <>
          <span className={classnames(styles.divider)}>du</span>
          <div className={classnames(styles['date-group'])}>
            <div
              className={classnames(styles.date, {
                [styles.hasTime]: shouldShowTimes,
              })}
            >
              {formatDate(start)}
              {shouldShowTimes && <div className={classnames(styles.time)}>{formatTime(start)}</div>}
            </div>
          </div>
        </>
      )}
      {!isSameDay && end && (
        <>
          <span className={classnames(styles.divider)}>au</span>
          <div className={classnames(styles['date-group'])}>
            <div
              className={classnames(styles.date, {
                [styles.hasTime]: shouldShowTimes,
              })}
            >
              {formatDate(end)}
              {shouldShowTimes && <div className={classnames(styles.time)}>{formatTime(end)}</div>}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DateSummary;
