import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo-hooks';

import notify from 'Utils/notify';
import useSessionStorage from 'Utils/hooks/useSessionStorage';

import { Panel, PanelBody, PanelHeader, PanelItem } from 'Components/panels';
import Button from 'Components/button';
import LoadingIndicator from 'Components/loadingIndicator';

import Field from 'Views/settings/field/field';
import InputControl from 'Views/settings/field/inputControl';
import styles from './accountForm.module.scss';

const accountSchema = Yup.object().shape({
  userName: Yup.string().required('Obligatoire'),
  email: Yup.string().required('Obligatoire').email('Email invalide'),
});

const AccountForm = () => {
  const [token] = useSessionStorage('token');
  const updateUserMutation = useMutation<any>(
    gql`
      mutation updateUser($userName: String!, $email: String!) {
        updateUser(userName: $userName, email: $email) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { error }: any = await updateUserMutation({
        variables: { userName: values.userName, email: values.email },
      });

      if (error) notify.error(error.message);
      else notify('Informations de compte mises à jour');
      actions.setSubmitting(false);
    } catch (e) {
      notify.error('error calling apollo');
      actions.setSubmitting(false);
    }
  };

  const { error, data, loading } = useQuery(gql('{ me { id, userName, email } }'), {
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });

  if (error) {
    notify('error loading profile', false);
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const accountForm = data.me;
  return (
    <Panel>
      <PanelHeader>Détails du compte</PanelHeader>
      <PanelBody disablePadding>
        <Formik initialValues={accountForm} validationSchema={accountSchema} onSubmit={onSubmit}>
          {({ errors, touched }) => (
            <Form>
              <Field label="Identifiant" help="Votre identifiant de connexion" required inline>
                <InputControl type="text" name="userName" errors={errors} touched={touched} disabled />
              </Field>
              <Field label="Email" help="Votre adresse email de contact" required inline>
                <InputControl type="text" name="email" errors={errors} touched={touched} />
              </Field>
              <PanelItem className={classnames(styles.actions)}>
                <Button size="small" priority="primary">
                  Enregistrer
                </Button>
              </PanelItem>
            </Form>
          )}
        </Formik>
      </PanelBody>
    </Panel>
  );
};

export default AccountForm;
