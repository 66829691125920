import * as React from 'react';
import Particles from 'react-particles-js';
import { Link } from 'react-router-dom';

import { Panel, PanelHeader, PanelBody } from 'Components/panels';

const NotFoundView: React.FunctionComponent = () => (
  // eslint-disable-next-line react/jsx-wrap-multilines
  <>
    <Particles
      style={{ position: 'fixed' }}
      params={
        /* eslint-disable @typescript-eslint/camelcase */
        {
          particles: {
            number: {
              value: 80,
              density: {
                enable: true,
                value_area: 800,
              },
            },
            color: {
              value: '#000000',
            },
            shape: {
              type: 'circle',
              stroke: {
                width: 0,
                color: '#000000',
              },
              polygon: {
                nb_sides: 5,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#000000',
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 6,
              direction: 'none',
              random: false,
              straight: false,
              out_mode: 'out',
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'repulse',
              },
              onclick: {
                enable: true,
                mode: 'push',
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        } as any
        /* eslint-enable @typescript-eslint/camelcase */
      }
    />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Panel style={{ width: 'fit-content' }}>
        <PanelHeader style={{ justifyContent: 'center' }}>Erreur 404</PanelHeader>
        <PanelBody style={{ backgroundColor: 'rgba(255,255,255,255)' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Page non trouvée</div>
            <div style={{ paddingTop: '10px' }}>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              Retour à <Link to="/">l&apos;accueil</Link>
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  </>
);

export default NotFoundView;
