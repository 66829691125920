import * as React from 'react';
import classnames from 'classnames';

import { AppLayoutProps } from 'Layouts';

import styles from './noLayout.module.scss';

type NoLayoutProps = AppLayoutProps;

const NoLayout: React.FunctionComponent<NoLayoutProps> = ({ view, viewProps }) => (
  <div className={classnames(styles.wrapper)}>
    <div className={classnames(styles.container)}>
      <div className={classnames(styles['view-wrapper'])}>
        <div className={classnames(styles['view-container'])}>{React.createElement(view, { ...viewProps })}</div>
      </div>
    </div>
  </div>
);

export default NoLayout;
