import * as React from 'react';
import classnames from 'classnames';
import * as Sentry from '@sentry/browser';
import { FaExclamationTriangle } from 'react-icons/fa';

import Button from 'Components/button';

import styles from './errorView.module.scss';

type ErrorViewProps = { eventId?: string };

const ErrorView: React.FunctionComponent<ErrorViewProps> = ({ eventId }) => {
  return (
    <div className={classnames(styles.container)}>
      <div className={classnames(styles.box)}>
        <div className={classnames(styles.title)}>
          <FaExclamationTriangle className={classnames(styles.icon)} size="80px" />
          <h1 className={classnames(styles.message)}>Une erreur est survenue.</h1>
        </div>
        <div className={classnames(styles.body)}>
          Notre équipe technique a été avertie, elle s&apos;efforce de corriger le problème dans les meilleurs délais.
          Vous pouvez retourner à la page d&apos;accueil ou nous soumettre vos commentaires.
        </div>
        <div className={classnames(styles.actions)}>
          <Button size="small" priority="default" to="/">
            Retour à l&apos;accueil
          </Button>
          {eventId && (
            <Button
              size="small"
              priority="primary"
              onClick={() =>
                Sentry.showReportDialog({
                  eventId,
                  lang: 'fr',
                  title: 'Décrivez nous le problème que vous avez rencontré.',
                })
              }
            >
              Commentaires
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
