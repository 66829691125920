import * as React from 'react';
import _ from 'lodash';

import { Client } from 'src/types';
import BaseBadge, { BaseBadgeProps } from 'Components/idBadge/baseBadge';
import BadgeDisplayName from 'Components/idBadge/badgeDisplayName';

type ClientBadgeProps = BaseBadgeProps & {
  client?: Client;
  avatarSize?: number;
  /**
   * If true, will use default max-width, or specify one as a string
   */
  hideOverflow?: boolean | string;
  hideAvatar?: boolean;
};

const ClientBadge: React.FunctionComponent<ClientBadgeProps> = props => {
  const render = () => {
    const { hideOverflow, client, ...innerProps } = props;
    return !_.isUndefined(client) ? (
      <BaseBadge
        displayName={<BadgeDisplayName hideOverflow={hideOverflow}>{client.name}</BadgeDisplayName>}
        client={client}
        {...innerProps}
      />
    ) : null;
  };
  return render();
};
ClientBadge.defaultProps = {
  hideAvatar: false,
  hideOverflow: true,
};
export default ClientBadge;
