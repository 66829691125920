import _ from 'lodash';
import { action, Action, thunk, Thunk } from 'easy-peasy';

import { RootStoreModel } from 'Store';

export enum LOADER {
  QUERY_LIMIT_UPDATE = 'query-limit-update',
  QUERY_OFFSET_INCREMENT = 'query-offset-increment',
  QUERY_OFFSET_DECREMENT = 'query-offset-decrement',

  INVOICE_LIST_INIT = 'invoice-list-init',
  INVOICE_LIST_DATA = 'invoice-list-data',
  INVOICE_LIST_RESET = 'invoice-list-reset',
  INVOICE_LIST_COUNT = 'invoice-list-count',
  INVOICE_LIST_RELOAD = 'invoice-list-reload',
  INVOICE_LIST_RESET_ORDER = 'invoice-list-reset-order',
  INVOICE_LIST_UPDATE_ORDER = 'invoice-list-update-order',

  CLIENT_LIST_INIT = 'client-list-init',
  CLIENT_LIST_DATA = 'client-list-data',
  CLIENT_LIST_RESET = 'client-list-reset',
  CLIENT_LIST_COUNT = 'client-list-count',
  CLIENT_LIST_RELOAD = 'client-list-reload',
  CLIENT_LIST_RESET_ORDER = 'client-list-reset-order',
  CLIENT_LIST_UPDATE_ORDER = 'client-list-update-order',

  SUPPLIER_LIST_INIT = 'supplier-list-init',
  SUPPLIER_LIST_DATA = 'supplier-list-data',
  SUPPLIER_LIST_RESET = 'supplier-list-reset',
  SUPPLIER_LIST_COUNT = 'supplier-list-count',
  SUPPLIER_LIST_RELOAD = 'supplier-list-reload',
  SUPPLIER_LIST_RESET_ORDER = 'supplier-list-reset-order',
  SUPPLIER_LIST_UPDATE_ORDER = 'supplier-list-update-order',

  PAYMENT_MEAN_LIST_INIT = 'payment-mean-list-init',
  PAYMENT_MEAN_LIST_DATA = 'payment-mean-list-data',
  PAYMENT_MEAN_LIST_RESET = 'payment-mean-list-reset',
  PAYMENT_MEAN_LIST_COUNT = 'payment-mean-list-count',
  PAYMENT_MEAN_LIST_RELOAD = 'payment-mean-list-reload',
  PAYMENT_MEAN_LIST_RESET_ORDER = 'payment-mean-list-reset-order',
  PAYMENT_MEAN_LIST_UPDATE_ORDER = 'payment-mean-list-update-order',
}
export type LOADERS = LOADER[];

type LoaderProps = {
  onStart?: (...args: any[]) => void;
  onEnd?: (...args: any[]) => void;
};
type Loaders = {
  [x: string]: LoaderProps;
};

export type LoadersModel = {
  items: Loaders;
  started: Action<LoadersModel, { loader: LOADER; props?: LoaderProps }>;
  ended: Action<LoadersModel, LOADER>;
  isLoading: Thunk<LoadersModel, LOADER, any, RootStoreModel, boolean>;
  start: Thunk<LoadersModel, { loader: LOADER; props: LoaderProps }[], any, RootStoreModel, Promise<void>>;
  end: Thunk<LoadersModel, LOADERS, void, RootStoreModel, Promise<void>>;
};

const loadersModel: LoadersModel = {
  items: {},
  started: action((state, { loader, props }) => {
    _.set(state.items, loader, props);
  }),
  ended: action((state, loader) => {
    delete state.items[loader]; // eslint-disable-line
  }),
  isLoading: thunk((_1, loader, { getState }) => _.has(getState().items, loader)),
  start: thunk(async (actions, loaders, { getState }) => {
    _.forEach(
      _.filter(loaders, _1 => !_.has(getState().items, _1.loader)),
      _2 => {
        actions.started(_2);
        const onStart = _.get(getState().items[_2.loader], 'onStart');
        if (onStart) onStart();
      },
    );
  }),
  end: thunk(async (actions, loaders, { getState }) => {
    _.forEach(loaders, loader => {
      const onEnd = _.get(getState().items[loader], 'onEnd');
      actions.ended(loader);
      if (onEnd) onEnd();
    });
  }),
};
export default loadersModel;
