import moment from 'moment-timezone';

import getUserTimezone from 'Utils/date/getUserTimezone';

// Strips timezone from local date, creates a new moment date object with timezone
// Then returns as a Date object
export default (date: moment.MomentInput, { utc, stringify }: { utc?: boolean | null; stringify?: boolean } = {}) => {
  const temp = moment(date).local().format('YYYY-MM-DDTHH:mm:ss.SSSS');
  let result: any = moment.tz(temp, utc ? 'UTC' : getUserTimezone());
  if (utc) result = result.utc();
  result = !!stringify ? result.format() : result.toDate(); // eslint-disable-line
  return result;
};
