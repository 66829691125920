import * as React from 'react';
import classnames from 'classnames';

import styles from './textAreaControl.module.scss';

type TextAreaControlProps = {
  className?: string;
  readOnly?: boolean;
  monospace?: boolean;
  disabled?: boolean;
  withoutResize?: boolean;
  [x: string]: any;
};

const TextAreaControl: React.FunctionComponent<TextAreaControlProps> = ({
  className,
  readOnly,
  monospace,
  disabled,
  withoutResize,
  ...props
}) => (
  <textarea
    readOnly={readOnly}
    disabled={disabled}
    className={classnames(
      styles.textarea,
      {
        [styles.readOnly]: readOnly,
        [styles.monospace]: monospace,
        [styles.disabled]: disabled,
        [styles.withoutResize]: withoutResize,
      },
      className,
    )}
    {...props}
  />
);
export default TextAreaControl;
