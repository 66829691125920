import * as React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { Field, FieldProps, ErrorMessage } from 'formik';

import styles from './field.module.scss';

const InputControl = (props: any) => {
  const { name, errors, touched, type, disabled, defaultValue = '' } = props;
  const hasError = _.get(errors, name) && _.get(touched, name);
  return (
    <div className={classnames(styles['field-container'])}>
      <Field
        name={name}
        render={({ field: { value, ...rest } }: FieldProps) => (
          <input
            value={value || defaultValue}
            disabled={disabled}
            type={type}
            autoComplete="off"
            className={classnames({
              'form-control': true,
              'is-invalid': hasError,
            })}
            {...rest}
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={(msg: string) => <div className={classnames({ 'invalid-feedback': hasError })}>{msg}</div>}
      />
    </div>
  );
};

export default InputControl;
