import _ from 'lodash';

import { DEFAULT_RELATIVE_PERIODS } from 'Constants/date';
import parsePeriod from 'Utils/date/parsePeriod';

/**
 * Given a relative period, e.g. `1h`, return a pretty string if it
 * is a default period. Otherwise if it's a valid period (can be any number
 * followed by a single character s|m|h|d) display "Other" or "Invalid period" if invalid
 *
 * @param {String} relative Relative period
 * @return {String} Returns either one of the default "Last x days" string,
 * "Other" if period is valid on the backend, or "Invalid period" otherwise
 */
export default (relative: string) => {
  const defaultRelativePeriodString = _.get(DEFAULT_RELATIVE_PERIODS, relative);
  if (!defaultRelativePeriodString) {
    try {
      parsePeriod(relative);
      return 'Autre';
    } catch (err) {
      return 'Période non supportée';
    }
  }
  return defaultRelativePeriodString;
};
