import _ from 'lodash';
import * as React from 'react';
import { saveAs } from 'file-saver';
import { FaDownload } from 'react-icons/fa';

import Button from './button';
import Tooltip from './tooltip';
import OverlayTrigger, { OverlayTriggerProps } from './overlayTrigger';

type CSVHeader = { label: string; key: string };
type CSVHeaders = CSVHeader[];
type CSVData = {
  [x: string]: any;
}[];

type CSVDownloadProps = {
  filename: string;
  headers: CSVHeaders;
  tooltip?: string;
  onClick?: _.Function2<any, _.Function1<{ data: CSVData } | { error: any }, void>, Promise<void>>;
  onError?: _.Function1<any, void>;
  options?: {
    columnDelimiter?: string;
    lineDelimiter?: string;
    uFEFF?: boolean;
  };
  overlayTriggerProps?: Omit<OverlayTriggerProps, 'overlay'>;
};

/**
 * Simple safari detection based on user agent test
 */
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const CSVDownload: React.FC<CSVDownloadProps> = ({
  headers,
  filename,
  tooltip,
  onClick,
  onError,
  options = {},
  overlayTriggerProps,
}) => {
  const renderButton = React.useCallback(
    () => (
      <Button
        withoutBoxShadow
        priority="default"
        size="xsmall"
        icon={() => <FaDownload size="16px" />}
        onClick={
          _.isFunction(onClick)
            ? _.partialRight(onClick, arg => {
                const { data, error }: { data?: CSVData; error?: any } = _.omitBy(
                  _.pick(arg, ['data', 'error']),
                  _.isUndefined,
                );
                if (!_.isUndefined(error) && _.isFunction(onError)) {
                  onError(error);
                } else if (_.isArray(data)) {
                  let csv = '';
                  if (!_.isEmpty(data)) {
                    const { columnDelimiter = ',', lineDelimiter = '\n' } = options;
                    const headersGiven = _.isArray(headers);
                    const keys = (!headersGiven
                      ? _.map(_.keys(_.head(data)), key => ({ key, label: key }))
                      : headers) as CSVHeaders;
                    csv = _.join(
                      _.concat(
                        [],
                        _.join(
                          _.map(keys, ({ label }) => label),
                          columnDelimiter,
                        ),
                        _.map(!headersGiven ? _.slice(data, 1) : data, datum =>
                          _.join(
                            _.map(keys, ({ key }) => JSON.stringify(_.get(datum, key, ''))),
                            columnDelimiter,
                          ),
                        ),
                      ),
                      lineDelimiter,
                    );
                  }
                  const { uFEFF = true } = options;
                  saveAs(
                    new Blob(_.concat([], [uFEFF ? '\uFEFF' : ''], csv), {
                      type: isSafari() ? 'application/csv' : 'text/csv',
                    }),
                    filename,
                  );
                }
              })
            : undefined
        }
      />
    ),
    [filename, headers, onClick, onError, options],
  );
  return React.useMemo(
    () =>
      _.isString(tooltip) ? (
        <OverlayTrigger
          {..._.merge({}, overlayTriggerProps, {
            overlay: overlayProps => <Tooltip {...(_.omit(overlayProps, 'show') as any)}>{tooltip}</Tooltip>,
          } as Pick<OverlayTriggerProps, 'overlay'>)}
        >
          {renderButton()}
        </OverlayTrigger>
      ) : (
        renderButton()
      ),
    [overlayTriggerProps, renderButton, tooltip],
  );
};
export default CSVDownload;
