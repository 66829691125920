import _ from 'lodash';
import { Select, select, Thunk, thunk, Action, action } from 'easy-peasy';

import { RootStoreModel } from 'Store';
import { LOADER } from './loaders.model';

export const LIMIT_KEY = '__limit';
const DEFAULT_LIMIT = 10;
export const OFFSET_KEY = '__offset';
const DEFAULT_OFFSET = 0;
const TEXT_FILTER_KEY = '__text';

const queryToObj: (queryStr: string) => QueryTags = queryStr => {
  if (!queryStr) return { [TEXT_FILTER_KEY]: '' };
  const text: string[] = [];
  const queryItems = queryStr.match(/\S+:"[^"]*"?|\S+/g);
  const queryObj = _.reduce<string, { [x: string]: string }>(
    queryItems,
    (obj, item) => {
      const index = item.indexOf(':');
      if (index === -1) {
        text.push(item);
      } else {
        const tagKey = item.slice(0, index);
        const value = item.slice(index + 1).replace(/^"|"$/g, '');
        obj[tagKey] = value; // eslint-disable-line
      }
      return obj;
    },
    {},
  );

  queryObj[TEXT_FILTER_KEY] = '';
  if (text.length) {
    queryObj[TEXT_FILTER_KEY] = text.join(' ');
  }

  return queryObj as QueryTags;
};

const objToQuery: (queryObj: QueryTags) => string = queryObj => {
  if (!queryObj) return '';
  const tags = _.omit(queryObj, TEXT_FILTER_KEY);
  const parts = _.map(tags, (value, tagKey) => {
    let newValue = value;
    if (value.indexOf(' ') > -1) newValue = `"${value}"`;
    return `${tagKey}:${newValue}`;
  });
  if (queryObj[TEXT_FILTER_KEY]) parts.push(queryObj[TEXT_FILTER_KEY]);
  return parts.join(' ');
};

type QueryTags = { [TEXT_FILTER_KEY]: string; [x: string]: string };
export type Query = {
  [LIMIT_KEY]: number;
  [OFFSET_KEY]: number;
  tags: QueryTags;
};
export type QueryModel = {
  obj: Query;
  string: Select<QueryModel, string>;
  tags: Select<QueryModel, QueryTags>;
  limit: Select<QueryModel, number>;
  offset: Select<QueryModel, number>;
  hasPrevious: Select<QueryModel, boolean>;
  updated: Action<QueryModel, Query>;
  tagsUpdated: Action<QueryModel, QueryTags>;
  limitUpdated: Action<QueryModel, number>;
  offsetUpdated: Action<QueryModel, number>;
  offsetIncremented: Action<QueryModel, number>;
  offsetDecremented: Action<QueryModel, number>;
  tagUpdated: Action<QueryModel, { tagKey: string; newValue: string }>;
  tagRemoved: Action<QueryModel, string>;
  updateTag: Thunk<QueryModel, { tagKey: string; newValue?: string }, any, RootStoreModel, void>;
  reset: Thunk<QueryModel, void, any, RootStoreModel, Promise<void>>;
  updateLimit: Thunk<
    QueryModel,
    { limit: number; onEnd?: (...args: any[]) => void },
    any,
    RootStoreModel,
    Promise<void>
  >;
  updateOffset: Thunk<QueryModel, number, any, RootStoreModel, void>;
  incrementOffset: Thunk<
    QueryModel,
    { offset?: number; onEnd?: (...args: any[]) => void },
    any,
    RootStoreModel,
    Promise<void>
  >;
  decrementOffset: Thunk<
    QueryModel,
    { offset?: number; onEnd?: (...args: any[]) => void },
    any,
    RootStoreModel,
    Promise<void>
  >;
  updateTextFilter: Thunk<QueryModel, string, any, RootStoreModel, void>;
  fromString: Thunk<QueryModel, string, any, RootStoreModel, void>;
};

/* eslint-disable no-param-reassign */

const queryModel: QueryModel = {
  obj: {
    [LIMIT_KEY]: DEFAULT_LIMIT,
    [OFFSET_KEY]: DEFAULT_OFFSET,
    tags: { [TEXT_FILTER_KEY]: '' },
  },
  string: select(state => objToQuery(state.obj.tags)),
  tags: select(state => state.obj.tags),
  limit: select(state => state.obj[LIMIT_KEY]),
  offset: select(state => state.obj[OFFSET_KEY]),
  hasPrevious: select(state => state.obj[OFFSET_KEY] >= state.obj[LIMIT_KEY]),
  updated: action((state, payload) => {
    state.obj[LIMIT_KEY] = payload[LIMIT_KEY];
    state.obj[OFFSET_KEY] = payload[OFFSET_KEY];
    state.obj.tags = { ...state.obj.tags, ...payload.tags };
  }),
  limitUpdated: action((state, payload) => {
    state.obj[LIMIT_KEY] = payload;
  }),
  offsetUpdated: action((state, payload) => {
    state.obj[OFFSET_KEY] = payload;
  }),
  offsetIncremented: action((state, payload) => {
    state.obj[OFFSET_KEY] += payload * state.obj[LIMIT_KEY];
  }),
  offsetDecremented: action((state, payload) => {
    state.obj[OFFSET_KEY] -= payload * state.obj[LIMIT_KEY];
  }),
  tagsUpdated: action((state, payload) => {
    state.obj.tags = { ...state.obj.tags, ...payload };
  }),
  tagUpdated: action((state, { tagKey, newValue }) => {
    state.obj.tags[tagKey] = newValue;
  }),
  tagRemoved: action((state, tagKey) => {
    delete state.obj.tags[tagKey];
  }),
  updateTag: thunk((actions, { tagKey, newValue }) => {
    if (newValue) actions.tagUpdated({ tagKey, newValue });
    else actions.tagRemoved(tagKey);
  }),
  reset: thunk(async actions => {
    actions.limitUpdated(DEFAULT_LIMIT);
    actions.offsetUpdated(DEFAULT_OFFSET);
  }),
  updateLimit: thunk(async (actions, { limit, onEnd }, { dispatch }) => {
    /* dispatch.loaders.start([
      {
        loader: LOADER.QUERY_LIMIT_UPDATE,
        props: {
          onEnd,
          onStart: () => {
            actions.limitUpdated(limit);
            dispatch.loaders.end([LOADER.QUERY_LIMIT_UPDATE]);
          },
        },
      },
    ]); */
    await dispatch.loaders
      .start([{ loader: LOADER.QUERY_LIMIT_UPDATE, props: { onEnd } }])
      .then(() => {
        actions.limitUpdated(limit);
      })
      .then(async () => {
        await dispatch.loaders.end([LOADER.QUERY_LIMIT_UPDATE]);
      });
  }),
  updateOffset: thunk((actions, newOffset) => {
    actions.offsetUpdated(newOffset);
  }),
  incrementOffset: thunk(async (actions, { onEnd, offset = 1 }, { dispatch }) => {
    /* dispatch.loaders.start([
      {
        loader: LOADER.QUERY_OFFSET_INCREMENT,
        props: {
          onEnd,
          onStart: () => {
            for (let i = 0; i < Math.abs(offset); i += 1) actions.offsetIncremented();
            dispatch.loaders.end([LOADER.QUERY_OFFSET_INCREMENT]);
          },
        },
      },
    ]); */
    await dispatch.loaders
      .start([{ loader: LOADER.QUERY_OFFSET_INCREMENT, props: { onEnd } }])
      .then(() => {
        actions.offsetIncremented(Math.max(1, offset));
      })
      .then(async () => {
        await dispatch.loaders.end([LOADER.QUERY_OFFSET_INCREMENT]);
      });
  }),
  decrementOffset: thunk(async (actions, { onEnd, offset = 1 }, { dispatch }) => {
    /* dispatch.loaders.start([
      {
        loader: LOADER.QUERY_OFFSET_DECREMENT,
        props: {
          onEnd,
          onStart: () => {
            for (let i = 0; i < Math.abs(offset); i += 1) actions.offsetDecremented();
            dispatch.loaders.end([LOADER.QUERY_OFFSET_DECREMENT]);
          },
        },
      },
    ]); */
    await dispatch.loaders
      .start([{ loader: LOADER.QUERY_OFFSET_DECREMENT, props: { onEnd } }])
      .then(() => {
        actions.offsetDecremented(Math.max(1, offset));
      })
      .then(async () => {
        await dispatch.loaders.end([LOADER.QUERY_OFFSET_DECREMENT]);
      });
  }),
  updateTextFilter: thunk((actions, newTextFilter) => {
    actions.tagUpdated({ tagKey: TEXT_FILTER_KEY, newValue: newTextFilter });
  }),
  fromString: thunk((actions, newQueryString) => {
    actions.tagsUpdated(queryToObj(newQueryString));
  }),
};

/* eslint-enable no-param-reassign */

export default queryModel;
