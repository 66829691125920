import * as React from 'react';
import classnames from 'classnames';
import { useQuery, useMutation, useApolloClient } from 'react-apollo-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import _ from 'lodash';
import { IoMdCloseCircleOutline, IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { withProps } from 'recompose';
import { Formik, Form, Field as FormikField } from 'formik';
import * as Yup from 'yup';
import AsyncSelect from 'react-select/async';

import LoadingIndicator from 'Components/loadingIndicator';
import Button from 'Components/button';
import useSessionStorage from 'UtilHooks/useSessionStorage';
import QueryCount from 'Components/queryCount';
import Paginator from 'Components/paginator';
import ViewTable, { ViewTableProps } from 'Views/components/viewTable';
import useModal from 'UtilHooks/useModal';
import useUrlSearchParams from 'UtilHooks/useUrlSearchParams';
import useObservable from 'UtilHooks/useObservable';

import notify from 'Utils/notify';
import styles from './invoicesValidationView.module.scss';
import Field from './settings/field/field';

enum ModalType {
  VALIDATION,
  REFUSAL,
}

enum CadreFacturation {
  A9_FACTURE_SOUSTRAITANT = 'A9_FACTURE_SOUSTRAITANT',
  A12_FACTURE_COTRAITANT = 'A12_FACTURE_COTRAITANT',
}

type InvoicesValidationViewProps = {};

const InvoicesValidationView: React.FunctionComponent<InvoicesValidationViewProps> = () => {
  const INVOICES_VALIDATION_QUERY = gql`
    query invoicesToValidate($page: Int, $perPage: Int, $order: FactureAValiderInputOrder) {
      facturesAValider(input: { page: $page, perPage: $perPage, order: $order }) {
        count
        factures {
          idFacture
          fournisseur {
            nom
          }
          client {
            nom
          }
          numeroFacture
          dateFacture
          numeroEngagement
          dateDepot
          devise
          montantHT
          montantTTC
          statut
          cadreDeFacturation {
            codeCadreFacturation
          }
        }
        pageInfo {
          page
          perPage
          pages
        }
      }
    }
  `;
  const [token] = useSessionStorage('token');
  const {
    params: { page, per_page: perPage, order },
    updateParams,
    errorParams: { page: errorPage, per_page: errorPerPage },
  } = useUrlSearchParams({
    order: {
      initialValue: [{ field: 'dateDepot', direction: 'DESC' }],
      defaultValue: [],
      mapFromUrl: value => JSON.parse(value),
      // eslint-disable-next-line no-shadow
      mapToUrl: order => JSON.stringify(order),
    },
    page: {
      defaultValue: 1,
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
    },
    // eslint-disable-next-line @typescript-eslint/camelcase
    per_page: {
      defaultValue: 10,
      acceptedValues: [10, 20, 50],
      mapFromUrl: value => parseInt(value, 10),
      mapToUrl: value => _.toString(value),
      onUpdate: ({ pathname, search, helpers: { omit } }) => ({ pathname, search: omit(search, 'page') }),
    },
  });
  const updateOrder = React.useCallback(
    (newOrder: any) => updateParams({ order: newOrder.length > 0 ? newOrder : null }),
    [updateParams],
  );
  const mappedOrder = React.useMemo(
    () =>
      _.map(order, ({ field, direction }) =>
        _.omitBy(
          {
            direction,
            field: _.get(
              {
                idFacture: 'ID_FACTURE',
                'fournisseur.nom': 'DESIGNATION_FOURNISSEUR',
                'client.nom': 'DESIGNATION_DESTINATAIRE',
                numeroFacture: 'NUMERO_FACTURE',
                dateFacture: 'DATE_FACTURE',
                numeroEngagement: 'NUMERO_ENGAGEMENT',
                dateDepot: 'DATE_DEPOT',
                devise: 'DEVISE',
                montantHT: 'MONTANT_HT',
                montantTTC: 'MONTANT_TTC',
              },
              field,
            ),
          },
          _.isUndefined,
        ),
      ),
    [order],
  );
  // eslint-disable-next-line no-shadow
  const updatePage = React.useCallback((page: any) => updateParams({ page }), [updateParams]);
  const updatePerPage = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/camelcase
    (per_page: any) => updateParams({ per_page }),
    [updateParams],
  );
  const queryVariables = React.useMemo(
    () =>
      _.omitBy(
        {
          perPage: !errorPerPage ? perPage : undefined,
          // eslint-disable-next-line no-nested-ternary
          page: !errorPerPage && !errorPage ? page : undefined,
          order: _.head(mappedOrder),
        },
        _.isUndefined,
      ),
    [errorPage, errorPerPage, mappedOrder, page, perPage],
  );
  const { data, error, loading, refetch } = useQuery(INVOICES_VALIDATION_QUERY, {
    variables: queryVariables,
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const { sendMessage } = useObservable('nbInvoicesToValidate', {
    regex: new RegExp(/^refetch$/),
    onMessage: async () => {
      await refetch({ variables: queryVariables });
    },
  });
  const maxPageError = React.useMemo(() => {
    if (loading || _.isUndefined(_.get(data, 'facturesAValider'))) return undefined;
    if (_.isNull(_.get(data, 'facturesAValider.pageInfo')))
      return new Error("Paramètre 'page' dépasse le maximum autorisé.");
    const maxPage = _.get(data, 'facturesAValider.pageInfo.pages');
    return !_.isUndefined(maxPage) && maxPage > 0 && page > maxPage
      ? new Error(
          `Paramètre 'page' doit être ${maxPage === 1 ? 'égal à 1' : `un entier inférieur ou égal à ${maxPage}`}.`,
        )
      : undefined;
  }, [data, loading, page]);
  const {
    data: { chorusSetting },
  }: any = useQuery(
    gql`
      query {
        chorusSetting {
          id
        }
      }
    `,
    { context: { headers: { Authorization: `Bearer ${token}` } }, fetchPolicy: 'network-only' },
  );
  const [showModal, closeModal] = useModal<{
    type: ModalType;
    invoiceId: number;
    codeCadreFacturation: CadreFacturation;
    close: () => void;
  }>(({ type, invoiceId, codeCadreFacturation, close }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const graphqlClient = useApolloClient();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refusalMutation = useMutation<any>(
      gql`
        mutation rejectInvoice($invoiceId: Int!, $reason: String!, $comment: String) {
          rejeterFacture(input: { idFacture: $invoiceId, motifRefus: $reason, complementInfo: $comment })
        }
      `,
      {
        context: { headers: { Authorization: `Bearer ${token}` } },
      },
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const validationMutation = useMutation<any>(
      gql`
        mutation validateInvoice($invoiceId: Int!, $comment: String) {
          validerFacture(input: { idFacture: $invoiceId, complementInfo: $comment })
        }
      `,
      {
        context: { headers: { Authorization: `Bearer ${token}` } },
      },
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getTitle = React.useCallback(() => {
      switch (type) {
        case ModalType.REFUSAL:
          return 'Formulaire de refus';
        case ModalType.VALIDATION:
          return 'Formulaire de validation';
        default:
          throw new Error('Invalid ModalType');
      }
    }, [type]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const getValidationSchema = React.useCallback(() => {
      switch (type) {
        case ModalType.REFUSAL:
          return Yup.object().shape({
            refusalReason: Yup.string().required('Obligatoire'),
            comment: Yup.mixed().when('refusalReason', {
              is: 'AUTRE',
              then: Yup.string()
                .test(
                  'moreThan3Characters',
                  'Doit contenir au minimum 3 caractères',
                  (value: any) => (value || '').replace(new RegExp(/\s*/g), '').length >= 3,
                )
                .required('Obligatoire'),
              otherwise: Yup.string(),
            }),
          });
        case ModalType.VALIDATION:
          return Yup.object().shape({
            comment: Yup.string(),
          });
        default:
          throw new Error('Invalid ModalType');
      }
    }, [type]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const handleSubmit = React.useCallback(
      async (values: any) => {
        // eslint-disable-next-line no-shadow
        let error;
        switch (type) {
          case ModalType.REFUSAL:
            // eslint-disable-next-line no-case-declarations
            const { error: _1 }: any = await refusalMutation({
              variables: {
                invoiceId,
                reason: values.refusalReason,
                comment: (values.comment || '').trim(),
              },
            });
            error = _1;
            break;
          case ModalType.VALIDATION:
            // eslint-disable-next-line no-case-declarations
            const { error: _2 }: any = await validationMutation({
              variables: {
                invoiceId,
                comment: (values.comment || '').trim(),
              },
            });
            error = _2;
            break;
          default:
            break;
        }
        close();
        if (error) notify.error('Une erreur est survenue');
        else {
          switch (type) {
            case ModalType.REFUSAL:
              notify('Facture rejetée');
              break;
            case ModalType.VALIDATION:
              notify('Facture validée');
              break;
            default:
              break;
          }
          sendMessage({ type: 'refetch' });
        }
      },
      [close, invoiceId, refusalMutation, type, validationMutation],
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return React.useMemo(
      () => (
        <div className={classnames(styles['modal-wrapper'])}>
          <div className={classnames(styles.container)}>
            <div className={classnames(styles.header)}>
              <div className={classnames(styles.title)}>
                <h4>{getTitle()}</h4>
              </div>
            </div>
            <div className={classnames(styles.body)}>
              <Formik initialValues={{}} validationSchema={getValidationSchema} onSubmit={handleSubmit}>
                {({ values, errors, isSubmitting }: any) => (
                  <Form>
                    {type === ModalType.REFUSAL && (
                      <Field label="Motif" required inline withoutSeparator className={classnames(styles.field)}>
                        <FormikField
                          name="refusalReason"
                          render={({ field: { name, value, onBlur }, form: { setFieldValue } }: any) => (
                            <div
                              style={{ width: '100%' }}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <AsyncSelect
                                blurInputOnSelect
                                controlShouldRenderValue
                                hideSelectedOptions
                                cacheOptions
                                defaultOptions
                                name={name}
                                defaultValue={value}
                                onChange={(option: any) => setFieldValue(name, option.value)}
                                onBlur={onBlur}
                                isClearable={false}
                                isSearchable={false}
                                components={{
                                  LoadingIndicator: () => (
                                    <LoadingIndicator className={classnames(styles.loading)} mini hideMessage />
                                  ),
                                  IndicatorSeparator: () => null,
                                }}
                                styles={{
                                  container: (base: any) => ({
                                    ...base,
                                    fontSize: '15px',
                                  }),
                                  control: (base: any, state: any) => ({
                                    ...base,
                                    boxShadow: 0,
                                    // eslint-disable-next-line no-nested-ternary
                                    borderColor: errors[name]
                                      ? 'red'
                                      : state.isFocused
                                      ? 'rgb(209, 202, 216)'
                                      : base.borderColor,
                                    '&:hover': {
                                      // eslint-disable-next-line no-nested-ternary
                                      borderColor: errors[name]
                                        ? 'red'
                                        : state.isFocused
                                        ? 'rgb(209, 202, 216)'
                                        : base.borderColor,
                                    },
                                    borderBottomLeftRadius: state.menuIsOpen ? 0 : base.borderBottomLeftRadius,
                                    borderBottomRightRadius: state.menuIsOpen ? 0 : base.borderBottomRightRadius,
                                  }),
                                  menu: (base: any) => ({
                                    ...base,
                                    borderRadius: 0,
                                    marginTop: 0,
                                    marginLeft: '1px',
                                    width: '99.5%',
                                  }),
                                  menuList: (base: any) => ({
                                    ...base,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                  }),
                                  input: (base: any) => ({
                                    ...base,
                                    input: { padding: '0 0' },
                                  }),
                                }}
                                placeholder="--"
                                loadingMessage={() => 'Chargement...'}
                                noOptionsMessage={() => 'Aucun Résultat.'}
                                loadOptions={() =>
                                  // eslint-disable-next-line no-async-promise-executor
                                  new Promise<{ value: string; label: string }[]>(async resolve => {
                                    // eslint-disable-next-line no-shadow
                                    const { data, errors } = await graphqlClient.query({
                                      query: gql`
                                        query refusalReasons($codeCadreFacturation: CadreFacturationAValider!) {
                                          refusalReasons: recupererMotifsRefusFactureAValider(
                                            code: $codeCadreFacturation
                                          ) {
                                            codeMotif
                                            libelleMotif
                                          }
                                        }
                                      `,
                                      context: {
                                        headers: {
                                          Authorization: `Bearer ${token}`,
                                        },
                                      },
                                      variables: {
                                        codeCadreFacturation,
                                      },
                                      fetchPolicy: 'network-only',
                                    });
                                    if (errors) {
                                      notify.error("Impossible d'accéder au serveur.");
                                      resolve([]);
                                    } else if (data) {
                                      resolve(
                                        _.map(data.refusalReasons || [], ({ codeMotif, libelleMotif }) => ({
                                          value: codeMotif,
                                          label: libelleMotif,
                                        })),
                                      );
                                    }
                                  })
                                }
                              />
                              {errors[name] && (
                                <div
                                  className={classnames({
                                    'invalid-feedback': true,
                                  })}
                                  style={{ display: 'unset' }}
                                >
                                  {errors[name]}
                                </div>
                              )}
                            </div>
                          )}
                        />
                      </Field>
                    )}
                    <Field
                      label="Commentaire"
                      required={type === ModalType.REFUSAL && _.get(values, 'refusalReason') === 'AUTRE'}
                      inline
                      className={classnames(styles.field)}
                    >
                      <FormikField
                        name="comment"
                        render={({ field: { name, value, ...rest } }: any) => (
                          <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                            <textarea
                              name={name}
                              value={value || ''}
                              className={classnames({
                                'form-control': true,
                                'is-invalid': errors[name],
                              })}
                              style={{
                                resize: 'none',
                                ...(errors[name] ? { border: '1px solid red' } : {}),
                              }}
                              {...rest}
                            />
                            {errors[name] && (
                              <div
                                className={classnames({
                                  'invalid-feedback': true,
                                })}
                                style={{ display: 'unset' }}
                              >
                                {errors[name]}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </Field>
                    <div style={{ display: 'flex', margin: '20px 0', paddingRight: '10px' }}>
                      <div style={{ marginLeft: 'auto' }}>
                        <Button
                          type="button"
                          size="small"
                          priority="default"
                          style={{ marginRight: '8px' }}
                          onClick={close}
                        >
                          Annuler
                        </Button>
                        <Button
                          disabled={!_.isEmpty(errors) || isSubmitting}
                          type="submit"
                          size="small"
                          priority="primary"
                        >
                          Confirmer
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ),
      [close, codeCadreFacturation, getTitle, getValidationSchema, graphqlClient, handleSubmit, type],
    );
  });
  const getTableColumns = React.useCallback(
    () =>
      [
        { property: 'numeroFacture', header: { label: 'N° Facture' } },
        { property: 'numeroEngagement', header: { label: 'N° Engagement' } },
        {
          property: 'dateFacture',
          header: {
            label: 'Date Facture',
          },
          cell: {
            formatters: [value => moment(value, 'DD-MM-YYYY HH:mm:ss.SSS').format(moment.HTML5_FMT.DATE)],
          },
        },
        {
          property: 'dateDepot',
          header: {
            label: 'Date Dépôt',
          },
          cell: {
            formatters: [value => moment(value, 'DD-MM-YYYY HH:mm:ss.SSS').format(moment.HTML5_FMT.DATE)],
          },
        },
        {
          property: 'fournisseur.nom',
          header: { label: 'émetteur' },
        },
        {
          property: 'client.nom',
          header: { label: 'client' },
        },
        {
          property: 'montantHT',
          header: { label: 'Total HT' },
          cell: {
            formatters: [
              (value, { rowData: { devise } }) =>
                new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: devise,
                }).format(parseFloat(value)),
            ],
          },
        },
        {
          property: 'montantTTC',
          header: { label: 'Total TTC' },
          cell: {
            formatters: [
              (value, { rowData: { devise } }) =>
                new Intl.NumberFormat('fr-FR', {
                  style: 'currency',
                  currency: devise,
                }).format(parseFloat(value)),
            ],
          },
        },
        {
          disableSort: true,
          property: 'statut',
          header: { label: 'Statut' },
          cell: {
            formatters: [status => (_.isNull(status) ? <small>N/A</small> : <StatusBadge status={status} />)],
          },
        },
      ] as ViewTableProps['columns'],
    [],
  );
  const renderTitle = React.useCallback(
    () => (
      <h1 className={classnames(styles.title)}>
        Mes validations de facture
        {!(error || maxPageError || errorPage || errorPerPage) &&
          !_.isNull(chorusSetting) &&
          data &&
          data.facturesAValider && (
            <QueryCount className={classnames(styles.counter)} count={data.facturesAValider.count} max={100} />
          )}
      </h1>
    ),
    [chorusSetting, data, error, errorPage, errorPerPage, maxPageError],
  );
  const renderTable = React.useCallback(
    () => (
      <div className={classnames(styles.table)}>
        <div className={classnames(styles.container)}>
          <ViewTable
            useDescendingFirst
            withoutNeutralOrder
            withoutMultiSort
            loading={loading}
            error={
              _.isNull(chorusSetting)
                ? Error("Les paramètres d'accès à Chorus Pro n'ont pas été renseignés")
                : error || maxPageError || errorPage || errorPerPage
            }
            columns={getTableColumns()}
            rowKey="idFacture"
            rows={_.get(data, 'facturesAValider.factures', [])}
            order={order}
            updateOrder={updateOrder}
            actions={[
              {
                key: 'reject',
                Icon: withProps({ size: '18px' })(IoMdCloseCircleOutline),
                disabled: ({ statut }) => !_.includes(['A_VALIDER_1', 'A_VALIDER_2'], statut),
                onClick: ({ idFacture: invoiceId, cadreDeFacturation: { codeCadreFacturation } }) =>
                  showModal({
                    invoiceId,
                    codeCadreFacturation,
                    type: ModalType.REFUSAL,
                    close: closeModal,
                  }),
              },
              {
                key: 'accept',
                Icon: withProps({ size: '18px' })(IoMdCheckmarkCircleOutline),
                disabled: ({ statut }) => !_.includes(['A_VALIDER_1', 'A_VALIDER_2'], statut),
                onClick: ({ idFacture: invoiceId, cadreDeFacturation: { codeCadreFacturation } }) =>
                  showModal({
                    invoiceId,
                    codeCadreFacturation,
                    type: ModalType.VALIDATION,
                    close: closeModal,
                  }),
              },
            ]}
          />
        </div>
      </div>
    ),
    [
      chorusSetting,
      closeModal,
      data,
      error,
      errorPage,
      errorPerPage,
      getTableColumns,
      loading,
      maxPageError,
      order,
      showModal,
      updateOrder,
    ],
  );
  const renderPagination = React.useCallback(
    () => (
      <div className={classnames(styles.pagination)}>
        {!error && !loading && data && !_.isNull(data.facturesAValider.pageInfo) && (
          <Paginator
            page={data.facturesAValider.pageInfo.page}
            perPage={data.facturesAValider.pageInfo.perPage}
            pages={data.facturesAValider.pageInfo.pages}
            perPageOptions={[10, 20, 50]}
            updatePage={updatePage}
            updatePerPage={updatePerPage}
            errorPage={errorPage || maxPageError}
            errorPerPage={errorPerPage}
          />
        )}
      </div>
    ),
    [data, error, errorPage, errorPerPage, loading, maxPageError, updatePage, updatePerPage],
  );
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)}>
        <div className={classnames(styles.content)}>
          <div className={classnames(styles.header)}>{renderTitle()}</div>
          {renderTable()}
          {renderPagination()}
        </div>
      </div>
    ),
    [renderPagination, renderTable, renderTitle],
  );
};

type StatusBadgeProps = {
  status: string;
};

enum StatusBadgeColor {
  BLUE,
  ORANGE,
  RED,
  GREEN,
  BLACK,
}

const StatusBadge: React.FunctionComponent<StatusBadgeProps> = props => {
  const mapping: { [x: string]: [string, StatusBadgeColor] } = {
    A_VALIDER_1: ['À valider', StatusBadgeColor.ORANGE],
    VALIDEE_1: ['Déposée', StatusBadgeColor.GREEN],
    REFUSEE_1: ['Refusée', StatusBadgeColor.RED],
    A_VALIDER_2: ['À valider', StatusBadgeColor.ORANGE],
    VALIDEE_2: ['Déposée', StatusBadgeColor.GREEN],
    REFUSEE_2: ['Refusée', StatusBadgeColor.RED],
    DEPOSEE: ['Déposée', StatusBadgeColor.GREEN],
    ERREUR_COTRAITANT_SUR_VALIDATEUR: ['Erreur valideur', StatusBadgeColor.RED],
    SUSPENDUE: ['Suspendue', StatusBadgeColor.RED],
    ABSENCE_VALIDATION_1_HORS_DELAI: ['Hors délai', StatusBadgeColor.BLUE],
    ABSENCE_VALIDATION_2_HORS_DELAI: ['Hors délai', StatusBadgeColor.BLUE],
    ERREUR_FOURNISSEUR_SUR_VALIDATEUR: ['Erreur valideur', StatusBadgeColor.RED],
    A_ASSOCIER_MOE: ['Erreur MOE', StatusBadgeColor.RED],
    MISE_A_DISPOSITION_MOE: ['Transmise MOE', StatusBadgeColor.GREEN],
    PRISE_EN_COMPTE_MOE: ['Attente MOE', StatusBadgeColor.GREEN],
    REFUSEE_MOE: ['Refusée MOE', StatusBadgeColor.RED],
    EN_COURS_ACHEMINEMENT: ['En cours', StatusBadgeColor.GREEN],
    MISE_A_DISPOSITION: ['À disposition', StatusBadgeColor.GREEN],
    A_RECYCLER: ['À recycler', StatusBadgeColor.RED],
    ABANDONNEE: ['Abandonnée', StatusBadgeColor.RED],
    COMPLETEE: ['Complétée', StatusBadgeColor.GREEN],
    REJETEE: ['Rejetée', StatusBadgeColor.RED],
    MANDATEE: ['Mandatée', StatusBadgeColor.GREEN],
    MISE_EN_PAIEMENT: ['En paiement', StatusBadgeColor.GREEN],
    COMPTABILISEE: ['Comptabilisée', StatusBadgeColor.GREEN],
    MISE_A_DISPOSITION_COMPTABLE: ['À disposition comptable', StatusBadgeColor.GREEN],
    ASSOCIEE: ['Associée', StatusBadgeColor.GREEN],
    EN_COURS_TRAITEMENT_PIVOT_S: ['En traitement', StatusBadgeColor.BLUE],
    T_INTERPRETEE_OCR: ['Interprétée OCR', StatusBadgeColor.BLUE],
    SERVICE_FAIT: ['Service fait', StatusBadgeColor.GREEN],
  };
  const [status, color] = _.get(mapping, props.status, [props.status, StatusBadgeColor.BLACK]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAll = React.useCallback(
    () => (
      <>
        {_.map(_.keys(mapping), k => (
          <div
            key={k}
            className={classnames(
              styles.status,
              _.fromPairs(
                _.map(
                  _.filter(
                    _.reduce(
                      _.keys(StatusBadgeColor),
                      (arr, key) => (!_.includes(arr, key) ? _.concat(arr, _.get(StatusBadgeColor, key)) : arr),
                      [] as any[],
                    ),
                    key => _.has(styles, key),
                  ),
                  key => [_.get(styles, key), _.isEqual(_.get(mapping, `${k}[1]`), _.get(StatusBadgeColor, key))],
                ),
              ),
            )}
          >
            {_.get(mapping, `${k}[0]`)}
          </div>
        ))}
      </>
    ),
    [mapping],
  );
  const render = React.useCallback(
    () => (
      <div className={classnames(styles['status-container'])}>
        <div
          className={classnames(
            styles.status,
            _.fromPairs(
              _.map(
                _.filter(
                  _.reduce(
                    _.keys(StatusBadgeColor),
                    (arr, key) => (!_.includes(arr, key) ? _.concat(arr, _.get(StatusBadgeColor, key)) : arr),
                    [] as any[],
                  ),
                  key => _.has(styles, key),
                ),
                key => [_.get(styles, key), _.isEqual(color, _.get(StatusBadgeColor, key))],
              ),
            ),
          )}
        >
          {status}
        </div>
      </div>
    ),
    [color, status],
  );
  return render();
};

export default InvoicesValidationView;
