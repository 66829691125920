import _ from 'lodash';
import * as React from 'react';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useMutation } from 'react-apollo-hooks';
import gql from 'graphql-tag';

import Button from 'react-bootstrap/Button';

import useSessionStorage from 'UtilHooks/useSessionStorage';
import useRouter from 'UtilHooks/useRouter';

import Alert from '../components/alert';
import FormGroup from '../components/forms/formGroup';
import InputField from '../components/forms/inputField';
import PasswordField from '../components/forms/passwordField';

import styles from './login.module.scss';

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Obligatoire'),
  password: Yup.string().required('Obligatoire'),
});

const LoginView = () => {
  const [token, setToken, clearToken] = useSessionStorage('token');
  const { replace } = useRouter();
  const [currentToken, setCurrentToken] = React.useState(token);
  const [currentLoginError, setLoginError] = React.useState<Error>();
  const loginMutation = useMutation<any>(
    gql`
      mutation login($username: String!, $password: String!) {
        login(userName: $username, password: $password)
      }
    `,
  );
  const login = React.useCallback(
    async (username: string, password: string) => {
      clearToken();
      setLoginError(undefined);
      let tokenTmp; // tslint:disable-line: variable-name
      let errorTmp; // tslint:disable-line: variable-name
      try {
        const {
          data: { login: loginToken },
          error: mutationError,
        }: any = await loginMutation({ variables: { username, password } });
        tokenTmp = loginToken;
        errorTmp = mutationError;
      } catch (e) {
        errorTmp = e;
      }
      if (errorTmp) {
        setLoginError(errorTmp);
      } else if (tokenTmp) {
        setToken(tokenTmp);
      }
    },
    [clearToken, loginMutation, setToken],
  );
  const onSubmit = React.useCallback(
    (values: FormikValues) => {
      login(values.username, values.password);
    },
    [login],
  );
  React.useEffect(() => {
    setCurrentToken(token);
    if (!currentLoginError && currentToken) replace('/dashboard');
    return _.noop;
  }, [currentLoginError, currentToken, replace, token]);
  return React.useMemo(
    () => (
      <>
        <div className={classNames(styles.header)}>
          <h3>Seize by Dataprisme</h3>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classNames(styles.authContainer)}>
            {currentLoginError && (
              <Alert className={styles.alert} level="danger">
                <p>Veuillez renseigner un identifiant et un mot de passe correct.</p>
              </Alert>
            )}
            <Formik initialValues={{ username: '', password: '' }} validationSchema={loginSchema} onSubmit={onSubmit}>
              {({ errors, touched, isSubmitting }) => (
                /* eslint-disable jsx-a11y/label-has-associated-control */
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Form style={{ width: '100%' }}>
                  <FormGroup>
                    <label htmlFor="username" className="control-label">
                      Identifiant
                    </label>
                    <InputField name="username" placeholder="username" errors={errors} touched={touched} />
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="password" className="control-label">
                      Mot de passe
                    </label>
                    <PasswordField name="password" placeholder="Mot de passe" errors={errors} touched={touched} />
                  </FormGroup>
                  <div className={styles.footer}>
                    <Button
                      className={styles.btn}
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting && !currentLoginError}
                    >
                      {isSubmitting && !currentLoginError && (
                        <span
                          className={classNames({
                            'spinner-border': true,
                            'spinner-border-sm': true,
                          })}
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {!(isSubmitting && !currentLoginError) && <>Se connecter</>}
                      {isSubmitting && !currentLoginError && <> Connexion en cours...</>}
                    </Button>
                    {/* <Link to="/" className="secondary">
                  Mot de passe oublié ?
                </Link> */}
                  </div>
                </Form>
              )
              /* eslint-enable jsx-a11y/label-has-associated-control */
              }
            </Formik>
          </div>
          <div style={{ display: 'flex', width: '40%', padding: '30px 0' }}>
            <div style={{ display: 'flex', width: '100%', height: '100%', borderLeft: '1px solid #bdb4c7' }}>
              <div style={{ width: '100%', padding: '30px 40px' }}>
                <img
                  alt=""
                  src={`${process.env.PUBLIC_URL}/logo.png`}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    ),
    [currentLoginError, onSubmit],
  );
};

export default LoginView;
