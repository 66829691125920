import * as React from 'react';
import classnames from 'classnames';

import HeroIcon from 'Components/heroIcon';
import InlineSvg from 'Components/inlineSvg';
import EmptyMessage from 'Components/emptyMessage';
import styles from './emptyStateWarning.module.scss';

type EmptyStateWarningProps = {
  message: string;
  small?: boolean;
};

const EmptyStateWarning: React.FunctionComponent<EmptyStateWarningProps> = ({ message, small }) => {
  if (small) {
    return (
      <EmptyMessage>
        <div className={classnames(styles.small)}>
          <InlineSvg src="icon-circle-exclamation" width="34px" />
          <p>{message}</p>
        </div>
      </EmptyMessage>
    );
  }

  return (
    <div className={classnames(styles.wrapper)}>
      <HeroIcon src="icon-circle-exclamation" size="54" />
      <p>{message}</p>
    </div>
  );
};

export default EmptyStateWarning;
