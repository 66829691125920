import * as React from 'react';
import classNames from 'classnames';

import PanelBody from './panelBody';
import PanelHeader from './panelHeader';
import styles from './panel.module.scss';

interface PanelProps {
  title?: string;
  body?: string;
  dashedBorder?: boolean;
  children?: React.ReactNode;
  noBorder?: boolean;
  noMargin?: boolean;
  [propName: string]: any;
}

const Panel = (props: PanelProps) => {
  const { title, body, dashedBorder, noMargin, noBorder, children, ...moreProps } = props;
  const hasHeaderAndBody = !!title && !!body;
  if (hasHeaderAndBody) {
    return (
      <div
        className={classNames(styles.panel, {
          [styles.dashedBorder]: dashedBorder,
          [styles.noBorder]: noBorder,
          [styles.noMargin]: noMargin,
        })}
        {...moreProps}
      >
        <PanelHeader>{title}</PanelHeader>
        <PanelBody>{body}</PanelBody>
      </div>
    );
  }
  return (
    <div
      className={classNames(styles.panel, {
        [styles.dashedBorder]: dashedBorder,
        [styles.noBorder]: noBorder,
        [styles.noMargin]: noMargin,
      })}
      {...moreProps}
    >
      {children}
    </div>
  );
};

export default Panel;
