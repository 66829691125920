import * as React from 'react';

import ConfirmationViewModal, { ConfirmationViewModalActions } from 'Views/components/modals/confirmationViewModal';

export type DeleteConfirmationViewModalProps = {
  title: string;
  close: () => Promise<void>;
  onConfirm?: () => Promise<void>;
};

const DeleteConfirmationViewModal: React.FC<DeleteConfirmationViewModalProps> = ({ title, close, onConfirm }) => {
  const actions: ConfirmationViewModalActions = React.useMemo(
    () => [
      {
        priority: 'default',
        label: 'Annuler',
      },
      {
        onClick: onConfirm,
        priority: 'danger',
        label: 'Confirmer',
      },
    ],
    [onConfirm],
  );
  return React.useMemo(
    () => (
      <ConfirmationViewModal
        permanent
        loadingMessage="Suppression en cours"
        title={`de vouloir supprimer ${title}`}
        close={close}
        actions={actions}
      />
    ),
    [title, close, actions],
  );
};

export default DeleteConfirmationViewModal;
