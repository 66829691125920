import _ from 'lodash';

export type PaginatorSegments = {
  beginPages: number[];
  previousPages: number[];
  centerPage: number[];
  nextPages: number[];
  endPages: number[];
};

export default (o: { page: number; pages: number; beginPages?: number; sidePages?: number; endPages?: number }) => {
  const { pages } = o;
  const page = Math.min(Math.max(o.page, 1), pages);
  const previousPages = o.sidePages ? _.range(Math.max(page - o.sidePages, 1), page) : [];
  const beginPages = o.beginPages ? _.range(1, Math.min(o.beginPages, pages) + 1) : [];
  const nextPages = o.sidePages
    ? _.filter(_.range(page + 1, Math.min(page + o.sidePages + 1, pages)), v => _.inRange(v, 1, pages))
    : [];
  const endPages = o.endPages ? _.range(Math.max(pages - o.endPages + 1, 0), pages + 1) : [];
  const result: PaginatorSegments = {
    beginPages: _.difference(beginPages, o.beginPages ? _.range(page, Math.max(pages, o.beginPages) + 1) : []),
    previousPages: _.difference(previousPages, beginPages),
    centerPage: [page],
    nextPages: _.difference(nextPages, endPages),
    endPages: _.difference(endPages, _.range(0, page + 1)),
  };
  return result;
};
