import { useEffect, useCallback, useMemo } from 'react';
import { Subject } from 'rxjs';
import _ from 'lodash';

type Message = {
  type: string;
  data?: any;
};
const subjects: { [x: string]: Subject<Message | undefined> } = {};

export default (
  topic: string,
  {
    regex,
    onMessage,
    onEmptyMessage,
  }: { regex?: RegExp; onMessage?: (data: any) => void; onEmptyMessage?: () => void } = {},
) => {
  const subject = useMemo(() => {
    if (!_.has(subjects, topic)) _.set(subjects, topic, new Subject<Message | undefined>());
    return _.get(subjects, topic);
  }, [topic]);
  const observable = useMemo(() => subject.asObservable(), [subject]);
  const sendMessage = useCallback((message?: Message) => subject.next(message), [subject]);
  useEffect(() => {
    const subscription = observable.subscribe(message => {
      if (!_.isUndefined(message)) {
        if (
          _.isFunction(onMessage) &&
          (_.isUndefined(regex) || (_.isRegExp(regex) && !_.isNull(_.get(message, 'type').match(regex))))
        )
          onMessage(_.get(message, 'data'));
      } else if (_.isFunction(onEmptyMessage)) onEmptyMessage();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [observable, onEmptyMessage, onMessage, regex]);
  return { sendMessage };
};
