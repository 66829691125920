import * as React from 'react';

import { DEFAULT_RELATIVE_PERIODS } from 'Constants/date';
import SelectorItem from 'Views/components/timeRangeSelector/dateRange/selectorItem';

type RelativeSelectorProps = {
  onClick?: Function;
  selected?: string;
};

const RelativeSelector: React.FunctionComponent<RelativeSelectorProps> = ({ onClick, selected }) => (
  <>
    {Object.entries(DEFAULT_RELATIVE_PERIODS).map(([value, label], index, array) => (
      <SelectorItem
        key={value}
        onClick={onClick}
        value={value}
        label={label}
        isSelected={selected === value}
        isLast={index === array.length}
      />
    ))}
  </>
);
export default RelativeSelector;
