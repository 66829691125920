export const DEFAULT_DAY_START_TIME = '00:00:00';
export const DEFAULT_DAY_END_TIME = '23:59:59';
export const DATE_FORMAT_NO_TIMEZONE = 'YYYY/MM/DD HH:mm:ss';
export const DEFAULT_RELATIVE_PERIODS = {
  'C|M': 'Mois en cours',
  'P|M': 'Mois dernier',
  'C|Q': 'Trimestre en cours',
  'C|S': 'Semestre en cours',
  'C|Y': 'Année en cours',
  'P|Y': 'Année dernière',
};
export const DEFAULT_PERIOD = 'C|Y';
// This is currently the max number of days back you can search
export const MAX_PICKABLE_DAYS = 2 * 365 + 1;
