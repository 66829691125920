import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import theme from 'Utils/theme';
import sortArray from 'Utils/sortArray';
import Button from 'Components/button';
import DropdownAutoComplete from 'Components/dropdownAutoComplete';
import Highlight from 'Components/highlight';
import IdBadge from 'Components/idBadge';
import GlobalSelectionHeaderRow from 'Components/globalSelectionHeaderRow';

import styles from './clientSelector.module.scss';

export type Client = { id: string; name: string; isBookmarked?: boolean };
export type Clients = Client[];
type ClientSelectorProps = {
  clientId?: string | null;
  clients?: Clients;

  // used by multiClientSelector
  multiClients?: Clients;

  // Render a footer at the bottom of the list
  // render function that is passed an `actions` object with `close` and `open` properties.
  menuFooter?: (renderProps: any) => void | React.ReactNode;

  // Allow selecting multiple projects?
  multi?: boolean;

  // Use this if the component should be a controlled component
  selectedClients?: Clients | null;
  initialSelectedClients?: Clients | null;

  // Callback when a project is selected
  onSelect?: (client: Client) => void;

  // Callback when the menu is closed
  onClose?: () => void;

  // Callback when projects are selected via the multiple client selector
  // Calls back with (client[], event)
  onMultiSelect?: (clients: Clients, e: any) => void;
  rootClassName?: string;
  className?: string;
  children: ({
    activeClient,
    selectedClients,
    ...renderProps
  }: {
    activeClient: Client;
    selectedClients: Clients;
    [x: string]: any;
  }) => React.ReactNode;
};

const ClientSelector: React.FunctionComponent<ClientSelectorProps> = props => {
  const getClients = React.useCallback(() => {
    const { clients, multiClients } = props;

    if (!_.isUndefined(multiClients)) {
      return [
        sortArray(multiClients, client => {
          return [!!client.isBookmarked, client.name];
        }),
      ];
    }

    // const {isSuperuser} = ConfigStore.get('user');
    const unfilteredClients = clients || [];

    const filteredClients = unfilteredClients;

    return [
      sortArray(filteredClients, client => {
        return [!!client.isBookmarked, client.name];
      }),
      [],
    ];
  }, [props]);
  const getActiveClient = React.useCallback(() => {
    const { clientId } = props;
    const clients = _.flatten(getClients());

    return clients.find(({ id }) => id === clientId);
  }, [getClients, props]);
  const [activeClient, setActiveClient] = React.useState(getActiveClient);
  React.useEffect(() => {
    setActiveClient(getActiveClient());
    return _.noop;
  }, [getActiveClient]);
  const getSelectedClientsFromProps = React.useCallback(() => {
    const { initialSelectedClients } = props;
    if (_.isUndefined(initialSelectedClients) || _.isNull(initialSelectedClients)) return new Map();
    return new Map(_.map(initialSelectedClients, c => [c.id, c]));
  }, [props]);
  const [selectedClients, setSelectedClients] = React.useState(getSelectedClientsFromProps);
  React.useEffect(() => {
    setSelectedClients(getSelectedClientsFromProps());
  }, [getSelectedClientsFromProps]);

  const isControlled = () => !_.isUndefined(props.selectedClients);
  const toggleClient = (client: Client, e: any) => {
    // Don't update state if this is a controlled component
    if (isControlled()) return;
    // eslint-disable-next-line no-underscore-dangle
    const _selectedClients = new Map(selectedClients.entries());
    const { id } = client;
    if (_selectedClients.has(id)) _selectedClients.delete(id);
    else _selectedClients.set(id, client);
    const { onMultiSelect } = props;
    if (_.isFunction(onMultiSelect)) onMultiSelect(Array.from(_selectedClients.values()), e);
    setSelectedClients(_selectedClients);
  };
  const handleSelect = ({ value: client }: { value: Client }) => {
    const { onSelect } = props;
    setActiveClient(client);
    if (_.isFunction(onSelect)) onSelect(client);
  };
  const handleMultiSelect = (client: Client, e: any) => {
    const { onMultiSelect, selectedClients } = props;
    if (isControlled()) {
      if (!_.isFunction(onMultiSelect)) {
        throw new Error('ClientSelector is a controlled component but `onMultiSelect` callback is not defined');
      } else {
        const selectedClientsMap = new Map(
          _.map(_.isNull(selectedClients) ? _.flatten(getClients()) : selectedClients, c => [c.id, c]),
        );
        if (selectedClientsMap.has(client.id)) selectedClientsMap.delete(client.id);
        else selectedClientsMap.set(client.id, client);
        onMultiSelect(Array.from(selectedClientsMap.values()), e);
      }
    }
    toggleClient(client, e);
  };
  const { children, menuFooter, multi, className, rootClassName, onClose, clients } = props;
  const hasClients = clients && clients.length > 0;
  const getClientItem = (client: Client) => ({
    value: client,
    searchKey: `${client.name} (${client.id})`,
    label: ({ inputValue }: { inputValue: string }) => {
      return (
        <ClientSelectorItem
          className={classnames(styles.item)}
          client={client}
          multi={multi}
          inputValue={inputValue}
          isChecked={
            isControlled()
              ? // eslint-disable-next-line react/destructuring-assignment
                _.isNull(props.selectedClients) ||
                // eslint-disable-next-line react/destructuring-assignment
                !_.isUndefined((props.selectedClients || []).find(({ id }) => id === client.id))
              : selectedClients.has(client.id)
          }
          onMultiSelect={handleMultiSelect}
        />
      );
    },
  });
  return (
    <DropdownAutoComplete
      allowActorToggle
      closeOnSelect
      alignMenu="left"
      blendCorner={false}
      searchPlaceholder="Filtrer par client"
      onSelect={handleSelect}
      onClose={onClose}
      maxHeight={300}
      zIndex={theme.zIndex.dropdown}
      css={{ marginTop: 6 }}
      inputProps={{ style: { padding: 8, paddingLeft: 10 } }}
      rootClassName={classnames(rootClassName)}
      className={classnames(className)}
      emptyMessage="Aucun résultat"
      noResultsMessage="Aucun client trouvé"
      virtualizedHeight={theme.headerSelectorRowHeight}
      virtualizedLabelHeight={theme.headerSelectorLabelHeight}
      emptyHidesInput
      /* inputActions={() => (
          <Button
            className={classnames(styles['add-button'])}
            // disabled={!hasProjectWrite}
            to="/clients/new"
            size="xsmall"
            // title={hasProjectWrite ? null : t("You don't have permission to add a project")}
          >
            <InlineSvg className={classnames(styles['add-button-icon'])} src="icon-circle-add" /> Client
          </Button>
        )} */
      menuFooter={(renderProps: any) => {
        const renderedFooter = _.isFunction(menuFooter) ? menuFooter(renderProps) : menuFooter;
        const showCreateClientButton = !hasClients; /*  && hasProjectWrite */

        if (!renderedFooter && !showCreateClientButton) return null;

        return (
          <>
            {showCreateClientButton && (
              <Button className={classnames(styles['create-button'])} priority="primary" size="small" to="/clients/new">
                Créer client
              </Button>
            )}
            {renderedFooter}
          </>
        );
      }}
      items={
        // tslint:disable: ter-indent
        hasClients
          ? [
              {
                hideGroupLabel: true,
                items: _.map(clients, getClientItem),
              },
              /* {
            hideGroupLabel: nonMemberProjects.length === 0,
            itemSize: 'small',
            id: 'no-membership-header', // needed for tests for non-virtualized lists
            label: <Label>{t("Projects I don't belong to")}</Label>,
            items: nonMemberProjects.map(getProjectItem),
          }, */
            ]
          : []
        // tslint:enable: ter-indent
      }
    >
      {(renderProps: any) =>
        children({
          ...renderProps,
          activeClient,
          selectedClients: isControlled() ? props.selectedClients : Array.from(selectedClients.values()),
        })
      }
    </DropdownAutoComplete>
  );
};
ClientSelector.defaultProps = {
  clientId: null,
  multi: false,
  onSelect: _.noop,
};
export default ClientSelector;

type ClientSelectorItemProps = {
  client: Client;
  multi?: boolean;
  inputValue?: string;
  isChecked?: boolean;
  onMultiSelect?: (client: Client, e: any) => void;
  className?: string;
};

const ClientSelectorItem: React.FunctionComponent<ClientSelectorItemProps> = props => {
  const getBookmarkHasChangedFromProps = React.useCallback(() => props.client.isBookmarked, [props]);
  const [bookmarkHasChanged, setBookmarkHasChanged] = React.useState(getBookmarkHasChangedFromProps);
  React.useEffect(() => {
    setBookmarkHasChanged(getBookmarkHasChangedFromProps());
    return _.noop;
  }, [getBookmarkHasChangedFromProps]);
  const handleMultiSelect = (e: any) => {
    const { client, onMultiSelect } = props;
    if (_.isFunction(onMultiSelect)) onMultiSelect(client, e);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleMultiSelect(e);
  };
  /* const handleBookmarkToggle = (isBookmarked: boolean) => {
    setBookmarkHasChanged(isBookmarked);
  }; */
  const clearAnimation = () => {
    setBookmarkHasChanged(false);
  };
  const render = () => {
    const { client, multi, inputValue, isChecked } = props;
    return (
      <div
        className={classnames(styles['badge-and-actions-wrapper'], {
          [styles.bookmarkHasChanged]: bookmarkHasChanged,
        })}
        onAnimationEnd={clearAnimation}
      >
        <GlobalSelectionHeaderRow checked={!!isChecked} onCheckClick={handleClick} multi={multi} priority="secondary">
          <div className={classnames(styles['badge-wrapper'])}>
            <IdBadge
              client={client}
              avatarSize={16}
              displayName={<Highlight text={inputValue}>{`${client.name} (${client.id})`}</Highlight>}
              avatarProps={{ consistentWidth: true }}
            />
          </div>
          {/* <StyledBookmarkStar
            project={client}
            bookmarkHasChanged={bookmarkHasChanged}
            onToggle={handleBookmarkToggle}
          />
          <SettingsIconLink to={`/clients/${client.id}/edit`} onClick={(e: any) => e.stopPropagation()}>
            <SettingsIcon src="icon-settings" />
          </SettingsIconLink> */}
        </GlobalSelectionHeaderRow>
      </div>
    );
  };
  return render();
};
