import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { compose } from 'recompose';

import ErrorView from 'Views/errorView';
import NoLayout from 'Layouts/noLayout';
import config from 'Config';
import branch from 'Utils/branch';

class SentryErrorBoundary extends React.Component<any, { error?: Error; eventId?: string }> {
  public constructor(props: any) {
    super(props);
    this.state = { error: undefined, eventId: undefined };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    Sentry.withScope((scope: any) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  public render() {
    const { error, eventId } = this.state;
    const { children } = this.props;
    if (error) {
      return React.createElement(NoLayout, {
        view: ErrorView,
        viewProps: { eventId },
      });
    }
    // when there's not an error, render children untouched
    return children;
  }
}

export default compose<any, {}>(branch(config.SENTRY_ENABLED))(SentryErrorBoundary);
