import * as React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import MultipleSelectorSubmitRow from 'Views/components/multipleSelectorSubmitRow';
import ClientSelector, { Client, Clients } from 'Views/components/multipleClientSelector/clientSelector';
import HeaderItem from 'Components/headerItem';

import styles from './multipleClientSelector.module.scss';

type MultipleClientSelectorProps = {
  clients: Clients;
  selected: Client['id'][] | null;
  onUpdate?: (ids: Client['id'][] | null) => void;
  multi?: boolean;
};

const MultipleClientSelector: React.FunctionComponent<MultipleClientSelectorProps> = props => {
  const [hasChanges, setHasChanges] = React.useState(false);
  const getSelectedFromProps = React.useCallback(() => {
    const { selected, clients } = props;
    const allClients = [...clients];
    if (_.isNull(selected)) return null;
    const selectedClientIds = new Set(selected);
    return _.filter(allClients, client => selectedClientIds.has(client.id));
  }, [props]);
  const [selected, setSelected] = React.useState(getSelectedFromProps);
  React.useEffect(() => {
    setSelected(getSelectedFromProps());
  }, [getSelectedFromProps]);
  const selectedRef = React.useRef<Client[] | null>();
  React.useEffect(() => {
    selectedRef.current = selected;
  });

  const doUpdate = () => {
    const { onUpdate, clients } = props;
    setHasChanges(false);
    if (_.isFunction(onUpdate)) {
      const selectedTmp = selectedRef.current; // tslint:disable-line: variable-name
      const hasNoneSelected = !_.isArray(selectedTmp) || selectedTmp.length === 0;
      const hasSomeSelected = _.isArray(selectedTmp) && selectedTmp.length > 0;
      const allSelected =
        hasNoneSelected ||
        (hasSomeSelected &&
          _.reduce<Client, boolean>(
            clients,
            (acc, { id }) => acc && !_.isUndefined(_.find(selectedTmp, ({ id: selectedId }) => selectedId === id)),
            true,
          ));
      onUpdate(!allSelected && hasSomeSelected ? _.map(selectedTmp, ({ id }) => id) : null);
      if (allSelected) setSelected(null);
    }
  };
  /**
   * Handler for when an explicit update call should be made.
   * e.g. an "Update" button
   *
   * Should perform an "update" callback
   */
  const handleUpdate = (actions: { close: () => void }) => {
    actions.close();
    doUpdate();
  };
  /**
   * Handler for when a dropdown item was selected directly (and not via multi select)
   *
   * Should perform an "update" callback
   */
  // eslint-disable-next-line no-shadow
  const handleQuickSelect = (selected: Client) => {
    setSelected([selected]);
    selectedRef.current = [selected];
    doUpdate();
  };
  /**
   * Handler for when dropdown menu closes
   *
   * Should perform an "update" callback
   */
  const handleClose = () => {
    // Only update if there are changes
    if (!hasChanges) return;
    doUpdate();
  };
  /**
   * Handler for clearing the current value
   *
   * Should perform an "update" callback
   */
  const handleClear = () => {
    setSelected(null);
    selectedRef.current = null;
    // Update on clear
    doUpdate();
  };
  /**
   * Handler for selecting multiple items, should NOT call update
   */
  // eslint-disable-next-line no-shadow
  const handleMultiSelect = (selected: Clients) => {
    setSelected(selected);
    selectedRef.current = selected;
    setHasChanges(true);
  };

  const { clients, multi } = props;
  return (
    <ClientSelector
      className={classnames(styles['client-selector'])}
      multi={multi}
      initialSelectedClients={selected}
      multiClients={clients}
      onSelect={handleQuickSelect}
      onClose={handleClose}
      onMultiSelect={handleMultiSelect}
      rootClassName={classnames(styles.root)}
      menuFooter={({ actions }) =>
        hasChanges && <MultipleSelectorSubmitRow onSubmit={_.partial(handleUpdate, actions)} />
      }
      {...props}
    >
      {({ getActorProps, selectedClients, isOpen }) => {
        const hasNoneSelected = !_.isArray(selectedClients) || selectedClients.length === 0;
        const hasSomeSelected = _.isArray(selectedClients) && selectedClients.length > 0;
        const allClientsSelected =
          hasNoneSelected ||
          (hasSomeSelected &&
            _.reduce<Client, boolean>(
              clients,
              (acc, { id }) =>
                acc && !_.isUndefined(_.find(selectedClients, ({ id: selectedId }) => selectedId === id)),
              true,
            ));
        const title =
          hasSomeSelected && !allClientsSelected
            ? _.map(selectedClients, ({ name }) => name).join(', ')
            : 'Tous les clients';
        const hasSelected = !allClientsSelected && hasSomeSelected;
        return (
          <HeaderItem
            className={classnames(styles['header-item'])}
            icon={multi ? 'icon-user-multi' : 'icon-user'}
            hasSelected={hasSelected}
            hasChanges={hasChanges}
            isOpen={isOpen}
            onClear={handleClear}
            allowClear={multi}
            {...getActorProps({})}
          >
            {title}
          </HeaderItem>
        );
      }}
    </ClientSelector>
  );
};
MultipleClientSelector.defaultProps = {
  multi: true,
};
export default MultipleClientSelector;
