import * as React from 'react';
import _ from 'lodash';
// @ts-ignore
import { Vega } from 'react-vega';
// @ts-ignore
import { Handler } from 'vega-tooltip';
// @ts-ignore
import { withResizeDetector } from 'react-resize-detector';

import EmptyStateWarning from 'Components/emptyStateWarning';

const getSpec: (values: any) => any = values => ({
  $schema: 'https://vega.github.io/schema/vega/v3.0.json',
  width: 300,
  height: 300,
  autosize: { type: 'pad', resize: true },
  signals: [
    {
      name: 'startAngle',
      value: 0,
    },
    {
      name: 'endAngle',
      value: 6.29,
    },
    {
      name: 'padAngle',
      value: 0,
    },
    {
      name: 'sort',
      value: 'true',
    },
    {
      name: 'strokeWidth',
      value: 2,
    },
    // {
    //   name: 'title',
    //   value: 'title',
    // },
    {
      name: 'selected',
      on: [
        {
          events: 'mouseover',
          update: 'datum',
        },
      ],
    },
  ],
  data: [
    {
      values,
      name: 'table',
      transform: [
        {
          type: 'pie',
          field: 'value',
          startAngle: {
            signal: 'startAngle',
          },
          endAngle: {
            signal: 'endAngle',
          },
          sort: {
            signal: 'sort',
          },
        },
        {
          type: 'joinAggregate',
          fields: ['value'],
          ops: ['sum'],
        },
        {
          type: 'formula',
          as: 'percentage',
          expr: 'datum.value / datum.sum_value * 100',
        },
      ],
    },
  ],
  legends: [
    {
      fill: 'color',
      title: 'Statuts',
      orient: 'none',
      padding: {
        value: 10,
      },
      labelFontSize: {
        signal: 'if(width < 375 / 2, 10, 14)',
      },
      titleFontSize: {
        signal: 'if(width < 375 / 2, 10, 14)',
      },
      encode: {
        symbols: {
          enter: {
            fillOpacity: {
              value: 1,
            },
          },
        },
        legend: {
          update: {
            x: {
              // tslint:disable-next-line
              signal:
                '(width / 2) + if(selected && selected.statut == datum.statut, if(width >= height, height, width) / 2 * 1.1 * 0.8, if(width >= height, height, width) / 2 * 0.8)',
              offset: 20,
            },
            y: {
              signal: '(height / 2)',
              offset: -50,
            },
          },
        },
      },
    },
  ],
  scales: [
    {
      name: 'color',
      type: 'ordinal',
      range: {
        scheme: 'category20',
      },
    },
  ],
  marks: [
    {
      type: 'arc',
      from: {
        data: 'table',
      },
      encode: {
        enter: {
          tooltip: {
            signal: 'datum',
          },
          fill: {
            scale: 'color',
            field: 'statut',
          },
          x: {
            signal: 'width / 2',
          },
          y: {
            signal: 'height / 2',
          },
        },
        update: {
          startAngle: {
            field: 'startAngle',
          },
          endAngle: {
            field: 'endAngle',
          },
          padAngle: {
            signal: 'if(selected && selected.statut == datum.statut, 0.015, 0.015)',
          },
          innerRadius: {
            // tslint:disable-next-line
            signal:
              'if(selected && selected.statut == datum.statut, if(width >= height, height, width) / 2 * 0.45, if(width >= height, height, width) / 2 * 0.5)',
          },
          outerRadius: {
            // tslint:disable-next-line
            signal:
              'if(selected && selected.statut == datum.statut, if(width >= height, height, width) / 2 * 1.05 * 0.8, if(width >= height, height, width) / 2 * 0.8)',
          },
          opacity: {
            // tslint:disable-next-line
            signal: 'if(selected && selected.statut !== datum.statut, 1, 1)',
          },
          stroke: {
            signal: 'scale("color", datum.statut)',
          },
          strokeWidth: {
            signal: 'strokeWidth',
          },
          fillOpacity: {
            signal: 'if(selected && selected.statut == datum.statut, 0.8, 0.8)',
          },
        },
      },
    },
    {
      type: 'text',
      encode: {
        enter: {
          fill: {
            value: '#525252',
          },
          fontWeight: {
            value: 'bold',
          },
          text: {
            signal: 'title',
          },
        },
        update: {
          opacity: {
            value: 1,
          },
          x: {
            signal: 'width / 2',
          },
          y: {
            signal: 'height / 2',
          },
          align: {
            value: 'center',
          },
          baseline: {
            value: 'middle',
          },
          fontSize: {
            signal: 'if(width >= height, height, width) * 0.07',
          },
        },
      },
    },
    {
      name: 'mark_value',
      type: 'text',
      from: {
        data: 'table',
      },
      encode: {
        enter: {
          tooltip: {
            signal: 'datum',
          },
          text: {
            signal: 'if(datum["endAngle"] - datum["startAngle"] < 0.3, "", format(datum["percentage"], ".0f") + "%")',
          },
          x: {
            signal: 'if(width >= height, height, width) / 2',
          },
          y: {
            signal: 'if(width >= height, height, width) / 2',
          },
          radius: {
            // tslint:disable-next-line
            signal:
              'if(selected && selected.statut == datum.statut, if(width >= height, height, width) / 2 * 1.05 * 0.65, if(width >= height, height, width) / 2 * 0.65)',
          },
          theta: {
            signal: '(datum["startAngle"] + datum["endAngle"])/2',
          },
          fill: {
            value: '#FFFFFF',
          },
          fontSize: {
            value: 12,
          },
          fontWeight: {
            value: 'bold',
          },
          align: {
            value: 'center',
          },
          baseline: {
            value: 'middle',
          },
        },
      },
    },
  ],
});

type Props = {
  data: {
    table: any[];
  };
  className?: string;
  title: string;
  width: number;
  formatTooltip?: (datum: object) => any;
  onClick?: (datum: object) => void;
};

const InvoiceStatusPieChart = (props: Props) => {
  const handleClick = React.useCallback(
    (e: any, item: any) => {
      e.preventDefault();
      if (_.isUndefined(item)) return;
      if (_.isFunction(props.onClick)) props.onClick(_.get(item, 'datum', {}));
    },
    [props],
  );
  const { width, data, title, className, formatTooltip = _.identity } = props;
  if (data.table.length === 0) return <EmptyStateWarning message="Aucun résultat" />;
  const spec = getSpec(data.table);
  const specWithTitle = {
    ...spec,
    signals: [...spec.signals, { name: 'title', value: title }],
  };
  return (
    <Vega
      actions={false}
      spec={specWithTitle}
      className={className}
      width={width / 2}
      height={width / 2}
      tooltip={(handler: any, event: any, item: any, value: any) =>
        new Handler({ sanitize: _.identity }).call(handler, event, item, value ? formatTooltip(value) : value)
      }
      onNewView={(view: any) => {
        view.removeEventListener('click', handleClick);
        view.addEventListener('click', handleClick);
      }}
    />
  );
};

export default withResizeDetector(InvoiceStatusPieChart);
