import * as React from 'react';
import classnames from 'classnames';

import { useStoreActions } from 'Store';
import styles from './modal.module.scss';

type ModalProps = {
  zIndex: number;
  closeOnOutsideClickDisabled?: boolean;
};

const Modal: React.FunctionComponent<ModalProps> = ({ zIndex, closeOnOutsideClickDisabled, children }) => {
  const pop = useStoreActions(actions => actions.modals.pop);
  const nodeRef = React.useRef<any>();
  const handleClick = React.useCallback(
    (e: any) => {
      if (nodeRef && nodeRef.current.contains(e.target)) return;
      if (!closeOnOutsideClickDisabled) pop();
    },
    [closeOnOutsideClickDisabled, pop],
  );
  React.useEffect(() => {
    window.addEventListener('click', handleClick, false);
    return () => {
      window.removeEventListener('click', handleClick, false);
    };
  }, [handleClick]);
  return React.useMemo(
    () => (
      <div className={classnames(styles.wrapper)} style={{ zIndex }}>
        <div ref={nodeRef} className={classnames(styles.container)}>
          {children}
        </div>
      </div>
    ),
    [children, zIndex],
  );
};

export default Modal;
