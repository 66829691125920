import * as React from 'react';

export type ViewSidebarOptionSectionItem<T = any> = {
  type: 'item';
  props?: ViewSidebarSectionItemProps;
  Component?: React.ComponentType<T>;
  componentProps?: T;
};
export type ViewSidebarSectionItemProps = {
  className?: string;
  leadingSeparator?: React.ComponentType<any>;
  withoutLeadingSeparator?: boolean;
  trailingSeparator?: React.ComponentType<any>;
  withoutTrailingSeparator?: boolean;
};

const ViewSidebarSectionItem: React.FunctionComponent<ViewSidebarSectionItemProps> = ({
  className,
  leadingSeparator,
  withoutLeadingSeparator,
  trailingSeparator,
  withoutTrailingSeparator,
  children,
}) => (
  <>
    {!withoutLeadingSeparator && leadingSeparator && React.createElement(leadingSeparator)}
    {React.createElement('div', { className }, children)}
    {!withoutTrailingSeparator && trailingSeparator && React.createElement(trailingSeparator)}
  </>
);
export default ViewSidebarSectionItem;
