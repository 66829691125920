import * as React from 'react';
import classnames from 'classnames';
import ReactSelect from 'react-select';
import Async from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';

import LoadingIndicator from 'Components/loadingIndicator';

import styles from './selectControl.module.scss';

type SelectControlProps = {
  options?: { label: React.ReactNode; value: any }[];
  multi?: boolean;
  [x: string]: any;
};

const SelectControl: React.FunctionComponent<SelectControlProps> = props => {
  const { async, isCreatable, options, isClearable, ...otherProps } = props;

  return (
    <StyledSelect
      async={async}
      isCreatable={isCreatable}
      isClearable={isClearable}
      backspaceRemovesValue={isClearable}
      {...otherProps}
      isMulti={otherProps.multi}
      options={options}
    />
  );
};

type SelectPickerProps = {
  async?: boolean;
  creatable?: boolean;
  forwardedRef?: any;
  [x: string]: any;
};

const SelectPicker: React.FunctionComponent<SelectPickerProps> = ({ async, creatable, forwardedRef, ...props }) => {
  // Pick the right component to use
  let Component: any;
  if (async && creatable) {
    Component = AsyncCreatable;
  } else if (async && !creatable) {
    Component = Async;
  } else if (creatable) {
    Component = Creatable;
  } else {
    Component = ReactSelect;
  }

  return React.createElement(Component, {
    ref: forwardedRef,
    className: 'react-select-container',
    classNamePrefix: 'react-select',
    components: {
      LoadingIndicator: () => (
        <LoadingIndicator className={classnames(styles.loading)} mini hideMessage relative={false} />
      ),
      IndicatorSeparator: () => null,
    },
    styles: {
      container: (base: any) => ({ ...base, fontSize: '15px' }),
      control: (base: any, state: any) => ({
        ...base,
        boxShadow: 0,
        borderColor: state.isFocused ? 'rgb(209, 202, 216)' : base.borderColor,
        '&:hover': {
          borderColor: state.isFocused ? 'rgb(209, 202, 216)' : base.borderColor,
        },
        borderBottomLeftRadius: state.menuIsOpen ? 0 : base.borderBottomLeftRadius,
        borderBottomRightRadius: state.menuIsOpen ? 0 : base.borderBottomRightRadius,
      }),
      menu: (base: any) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        marginLeft: '1px',
        width: '99.5%',
      }),
      menuList: (base: any) => ({ ...base, paddingTop: 0, paddingBottom: 0 }),
      input: (base: any) => ({ ...base, input: { padding: '0 0' } }),
    },
    ...props,
  });
};

const forwardRef = (props: any, ref: any) => <SelectPicker {...props} forwardedRef={ref} />;
forwardRef.displayName = 'SelectPicker';

const StyledSelect = React.forwardRef(forwardRef);

export default SelectControl;
