import _ from 'lodash';
import { replace } from 'connected-react-router';
// eslint-disable-next-line import/no-named-default
import { default as InternalApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';

import config from 'Config';
import { store } from 'src/app';

export type GraphQLClientCache = {};
export type GraphQLClient = InternalApolloClient<GraphQLClientCache>;

// eslint-disable-next-line
export const createGraphQLClient: (uri?: string) => GraphQLClient = uri => {
  const request = async (operation: any) => {
    // eslint-disable-next-line no-console
    if (config.ENV === 'development') console.log(operation);
  };
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: any;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );
  return new InternalApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (config.ENV === 'development') {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => {
              // eslint-disable-next-line no-console
              console.log('[GraphQL error][Message] ', message);
              // eslint-disable-next-line no-console
              console.log('[GraphQL error][Location] ', locations);
              // eslint-disable-next-line no-console
              console.log('[GraphQL error][Path] ', path);
            });
          // eslint-disable-next-line no-console
          if (networkError) console.log('[Network error] ', networkError);
        }
        if (graphQLErrors && _.some(_.map(graphQLErrors, e => (e.message as string).includes('Not Authenticated')))) {
          store.dispatch(replace('/logout'));
        }
      }),
      requestLink,
      createUploadLink({ uri }),
    ]),
    cache: new InMemoryCache(),
  });
};
