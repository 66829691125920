import _ from 'lodash';
import moment from 'moment';

/**
 * Given a UTC timestamp, return a system date object with the same date
 * e.g. given: system is -0700 (PST),
 * 1/1/2001 @ 22:00 UTC, return:  1/1/2001 @ 22:00 -0700 (PST)
 */
export default (dateObj?: moment.MomentInput | null) => {
  // This is required because if your system timezone !== user configured timezone
  // then there will be a mismatch of dates with `react-date-picker`
  //
  // We purposely strip the timezone when formatting from the utc timezone
  if (_.isNull(dateObj) || _.isUndefined(dateObj)) return dateObj;
  return moment.utc(dateObj);
};
