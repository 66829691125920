import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT_NO_TIMEZONE } from 'Constants/date';

/**
 * Given a timestamp, format to user preference timezone, and strip timezone to
 * return a system date object with the same date
 *
 * e.g. given: system is -0700 (PST) and user preference is -0400 (EST),
 * 1/1/2001 @ 22:00 UTC --> 1/1/2001 @ 18:00 -0400 (EST) -->
 * return:  1/1/2001 @ 18:00 -0700 (PST)
 */
export default (dateObj?: moment.MomentInput | null) => {
  // This is required because if your system timezone !== user configured timezone
  // then there will be a mismatch of dates with `react-date-picker`
  //
  // We purposely strip the timezone when formatting from the utc timezone
  if (_.isNull(dateObj) || _.isUndefined(dateObj)) return dateObj;
  return moment(new Date(moment(dateObj).format(DATE_FORMAT_NO_TIMEZONE)));
};
