/* eslint-disable no-shadow */
import * as React from 'react';
import _ from 'lodash';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { compose, withProps } from 'recompose';
import { useApolloClient } from 'react-apollo-hooks';
import SelectBase from 'react-select';
import AsyncSelectBase from 'react-select/async';

// import SmartSearchBar from "Components/smartSearchBar";
import notify from 'Utils/notify';
import useSessionStorage from 'UtilHooks/useSessionStorage';
import LoadingIndicator from 'Components/loadingIndicator';
import InputControl from 'Components/forms/inputControl';

import styles from './viewSidebarFilter.module.scss';

type InputFilter = {
  type: 'input';
  label: string;
};
type SelectFilterBase = {
  property: string;
  label?: string;
};
type AsyncSelectFilter = SelectFilterBase & {
  type: 'asyncSelect';
  restricted?: boolean;
  query: string;
  operator: 'eq' | 'ilike' | 'in';
  distinct?: boolean;
  mapProp?: (value: string, options: AsyncSelectFilter) => string;
  mapResult?: (value: string, options: AsyncSelectFilter) => string;
  mapFilter?: (value: string, options: AsyncSelectFilter) => string;
};
type SelectFilter = SelectFilterBase & {
  type: 'select';
  values?: { value: string; label?: string }[];
};
export type Filter = InputFilter | SelectFilter | AsyncSelectFilter;
export type ViewSidebarFilterProps = {
  filter: any;
  updateFilter: (newValue?: any) => void;
  errorFilter?: Error;
  options: Filter;
  placeholder?: string;
};

const ViewSidebarFilter: React.FunctionComponent<ViewSidebarFilterProps> = ({
  options,
  filter,
  updateFilter,
  placeholder,
}) => {
  const [token] = useSessionStorage('token');
  const client = useApolloClient();
  const getValueFromFilter = React.useCallback(() => (filter ? filter.value : undefined), [filter]);
  const [value, setValue] = React.useState(getValueFromFilter);
  React.useEffect(() => {
    setValue(getValueFromFilter());
    return _.noop;
  }, [getValueFromFilter]);
  const noOptionMessage = React.useCallback(
    ({ inputValue }: { inputValue: string }) => (!inputValue ? 'Saisissez votre recherche' : 'Aucun résultats.'),
    [],
  );
  const loadingMessage = React.useCallback(() => 'Chargement...', []);
  const onChange = React.useCallback(
    // eslint-disable-next-line no-shadow
    (value: any, { action }: any) => {
      switch (action) {
        case 'clear':
          updateFilter(undefined);
          break;
        case 'select-option':
          updateFilter(value.value);
          break;
        default:
          break;
      }
    },
    [updateFilter],
  );
  const loadOptions = React.useCallback(
    // eslint-disable-next-line no-shadow
    (value: any) =>
      options.type === 'asyncSelect' &&
      // eslint-disable-next-line no-async-promise-executor
      new Promise<{ value: string; label: string; fullSearch?: boolean }[]>(async resolve => {
        const { data, errors } = await client.query({
          query: gql`query ${options.query}Filter_${_.replace(
            (options.mapProp && options.mapProp(value, options)) || options.property,
            new RegExp(/\./, 'g'),
            '_',
          )}{${options.query}(filter:{${
            (options.mapFilter && options.mapFilter(value, options)) ||
            `${options.property}_${options.operator}:${JSON.stringify(value)}`
          }}){${(options.mapResult && options.mapResult(value, options)) || options.property}}}`,
          context: {
            headers: options.restricted ? { Authorization: `Bearer ${token}` } : {},
          },
          fetchPolicy: 'network-only',
        });
        if (errors) {
          notify.error("Impossible d'accéder au serveur.");
          resolve([]);
        } else if (data) {
          resolve(
            _.concat(
              [{ value, label: `Rechercher avec "${value}"` }],
              _.map(
                (options.distinct ? (arr: any) => _.uniq(arr) : _.identity)(
                  _.map(_.get(data, options.query), v =>
                    _.get(v, (options.mapProp && options.mapProp(v, options)) || options.property),
                  ),
                ),
                // eslint-disable-next-line no-shadow
                value => ({
                  value,
                  label: value,
                  fullSearch: true,
                }),
              ) as {
                value: string;
                label: string;
                fullSearch?: boolean;
              }[],
            ),
          );
        }
      }),
    [client, options, token],
  );
  const render = React.useCallback(() => {
    switch (options.type) {
      case 'input':
        return (
          <div className={classnames(styles.filter)}>
            <h6 className={classnames(styles['filter-header'])}>{options.label}</h6>
            <InputControl placeholder={placeholder || '--'} className={classnames(styles['filter-form-control'])} />
          </div>
        );
      case 'select':
        return (
          <div className={classnames(styles.filter)}>
            <h6 className={classnames(styles['filter-header'])}>{options.label || options.property}</h6>
            <Select
              isClearable
              isSearchable={false}
              blurInputOnSelect
              controlShouldRenderValue
              hideSelectedOptions
              backspaceRemovesValue
              escapeClearsValue
              value={
                value
                  ? {
                      value,
                      label: _.get(
                        _.fromPairs(_.map(options.values || [], ({ value: v, label }) => [v, label || v])),
                        value,
                        value,
                      ),
                    }
                  : undefined
              }
              onChange={onChange}
              placeholder={placeholder || '--'}
              noOptionsMessage={noOptionMessage}
              options={_.map(options.values || [], ({ value, label }) => ({
                value,
                label: label || value,
              }))}
            />
          </div>
        );
      case 'asyncSelect':
        return (
          <div className={classnames(styles.filter)}>
            <h6 className={classnames(styles['filter-header'])}>{options.label || options.property}</h6>
            <AsyncSelect
              isClearable
              blurInputOnSelect
              controlShouldRenderValue
              hideSelectedOptions
              defaultValue={value ? { value, label: value } : undefined}
              defaultInputValue={value || undefined}
              onChange={onChange}
              placeholder={placeholder || '--'}
              loadingMessage={loadingMessage}
              noOptionsMessage={noOptionMessage}
              loadOptions={loadOptions}
            />
          </div>
        );
      default:
        return null;
    }
  }, [loadOptions, loadingMessage, noOptionMessage, onChange, options, placeholder, value]);
  return render();
};

const customWithProps = ({ async }: { async?: boolean } = {}) =>
  withProps({
    components: {
      LoadingIndicator: () => <LoadingIndicator className={classnames(styles.loading)} mini hideMessage />,
      IndicatorSeparator: () => null,
    },
    styles: {
      container: (base: any) => ({ ...base, fontSize: '15px' }),
      control: (base: any, state: any) => ({
        ...base,
        boxShadow: 0,
        borderColor: state.isFocused ? 'rgb(209, 202, 216)' : base.borderColor,
        '&:hover': {
          borderColor: state.isFocused ? 'rgb(209, 202, 216)' : base.borderColor,
        },
        borderBottomLeftRadius: state.menuIsOpen ? 0 : base.borderBottomLeftRadius,
        borderBottomRightRadius: state.menuIsOpen ? 0 : base.borderBottomRightRadius,
      }),
      menu: (base: any) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        marginLeft: '1px',
        width: '99.5%',
      }),
      menuList: (base: any) => ({ ...base, paddingTop: 0, paddingBottom: 0 }),
      input: (base: any) => ({ ...base, input: { padding: '0 0' } }),
      option: (base: any, state: any) => {
        if (!async) {
          return {
            ...base,
            backgroundColor: state.isSelected ? '#2684FF' : 'initial',
            '&:hover': {
              backgroundColor: state.isSelected ? '#2684FF' : '#DEEBFF',
            },
            cursor: 'pointer',
          };
        }
        return base;
      },
    },
  });
const Select = compose<any, any>(customWithProps())(SelectBase);
const AsyncSelect = compose<any, any>(customWithProps({ async: true }))(AsyncSelectBase);

export default ViewSidebarFilter;
