import * as React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Panel, PanelBody, PanelHeader } from 'Components/panels';
import Button from 'Components/button';
import SelectControl from '../field/selectControl';

import Field from '../field/field';
import InputControl from '../field/inputControl';

const TYPE_MODE_PAIEMENTS: string[] = ['ESPECE', 'CHEQUE', 'VIREMENT', 'PRELEVEMENT', 'REPORT'];
const TYPE_REFERENCE_BANCAIRES: string[] = ['IBAN', 'RIB'];

const schema = Yup.object().shape({
  code: Yup.string().required('Obligatoire'),
  modePaiement: Yup.string().required('Obligatoire').oneOf(TYPE_MODE_PAIEMENTS),
  typeReferenceBancaire: Yup.string().required('Obligatoire').oneOf(TYPE_REFERENCE_BANCAIRES),
  referenceBancaire: Yup.string().required('Obligatoire'),
  referenceEtablissementBancaire: Yup.string(),
});

const SettingsPaymentMeanForm = (props: any) => {
  const onSubmit = async (values: any, actions: any) => {
    if (!schema.isValid(values)) {
      return;
    }
    props.onSubmit(values, actions);
  };

  // eslint-disable-next-line react/destructuring-assignment
  const paymentMeanForm = props.data;

  return (
    <Formik initialValues={paymentMeanForm} validationSchema={schema} onSubmit={onSubmit}>
      {({ errors, touched }) => (
        <Form>
          <Panel>
            <PanelHeader>Moyen de paiement</PanelHeader>
            <PanelBody disablePadding>
              <Field
                label="Code moyen de paiement"
                help="Un code interne identifiant de manière unique ce moyen de paiement utilisé dans vos facture"
                required
                inline
              >
                <InputControl type="text" name="code" errors={errors} touched={touched} />
              </Field>
              <Field label="Mode de paiement" help="Le mode de paiement associé" required inline>
                <SelectControl
                  name="modePaiement"
                  options={TYPE_MODE_PAIEMENTS.map(v => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Field>
              <Field label="Référence bancaire" help="Pour les virements, précisez l'IBAN ou le RIB" required inline>
                <SelectControl
                  name="typeReferenceBancaire"
                  options={TYPE_REFERENCE_BANCAIRES.map(v => ({
                    value: v,
                    label: v,
                  }))}
                />
                <InputControl type="text" name="referenceBancaire" errors={errors} touched={touched} />
              </Field>
              <Field
                label="Référence de l'établissement bancaire"
                help="La référence de votre agence bancaire. Par exemple le code BIC"
                inline
              >
                <InputControl type="text" name="referenceEtablissementBancaire" errors={errors} touched={touched} />
              </Field>
            </PanelBody>
          </Panel>
          <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'flex-end' }}>
            <Button size="small" priority="default" style={{ marginRight: '8px' }} to="/settings/payment-means">
              Annuler
            </Button>
            <Button type="submit" size="small" priority="primary">
              {props.submitLabel}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SettingsPaymentMeanForm;
