import * as React from 'react';
import classNames from 'classnames';

import styles from './page.module.scss';

interface PageContentProps {
  children: React.ReactNode;
  className?: string;
  style?: { [x: string]: any };
}

const PageContent = (props: PageContentProps) => {
  const { children, className, style } = props;
  return (
    <div className={classNames(className, styles.pageContent)} style={style}>
      {children}
    </div>
  );
};

export default PageContent;
