import * as React from 'react';
import _ from 'lodash';

import callIfFunction from 'Utils/callIfFunction';
import DropdownMenu from 'Components/dropdownMenu';

type AutoCompleteProps = {
  /**
   * Must be a function that returns a component
   */
  children: (props: any) => React.ReactNode;
  itemToString: (item: any) => string;
  defaultHighlightedIndex?: number;
  defaultInputValue?: string;
  disabled?: boolean;
  /**
   * Resets autocomplete input when menu closes
   */
  resetInputOnClose?: boolean;
  /**
   * Currently, this does not act as a "controlled" prop, only for initial state of dropdown
   */
  isOpen?: boolean;
  /**
   * If input should be considered an "actor". If there is another parent actor, then this should be `false`.
   * e.g. You have a button that opens this <AutoComplete> in a dropdown.
   */
  inputIsActor?: boolean;

  /**
   * Can select autocomplete item with "Enter" key
   */
  shouldSelectWithEnter?: boolean;

  /**
   * Can select autocomplete item with "Tab" key
   */
  shouldSelectWithTab?: boolean;

  onSelect?: (...args: any[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onMenuOpen?: () => void;
  closeOnSelect?: boolean;
};

const AutoComplete: React.FunctionComponent<AutoCompleteProps> = props => {
  const getIsOpenFromProps = React.useCallback(() => !!props.isOpen, [props.isOpen]);
  const [isOpen, setIsOpen] = React.useState(getIsOpenFromProps);
  React.useEffect(() => {
    setIsOpen(getIsOpenFromProps());
    return _.noop;
  }, [getIsOpenFromProps]);
  const getHighlightedIndexFromProps = React.useCallback(() => props.defaultHighlightedIndex || 0, [
    props.defaultHighlightedIndex,
  ]);
  const [highlightedIndex, setHighlightedIndex] = React.useState(getHighlightedIndexFromProps);
  React.useEffect(() => {
    setHighlightedIndex(getHighlightedIndexFromProps());
    return _.noop;
  }, [getHighlightedIndexFromProps]);
  const getInputValueFromProps = React.useCallback(() => props.defaultInputValue || '', [props.defaultInputValue]);
  const [inputValue, setInputValue] = React.useState(getInputValueFromProps);
  React.useEffect(() => {
    setInputValue(getInputValueFromProps());
    return _.noop;
  }, [getInputValueFromProps]);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const prevSelectedItem = React.useRef<any>();
  React.useEffect(() => {
    prevSelectedItem.current = selectedItem;
    return _.noop;
  });
  const itemsRef = React.useRef<Map<any, any>>();
  React.useEffect(() => {
    const items = new Map();
    itemsRef.current = items;
    return () => {
      if (!_.isUndefined(itemsRef.current)) itemsRef.current.clear();
    };
  });
  const blurTimerRef = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    return () => {
      if (!_.isUndefined(blurTimerRef.current)) clearTimeout(blurTimerRef.current);
    };
  });
  const itemCountRef = React.useRef<number>();

  /**
   * Resets `items` and `highlightedIndex`.
   * Should be called whenever `inputValue` changes.
   */
  const resetHighlightState = () => {
    // reset items and expect `getInputProps` in child to give us a list of new items
    setHighlightedIndex(getHighlightedIndexFromProps());
  };
  const isControlled = () => !_.isUndefined(props.isOpen);
  const getOpenState = () => (isControlled() ? props.isOpen : isOpen);
  /**
   * Close dropdown menu
   *
   * This is exposed to render function
   */
  const closeMenu = (...args: any[]) => {
    const { onClose, resetInputOnClose } = props;
    callIfFunction(onClose, ...args);
    if (isControlled()) return;
    setIsOpen(false);
    setInputValue(resetInputOnClose ? '' : inputValue);
  };
  /**
   * When an item is selected via clicking or using the keyboard (e.g. pressing "Enter")
   */
  const handleSelect = (item: any, e: any) => {
    const { onSelect, itemToString, closeOnSelect } = props;
    callIfFunction(
      onSelect,
      item,
      {
        isOpen,
        highlightedIndex,
        inputValue,
        selectedItem,
      },
      e,
    );
    setSelectedItem(item);
    if (closeOnSelect) {
      closeMenu();
      setInputValue(itemToString(item));
    }
  };
  /**
   * Open dropdown menu
   *
   * This is exposed to render function
   */
  const openMenu = (...args: any[]) => {
    const { onOpen, disabled } = props;
    callIfFunction(onOpen, ...args);
    if (disabled || isControlled()) return;
    resetHighlightState();
    setIsOpen(true);
  };
  const handleInputChange = ({ onChange }: { onChange?: (e: any) => void } = {}, e: any) => {
    const { value } = e.target;
    // We force `isOpen: true` here because:
    // 1) it's possible to have menu closed but input with focus (i.e. hitting "Esc")
    // 2) you select an item, input still has focus, and then change input
    openMenu();
    setInputValue(value);
    callIfFunction(onChange, e);
  };
  const handleInputFocus = ({ onFocus }: { onFocus?: (e: any) => void } = {}, e: any) => {
    openMenu();
    callIfFunction(onFocus, e);
  };
  /**
   *
   * We need this delay because we want to close the menu when input
   * is blurred (i.e. clicking or via keyboard). However we have to handle the
   * case when we want to click on the dropdown and causes focus.
   *
   * Clicks outside should close the dropdown immediately via <DropdownMenu />,
   * however blur via keyboard will have a 200ms delay
   */
  const handleInputBlur = ({ onBlur }: { onBlur?: (e: any) => void } = {}, e: any) => {
    blurTimerRef.current = setTimeout(() => {
      closeMenu();
      callIfFunction(onBlur, e);
    }, 200);
  };
  // Dropdown detected click outside, we should close
  const handleClickOutside = () => {
    // Otherwise, it's possible that this gets fired multiple times
    // e.g. click outside triggers closeMenu and at the same time input gets blurred, so
    // a timer is set to close the menu
    if (!_.isUndefined(blurTimerRef.current)) clearTimeout(blurTimerRef.current);
    closeMenu();
  };
  const moveHighlightedIndex = (step: number) => {
    let newIndex = highlightedIndex + step;
    // when this component is in virtualized mode, only a subset of items will be passed
    // down, making the array length inaccurate. instead we manually pass the length as itemCount
    const listSize =
      (!_.isUndefined(itemCountRef.current) && itemCountRef.current) ||
      (!_.isUndefined(itemsRef.current) && itemsRef.current.size) ||
      0;
    // Make sure new index is within bounds
    newIndex = Math.max(0, Math.min(newIndex, listSize - 1));
    setHighlightedIndex(newIndex);
  };
  const handleInputKeyDown = ({ onKeyDown }: { onKeyDown?: (e: any) => void } = {}, e: any) => {
    const hasHighlightedItem =
      !_.isUndefined(itemsRef.current) && itemsRef.current.size > 0 && itemsRef.current.has(highlightedIndex);
    const canSelectWithEnter = props.shouldSelectWithEnter && e.key === 'Enter';
    const canSelectWithTab = props.shouldSelectWithTab && e.key === 'Tab';
    if (!_.isUndefined(itemsRef.current) && hasHighlightedItem && (canSelectWithEnter || canSelectWithTab)) {
      handleSelect(itemsRef.current.get(highlightedIndex), e);
      e.preventDefault();
    }
    if (e.key === 'ArrowUp') {
      moveHighlightedIndex(-1);
      e.preventDefault();
    }
    if (e.key === 'ArrowDown') {
      moveHighlightedIndex(1);
      e.preventDefault();
    }
    if (e.key === 'Escape') closeMenu();
    callIfFunction(onKeyDown);
  };
  const handleItemClick = (
    {
      onClick,
      item,
      index,
    }: {
      onClick?: (item: any, e: any) => void;
      item?: any;
      index?: number;
    } = {},
    e: any,
  ) => {
    if (!_.isUndefined(blurTimerRef.current)) clearTimeout(blurTimerRef.current);
    setHighlightedIndex(index || props.defaultHighlightedIndex || 0);
    handleSelect(item, e);
    callIfFunction(onClick, item, e);
  };
  const handleMenuMouseDown = () => {
    // Cancel close menu from input blur (mouseDown event can occur before input blur :()
    if (!_.isUndefined(blurTimerRef.current)) clearTimeout(blurTimerRef.current);
  };
  const getInputProps = (inputProps: object) => ({
    ...inputProps,
    value: inputValue,
    onChange: _.partial(handleInputChange, inputProps),
    onKeyDown: _.partial(handleInputKeyDown, inputProps),
    onFocus: _.partial(handleInputFocus, inputProps),
    onBlur: _.partial(handleInputBlur, inputProps),
  });
  const getItemProps = ({ item, index, ...innerProps }: { item?: any; index?: number; [x: string]: any } = {}) => {
    if (_.isUndefined(item) || _.isNull(item)) {
      // eslint-disable-next-line no-console
      console.warn('getItemProps requires an object with an `item` key');
    }
    const newIndex = index || _.get(itemsRef, 'current.size');
    if (!_.isUndefined(itemsRef.current)) itemsRef.current.set(newIndex, item);
    return {
      ...innerProps,
      onClick: _.partial(handleItemClick, {
        item,
        index: newIndex,
        ...innerProps,
      }),
    };
  };
  const getMenuProps = (menuProps: { itemCount?: number; [x: string]: any }) => {
    itemCountRef.current = menuProps.itemCount;
    return {
      ...menuProps,
      onMouseDown: _.partial(handleMenuMouseDown, menuProps),
    };
  };
  const render = () => {
    const { children, onMenuOpen } = props;
    const isMenuOpen = getOpenState();
    return (
      <DropdownMenu isOpen={isMenuOpen} onClickOutside={handleClickOutside} onOpen={onMenuOpen}>
        {({ getMenuProps: _1, ...dropdownMenuProps }: any) =>
          children({
            ...dropdownMenuProps,
            inputValue,
            selectedItem,
            highlightedIndex,
            getItemProps,
            getMenuProps: (innerProps: any) => _1(getMenuProps(innerProps)),
            getInputProps: (innerProps: any) => {
              const inputProps = getInputProps(innerProps);
              if (!props.inputIsActor) return inputProps;
              return dropdownMenuProps.getActorProps(inputProps);
            },
            actions: {
              open: openMenu,
              close: closeMenu,
            },
          })
        }
      </DropdownMenu>
    );
  };
  return render();
};
AutoComplete.defaultProps = {
  itemToString: _.identity,
  inputIsActor: true,
  disabled: false,
  closeOnSelect: true,
  shouldSelectWithEnter: true,
  shouldSelectWithTab: false,
};
export default AutoComplete;
