import { createBrowserHistory, History } from 'history';
import { routerMiddleware, connectRouter, RouterState } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension'; // eslint-disable-line
import { createLogger } from 'redux-logger';
import { createStore, Reducer, reducer } from 'easy-peasy';
import { once } from 'lodash';

import config from 'Config';
import tagsModel, { TagsModel } from './tags.model';
import searchListModel, { SearchListModel } from './searchList.model';
import queryModel, { QueryModel } from './query.model';
import modalsModel, { ModalsModel } from './modals.model';
import loadersModel, { LoadersModel } from './loaders.model';
import layoutModel, { LayoutModel } from './layout.model';
import viewModel, { ViewModel } from './view.model';
import { useStoreState, useStoreActions, useStoreDispatch } from '../app';

export { useStoreState, useStoreActions, useStoreDispatch };

export const history = once(createBrowserHistory)();
export const logger = once(createLogger)();

export type RootStoreModel = {
  router: Reducer<RouterState>;
  tags: TagsModel;
  searchList: SearchListModel;
  query: QueryModel;
  modals: ModalsModel;
  loaders: LoadersModel;
  layout: LayoutModel;
  view: ViewModel;
};

// eslint-disable-next-line
export const storeModel: RootStoreModel = once((history: History) => ({
  router: reducer<RouterState>(connectRouter(history) as any),
  tags: tagsModel,
  searchList: searchListModel,
  query: queryModel,
  modals: modalsModel,
  loaders: loadersModel,
  layout: layoutModel,
  view: viewModel,
}))(history);

export default function configureStore(preloadedState = {}) {
  const options: any = {
    compose: composeWithDevTools({}),
    initialState: preloadedState,
    middleware: [routerMiddleware(history)],
  };
  if (config.ENV === 'development') options.middleware.push(logger);

  const store = createStore(storeModel, options);

  return store;
}
