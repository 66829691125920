import * as React from 'react';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo-hooks';
import { push } from 'connected-react-router';
// @ts-ignore
import omitDeep from 'omit-deep-lodash';
import _ from 'lodash';

import { useStoreState, useStoreDispatch } from 'Store';
import useSessionStorage from 'Utils/hooks/useSessionStorage';
import notify from 'Utils/notify';

import PageHeading from 'Components/pageHeading';
import PageContent from 'Components/pageContent';
import LoadingIndicator from 'Components/loadingIndicator';

import ClientForm from './clientForm';
import styles from './clientEditView.module.scss';

const ClientEditView = () => {
  const pathname = useStoreState(state => state.router.location.pathname);
  const [id, setId] = React.useState<string>();
  React.useEffect(() => {
    const match: any = matchPath(pathname, {
      path: '/clients/:id/edit',
      exact: true,
    });
    setId(match ? match.params.id : undefined);
    return _.noop;
  }, [pathname]);
  return React.useMemo(() => (!_.isUndefined(id) ? <ClientEditViewInner id={id} /> : null), [id]);
};

const ClientEditViewInner: React.FC<{ id: string }> = props => {
  const idRef = React.useRef<string>(props.id);
  React.useEffect(() => {
    idRef.current = props.id;
    return _.noop;
  }, [props.id]);
  const id = React.useMemo(() => idRef.current, []);
  const [token] = useSessionStorage('token');
  const dispatch = useStoreDispatch();
  const { data, loading } = useQuery(
    gql(`query clientById($id: ID!) {
      clientById(id: $id) {
        code, typeIdentification, identification, nom
        codeService, nomService, chorusDisabled,
        adresse { rue, codePostal, ville, pays }
        interceptionStatus, interceptionFilters { value, enabled }
        contact { nom, prenom, courriel }
      }
    }`),
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    },
  );
  const updateClientMutation = useMutation<any>(
    gql`
      mutation updateClient($id: ID!, $client: ClientInput!) {
        updateClient(id: $id, input: $client) {
          id
        }
      }
    `,
    {
      context: { headers: { Authorization: `Bearer ${token}` } },
    },
  );
  const onSubmit = React.useCallback(
    async (values: any, actions: any) => {
      const updateValues = omitDeep(values, '__typename');
      try {
        const { error }: any = await updateClientMutation({
          variables: { id, client: updateValues },
        });

        if (error) {
          notify(error.message);
        } else {
          notify('Client modifié avec succés.');

          dispatch(push('/clients'));
        }
        actions.setSubmitting(false);
      } catch (e) {
        notify('error calling apollo');
        actions.setSubmitting(false);
      }
    },
    [dispatch, id, updateClientMutation],
  );
  const formData = React.useMemo(() => data.clientById, [data.clientById]);
  const renderLoading = React.useCallback(() => <LoadingIndicator />, []);
  const render = React.useCallback(
    () => (
      <PageContent style={{ overflow: 'auto' }}>
        <PageHeading withMargins>{`Edition Client ${formData.nom}`}</PageHeading>
        <div className={classnames(styles['form-wrapper'])}>
          <ClientForm data={formData} submitLabel="Enregistrer" onSubmit={onSubmit} />
        </div>
      </PageContent>
    ),
    [formData, onSubmit],
  );
  return React.useMemo(() => {
    if (loading) return renderLoading();
    return render();
  }, [loading, render, renderLoading]);
};

export default ClientEditView;
