import * as React from 'react';
import classNames from 'classnames';

import styles from './page.module.scss';

interface PageHeadingProps {
  children: React.ReactNode;
  className?: string;
  withMargins?: boolean;
}

const PageHeading = (props: PageHeadingProps) => {
  const { children, className, withMargins } = props;
  return (
    <h1
      className={classNames(className, styles.pageHeading, {
        [styles.withMargins]: withMargins,
      })}
    >
      {children}
    </h1>
  );
};

export default PageHeading;
