import * as React from 'react';
import classnames from 'classnames';
import { findDOMNode } from 'react-dom';
import { Overlay as BaseOverlay } from 'react-overlays';

import Fade from 'Components/fade';

type OverlayProps = {
  /**
   * A component instance, DOM node, or function that returns either.
   * The `container` element will have the Overlay appended to it via a React portal.
   */
  container?: React.ComponentType<any>;
  /**
   * A component instance, DOM node, or function that returns either.
   * The children will be positioned in relation to the `target`
   */
  target?: React.ComponentType<any>;
  /**
   * Set the visibility of the Overlay
   */
  show?: boolean;
  /**
   * A set of popper options and props passed directly to react-popper's Popper component.
   */
  popperConfig?: object;
  /**
   * Specify whether the children should trigger onHide when the user clicks outside the children
   */
  rootClose?: boolean;
  /**
   * Specify event for triggering a "root close" toggle.
   */
  rootCloseEvent?: 'click' | 'mousedown';
  /**
   * A callback invoked by the children when it wishes to be hidden. Required if
   * `rootClose` is specified.
   */
  onHide?: () => void;
  /**
   * Animate the entering and exiting of the Ovelay. `true` will use the `<Fade>` transition,
   * or a custom react-transition-group `<Transition>` component can be provided.
   */
  transition?: boolean;
  /**
   * Callback fired before the Overlay transitions in
   */
  onEnter?: () => void;
  /**
   * Callback fired as the Overlay begins to transition in
   */
  onEntering?: () => void;
  /**
   * Callback fired after the Overlay finishes transitioning in
   */
  onEntered?: () => void;
  /**
   * Callback fired right before the Overlay transitions out
   */
  onExit?: () => void;
  /**
   * Callback fired as the Overlay begins to transition out
   */
  onExiting?: () => void;
  /**
   * Callback fired after the Overlay finishes transitioning out
   */
  onExited?: () => void;
  /**
   * The placement of the Overlay in relation to it's `target`.
   */
  placement?:
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'bottom-start'
    | 'left-end'
    | 'left'
    | 'left-start';
  anyModalShown?: boolean;
};
const defaultProps: Partial<OverlayProps> = {
  transition: true,
  rootClose: false,
  show: false,
  placement: 'top',
  anyModalShown: false,
};

const Overlay: React.FunctionComponent<OverlayProps> = ({ children, transition, anyModalShown, ...outerProps }) => {
  const wrapRefs = React.useCallback((props: any, arrowProps: any) => {
    const { ref } = props;
    const { ref: aRef } = arrowProps;

    props.ref = ref.__wrapped || (ref.__wrapped = (r: any) => ref(findDOMNode(r))); // eslint-disable-line
    arrowProps.ref = aRef.__wrapped || (aRef.__wrapped = (r: any) => aRef(findDOMNode(r))); // eslint-disable-line
  }, []);
  const render = React.useCallback(() => {
    if (anyModalShown) return null;
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <BaseOverlay {...(outerProps as any)} transition={transition ? Fade : undefined}>
        {({ props: overlayProps, arrowProps, show, ...props }) => {
          wrapRefs(overlayProps, arrowProps);
          if (typeof children === 'function') {
            return children({
              ...props,
              ...overlayProps,
              show,
              arrowProps,
            });
          }
          return React.cloneElement(children as any, {
            ...props,
            ...overlayProps,
            arrowProps,
            className: classnames((children as any).props.className, !transition && show && 'show'),
            style: { ...(children as any).props.style, ...overlayProps.style },
          });
        }}
      </BaseOverlay>
    );
  }, [anyModalShown, children, outerProps, transition, wrapRefs]);
  return render();
};
Overlay.defaultProps = defaultProps;
export default Overlay;
